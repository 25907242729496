import styled from "styled-components";

export const HeaderStyled = styled.div`
  #navbarCollapse {
    display: block !important;
    align-items: center;
    width: 100%;
    height: 72px;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.08);
    background-color: #fff;
    /* background-color: #e2e2e2; */
    /* background-color: #e2e2e2;  1db954*/
  }
  #headerWrapper {
    height: 72px;
    background-color: #fff;
    position: fixed;
    width: 100%;
    z-index: 99999;
  }
  .header-logo-main0sec {
    cursor: pointer;
  }
  .mainLogoImage {
    max-width: 75px;
  }
  .logo-main-item {
    color: #0061f6;
    /* font-family: Roboto; */
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
  }
  #navbarCollapse ul {
    flex-direction: row;
    /* margin-right: auto !important;
      margin-left: auto !important;
      display: flex;
      flex-direction: column;
      padding-left: 0;
      margin-bottom: 0;
      list-style: none; */
  }
  .allItemsSection {
    display: flex;
    justify-content: space-between;
    padding: 20px 62px;
  }
  .row-item-secion {
    select,
    select:hover,
    select:focus-visible,
    select:active {
      color: #000;
      /* font-family: Lexend; */
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.27px;
      border: none;
    }
  }
  .row-item-secion-select,
  .icons-sec-box {
  }
  .icons-sec-box {
    gap: 24px;
    display: flex;
  }
  .search-sec-box {
    border: 1px solid #e2e2e2;
    padding: 10px 10px;
    display: flex;
    width: 45%;
    border-radius: 4px;
    border: 1px solid #6a6a6a;

    img {
      position: relative;
      //   top: 9px;
      margin-right: 4px;
    }
    input {
      border: none;
      color: #b0b0b0;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
      letter-spacing: 0.14px;
      width: 100%;
      height: 24px;
    }
  }
  .sub-header-sec {
    padding-top: 85px;
    width: 100%;
    height: 48px;
    background: #fff;
  }
  .sub-header-sec-content {
    background: #fff;
    display: flex;
    justify-content: end;
    padding: 0px 30px;
    padding-top: 12px;
    height: 48px;
    span {
      color: #000;
      font-size: 15px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.225px;
    }
    .vertical-line {
      background: #6a6a6a;
      width: 1px;
      height: 20px;
      margin: 0px 30px;
      display: block;
    }
    .sub-heaser-doller {
      color: #3b8f46;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .profile-logo {
    div {
      font-family: Poppins;
      font-size: 18px;
      font-weight: 600;
      line-height: 30px;
      text-align: center;
    }
  }

  .search_sec .ant-input-wrapper,
  .search_sec .ant-input-affix-wrapper,
  .search_sec .ant-btn {
    height: 40px;
  }
  .search_sec .ant-space-compact {
    width: 400px;
  }
  .search_sec .ant-input-group-wrapper .ant-input-affix-wrapper {
    border-start-start-radius: 40px;
    border-bottom-left-radius: 40px;
  }
  .search_sec .ant-input {
    color: var(--www-target-com-dove-gray, #666);
    // font-family: "Helvetica Neue";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 137.5% */
  }
  .search_sec .ant-btn {
    border-radius: 0 40px 40px 0 !important;
    width: 40px;
    border-left: 0px;
  }
  .search_sec .ant-btn:hover {
    border-color: #d9d9d9 !important; /* Remove border color */
  }
  .search_sec .ant-input-affix-wrapper {
    border-color: #d9d9d9 !important; /* Remove border color */
  }
  .search_sec .ant-input-group-addon {
    background: transparent;
  }
  .header_offer_btn {
    display: inline-block;
    margin-left: 20px;
    width: fit-content;
    cursor: pointer;
    img {
      margin-left: 5px;
    }
  }
  .ss_deal_logo {
    cursor: pointer;
    max-width: 230px;
    max-height: 40px;
  }
  .ant-dropdown-menu-item {
    a {
      color: var(--secondary-colour-300-main, #0b0b0b) !important;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }

  // Mobile

  @media screen and (max-width: 767px) {
    .mobl_home_menu {
      svg {
        width: 24px;
        height: 24px;
      }
    }
    .search_sec {
      display: flex;
      align-items: center;
    }
  }
`;
