import React, { Component, useEffect, useState } from "react";
import { FooterStyled } from "./Footer.styled";
// import 'bootstrap/dist/css/bootstrap.min.css';

import SDIcon from "../../images/SunsetdealsIcon.svg";
import downArraow from "../../images/downArrow.svg";
import upArrow from "../../images/upArrow.svg";

import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { checkIsMobile } from "../../Utills/Utill";
const Footer = (props) => {
  const { categoriesList } = useSelector(
    // @ts-ignore
    (reduxState) => reduxState.app
  );
  const { themeDetails, selectedBrands } = useSelector((state) => state?.admin);
  const [showAbout, setShowAbout] = useState(true);
  const [showBrands, setShowBrands] = useState(true);
  const [showCates, setShowCates] = useState(true);
  useEffect(() => {}, []);
  const history = useHistory();

  return (
    <>
      <FooterStyled>
        <div
          className="extension-footer-container"
          style={{
            background: themeDetails?.footerBG
              ? themeDetails?.footerBG
              : "#E6E6E6",
          }}
        >
          <div className="extension-footer-top">
            <div className="row w-100">
              <div
                className={`${checkIsMobile() ? `col-12` : `col-2`} image_div`}
              >
                <img
                  src={themeDetails?.logo}
                  alt="icon"
                  className="footer_ss_deal_logo"
                />
                <p
                  className="add_text"
                  style={{
                    color: themeDetails?.footerTextColor
                      ? themeDetails?.footerTextColor
                      : `#000`,
                  }}
                >
                  641 Lexington Avenue, 15th Floor New York, NY
                  10022hello@theredclub.com (+1) 858 429 9159
                </p>
              </div>
              <div
                className={`${checkIsMobile() ? `col-12` : `col-2`} page_col`}
              >
                <p className="heading">
                  <span
                    style={{
                      color: themeDetails?.footerTextColor
                        ? themeDetails?.footerTextColor
                        : `#000`,
                    }}
                  >
                    About Sunset Deals
                  </span>
                  {checkIsMobile() && (
                    <span>
                      {showAbout ? (
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={() => setShowAbout(!showAbout)}
                        >
                          <g clip-path="url(#clip0_3800_108976)">
                            <path
                              d="M9.9994 9.02344L5.8744 13.1484L4.69607 11.9701L9.9994 6.66677L15.3027 11.9701L14.1244 13.1484L9.9994 9.02344Z"
                              fill={
                                themeDetails?.footerTextColor
                                  ? themeDetails?.footerTextColor
                                  : "#222222"
                              }
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_3800_108976">
                              <rect
                                width="20"
                                height="20"
                                fill="white"
                                transform="matrix(-1 0 0 -1 20 20)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      ) : (
                        <svg
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={() => setShowAbout(!showAbout)}
                        >
                          <g id="Group">
                            <path
                              id="Vector"
                              d="M10.0006 11.7969L14.1256 7.67188L15.3039 8.85021L10.0006 14.1535L4.69727 8.85021L5.8756 7.67188L10.0006 11.7969Z"
                              fill={
                                themeDetails?.footerTextColor
                                  ? themeDetails?.footerTextColor
                                  : "#222222"
                              }
                            />
                          </g>
                        </svg>
                      )}
                    </span>
                  )}
                </p>
                {showAbout && (
                  <p
                    className="TandC"
                    onClick={() => history?.push(`/aboutus`)}
                    style={{
                      color: themeDetails?.footerTextColor
                        ? themeDetails?.footerTextColor
                        : `#000`,
                    }}
                  >
                    About us
                  </p>
                )}
                {showAbout && (
                  <p
                    className="TandC"
                    onClick={() => history.push(`/contactus`)}
                    style={{
                      color: themeDetails?.footerTextColor
                        ? themeDetails?.footerTextColor
                        : `#000`,
                    }}
                  >
                    Contact Us
                  </p>
                )}
                {showAbout && (
                  <p
                    className="TandC"
                    onClick={() => history?.push(`/policy`)}
                    style={{
                      color: themeDetails?.footerTextColor
                        ? themeDetails?.footerTextColor
                        : `#000`,
                    }}
                  >
                    Your Privacy Rights
                  </p>
                )}
                {showAbout && (
                  <p
                    className="TandC"
                    onClick={() => history?.push("/termscondition")}
                    style={{
                      color: themeDetails?.footerTextColor
                        ? themeDetails?.footerTextColor
                        : `#000`,
                    }}
                  >
                    Terms of Use
                  </p>
                )}
                {showAbout && (
                  <p
                    className="TandC"
                    onClick={() => history?.push(`/cookiesPolicy`)}
                    style={{
                      color: themeDetails?.footerTextColor
                        ? themeDetails?.footerTextColor
                        : `#000`,
                    }}
                  >
                    Cookie Policy
                  </p>
                )}
              </div>

              <div
                className={`${checkIsMobile() ? `col-12` : `col-2`} page_col`}
              >
                <p className="heading">
                  <span
                    style={{
                      color: themeDetails?.footerTextColor
                        ? themeDetails?.footerTextColor
                        : `#000`,
                    }}
                  >
                    Top Brands
                  </span>
                  {checkIsMobile() && (
                    <span>
                      {showBrands ? (
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={() => setShowBrands(!showBrands)}
                        >
                          <g clip-path="url(#clip0_3800_108976)">
                            <path
                              d="M9.9994 9.02344L5.8744 13.1484L4.69607 11.9701L9.9994 6.66677L15.3027 11.9701L14.1244 13.1484L9.9994 9.02344Z"
                              fill={
                                themeDetails?.footerTextColor
                                  ? themeDetails?.footerTextColor
                                  : "#222222"
                              }
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_3800_108976">
                              <rect
                                width="20"
                                height="20"
                                fill="white"
                                transform="matrix(-1 0 0 -1 20 20)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      ) : (
                        <svg
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={() => setShowBrands(!showBrands)}
                        >
                          <g id="Group">
                            <path
                              id="Vector"
                              d="M10.0006 11.7969L14.1256 7.67188L15.3039 8.85021L10.0006 14.1535L4.69727 8.85021L5.8756 7.67188L10.0006 11.7969Z"
                              fill={
                                themeDetails?.footerTextColor
                                  ? themeDetails?.footerTextColor
                                  : "#222222"
                              }
                            />
                          </g>
                        </svg>
                      )}
                    </span>
                  )}
                </p>
                {showBrands &&
                  selectedBrands?.slice(0, 5)?.map((item) => (
                    <p
                      className="TandC"
                      onClick={() => {
                        history?.push(`/products?byBrand=${item?.mid}`);
                        window.scroll({ top: 100, behavior: "smooth" });
                      }}
                      style={{
                        color: themeDetails?.footerTextColor
                          ? themeDetails?.footerTextColor
                          : `#000`,
                      }}
                    >
                      {item?.merchantInfo?.name}
                    </p>
                  ))}
              </div>
              <div
                className={`${
                  checkIsMobile() ? `col-12` : `col-2`
                } contact_col`}
              >
                <p className="heading">
                  <span
                    style={{
                      color: themeDetails?.footerTextColor
                        ? themeDetails?.footerTextColor
                        : `#000`,
                    }}
                  >
                    Top Categories
                  </span>
                  {checkIsMobile() && (
                    <span>
                      {showCates ? (
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={() => setShowCates(!showCates)}
                        >
                          <g clip-path="url(#clip0_3800_108976)">
                            <path
                              d="M9.9994 9.02344L5.8744 13.1484L4.69607 11.9701L9.9994 6.66677L15.3027 11.9701L14.1244 13.1484L9.9994 9.02344Z"
                              fill={
                                themeDetails?.footerTextColor
                                  ? themeDetails?.footerTextColor
                                  : "#222222"
                              }
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_3800_108976">
                              <rect
                                width="20"
                                height="20"
                                fill="white"
                                transform="matrix(-1 0 0 -1 20 20)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      ) : (
                        <svg
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={() => setShowCates(!showCates)}
                        >
                          <g id="Group">
                            <path
                              id="Vector"
                              d="M10.0006 11.7969L14.1256 7.67188L15.3039 8.85021L10.0006 14.1535L4.69727 8.85021L5.8756 7.67188L10.0006 11.7969Z"
                              fill={
                                themeDetails?.footerTextColor
                                  ? themeDetails?.footerTextColor
                                  : "#222222"
                              }
                            />
                          </g>
                        </svg>
                      )}
                    </span>
                  )}
                </p>
                {showCates &&
                  categoriesList?.slice(0, 5)?.map((item) => (
                    <p
                      className="TandC"
                      onClick={() => {
                        history.push(`/products?byCate=${item?.id}`);
                        window.scroll({ top: 100, behavior: "smooth" });
                      }}
                      style={{
                        color: themeDetails?.footerTextColor
                          ? themeDetails?.footerTextColor
                          : `#000`,
                      }}
                    >
                      {item?.name}
                    </p>
                  ))}
              </div>
              <div className={`${checkIsMobile() ? `col-12 mb-3` : `col-2`}`}>
                <div className="footer-top-right">
                  <div
                    className="heading mb-3"
                    style={{
                      color: themeDetails?.footerTextColor
                        ? themeDetails?.footerTextColor
                        : `#000`,
                    }}
                  >
                    Follow us on
                  </div>
                  <div className="icon_section">
                    {/* <img src={FbIcon} alt="new" /> */}
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_3873_22935)">
                        <path
                          d="M15.402 21V14.034H17.735L18.084 11.326H15.402V9.598C15.402 8.814 15.62 8.279 16.744 8.279H18.178V5.857C17.4838 5.78334 16.7861 5.74762 16.088 5.75C14.021 5.75 12.606 7.012 12.606 9.33V11.326H10.268V14.034H12.606V21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H15.402Z"
                          fill={
                            themeDetails?.socialIconColor
                              ? themeDetails?.socialIconColor
                              : `#000`
                          }
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3873_22935">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_3873_22939)">
                        <path
                          d="M12 2C14.717 2 15.056 2.01 16.122 2.06C17.187 2.11 17.912 2.277 18.55 2.525C19.21 2.779 19.766 3.123 20.322 3.678C20.8305 4.1779 21.224 4.78259 21.475 5.45C21.722 6.087 21.89 6.813 21.94 7.878C21.987 8.944 22 9.283 22 12C22 14.717 21.99 15.056 21.94 16.122C21.89 17.187 21.722 17.912 21.475 18.55C21.2247 19.2178 20.8311 19.8226 20.322 20.322C19.822 20.8303 19.2173 21.2238 18.55 21.475C17.913 21.722 17.187 21.89 16.122 21.94C15.056 21.987 14.717 22 12 22C9.283 22 8.944 21.99 7.878 21.94C6.813 21.89 6.088 21.722 5.45 21.475C4.78233 21.2245 4.17753 20.8309 3.678 20.322C3.16941 19.8222 2.77593 19.2175 2.525 18.55C2.277 17.913 2.11 17.187 2.06 16.122C2.013 15.056 2 14.717 2 12C2 9.283 2.01 8.944 2.06 7.878C2.11 6.812 2.277 6.088 2.525 5.45C2.77524 4.78218 3.1688 4.17732 3.678 3.678C4.17767 3.16923 4.78243 2.77573 5.45 2.525C6.088 2.277 6.812 2.11 7.878 2.06C8.944 2.013 9.283 2 12 2ZM12 7C10.6739 7 9.40215 7.52678 8.46447 8.46447C7.52678 9.40215 7 10.6739 7 12C7 13.3261 7.52678 14.5979 8.46447 15.5355C9.40215 16.4732 10.6739 17 12 17C13.3261 17 14.5979 16.4732 15.5355 15.5355C16.4732 14.5979 17 13.3261 17 12C17 10.6739 16.4732 9.40215 15.5355 8.46447C14.5979 7.52678 13.3261 7 12 7ZM18.5 6.75C18.5 6.41848 18.3683 6.10054 18.1339 5.86612C17.8995 5.6317 17.5815 5.5 17.25 5.5C16.9185 5.5 16.6005 5.6317 16.3661 5.86612C16.1317 6.10054 16 6.41848 16 6.75C16 7.08152 16.1317 7.39946 16.3661 7.63388C16.6005 7.8683 16.9185 8 17.25 8C17.5815 8 17.8995 7.8683 18.1339 7.63388C18.3683 7.39946 18.5 7.08152 18.5 6.75ZM12 9C12.7956 9 13.5587 9.31607 14.1213 9.87868C14.6839 10.4413 15 11.2044 15 12C15 12.7956 14.6839 13.5587 14.1213 14.1213C13.5587 14.6839 12.7956 15 12 15C11.2044 15 10.4413 14.6839 9.87868 14.1213C9.31607 13.5587 9 12.7956 9 12C9 11.2044 9.31607 10.4413 9.87868 9.87868C10.4413 9.31607 11.2044 9 12 9Z"
                          fill={
                            themeDetails?.socialIconColor
                              ? themeDetails?.socialIconColor
                              : `#000`
                          }
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3873_22939">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_3873_22943)">
                        <path
                          d="M22.1643 5.65593C21.4008 5.99362 20.5912 6.2154 19.7623 6.31393C20.636 5.79136 21.29 4.96894 21.6023 3.99993C20.7823 4.48793 19.8833 4.82993 18.9463 5.01493C18.3169 4.34151 17.4826 3.89489 16.5732 3.74451C15.6638 3.59413 14.7302 3.74842 13.9175 4.18338C13.1048 4.61834 12.4586 5.30961 12.0794 6.14972C11.7001 6.98983 11.609 7.93171 11.8202 8.82893C10.1574 8.74558 8.53058 8.31345 7.04554 7.56059C5.56049 6.80773 4.25038 5.75097 3.20025 4.45893C2.82854 5.09738 2.63321 5.82315 2.63425 6.56193C2.63425 8.01193 3.37225 9.29293 4.49425 10.0429C3.83026 10.022 3.18088 9.84271 2.60025 9.51993V9.57193C2.60045 10.5376 2.93462 11.4735 3.5461 12.221C4.15758 12.9684 5.00873 13.4814 5.95525 13.6729C5.33886 13.84 4.69256 13.8646 4.06525 13.7449C4.33212 14.5762 4.85226 15.3031 5.55284 15.824C6.25343 16.3449 7.09938 16.6337 7.97225 16.6499C7.10473 17.3313 6.11143 17.8349 5.04913 18.1321C3.98683 18.4293 2.87638 18.5142 1.78125 18.3819C3.69295 19.6114 5.91835 20.264 8.19125 20.2619C15.8842 20.2619 20.0913 13.8889 20.0913 8.36193C20.0913 8.18193 20.0863 7.99993 20.0783 7.82193C20.8971 7.23009 21.6038 6.49695 22.1653 5.65693L22.1643 5.65593Z"
                          fill={
                            themeDetails?.socialIconColor
                              ? themeDetails?.socialIconColor
                              : `#000`
                          }
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3873_22943">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_3873_22947)">
                        <path
                          d="M21.543 6.498C22 8.28 22 12 22 12C22 12 22 15.72 21.543 17.502C21.289 18.487 20.546 19.262 19.605 19.524C17.896 20 12 20 12 20C12 20 6.107 20 4.395 19.524C3.45 19.258 2.708 18.484 2.457 17.502C2 15.72 2 12 2 12C2 12 2 8.28 2.457 6.498C2.711 5.513 3.454 4.738 4.395 4.476C6.107 4 12 4 12 4C12 4 17.896 4 19.605 4.476C20.55 4.742 21.292 5.516 21.543 6.498ZM10 15.5L16 12L10 8.5V15.5Z"
                          fill={
                            themeDetails?.socialIconColor
                              ? themeDetails?.socialIconColor
                              : `#000`
                          }
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3873_22947">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_3873_22951)">
                        <path
                          d="M18.335 18.339H15.67V14.162C15.67 13.166 15.65 11.884 14.28 11.884C12.891 11.884 12.679 12.968 12.679 14.089V18.339H10.013V9.75H12.573V10.92H12.608C12.966 10.246 13.836 9.533 15.136 9.533C17.836 9.533 18.336 11.311 18.336 13.624V18.339H18.335ZM7.003 8.575C6.79956 8.57526 6.59806 8.53537 6.41006 8.45761C6.22207 8.37984 6.05127 8.26574 5.90746 8.12184C5.76365 7.97793 5.64965 7.80706 5.57201 7.61901C5.49437 7.43097 5.4546 7.22944 5.455 7.026C5.4552 6.71983 5.54618 6.4206 5.71644 6.16615C5.8867 5.91169 6.12859 5.71343 6.41153 5.59645C6.69447 5.47947 7.00574 5.44902 7.30598 5.50894C7.60622 5.56886 7.88196 5.71648 8.09831 5.93311C8.31466 6.14974 8.46191 6.42566 8.52145 6.72598C8.58099 7.0263 8.55013 7.33753 8.43278 7.62032C8.31543 7.9031 8.11687 8.14474 7.86219 8.31467C7.60751 8.4846 7.30917 8.5752 7.003 8.575ZM8.339 18.339H5.666V9.75H8.34V18.339H8.339ZM19.67 3H4.329C3.593 3 3 3.58 3 4.297V19.703C3 20.42 3.594 21 4.328 21H19.666C20.4 21 21 20.42 21 19.703V4.297C21 3.58 20.4 3 19.666 3H19.67Z"
                          fill={
                            themeDetails?.socialIconColor
                              ? themeDetails?.socialIconColor
                              : `#000`
                          }
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3873_22951">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="all-rights-reser"
            style={{
              borderTopColor: themeDetails?.footerDeviderColor
                ? themeDetails?.footerDeviderColor
                : `#cfd3d7`,
            }}
          >
            <p
              style={{
                color: themeDetails?.footerTextColor
                  ? themeDetails?.footerTextColor
                  : `#000`,
              }}
            >
              Made with <span>❤️</span> by the Redclub team
            </p>
            <p
              style={{
                color: themeDetails?.footerTextColor
                  ? themeDetails?.footerTextColor
                  : `#000`,
              }}
            >
              Copyright © 2024 Sunsetdeals.com
            </p>
          </div>
        </div>
      </FooterStyled>
    </>
  );
};

export default Footer;
