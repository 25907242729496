import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import banner1 from "../../../../../images/courselImages/FashionBanner.png";
import offerBanner from "../../../../../images/offer_banner.svg";

import { Carousel } from "antd";

import Categories from "../Categories";
import { HomeStyled } from "./Home.styled";
import TopBrandDeals from "../TopBrands/TopBrandDeals";
import OfferCardSlider from "../OfferCardSlider";
import { getAllMerchantsAPI } from "../../../../../redux/slices/app/appService";
import { getAllImageApi } from "../../../../../redux/slices/Admin/AdminService";

// import {setallProductsData } from "../../redux/slices/productCart/productCartSlice";
const HomePreview = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [carouselList, setCarouselList]: any = useState([]);
  const [bannersList, setBannersList]: any = useState([]);
  // const { carouselList, bannersList } = useSelector(
  //   (state: any) => state?.admin
  // );

  useEffect(() => {
    dispatch(getAllMerchantsAPI());
    getBannerImageData();
    getCarouselImageData();
  }, []);

  const getBannerImageData = async () => {
    // const res: any = await dispatch(getAllImageApi({ type: "Banner" }));
    // @ts-ignore
    const res: any = JSON.parse(localStorage?.getItem("localBannerList"));
    setBannersList(res);
  };
  const getCarouselImageData = async () => {
    // const res: any = await dispatch(getAllImageApi({ type: "Carousel" }));
    // @ts-ignore
    const res: any = JSON.parse(localStorage?.getItem("localCarouselList"));
    setCarouselList(res);
  };

  console.log("REs Data", bannersList, carouselList);

  return (
    <>
      <HomeStyled>
        {carouselList?.filter((i: any) => i?.imageUrl)?.length > 0 && (
          <div className="home-container carousel-wrapper">
            <Carousel
              autoplay
              autoplaySpeed={2000}
              infinite
              speed={1000}
              dots
              dotPosition="bottom"
              className="banner_img_carsousel"
            >
              {carouselList?.filter((i: any) => i?.imageUrl)?.length > 0 &&
                carouselList
                  ?.filter((i: any) => i?.imageUrl)
                  ?.map((item: any) => (
                    <div className="carousel_img_wrapper">
                      <img
                        src={item?.imageUrl}
                        alt="banner1"
                        className="h-100"
                      />
                    </div>
                  ))}
            </Carousel>
          </div>
        )}
        <Categories />
        <TopBrandDeals />
        {bannersList[0]?.imageUrl && (
          <div className="ofefr_sm_banner mt-4 mb-4">
            <img
              src={bannersList[0]?.imageUrl}
              alt="offer_bnner"
              className="w-100"
            />
          </div>
        )}

        <OfferCardSlider
          heading={"Labour Day Sale Top Deals"}
          q_param="deals"
        />
        <OfferCardSlider heading={"Crocs Top Deals"} brand="199042" />
        {bannersList[1]?.imageUrl && (
          <div className="ofefr_sm_banner mt-4 mb-4">
            <img
              src={bannersList[1]?.imageUrl}
              alt="offer_bnner"
              className="w-100"
            />
          </div>
        )}
        <OfferCardSlider heading={"Epson Top Deals"} brand="27433" />
        <OfferCardSlider
          heading={"Clothing & Accessories Deals"}
          q_param="accessories"
          cardNo={2}
        />
        {bannersList[2]?.imageUrl && (
          <div className="ofefr_sm_banner mt-4 mb-4">
            <img
              src={bannersList[2]?.imageUrl}
              alt="offer_bnner"
              className="w-100"
            />
          </div>
        )}
        <OfferCardSlider
          heading={"Pet Supplies Deals"}
          q_param="pet"
          cardNo={2}
        />
        <OfferCardSlider
          heading={"Sports Equipment & Outdoor Gear Deals"}
          q_param="sport"
          cardNo={2}
        />
        {bannersList[3]?.imageUrl && (
          <div className="ofefr_sm_banner mt-4 mb-4">
            <img
              src={bannersList[3]?.imageUrl}
              alt="offer_bnner"
              className="w-100"
            />
          </div>
        )}
        <OfferCardSlider heading={"Toys Deals"} q_param="toy" cardNo={2} />
        <OfferCardSlider heading={"Brook Brothers Deals"} brand="19246" />
        {bannersList[4]?.imageUrl && (
          <div className="ofefr_sm_banner mt-4 mb-4">
            <img
              src={bannersList[4]?.imageUrl}
              alt="offer_bnner"
              className="w-100"
            />
          </div>
        )}
        <OfferCardSlider heading={"Aspen Pet Deals"} q_param="pet" />
        <OfferCardSlider heading={"Ortovox Deals"} q_pqram="games" />
        {bannersList[5]?.imageUrl && (
          <div className="ofefr_sm_banner mt-4 mb-4">
            <img
              src={bannersList[5]?.imageUrl}
              alt="offer_bnner"
              className="w-100"
            />
          </div>
        )}
        <OfferCardSlider
          heading={"Green toys Deals"}
          q_param="toy"
          cardNo={2}
        />
        {bannersList[6]?.imageUrl && (
          <div className="ofefr_sm_banner mt-4 mb-4">
            <img
              src={bannersList[6]?.imageUrl}
              alt="offer_bnner"
              className="w-100"
            />
          </div>
        )}
      </HomeStyled>
    </>
  );
};

export default HomePreview;
