import { ModalProps } from "react-bootstrap/Modal";

export const getImageProperties = (imageUrl: any) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = imageUrl;
      img.onload = () => {
        resolve({
          height: img.height,
          width: img.width,
        });
      };
      img.onerror = (err) => {
        reject(err);
      };
    });
  };

  export interface ModalWrapperProps extends ModalProps {
    headerChildren?: React.ReactElement;
    bodyChildren?: any;
    footerChildren?: any;
    closeButton?: boolean;
    onHide?: any;
  }

  export const checkIsMobile = () => {
    return window.innerWidth <= 767;
  };

  export const v_id = "5035";