import styled from "styled-components";

export const CategoriesStyled = styled.div`
  .main_container {
    padding-left: 30px;
    padding: 30px;
  }
  .ppl_cate_header {
    color: var(--www-target-com-mine-shaft, #333);

    /* Heading 05/H5/Small/Medium */
    font-family: "Helvetica Neue";
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px; /* 134.615% */
    margin-bottom: 24px;
  }
  .list_wrapper {
    justify-content: center;
    gap: 20px 50px;

    .cat_main_wrap {
      max-width: 150px;
      .cat_item {
        overflow: hidden;
        height: 132px;
        width: 132px;
        max-width: 132px;
        padding-top: 17px;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: var(
          --www-farfetch-com-mine-shaft-4,
          rgba(34, 34, 34, 0.04)
        );
        cursor: pointer;
      }
      .cat_name {
        color: var(--www-target-com-mine-shaft, #333);
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
        margin-top: 16px;
      }

      .img_wrap {
        height: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 64px;
          height: 64px;
          object-fit: cover;
        }
      }
      .botm_sec {
        height: 30%;
        background: var(--www-farfetch-com-mercury, #e6e6e6);
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
        color: var(--www-farfetch-com-mine-shaft, #222);
        text-align: center;
        font-family: Roboto;
        font-size: 13.891px;
        font-style: normal;
        font-weight: 600;
        line-height: 12px; /* 86.389% */
        img {
          width: 19px;
          height: 19px;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .all_cate_main_wrapper {
      padding: 30px 0px !important;
      .list_wrapper {
        flex-wrap: nowrap !important;
        margin-right: 0px;
        margin-left: 0px;
        overflow: scroll !important;
        justify-content: start !important;
        gap: 20px 30px !important;

        .cat_main_wrap {
          .cat_name {
            color: var(--www-target-com-mine-shaft, #333);
            text-align: center;
            font-family: "Helvetica Neue";
            font-size: 12px !important;
            font-style: normal;
            font-weight: 500 !important;
            line-height: 18px; /* 150% */
          }
          .cat_item {
            height: 72px;
            width: 72px;
            padding-top: 0px !important;
            .botm_sec {
              color: var(--www-farfetch-com-mine-shaft, #222);
              text-align: center;
              font-family: Roboto;
              font-size: 8px !important;
              font-style: normal;
              font-weight: 600 !important;
              line-height: 5.818px !important; /* 72.727% */

              img {
                width: 9px;
                height: 9px;
              }
            }
          }
          .img_wrap img {
            width: 31px;
            height: 31px;
            object-fit: cover;
          }
        }
      }
      .ppl_cate_header {
        color: var(--www-target-com-mine-shaft, #333);
        // font-family: "Helvetica Neue";
        font-size: 16px !important;
        font-style: normal;
        font-weight: 500 !important;
        line-height: 25px !important; /* 156.25% */
        margin-bottom: 16px;
      }
    }
  }
`;
