import styled from "styled-components";

export const OfferCardSliderStyled = styled.div`
  .main_wrapper {
    padding: 30px 0px;
    .heading {
      color: #000;
      font-family: Roboto;
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 0px;
      display: flex;
      justify-content: space-between;
      margin-left: 10px;
      p {
        margin-bottom: 0px;
      }
      .shop_all {
        color: #040816;
        text-align: right;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 125% */
        cursor: pointer;
      }
    }
  }
  .slick-slide {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .main_slide {
    .pro_card_styled {
      width: 100%;
      height: 100%;
    }
  }
  .slick-slide > div {
    display: flex;
    gap: 20px;
  }
  .main_slide {
    width: 24%;
    .pro_card_styled {
      .main_sec {
        width: 100%;
        height: 100%;
      }
    }
  }
  .loader_wrapper {
    width: 100%;
    gap: 1%;
    margin-top: 20px;
    margin-bottom: 20px;
    .ant-space-item {
      width: 24%;
      .ant-skeleton,
      .ant-skeleton-input {
        width: 100%;
        height: 300px;
        border-radius: 8px;
      }
    }
  }

  .card_carousel_wrp {
    position: relative;
    .left_arrow_btn {
      top: 36%;
      position: absolute;
      z-index: 9;
      border: none;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #000 !important;
      left: -8px;
    }
    .right_arrow_btn {
      top: 36%;
      position: absolute;
      z-index: 9;
      right: -8px;
      border: none;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #000 !important;
      img {
        width: 22px;
        height: 22px;
      }
    }
  }

  // Mobile

  @media screen and (max-width: 767px) {
    .mobl_card_slid_main_wrapper {
      margin-bottom: 40px;
      .heading {
        color: var(--www-target-com-mine-shaft, #333);
        // font-family: "Helvetica Neue";
        font-size: 16px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 24px !important; /* 150% */
        margin-bottom: 16px;
        margin-right: 10px;
      }
      .mobl_slider_wrapper {
        padding: 8px 8px;
        display: flex;
        overflow: auto;
        max-width: 100%;
        flex-wrap: nowrap;
        width: 100%;
        gap: 10px;
        white-space: nowrap;
        .main_slide {
          width: 85%;
          min-width: 85%;

          .title_sec {
            overflow: hidden;
            color: #181818;
            text-overflow: ellipsis;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            margin-bottom: 50px;
            div {
              display: block;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        .main_slide.multi_card2 {
          width: 48%;
          min-width: 48%;
        }
      }
    }
    .loader_wrapper {
      width: 100%;
      gap: 1%;
      margin-top: 20px;
      margin-bottom: 20px;
      .ant-space-item {
        width: 49%;
        .ant-skeleton,
        .ant-skeleton-input {
          width: 100%;
          height: 300px;
          border-radius: 8px;
        }
      }
    }
    .multi_card2 .grab_deal_wrp {
      width: 88%;
    }
    .loader_wrapper .ant-space-item .ant-skeleton {
      border-radius: 8px;
      width: 100%;
      overflow: hidden;
    }
  }
`;
