import styled from "styled-components";

export const ContactUsStyled = styled.div`
  .main_container {
    padding: 0px 50px 50px 50px;

    .header {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      cursor: pointer;
      h2 {
        margin-bottom: 0px;
        color: #212121;
        // font-family: "Helvetica Neue";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    .body_sec {
      display: flex;
      justify-content: center;
      margin-top: 50px;

      .inner_body {
        width: 480px;
        padding: 32px;
        border-radius: 16px;
        background: var(--www-farfetch-com-white, #fff);
        box-shadow: 1px 3px 16px 0px rgba(32, 32, 32, 0.12);

        .top_sec {
          text-align: center;
          h1 {
            color: #000;
            // font-family: "Helvetica Neue";
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
          .assicts {
            color: #000;
            text-align: center;
            font-family: "Helvetica Neue";
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
          }
        }

        .bottom_sec {
          .line {
            display: flex;
            align-items: baseline;
            width: 70%;
            margin-bottom: 12px;
            p {
              margin-bottom: 0px;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .main_container {
      padding: 0px 10px 10px 10px;

      .body_sec {
        .inner_body {
          .top_sec {
            h1 {
              color: #000;
              text-align: center;
              font-family: "Helvetica Neue";
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }
            .assicts {
              color: #000;
              text-align: center;
              font-family: "Helvetica Neue";
              font-size: 14px;
              font-style: normal;
              font-weight: 300;
              line-height: normal;
            }
          }
          .bottom_sec {
            .line {
              color: #000;
              font-family: "Helvetica Neue";
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 22px; /* 157.143% */
            }
            .email_line {
              align-items: center;
            }
            .phone_lien {
              align-items: center;
            }
          }
          .bottom_sec .email {
            color: var(--FARFETCH-COLORS-Blue, #6729ff);
          }
        }
      }
    }
  }
`;
