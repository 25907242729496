import styled from "styled-components";

export const BrandUpdateStyled = styled.div`
  .main_brand_wrp {
    .heading {
      color: var(--Shade-100, #000);
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 27px; /* 135% */
      margin-bottom: 24px;
    }
    .sec_top {
      color: var(--Shade-100, #000);
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px; /* 177.778% */
      margin-bottom: 16px;
    }
    .middle_wrapper {
      border-radius: 16px;
      border: 1px solid #cfd3d7;
      background: #fff;
      padding: 24px;
      .two_sec_wrapper {
        display: flex;
        gap: 8%;
        .left_sec {
          width: 46%;
          border-radius: 4px;
          border: 1px solid #cfd3d7;
          background: #fff;
          .sec_head {
            background: #f6f6f6;
            height: 40px;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--Shade-100, #000);
            font-family: Poppins;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
          .sec_body {
            padding: 16px;
            .each_item {
              display: flex;
              justify-content: space-between;
              margin-bottom: 16px;

              .ant-checkbox-input {
                width: 20px;
                height: 20px;
              }
              .ant-checkbox-inner {
                width: 20px;
                height: 20px;
                border: 2px solid #000;
              }

              .ant-checkbox-wrapper:hover .ant-checkbox-inner,
              .ant-checkbox:hover .ant-checkbox-inner,
              .ant-checkbox-input:focus + .ant-checkbox-inner {
                border-color: #000;
              }

              .ant-checkbox-checked .ant-checkbox-inner {
                background-color: #000;
                border-color: #000;
              }

              .left_side {
                display: flex;
                justify-content: start;
                align-items: center;
                gap: 10px;
                .m_name {
                  color: var(--Shade-100, #000);
                  font-family: Poppins;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
                .img_wrapper {
                  width: 120px;
                  height: 34px;
                  border-radius: 2px;
                  background: var(--Shade-0, #fff);
                  box-shadow: 0px 0px 0px 1px #d6d6d6;
                  padding: 10px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                  }
                }
              }
              .right_side {
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }

        .right_sec {
          width: 46%;
          border-radius: 4px;
          border: 1px solid #cfd3d7;
          background: #fff;

          .sec_head {
            background: #f6f6f6;
            height: 40px;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--Shade-100, #000);
            font-family: Poppins;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
          .sec_body {
            padding: 16px;
            .no_selected_brands {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 90px;
              text-align: center;

              .inner {
                p {
                  margin-bottom: 4px;
                }
                .top {
                  color: var(--Shade-100, #000);
                  text-align: center;
                  font-family: Poppins;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 20px; /* 166.667% */
                }
                .bottom {
                  color: var(--Neutral-800, #606e79);
                  text-align: center;
                  font-family: Poppins;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px; /* 166.667% */
                }
              }
            }
            .each_item {
              display: flex;
              justify-content: space-between;
              margin-bottom: 16px;

              .left_side {
                display: flex;
                justify-content: start;
                align-items: center;
                gap: 10px;
                .m_name {
                  color: var(--Shade-100, #000);
                  font-family: Poppins;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
                .img_wrapper {
                  width: 120px;
                  height: 34px;
                  border-radius: 2px;
                  background: var(--Shade-0, #fff);
                  box-shadow: 0px 0px 0px 1px #d6d6d6;
                  padding: 10px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                  }
                }
              }
              .right_side {
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }

    .button_sec {
      margin-top: 40px;
      display: flex;
      justify-content: end;

      button {
        width: 192px;
        height: 56px;
        border-radius: 18px;
        background: #000;
        color: #fff;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
        letter-spacing: 0.032px;
        text-transform: uppercase;
      }
      button:disabled {
        background: var(--Neutral-100, #eceeef);
        color: var(--Neutral-400, #BFC5C9);
      }
    }
  }
`;
