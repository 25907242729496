import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP } from "../../../config";
import { post, put, get } from "../../../library/Requests/helpers";

export const getAllMerchantsAPI = createAsyncThunk(
  "dashboard/getAllMerchantsAPI",
  async () => await get(`${SERVER_IP}/home/getMerchants`)
);

export const generateDeepLinkAPI = createAsyncThunk(
  "dashboard/generateDeepLinkAPI",
  async (body: any) => await post(`${SERVER_IP}/home/generateDeepLink`, body)
);

export const getAllProductsAPI = createAsyncThunk(
  "dashboard/getAllProductsAPI",
  async (body: any) => {
    // Initialize the query parameters array
    const queryParams: string[] = [];

    // Iterate over the body object and construct the query string
    for (const key in body) {
      if (body[key] !== undefined && body[key] !== null) {
        // Add the key-value pair to the query parameters array
        queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(body[key])}`);
      }
    }

    // Join all query parameters with '&'
    const queryString = queryParams.length ? `?${queryParams.join("&")}` : "";

    // Send the request with the dynamic query string
    return await get(`${SERVER_IP}/home/getProductslist${queryString}`);
  }
);

export const getAllCategoriesAPI = createAsyncThunk(
  "dashboard/getAllCategoriesAPI",
  async () => await get(`${SERVER_IP}/home/getCategories`)
);
