import styled from "styled-components";

export const LayoutStyled = styled.div`
  .signin_layout_container {
    padding-top: 73px;
    .my-account-sec-bottom {
      padding-top: 0px;
    }
    margin: 0px !important;
    .loginsignupContainer {
      margin-bottom: 0px;
      margin-top: 0px;
      height: 100%;
    }
  }
`;
