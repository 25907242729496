import styled from "styled-components";

export const AboutStyled = styled.div`
  #main-content {
    padding: 0px 50px 50px 50px;
    .page-header {
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      h1 {
        color: #212121;
        // font-family: "Helvetica Neue";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 0px;
      }
    }
    .entry-content {
      h2 {
        color: var(--www-farfetch-com-mine-shaft, #222);
        font-family: "Helvetica Neue";
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 100% */
        margin-bottom: 20px;
      }
      p {
        color: var(--www-farfetch-com-mine-shaft, #222);
        // font-family: "Helvetica Neue";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
      }
      h3 {
        color: var(--www-farfetch-com-mine-shaft, #222);
        // font-family: "Helvetica Neue";
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 100% */
        margin-bottom: 20px;
      }
      ol {
        li {
          margin-bottom: 10px;
          a {
            color: var(--www-farfetch-com-blue, #6729ff);
            // font-family: "Helvetica Neue";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 150% */
          }
        }
      }
      #gdpr-definitions {
        color: var(--www-farfetch-com-mine-shaft, #222);
        // font-family: "Helvetica Neue";
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 100% */
        margin-top: 20px;
      }
      .personal_data_wrp {
        color: var(--www-farfetch-com-mine-shaft, #222);
        // font-family: "Helvetica Neue";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
      }
      ul {
        li {
          color: var(--www-farfetch-com-mine-shaft, #222);
          // font-family: "Helvetica Neue";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 27px; /* 150% */
        }
      }
      ol {
        li {
          color: var(--www-farfetch-com-mine-shaft, #222);
          // font-family: "Helvetica Neue";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */
        }
      }
    }
  }

  // Mobile

  @media screen and (max-width: 767px) {
    #main-content {
      padding: 0px 10px 10px 10px;
    }
  }
`;
