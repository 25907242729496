import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";

import Button from "react-bootstrap/Button";
// import "./styles.css";
import { useHistory } from "react-router-dom";
import eyeIcon from "../../../../../images/eyeClose.svg";
import { SigninStyled } from "./SignIn.styled";
import openEyeIcon from "../../../../../images/openEye.svg";
import { loginUser } from "../../../../../redux/slices/auth/authService";
const SignInTab = (props) => {
  const { gotoDelivery } = props;
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [errorMobileNumber, setErrorMobileNumber] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [erroressageText, setErroressageText] = useState("");
  const [showPass, setShowPass] = useState(false);

  const { error, loading, user } = useSelector(({ auth }) => auth);
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    if (user?._id) {
      history.push("/admin/homeBanner");
    }
  }, [user]);

  function handleSubmit(event) {
    event.preventDefault();
  }
  const gotoValidateLogin = () => {
    if (password === "") {
      setErrorPassword(true);
    }
    if (mobileNumber === "") {
      setErrorMobileNumber(true);
    }
    if (password !== "" && mobileNumber !== "") {
      signInCall();
      // gotoDelivery();
    }
  };
  const signInCall = async () => {
    console.log("vvvv");
    const body = {
      email: mobileNumber,
      password,
    };
    // dispatch(updateUserDetails(body1));
    // localStorage.setItem("user", JSON.stringify(body1));
    const resp = await dispatch(loginUser(body));
    console.log("resp", resp);
    if (resp?.payload?.error) {
      setErroressageText(resp?.payload?.error);
    }
  };
  const handleChangeValue = (e) => {
    let { name, value } = e.target;
    setErroressageText("");
    setErrorMobileNumber(false);
    setMobileNumber(value);
    // if (name == 'mobileNumber') {
    //     const re = /^[0-9\b]+$/;
    //     if (value === '' || re.test(value)) {

    //     }
    // }
  };

  return (
    <SigninStyled>
      <div className="LoginContainer exten-login-container">
        <Form.Group size="lg" controlId="mobileNumber">
          <Form.Label>Email Address*</Form.Label>
          <Form.Control
            autoFocus
            name="mobileNumber"
            type="text"
            placeholder="Enter Email Address"
            // maxLength={10}
            value={mobileNumber}
            className={errorMobileNumber ? "error" : ""}
            onChange={(e) => {
              handleChangeValue(e);
            }}
          />
        </Form.Group>
        <Form.Group size="lg" controlId="password" className="password_field">
          <Form.Label>Password</Form.Label>
          {showPass ? (
            <img
              src={eyeIcon}
              alt="eye"
              className="eyeIcon"
              onClick={() => setShowPass(!showPass)}
            />
          ) : (
            <img
              src={openEyeIcon}
              alt="open-eye"
              className="eyeIcon"
              onClick={() => setShowPass(!showPass)}
            />
          )}
          <Form.Control
            type={showPass ? "text" : "password"}
            placeholder="Enter Password"
            value={password}
            className={errorPassword ? "error" : ""}
            onChange={(e) => {
              setErroressageText("");
              setPassword(e.target.value);
              setErrorPassword(false);
            }}
          />
        </Form.Group>
        {error && <p className="errorMessage">{error}</p>}
        {erroressageText && <p className="errorMessage">{erroressageText}</p>}
        <div className="forgot-pass-sec">
          <span>Forgot Password?</span>
        </div>

        <Button
          onClick={() => {
            gotoValidateLogin();
          }}
          block
          size="lg"
          type="submit"
          className="sign_in_btn"
        >
          {loading ? "Loading" : "Login"}
        </Button>
        <div className="dont-have-acc-sec">
          <p>
            Don’t have an account?{" "}
            <span>
              <a href="/admin/signup">Sign up</a>
            </span>
          </p>
        </div>
      </div>
    </SigninStyled>
  );
};

export default SignInTab;
