import { Button, Input } from "antd";
import { ThemeStyled } from "./Theme.styled";
import cameraIcon from "../../../images/camera.svg";
import CardBody from "./CardBody";
import { useDispatch, useSelector } from "react-redux";
import { updateTheme } from "../../../redux/slices/Admin/AdminSlice";
import ImageUploadModel from "../../components/ImageUploadModel/ImageUploadModel";
import { useEffect, useState } from "react";
import { S3_BUCKET } from "../../../config";
import {
  getThemeDetailsApi,
  uploadThemeApi,
} from "../../../redux/slices/Admin/AdminService";
import deleteIcon from "../../../images/delete.svg";
import { siteName } from "../../../Utills/Utill";

const Theme = () => {
  const [showUploadImage, setShowUploadImage] = useState(false);
  const [selectedImg, setSelectedImg] = useState("");
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const { themeDetails } = useSelector((state: any) => state?.admin);

  useEffect(() => {
    dispatch(
      getThemeDetailsApi({
        websiteName: siteName,
      })
    );
  }, []);

  const siteNameChange = (value: any) => {
    dispatch(updateTheme({ ...themeDetails, siteName: value }));
  };

  const handleCloseImage = () => {
    setShowUploadImage(false);
  };

  const handleImageUploadSuccess = async (imgName: any) => {
    handleCloseImage();

    const prevImage1 = `https://${S3_BUCKET}.s3.amazonaws.com/${imgName}`;
    // const payload1 = {
    //   base64Image: prevImage1,
    //   mime: "image/png",
    // };
    // const res: any = await dispatch(ImageUploadAPi(payload1));
    dispatch(updateTheme({ ...themeDetails, [selectedImg]: prevImage1 }));
  };

  const logoTitleChange = (val: any, type: any) => {
    dispatch(updateTheme({ ...themeDetails, [type]: val }));
  };

  const upadteThemeCall = async () => {
    setLoader(true);
    const payload = {
      websiteName: siteName,
      websiteData: {
        ...themeDetails,
      },
    };
    await dispatch(uploadThemeApi(payload));

    await dispatch(
      getThemeDetailsApi({
        websiteName: siteName,
      })
    );
    setLoader(false);
  };

  const deleteImages = (place: any) => {
    dispatch(updateTheme({ ...themeDetails, [place]: "" }));
  };

  return (
    <ThemeStyled>
      <ImageUploadModel
        show={showUploadImage}
        handleClose={handleCloseImage}
        handleImageUploadSuccess={handleImageUploadSuccess}
      />
      <div className="main_thm_wrapper">
        <div className="heading">Theme</div>
        <div className="theme_card">
          <div className="card_head">Site Name</div>
          <Input
            placeholder="Enter Site Name"
            onChange={(e: any) => siteNameChange(e.target.value)}
            value={themeDetails?.siteName}
          />
        </div>
        <div className="theme_card logo_card">
          <div className="card_head">Logo Image</div>
          <div className="main_img_wrp">
            {!themeDetails?.logo && (
              <div className="pre_img_sec">
                <img
                  src={cameraIcon}
                  alt="camera"
                  onClick={() => {
                    setSelectedImg("logo");
                    setShowUploadImage(true);
                  }}
                />
                <p>
                  Upload Logo Image<span className="required">*</span>
                </p>
              </div>
            )}
            {themeDetails?.logo && (
              <div
                className="delete_btn_wrap"
                onClick={() => deleteImages("logo")}
              >
                <img src={deleteIcon} alt="tetst" />
              </div>
            )}
            {themeDetails?.logo && (
              <img
                src={themeDetails?.logo}
                alt="logo"
                className="uploaded_logo"
              />
            )}
          </div>
          <div className="input_sec">
            <label>
              Deals Icon Image Title<span className="required">*</span>
            </label>
            <Input
              placeholder="Enter Deals Icon Image Title"
              onChange={(e: any) =>
                logoTitleChange(e.target.value, "logoTitle")
              }
              value={themeDetails?.logoTitle}
            />
          </div>
        </div>
        <div className="theme_card small_logo_card">
          <div className="card_head">Top Brands - Deals Icon Image</div>
          <div className="main_img_wrp">
            {!themeDetails?.icon && (
              <div className="pre_img_sec">
                <img
                  src={cameraIcon}
                  alt="camera"
                  onClick={() => {
                    setSelectedImg("icon");
                    setShowUploadImage(true);
                  }}
                />
                <p>
                  Upload Logo Image<span className="required">*</span>
                </p>
              </div>
            )}
            {themeDetails?.icon && (
              <div
                className="delete_btn_wrap"
                onClick={() => deleteImages("icon")}
              >
                <img src={deleteIcon} alt="tetst" />
              </div>
            )}
            {themeDetails?.icon && (
              <img
                src={themeDetails?.icon}
                alt="new"
                className="uploaded_icon"
              />
            )}
          </div>
          <div className="input_sec">
            <label>
              Deals Icon Image Title<span className="required">*</span>
            </label>
            <Input
              placeholder="Enter Deals Icon Image Title"
              onChange={(e: any) =>
                logoTitleChange(e.target.value, "iconTitle")
              }
              value={themeDetails?.iconTitle}
            />
          </div>
        </div>
        <div className="theme_card">
          <div className="card_head">Top Brand Deals</div>
          <CardBody
            title="Top Brand Deals Background Colour"
            name="topBrandDealBG"
          />
          <CardBody
            title="Top Brand Deals Pattern Colour"
            name="topBrandDealPattrenColor"
          />
        </div>
        <div className="theme_card">
          <div className="card_head">Footer</div>
          <CardBody title="Footer Background Colour" name="footerBG" />
          <CardBody title="Footer Text Colour" name="footerTextColor" />
          <CardBody
            title="Footer Divider Line Colour"
            name="footerDeviderColor"
          />
          <CardBody title="Social Media Icons Colour" name="socialIconColor" />
        </div>
        <div className="theme_card">
          <div className="card_head">Text Colours</div>
          <CardBody title="Common (Black) Text Colour" name="commonTextColor" />
          <CardBody
            title="Header Offers Tab Icon Colour"
            name="headerOfferIconColor"
          />
          <CardBody title="Link Text Colour" name="linkTextColor" />
        </div>
        <div className="theme_card">
          <div className="card_head">Button Colours</div>
          <CardBody
            title="Grab Deals Button Background Colour"
            name="grabDealButtonBG"
          />
          <CardBody
            title="Grab Deals Button Text Colour"
            name="grabDealButtonTextColor"
          />
          <CardBody title="Brand Tag Background Colour" name="brandTagBG" />
          <CardBody title="Brand Tag Text colour" name="brandTagTextColor" />
        </div>
        <div className="btn_sec">
          <Button loading={loader} className="add_btn" onClick={() => upadteThemeCall()}>
            Add
          </Button>
        </div>
      </div>
    </ThemeStyled>
  );
};

export default Theme;
