import styled from "styled-components";

export const OfferPageStyled = styled.div`
  .main_wrapper {
    margin-top: 10px;
    padding: 30px;

    img {
      margin-bottom: 20px;
      width: 100%;
    }
  }

  @media screen and (max-width: 767px) {
    .main_wrapper {
      padding: 0px 10px;
      img {
        margin-bottom: 30px;
        width: 100%;
      }
    }
  }
`;
