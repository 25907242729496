import styled from "styled-components";

export const CanvasFilterStyled = styled.div`
  .ant-checkbox-input {
    width: 20px;
    height: 20px;
  }
  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    border: 2px solid #d9d9d9;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #000;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #000;
    border-color: #000;
  }

  .check_wrapper .ant-checkbox-wrapper span {
    color: var(--secondary-colour-300-main, #0b0b0b);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  .all_brands {
    max-height: 60vh !important;
    height: 40vh !important;
    overflow: auto;
  }
  .Main_Filter_wrapper {
    color: var(--www-target-com-mine-shaft, #333);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #ECEEEF;
    margin-bottom: 10px;
    span {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .sloder_wrapper {
    margin-top: 20px;
    margin-right: 20px;
    .range-slider {
      width: 100%;
    }
  }

  .range-slider {
    margin-top: 25px;
  }
  .range-slider__range {
    background: #000;
    transition: height 0.3s;
  }

  .range-slider__thumb {
    background: #000;
    transition: transform 0.3s;
  }
  .price_section {
    margin-top: 20px;
    margin-bottom: 70px;
    .input_wrap {
      display: flex;
      gap: 10px;
      label {
        color: var(--www-target-com-mine-shaft, #333);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
      .first_input {
        width: 46%;
        input {
          width: 100%;
          height: 40px;
          border-radius: 8px;
          background: #fff;
          padding: 10px 10px;
        }
      }
      .second_input {
        width: 46%;
        input {
          width: 100%;
          height: 40px;
          border-radius: 8px;
          background: #fff;
          padding: 10px 10px;
        }
      }
    }
  }
`;
