import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";

import Settings from "../pages/Settings";
import HandleClick from "../pages/HandleClick/HandleClick";
import Links from "../pages/Links/Links";
import Home from "../pages/Home/index";

import { useLocation } from "react-router-dom";
import TermsCondition from "../pages/TermsCondition/TermsCondition";
import Policy from "../pages/Policy/Policy";
import AllProduct from "../pages/AllProduct/AllProduct";
import RedirectionPage from "../pages/RedirectionPage";
import OfferPage from "../pages/OfferPage";
import TermsAndCondition from "../pages/TermsAndCondition";
import Cookies from "../pages/cookies";
import AboutUs from "../pages/AboutUs";
import ContactUs from "../pages/ContactUs";
import BrandPage from "../pages/BrandPage";
import MainHomePage from "../Dashboard/pages/MainHomePage";
import CarouselImage from "../Dashboard/pages/CarouselImage";
import OfferPagePreview from "../Dashboard/pages/PreviewPage/Offer";
import DashboardHome from "../Dashboard/pages/Home";
import Signin from "../Dashboard/pages/Signin";
import SignUp from "../Dashboard/pages/Signin/SignUp";
import MyProfileInfo from "../Dashboard/pages/MyProfileInfo";
import ChangePassword from "../Dashboard/pages/ChangePassword/ChangePassword";
import HomePreview from "../Dashboard/pages/PreviewPage/Banner/Home";
import OfferBanner from "../Dashboard/pages/OfferPage";
import { useSelector } from "react-redux";

import Theme from "../Dashboard/pages/Theme/Index";
import BrandsUpdate from "../Dashboard/pages/BrandsUpdate/Index";

const MainRoutes: React.FC = () => {
  const { pathname } = useLocation();

  const { user } = useSelector((reduxState: any) => reduxState.auth);
  const vendorId = user?.vendorId;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const isAdmin = window.location.pathname?.split("/")[1] === "admin" ? true : false;

  return (
    <Switch>
      {/*Route exact path="/" component={Home} />*/}

      <Route exact path="/" component={isAdmin ? MainHomePage : Home} />
      <Route exact path="/home" component={isAdmin ? MainHomePage  : Home} />
      <Route exact path="/dashboard" component={Home} />
      {/* <Route exact path="/signin" component={Signin} /> */}
      <Route exact path="/links" component={Links} />
      {/* <Route exact path="/signUp" component={SignUp} /> */}
      {/* <Route exact path="/myProfile" component={MyProfile} /> */}
      {/* <Route exact path="/Settings" component={Settings} /> */}
      {/* <Route exact path="/MyProfileInfo" component={MyProfileInfo} /> */}
      <Route exact path="/handleclick" component={HandleClick} />

      <Route exact path="/termscondition" component={TermsCondition} />
      <Route exact path="/policy" component={Policy} />
      {/* <Route exact path="/changePassword" component={ChangePassword} /> */}
      <Route exact path="/products" component={AllProduct} />
      <Route exact path="/redirection" component={RedirectionPage} />
      <Route exact path="/offers" component={OfferPage} />
      <Route exact path="/cookiesPolicy" component={Cookies} />
      <Route exact path="/aboutus" component={AboutUs} />
      <Route exact path="/contactus" component={ContactUs} />
      <Route exact path="/brands" component={BrandPage} />

      {/* Admin Route */}

      <Route exact path="/admin/signin" component={Signin} />
      <Route exact path="/admin/signup" component={SignUp} />
      <Route exact path="/admin/MyProfileInfo" component={MyProfileInfo} />
      <Route exact path="/admin/changePassword" component={ChangePassword} />
      <Route exact path="/admin/homeBanner" component={MainHomePage} />
      <Route exact path="/admin/offerBanner" component={OfferBanner} />
      <Route exact path="/admin/carousel" component={CarouselImage} />
      <Route exact path={"/admin/HomePreview"} component={HomePreview} />
      <Route exact path={"/admin/offerPreview"} component={OfferPagePreview} />
      <Route exact path={"/admin/brandsUpdate"} component={BrandsUpdate} />
      <Route exact path={"/admin/theme"} component={Theme} />
    </Switch>
  );
};

export default MainRoutes;
