import React, { useState, useEffect } from "react";
import { SideMenuStyled } from "./SideMenu.styled";
import dashboardIcon from "../../../images/darkBanner.svg";
import deepLinkIcon from "../../../images/darkPercent.svg";
import personIcon from "../../../images/person.svg";
import logoutIcon from "../../../images/logout.svg";
import WhiteDash from "../../../images/whiteBanner.svg";
import whiteLink from "../../../images/whitePercent.svg";
import whiteUser from "../../../images/whiteUser.svg";
import whiteBrand from "../../../images/brandWhite.svg";
import darkBrand from "../../../images/brandDark.svg";
import whiteTheme from "../../../images/themeWhite.svg";
import darkTheme from "../../../images/themeDark.svg";

import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateUserDetails } from "../../../redux/slices/auth/authService";
import { clearUserData } from "../../../redux/slices/auth/authSlice";

const Sidebar = (props: any) => {
  const [sidebar, setSidebar] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((reduxState: any) => reduxState.auth);
  const vendorId = user?.vendorId;

  // useEffect(() => {
  //   if (vendorId) {
  //   } else {
  //     history.push("/signin");
  //   }
  // }, []);

  const logoutGotoNewPage = () => {
    localStorage.clear();
    dispatch(clearUserData(null));
    history.push("/admin/signin");
  };
  const gotoNewPage = (url: any) => {
    if (url === "/createjobcard") {
      window.location.href = url;
    } else if (url) {
      history.push(url);
    }
  };
  const menuItems = [
    {
      title: "Homepage Offers",
      link: "/admin/homeBanner",
      img: dashboardIcon,
      activeImg: WhiteDash,
    },
    {
      title: "Offers",
      link: "/admin/offerBanner",
      img: deepLinkIcon,
      activeImg: whiteLink,
    },
    {
      title: "Brands",
      link: "/admin/brandsUpdate",
      img: darkBrand,
      activeImg: whiteBrand,
    },
    {
      title: "Theme",
      link: "/admin/theme",
      img: darkTheme,
      activeImg: whiteTheme,
    },
    {
      title: "My Account",
      link: "/admin/MyProfileInfo",
      img: personIcon,
      activeImg: whiteUser,
    },
    // {
    //   title: "Change Password",
    //   link: "changePassword",
    //   img: editIcon,
    //   activeImg: whiteEdit,
    // },
  ];
  const selectedUrl = window.location.pathname;
  return (
    <>
      <SideMenuStyled>
        <div className="menu-sec-add-list">
          {menuItems?.map((item: any, idx: number) => {
            return (
              <div
                key={`bar_${idx}`}
                className={
                  selectedUrl === `${item?.link}`
                    ? "selected-link-item"
                    : "row-link-item"
                }
                onClick={() => {
                  gotoNewPage(item?.link);
                }}
              >
                <span>
                  <span>
                    <img
                      alt="new_image"
                      src={
                        selectedUrl === `${item?.link}`
                          ? item?.activeImg
                          : item?.img
                      }
                    />
                  </span>
                  {item?.title}
                </span>
              </div>
            );
          })}
          <div
            className={"row-link-item row-link-item-logout"}
            onClick={() => {
              logoutGotoNewPage();
            }}
          >
            <span>
              <img src={logoutIcon} alt="logout" />
              Log out
            </span>
          </div>
        </div>
      </SideMenuStyled>
    </>
  );
};

export default Sidebar;
