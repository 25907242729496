import styled from "styled-components";

export const OfferStyled = styled.div`
  .main_home_banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    .banner_header {
      color: var(--Shade-100, #000);
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 27px; /* 135% */
      margin-bottom: 0px;
    }
    button {
      color: var(--Shade-0, #fff);
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
      letter-spacing: 0.032px;
      text-transform: uppercase;
      border-radius: 18px;
      background: var(--FARFETCH-COLORS-Mine-Shaft, #222);
      height: 52px;
      img {
        margin-right: 10px;
      }
    }
    .pre_view_btn:disabled {
      opacity: 0.5;
    }
  }
  .buttons_sec {
    display: flex;
    justify-content: end;
    gap: 24px;

    .ant-btn {
      display: flex;
      width: 277px;
      height: 45px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      flex-shrink: 0;
      border-radius: 18px;
      background: var(--FARFETCH-COLORS-Mine-Shaft, #222);
      color: var(--Shade-0, #fff);
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
      letter-spacing: 0.032px;
      text-transform: uppercase;
    }
    .add_btn {
      width: 192px;
    }
    .add_btn:disabled {
      opacity: 0.5;
    }
  }

  .Main_card_wrapper {
    position: relative;
    .del_sec {
      cursor: pointer;
      position: absolute;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: 1px solid var(--Neutral-100, #eceeef);
      background: #fff;
      right: 40px;
      top: 80px;
      box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.08);
      backdrop-filter: blur(4.5px);
    }
  }
`;
