import { PolicyStyled } from "./Policy.styled";
import arrow from "../../images/arrow-right-black.svg";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const Policy = () => {
  const history = useHistory();
  const { themeDetails } = useSelector((state: any) => state?.admin);
  return (
    <PolicyStyled>
      <div
        id="main-content"
        className="mh-content"
        role="main"
        // itemprop="mainContentOfPage"
      >
        <div className="entry-content clearfix">
          <article
            id="page-729"
            className="post-729 page type-page status-publish"
          >
            <header className="page-header"  onClick={() => history?.goBack()}>
              <img src={arrow} alt="arrow" />
              <h1 className="entry-title page-title">Your Privacy Rights</h1>
            </header>
            <div className="entry-content clearfix">
              <h2>Privacy Policy</h2>
              <p>
                Sunsetdeals is committed to safeguarding your privacy. Contact
                us at if you have any questions or problems regarding the use of
                your Personal Data and we will gladly assist you.
              </p>
              <p>
                By using this site or/and our services, you consent to the
                Processing of your Personal Data as described in this Privacy
                Policy.
              </p>
              <h3>Table of Contents</h3>
              <ol>
                <li>
                  <a href="#gdpr-definitions" style={{color: themeDetails?.linkTextColor}}>
                    Definitions used in this Policy
                  </a>
                </li>
                <li>
                  <a href="#gdpr-principles" style={{color: themeDetails?.linkTextColor}}>
                    Data protection principles we follow
                  </a>
                </li>
                <li>
                  <a href="#gdpr-rights" style={{color: themeDetails?.linkTextColor}}>
                    What rights do you have regarding your Personal Data
                  </a>
                </li>
                <li>
                  <a href="#gdpr-information" style={{color: themeDetails?.linkTextColor}}>
                    What Personal Data we gather about you
                  </a>
                </li>
                <li>
                  <a href="#gdpr-processing" style={{color: themeDetails?.linkTextColor}}>How we use your Personal Data</a>
                </li>
                <li>
                  <a href="#gdpr-sharing" style={{color: themeDetails?.linkTextColor}}>
                    Who else has access to your Personal Data
                  </a>
                </li>
                <li>
                  <a href="#gdpr-security" style={{color: themeDetails?.linkTextColor}}>How we secure your data</a>
                </li>
                <li>
                  <a href="#gdpr-cookies" style={{color: themeDetails?.linkTextColor}}>Information about cookies</a>
                </li>
                <li>
                  <a href="#gdpr-contact" style={{color: themeDetails?.linkTextColor}}>Contact information</a>
                </li>
              </ol>
              <h3 id="gdpr-definitions">Definitions</h3>
              <p className="personal_data_wrp">
                <strong>Personal Data</strong>&nbsp;– any information relating
                to an identified or identifiable natural person.
                <br />
                <strong>Processing</strong>&nbsp;– any operation or set of
                operations which is performed on Personal Data or on sets of
                Personal Data.
                <br />
                <strong>Data subject</strong>&nbsp;– a natural person whose
                Personal Data is being Processed.
                <br />
                <strong>Child</strong> –&nbsp;a natural person under 16 years of
                age.
                <br />
                <strong>We/us</strong> (either capitalized or not)&nbsp;–
              </p>
              <h3 id="gdpr-principles">Data Protection Principles</h3>
              <p>
                We promise to follow the following data protection principles:
              </p>
              <ul>
                <li>
                  Processing is lawful, fair, transparent. Our Processing
                  activities have lawful grounds. We always consider your rights
                  before Processing Personal Data. We will provide you
                  information regarding Processing upon request.
                </li>
                <li>
                  Processing is limited to the purpose. Our Processing
                  activities fit the purpose for which Personal Data was
                  gathered.
                </li>
                <li>
                  Processing is done with minimal data. We only gather and
                  Process the minimal amount of Personal Data required for any
                  purpose.
                </li>
                <li>
                  Processing is limited with a time period. We will not store
                  your personal data for longer than needed.
                </li>
                <li>We will do our best to ensure the accuracy of data.</li>
                <li>
                  We will do our best to ensure the integrity and
                  confidentiality of data.
                </li>
              </ul>
              <h3 id="gdpr-rights">Data Subject’s rights</h3>
              <p>The Data Subject has the following rights:</p>
              <ol>
                <li>
                  Right to information&nbsp;– meaning you have to right to know
                  whether your Personal Data is being processed; what data is
                  gathered, from where it is obtained and why and by whom it is
                  processed.
                </li>
                <li>
                  Right to access&nbsp;– meaning you have the right to access
                  the data collected from/about you. This includes your right to
                  request and obtain a copy of your Personal Data gathered.
                </li>
                <li>
                  Right to rectification&nbsp;– meaning you have the right to
                  request rectification or erasure of your Personal Data that is
                  inaccurate or incomplete.
                </li>
                <li>
                  Right to erasure – meaning in certain circumstances you can
                  request for your Personal Data to be erased from our records.
                </li>
                <li>
                  Right to restrict processing – meaning&nbsp;where certain
                  conditions apply, you have the right to restrict the
                  Processing of your Personal Data.
                </li>
                <li>
                  Right to object to processing&nbsp;– meaning in certain cases
                  you have the right to object to Processing of your Personal
                  Data, for example in the case of direct marketing.
                </li>
                <li>
                  Right to object to automated Processing&nbsp;– meaning you
                  have the right to object to automated Processing, including
                  profiling; and not to be subject to a decision based solely on
                  automated Processing. This right you can exercise whenever
                  there is an outcome of the profiling that produces legal
                  effects concerning or significantly affecting you.
                </li>
                <li>
                  Right to data portability&nbsp;– you have the right to obtain
                  your Personal Data in a machine-readable format or if it is
                  feasible, as a direct transfer from one Processor to another.
                </li>
                <li>
                  Right to lodge a complaint&nbsp;– in the event that we refuse
                  your request under the Rights of Access, we will provide you
                  with a reason as to why. If you are not satisfied with the way
                  your request has been handled please contact us.
                </li>
                <li>
                  Right for the help of&nbsp;
                  <a href="#gdpr-contact" style={{color: themeDetails?.linkTextColor}}>supervisory authority</a>&nbsp;–
                  meaning you have the right for the help of a supervisory
                  authority and the right for other legal remedies such as
                  claiming damages.
                </li>
                <li>
                  Right to withdraw consent&nbsp;– you have the right withdraw
                  any given consent for Processing of your Personal Data.
                </li>
              </ol>
              <h3 id="gdpr-information">Data we gather</h3>
              <p>
                <strong>Information you have provided us with</strong>
                <br />
                This might be your e-mail address, name, etc – mainly
                information that is necessary for delivering you a
                product/service or to enhance your customer experience with us.
                We save the information you provide us with in order for you to
                comment or perform other activities on the website. This
                information includes, for example, your name and e-mail address.
              </p>
              <p>
                <strong>Information automatically collected about you</strong>
                <br />
                This includes information that is automatically stored by
                cookies and other session tools. For example, Geo location, your
                IP address, previously visited pages or articles, etc. This
                information is used to improve your customer experience. When
                you use our services or look at the contents of our website,
                your activities may be logged.
              </p>
              <p>
                <strong>Information from our partners</strong>
                <br />
                We gather information from our trusted partners with
                confirmation that they have legal grounds to share that
                information with us. This is either information you have
                provided them directly with or that they have gathered about you
                on other legal grounds. See the list of our partners&nbsp;
                <a href="#gdpr-sharing" style={{color: themeDetails?.linkTextColor}}>here</a>.
              </p>
              <p>
                <strong>Publicly available information</strong>
                <br />
                We might gather information about you that is publicly
                available.
              </p>
              <h3 id="gdpr-processing">How we use your Personal Data</h3>
              <p>We use your Personal Data in order to:</p>
              <ul>
                <li>
                  provide our service to you. This includes for example
                  registering your account; providing you with other products
                  and services that you have requested; providing you with
                  promotional items at your request and communicating with you
                  in relation to those products and services; communicating and
                  interacting with you; and notifying you of changes to any
                  services.
                </li>
                <li>enhance your customer experience;</li>
                <li>fulfil an obligation under law or contract;</li>
              </ul>
              <p>
                We use your Personal Data on legitimate grounds and/or with your
                Consent.
              </p>
              <p>
                On the grounds of entering into a contract or fulfilling
                contractual obligations, we Process your Personal Data for the
                following purposes:
              </p>
              <ul>
                <li>to identify you;</li>
                <li>
                  to provide you a service or to send/offer you a contest offer;
                </li>
                <li>to communicate either for sales or marketing purposes</li>
              </ul>
              <p>
                On the ground of legitimate interest, we Process your Personal
                Data for the following purposes:
              </p>
              <ul>
                <li>
                  to send you personalized offers* (from us and/or our carefully
                  selected partners);
                </li>
                <li>
                  to administer and analyze our client base (purchasing behavior
                  and history) in order to improve the quality, variety, and
                  availability of services offered/provided via newsletters;
                </li>
                <li>
                  to conduct questionnaires concerning client satisfaction
                </li>
              </ul>
              <p>
                As long as you have not informed us otherwise, we consider
                offering you products/services that are similar or same to your
                purchasing history/browsing behavior to be our legitimate
                interest.
              </p>
              <p>
                With your consent we Process your Personal Data for the
                following purposes:
              </p>
              <ul>
                <li>
                  to send you newsletters and campaign offers (from us and/or
                  our carefully selected partners);
                </li>
                <li>
                  for other purposes we have asked your consent for, such as
                  customer surveys
                </li>
              </ul>
              <p>
                We Process your Personal Data in order to fulfil obligation
                rising from law and/or use your Personal Data for options
                provided by law. We reserve the right to anonymize Personal Data
                gathered and to use any such data. We will use data outside the
                scope of this Policy only when it is anonymized. We save&nbsp;
                information gathered about you for as long as needed for
                obligations deriving from law, but not longer than the law
                requires.
              </p>
              <p>
                We might process your Personal Data for additional purposes that
                are not mentioned here, but are compatible with the original
                purpose for which the data was gathered. To do this, we will
                ensure that:
              </p>
              <ul>
                <li>
                  the link between purposes, context and nature of Personal Data
                  is suitable for further Processing;
                </li>
                <li>
                  the further Processing would not harm your interests and
                </li>
                <li>there would be appropriate safeguard for Processing.</li>
              </ul>
              <p>We will inform you of any further Processing and purposes.</p>
              <h3 id="gdpr-sharing">Who else can access your Personal Data</h3>
              <p>
                We do not share your Personal Data with strangers. Personal Data
                about you is in some cases provided to our trusted
                partners&nbsp;in order to either make providing the service to
                you possible or to enhance your customer experience. We share
                your data with:
              </p>
              <p>
                <strong>Our business partners:</strong>
              </p>
              <ul>
                <li>
                  Affiliate Networks including Rakuten, Pepperjam, ShareASale,
                  Impact
                </li>
              </ul>
              <p>
                <strong>Connected third parties:</strong>
              </p>
              <ul>
                <li>Google</li>
                <li>Facebook</li>
                <li>Instagram</li>
              </ul>
              <p>
                Any direct payments for products/services (outside of
                Newsletters and Customer Surveys, both of which carry no
                requirement of payment) are handled directly by the respective
                Merchant and the Processing Partners those Merchants use. We
                disclose your Personal Data to third parties or public officials
                when we are legally obliged to do so. We might disclose your
                Personal Data to third parties if you have consented to it or if
                there are other legal grounds for it.
              </p>
              <h3 id="gdpr-security">Searching on our site</h3>
              <p>
                Some of the web search services provided herein are provided by
                providers of third party web services. For information on the
                web search services data collection, which may differ from the
                data collection practices described in this Privacy Policy,
                please visit the end user privacy statements of our 3rd party
                web services providers by clicking
                <a href="https://www.codefuel.com/legal/end_user_privacy_policy/" style={{color: themeDetails?.linkTextColor}}>
                  here
                </a>
                &nbsp;and Bing at&nbsp;
                <a
                  href="http://go.microsoft.com/fwlink/?LinkId=521839"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{color: themeDetails?.linkTextColor}}
                >
                  http://go.microsoft.com/fwlink/?LinkId=521839
                </a>
                , both as updated from time to time and at any successor
                locations
              </p>
              <h3 id="gdpr-security">How we secure your data</h3>
              <p>
                We do our best to keep your Personal Data safe.&nbsp; We use
                safe protocols for communication and transferring data (such as
                HTTPS). We use anonymising and pseudonymising where suitable. We
                monitor our systems for possible vulnerabilities and attacks.
              </p>
              <p>
                Even though we try our best we can not guarantee the security of
                information. However, we promise to notify suitable authorities
                of data breaches. We will also notify you if there is a threat
                to your rights or interests. We will do everything we reasonably
                can to prevent security breaches and to assist authorities
                should any breaches occur.
              </p>
              <h3>Children</h3>
              <p>
                We do not intend to collect or knowingly collect information
                from children. We do not target children with our services.
              </p>
              <h3 id="gdpr-cookies">Cookies and other technologies we use</h3>
              <p>
                We use cookies and/or similar technologies to analyse customer
                behavior, administer the website, track users’ movements, and to
                collect information about users. This is done in order to
                personalize and enhance your experience with us.
              </p>
              <p>
                A cookie is a tiny text file stored on your computer. Cookies
                store information that is used to help make sites work. Only we
                can access the cookies created by our website. You can control
                your cookies at the browser level. Choosing to disable cookies
                may hinder your use of certain functions.
              </p>
              <p>
                <strong>We use cookies for the following purposes:</strong>
              </p>
              <ul>
                <li>
                  Necessary cookies – these cookies are required for you to be
                  able to use some important features on our website, such as
                  logging in. These cookies don’t collect any personal
                  information.
                </li>
                <li>
                  Functionality cookies – these cookies provide functionality
                  that makes using our service more convenient and makes
                  providing more personalized features possible. For example,
                  they might remember your name and e-mail in comment forms so
                  you don’t have to re-enter this information next time when
                  commenting.
                </li>
                <li>
                  Analytics cookies – these cookies are used to track the use
                  and performance of our website and services
                </li>
                <li>
                  Advertising cookies – these cookies are used to deliver
                  advertisements that are relevant to you and to your interests.
                  In addition, they are used to limit the number of times you
                  see an advertisement. They are usually placed to the website
                  by advertising networks with the website operator’s
                  permission. These cookies remember that you have visited a
                  website and this information is shared with other
                  organisations such as advertisers. Often targeting or
                  advertising cookies will be linked to site functionality
                  provided by the other organisation.
                </li>
              </ul>
              <p>
                You can remove cookies stored in your computer via your browser
                settings. Alternatively, you can control some 3rd party cookies
                by using a privacy enhancement platform such as
                <a
                  href="http://optout.aboutads.info/#!/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{color: themeDetails?.linkTextColor}}
                >
                  optout.aboutads.info
                </a>
                or
                <a
                  href="http://www.youronlinechoices.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{color: themeDetails?.linkTextColor}}
                >
                  youronlinechoices.com
                </a>
                . For more information about cookies, visit
                <a
                  href="http://www.allaboutcookies.org"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{color: themeDetails?.linkTextColor}}
                >
                  allaboutcookies.org
                </a>
                .
              </p>
              <p>
                We use Google Analytics to measure traffic on our website.
                Google has their own Privacy Policy which you can review
                <a
                  href="https://support.google.com/analytics/answer/6004245"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{color: themeDetails?.linkTextColor}}
                >
                  here
                </a>
                . If you’d like to opt out of tracking by Google Analytics,
                visit the
                <a
                  href="https://tools.google.com/dlpage/gaoptout"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{color: themeDetails?.linkTextColor}}
                >
                  Google Analytics opt-out page
                </a>
                .
              </p>
              <h3 id="gdpr-contact">Contact Information</h3>
              <p>
                <strong id="gdpr-dpa">admin@Sunsetdeals.com</strong>
              </p>
              <h3 id="gdpr-changes">Changes to this Privacy Policy</h3>
              <p>
                We reserve the right to make change to this Privacy Policy.
                <br />
                Last modification was made 12/08/2020
              </p>
            </div>
          </article>
        </div>
      </div>
    </PolicyStyled>
  );
};

export default Policy;
