import { Carousel, Skeleton, Space } from "antd";

import topoIcon from "../../../../../images/Topographic5.svg";
import arrow from "../../../../../images/arrowRight.svg";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { TopBrandStyled } from "./TopBrand.styled";
import { checkIsMobile } from "../../../../module/utills";
import { topBrandsList } from "../../../../module/Brands";

const TopBrandDeals = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  return (
    <TopBrandStyled className="top_brands_styled">
      <div className="topo">
        <img src={topoIcon} alt="topo" />
      </div>
      <div className="header_wrp">
        <h1 className="header">Top Brand’s Deals</h1>
        <p className="view_all" onClick={() => history?.push("/brands")}>
          View All
        </p>
      </div>

      <div className="w-100 carousel_wrap">
        {loading && (
          <>
            <Space className="loader_wrapper">
              <Skeleton.Input active={true} size={"large"} />
              <Skeleton.Input active={true} size={"large"} />
              {!checkIsMobile() && (
                <Skeleton.Input active={true} size={"large"} />
              )}
              {!checkIsMobile() && (
                <Skeleton.Input active={true} size={"large"} />
              )}
            </Space>
            <Space className="loader_wrapper">
              <Skeleton.Input active={true} size={"large"} />
              <Skeleton.Input active={true} size={"large"} />
              {!checkIsMobile() && (
                <Skeleton.Input active={true} size={"large"} />
              )}
              {!checkIsMobile() && (
                <Skeleton.Input active={true} size={"large"} />
              )}
            </Space>
          </>
        )}
        {!loading && !checkIsMobile() && (
          <Carousel
            arrows={checkIsMobile() ? false : true}
            infinite={true}
            slidesPerRow={checkIsMobile() ? 1 : 5}
            // @ts-ignore
            arrowOffset={10}
            arrowSize={30}
            dotWidth={20}
          >
            {topBrandsList?.slice(0, 5)?.map((item: any, idx: number) => (
              <div
                className={`main_slide ${checkIsMobile() ? `ml-2` : ``}`}
                key={`ms_${idx}`}
              >
                <div className={`inner_slide`}>
                  <div className="img_wrapper">
                    <img
                      src={topBrandsList[idx * 2]?.merchantInfo?.logoUrl}
                      alt="new"
                      className="brand_logo"
                    />
                  </div>
                  <div className="grab_deal_wrp">
                    <div className="grab_deal">
                      Grab Deals
                      <img src={arrow} alt="arraow" />
                    </div>
                  </div>
                  <div className="time_wrp">
                    <div className="timer_sec">
                      {topBrandsList[idx * 2]?.merchantInfo?.name}
                    </div>
                  </div>
                </div>
                <div className="inner_slide">
                  <div className="img_wrapper">
                    <img
                      src={topBrandsList[idx * 2 + 1]?.merchantInfo?.logoUrl}
                      alt="new"
                      className="brand_logo"
                    />
                  </div>
                  <div className="grab_deal_wrp">
                    <div className="grab_deal">
                      Grab Deals
                      <img src={arrow} alt="arraow" />
                    </div>
                  </div>
                  <div className="time_wrp">
                    <div className="timer_sec">
                      {topBrandsList[idx * 2 + 1]?.merchantInfo?.name}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        )}
        {checkIsMobile() && !loading && (
          <div className="mobl_brand_wrapper">
            {topBrandsList?.slice(0, 5)?.map((item: any, idx: number) => (
              <div
                className={`main_slide ${checkIsMobile() ? `ml-2` : ``}`}
                key={`ms_${idx}`}
              >
                <div className={`inner_slide`}>
                  <div className="img_wrapper">
                    <img
                      src={topBrandsList[idx * 2]?.merchantInfo?.logoUrl}
                      alt="new"
                      className="brand_logo"
                    />
                  </div>
                  <div className="grab_deal_wrp">
                    <div className="grab_deal">
                      Grab Deals
                      <img src={arrow} alt="arraow" />
                    </div>
                  </div>
                  <div className="time_wrp">
                    <div className="timer_sec">
                      {topBrandsList[idx * 2]?.merchantInfo?.name}
                    </div>
                  </div>
                </div>
                <div className="inner_slide">
                  <div className="img_wrapper">
                    <img
                      src={topBrandsList[idx * 2 + 1]?.merchantInfo?.logoUrl}
                      alt="new"
                      className="brand_logo"
                    />
                  </div>
                  <div className="grab_deal_wrp">
                    <div className="grab_deal">
                      Grab Deals
                      <img src={arrow} alt="arraow" />
                    </div>
                  </div>
                  <div className="time_wrp">
                    <div className="timer_sec">
                      {topBrandsList[idx * 2 + 1]?.merchantInfo?.name}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <br />
    </TopBrandStyled>
  );
};

export default TopBrandDeals;
