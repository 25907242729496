import { Row } from "react-bootstrap";
import { CategoriesStyled } from "./Categories.styled";
import sIcon from "../../../../../images/S.svg";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Categoriesimages, categoriesList } from "../../../../module/Categories";

const Categories = () => {
  const history = useHistory();

  

  return (
    <CategoriesStyled className="mt-5">
      <div className="main_container all_cate_main_wrapper">
        <h2 className="ppl_cate_header">Popular Categories</h2>
        <Row className="list_wrapper">
          {categoriesList?.map((item: any, idx: number) => {
            return (
              <div
                key={`item_${idx}`}
                className="mb-3 cat_main_wrap"
                
              >
                <div className="cat_item">
                  <div className="img_wrap">
                    {/* @ts-ignore */}
                    <img src={Categoriesimages[item?.id]} alt="leaf" />
                  </div>
                  <div className="botm_sec">
                    <img src={sIcon} alt="SD" />
                    Deals
                  </div>
                </div>
                <p className="cat_name">{item?.name}</p>
              </div>
            );
          })}
        </Row>
      </div>
    </CategoriesStyled>
  );
};

export default Categories;
