import styled from "styled-components";

export const FooterStyled = styled.div`
  .extension-footer-container {
    background: #f9f9f9;
    padding: 24px 32px;
    box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.13);
  }
  .extension-footer-top {
    display: flex;
    justify-content: space-between;
  }
  .footer-top-left {
    padding-left: 0px;
    ul {
      list-style-type: none;
      display: flex;
    }
    li {
      padding: 0px 30px;
      color: var(--Shade-100, #000);
      font-size: 17px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 200% */
    }
    li:not(:last-child) {
      border-right: 1px solid #575757;
    }
  }
  .footer-top-right {
    color: var(--Shade-100, #000);
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 200% */

    display: flex;
    ul {
      padding-left: 15px;
      list-style-type: none;
      display: flex;
    }
    li {
      padding: 0px 13px;
    }
  }
  .all-rights-reser {
    border-top: 1px solid #cfd3d7;
    text-align: center;
    padding-top: 20px;
    color: var(--Shade-100, #000);
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px; /* 200% */
  }
  .image_div {
    p {
      color: #000;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
    }
    img {
      margin-bottom: 20px;
    }
  }
  .page_col {
    .heading {
      color: #0e0f1d;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    p {
      color: #0e0f1d;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .contact_col {
    .heading {
      color: #0e0f1d;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    p {
      color: #0e0f1d;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .flw_text {
    color: #0e0f1d;
    font-family: Poppins;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .footer-top-right {
    justify-content: space-between;
    gap: 20px;
    .icon_section {
      display: flex;
      gap: 10px;
    }
  }
  .all-rights-reser {
    p {
      color: var(--Shade-100, #000);
      font-family: Poppins;
      font-size: 17px;
      font-style: normal;
      font-weight: 500;
      line-height: 34px; /* 200% */
      span {
        color: var(--Primary-Red, #ed1c24);
        font-family: Poppins;
        font-size: 17px;
        font-style: normal;
        font-weight: 500;
        line-height: 34px;
      }
    }
  }
  .TandC {
    cursor: pointer;
  }

  .footer_main_container {
    display: flex;
    justify-content: space-between;
    padding-left: 40px;
    padding-right: 40px;
    .footer_left_sec {
      display: flex;
      align-items: center;
      gap: 15px;
      .TandC_boder {
        border-left: 1px solid #575757;
        padding-left: 10px;
      }
    }
  }
`;
