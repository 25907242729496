import { AboutStyled } from "./About.styled";
import arrow from "../../images/arrow-right-black.svg";
import { useHistory } from "react-router-dom";

const AboutUs = () => {
  const history = useHistory();
  return (
    <AboutStyled>
      <div
        id="main-content"
        className="mh-content"
        role="main"
        // itemprop="mainContentOfPage"
      >
        <div className="entry-content clearfix">
          <article
            id="page-738"
            className="post-738 page type-page status-publish"
          >
            <header className="page-header" onClick={() => history?.goBack()}>
              <img src={arrow} alt="arrow" />
              <h1 className="entry-title page-title">About Us</h1>{" "}
            </header>
            <div className="entry-content clearfix">
              <p>
                So glad you found us! Now that you’re here, let us tell you a
                little about us…
              </p>
              <p>
                At Sunsetdeals we believe Beauty has no size and Fashion should
                be fun for everyone. That’s why we hunt for, try, test and
                collect for you the very best of everything we find.
              </p>
              <p>
                Whether you are a size 00 or 44 at Sunsetdeals we celebrate
                YOU!!
              </p>
            </div>
          </article>
        </div>
      </div>
    </AboutStyled>
  );
};

export default AboutUs;
