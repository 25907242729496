import styled from "styled-components";

export const BrandStyed = styled.div`
  .main_wrapper {
    padding: 20px 40px;
    .page-header {
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      h1 {
        color: #212121;
        // font-family: "Helvetica Neue";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 0px;
      }
    }
  }
  .inner_slide {
    display: block !important;
    width: 100%;
    height: 207px;
    border-radius: 8px;
    background: var(--Shade-0, #fff);
    box-shadow: 0px 0px 36px 0px rgba(0, 0, 0, 0.04), 0px 0px 0px 1px #d6d6d6;
    margin-bottom: 30px;
    padding: 20px;
    .img_wrapper {
      width: 100%;
      height: 75px;
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 10px;
      .brand_logo {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        object-fit: scale-down;
      }
    }
    .grab_deal_wrp {
      display: flex;
      justify-content: center;
    }
    .time_wrp {
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }
    .grab_deal {
      display: flex;
      width: 173px;
      padding: 8px 24px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 90px;
      background: var(--www-farfetch-com-mine-shaft, #222);
      color: var(--Shade-0, #fff);
      cursor: pointer;

      /* Button Text/Medium */
      font-family: "Helvetica Neue";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 171.429% */
      letter-spacing: 0.028px;
      text-transform: uppercase;
    }
    .timer_sec {
      display: inline-flex;
      padding: 6px 14px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 6px;
      background: #cc1433;
      color: var(--Shade-0, #fff);
      text-align: center;
      font-family: "Helvetica Neue";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 157.143% */
      text-transform: uppercase;
    }
  }

  @media screen and (max-width: 767px) {
    .main_wrapper {
      padding: 20px 0px;

      .inner_slide {
        padding: 10px;

        .grab_deal {
          font-size: 11px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          padding: 8px 0px;
        }

        .timer_sec {
          padding: 6px 6px;
          font-size: 11px;
          line-height: 20px;
          max-width: 100%;
          display: flex;
          width: 100%;
          overflow-wrap: anywhere;
        }
      }
    }
  }
`;
