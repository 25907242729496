import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SignInTab from "./components/SingInTab";
import logo1 from "../../../images/SunsetdealsIcon.svg";

import "./styles.css";

const Signin = (props) => {
  // local state

  // local state
  let location = useLocation();

  useEffect(() => {
    let urlQueryString = location.search;
    let signInType = new URLSearchParams(urlQueryString).get("returnUrl");
  }, [location.search]);
  const gotoDelivery = () => {
    props?.updateStep("delivery");
  };

  return (
    <div className="loginsignupContainer">
      <div className="data-loginsignupContainer">
        <div className="extension_signin_header">
          <div className="exten-sign-header-logo">
            <img src={logo1} alt="logo" />
            <div className="site_manager_txt">Site Manager</div>
          </div>
          <p className="exten-sign-header">Welcome Back</p>
          <p className="exten-sign-sub-header">
            Please enter Details to Login.
          </p>
        </div>
        <SignInTab gotoDelivery={gotoDelivery} />
      </div>
    </div>
  );
};

export default Signin;
