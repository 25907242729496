import { ContactUsStyled } from "./ContactUs.styled";
import locationIcon from "../../images/location.svg";
import emailIcon from "../../images/mail.svg";
import phoneIcon from "../../images/phone.svg";
import arrow from "../../images/arrow-right-black.svg";
import { useHistory } from "react-router-dom";

const ContactUs = () => {
  const history = useHistory();
  return (
    <ContactUsStyled>
      <div className="main_container">
        <div className="header" onClick={() => history?.goBack()}>
          <img src={arrow} alt="arrow" />
          <h2>Contact Us</h2>
        </div>
        <div className="body_sec">
          <div className="inner_body">
            <div className="top_sec">
              <h1>Contact with Us</h1>
              <p className="assicts">For any assistance, reach us</p>
            </div>
            <div className="bottom_sec">
              <div className="line">
                <img src={locationIcon} alt="location" />
                <p>641 Lexington Avenue, 15th Floor New York, NY 10022</p>
              </div>
              <div className="line email_line">
                <img src={emailIcon} alt="location" />
                <p className="email">hello@theredclub.com</p>
              </div>
              <div className="line phone_line">
                <img src={phoneIcon} alt="location" />
                <p className="phone">(+1) 858 429 9159</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContactUsStyled>
  );
};

export default ContactUs;
