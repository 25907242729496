import styled from "styled-components";

export const HomeCanvasStyled = styled.div`
  .section_wrapper {
    .sec_heading {
      display: flex;
      justify-content: space-between;
      margin-bottom: 14px;
      margin-top: 26px;
      p {
        margin-bottom: 0px;
        color: var(--secondary-colour-300-main, #0b0b0b);
        // font-family: "Helvetica Neue";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 125% */
      }
    }

    .bodi_sec {
      p {
        margin-bottom: 8px;
        color: #666;
        // font-family: "Helvetica Neue";
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        cursor: pointer;
      }
    }
  }
  .header_offer_btn {
    margin-top: 26px;
    color: var(--secondary-colour-300-main, #0b0b0b);
    // font-family: "Helvetica Neue";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
    img {
      margin-left: 8px;
    }
  }
`;
