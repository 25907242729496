import React, { Component, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { LinksStyled } from "./Links.styled";
import { bindActionCreators } from "redux";
import { Row, Col, Button, CardDeck, CardColumns } from "react-bootstrap";
// import 'bootstrap/dist/css/bootstrap.min.css';
import facebook_fill from "../../img/facebook-box-fill.png";
import instagram_fill from "../../img/instagram-fill.png";
import twitter_fill from "../../img/twitter-x-fill.png";
import linkedin_fill from "../../img/linkedin-box-fill.png";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SERVER_IP } from "../../config";
// import { SaleStatusStyled } from "../Dashboard/SaleStatus/SaleStatus.styled";
import {
  getAllPriceLineFeedsApi,
  getAllYahooFeedsApi,
} from "../../redux/slices/auth/authService";

import {
  // createUserClicksApi,
  createUserClicksApi1,
  createUserClicksApi2,
} from "../../redux/slices/auth/authService";

import { Link, withRouter } from "react-router-dom";
const Footer = (props: any) => {
  const dispatch = useDispatch();
  const { allYahooFeedsData, allPriceLineFeedsData } = useSelector(
    (state: any) => state.auth
  );
  const [chanelName, setChanelName] = useState("4");
  //   const link2 =
  //     "https://api-prod.getfeedz.com/ad/sDeDArAkov?clickId=56789&checkIn=20240310&checkOut=20240313&city=new york";
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();

  useEffect(() => {
    getAllClicksInfoData();
  }, []);
  const getAllClicksInfoData = () => {
    dispatch(getAllPriceLineFeedsApi());
    dispatch(getAllYahooFeedsApi());
  };

  //   localhost:3000/Links?checkIn=20240310&checkOut=20240313&city=new%20york&chanel=times of india

  const priceLink = `${SERVER_IP}/addPriceLineFeeds?chanel=${chanelName}`;
  const yahooLink = `${SERVER_IP}/addYahooFeeds?chanel=${chanelName}&q=3000016152`;
  return (
    <>
      <LinksStyled>
        <div className="extension-footer-container">
          <br />
          <br />
          <input
            value={chanelName}
            className="chanel-name-box"
            onChange={(e) => {
              setChanelName(e.target.value);
            }}
          />
          <br />
          <br />
          <div>
            <h5>{priceLink}</h5>
            <a href={priceLink} target="_blank">
              Price link Click
            </a>
          </div>
          <br />
          <br />
          <div>
            <h5>{yahooLink}</h5>
            <a href={yahooLink} target="_blank">
              yahoo Link Click
            </a>
          </div>
          <div></div>
          <br />
          <br />
          <br />
          <br />
        </div>
      </LinksStyled>
    </>
  );
};

export default Footer;
