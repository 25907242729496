import { configureStore } from "@reduxjs/toolkit";

// reducers
import appReducer from "../slices/app/appSlice";
import authReducer from "../slices/auth/authSlice";
import reportsReducer from "../slices/reports/reportsSlice";
import adminReducer from "../slices/Admin/AdminSlice";

const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    reports: reportsReducer,
    admin: adminReducer,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
