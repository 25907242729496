import { ImageUploadModelStyled } from "./ImageUploadModel.styled";
import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import ImageCropper from "./ImageCropper";
import Loader from "./../../../components/Common/Loader";

import {
  saveUserProdCasts,
  updateUserPodcast,
  getAllProdCasts,
  getProdcastById,
  getSignedUrlApi,
} from "../../../redux/slices/auth/authService";
import moment from "moment";
import AWS from "aws-sdk";

import Modal, { ModalProps } from "react-bootstrap/Modal";
import clsx from "clsx";
import CloseButton from "react-bootstrap/CloseButton";
import { useRef } from "react";
import uploadImageNew from "./../../../Scenes/fileUpload";
import {
  S3_BUCKET,
  REGION,
  a_accessKeyId,
  a_secretAccessKey,
} from "../../../config";
const ImageUploadModel = (props: any) => {
  const history = useHistory();
  const [passError, setPassError] = useState("");
  const dispatch = useDispatch();
  const hiddenFileInput = useRef<any>();
  const tagTabRef = useRef<any>();
  const [upImg, setUpImg] = React.useState<any>();
  const [originalImg, setOriginalImg] = React.useState<any>();
  const [infoMsg, setInfoMsg] = React.useState<any>(null);
  const [isLoader, setIsLoader] = useState(false);

  const [btnDisable, setBtnDisable] = React.useState<any>(true);
  const [fileSelected, setFileSelected] = React.useState<any>();
  const [croppedImage, setCroppedImage] = React.useState<any>(undefined);

  const [isUploadedImg, setIsUploadedImg] = React.useState<any>(null);
  const mille_sec = moment.now();

  useEffect(() => {
    if (props?.show) {
      setBtnDisable(true);
      setIsUploadedImg(null);
    }
  }, [props?.show]);

  const handleClick = () => {
    // document.getElementById("image-upload-input-box").click();
    if (hiddenFileInput) {
      hiddenFileInput?.current?.click();
    }
  };
  const onSelectFile = (event: any) => {
    setBtnDisable(false);
    if (event.target.files && event.target.files.length > 0) {
      const imgFile = event.target.files[0];

      console.log("imgFileimgFile", imgFile);
      if (!imgFile.type.includes("image")) {
        setInfoMsg(imgFile.name + " is not an image");
        return;
      }
      if (imgFile.size > 10 * 1024 * 1024) {
        setInfoMsg("Image file size should be less than or equal to 10MB");
        return;
      }
      setOriginalImg(imgFile);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setIsUploadedImg(reader.result);
      });
      reader.readAsDataURL(imgFile);
    }
  };
  const handleConfirmClick = () => {
    uploadFile(fileSelected);
  };
  const handleCancelClick = () => {
    props?.handleClose();
  };
  const uploadFile = async (file: any) => {
    console.log("filefile", file);
    AWS.config.update({
      accessKeyId: a_accessKeyId,
      secretAccessKey: a_secretAccessKey,
    });
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    const dFile = `${mille_sec}_${originalImg?.name}`;
    console.log("dFile", dFile);
    const params = {
      Bucket: S3_BUCKET,
      Key: dFile,
      ContentType: "image/png",
      Body: originalImg,
    };
    // Uploading file to s3
    setIsLoader(true);
    var upload = s3
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        // File uploading progress
        console.log("Uploading %");
      })
      .promise();

    await upload.then((err: any) => {
      console.log("err", err);
      // console.log("data", data);
      setIsLoader(false);
      props?.handleImageUploadSuccess(dFile);
    });
  };

  return (
    <>
      <Modal {...props} size="sm" className="image-upload-model-box" centered>
        <div className="conformation-model">
          <ImageUploadModelStyled>
            <Modal.Body>
              {/* <span
                className="color-picker-close-icon"
                onClick={() => props.setShowImageUploadModel(false)}
              >
                x
              </span> */}
              {isLoader && <Loader />}
              <div className="content-image-upload-model-box">
                <div>
                  <h5>Upload Files From My Computer</h5>
                </div>
                {!isUploadedImg ? (
                  <div className="upload-model-box-content">
                    <div className="top-hea-sec-top">
                      <p className="top-hea-sec">
                        Drop your file from anywhere or
                      </p>
                      <button className="button-upload" onClick={handleClick}>
                        Upload A File
                      </button>
                      <input
                        type="file"
                        id="image-upload-input-box"
                        // onChange={handleChange}
                        onChange={onSelectFile}
                        ref={hiddenFileInput}
                        style={{ display: "none" }} // Make the file input element invisible
                      />
                      {/* <input placeholder="Upload A File" type="file" /> */}
                    </div>
                    <div className="top-hea-sec-content">
                      <ul>
                        <li>
                          Image Dimensions are Equal to (or) Greater Than
                          500X500px
                        </li>
                        <li>High resolution images (PNG, JPG)</li>
                        <li>Orientation of the image should be Portrait</li>
                      </ul>
                    </div>
                    {infoMsg && <p>{infoMsg}</p>}
                  </div>
                ) : (
                  <>
                    <div className="uploaded-img-sec">
                      <img src={isUploadedImg} />
                    </div>
                  </>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="new-option-body-actions">
                <button className="cancel-btn" onClick={handleCancelClick}>
                  Cancel
                </button>
                <button
                  disabled={btnDisable}
                  className="confirm-btn"
                  onClick={handleConfirmClick}
                >
                  Confirm
                </button>
              </div>
            </Modal.Footer>
          </ImageUploadModelStyled>
        </div>
      </Modal>
    </>
  );
};
export default ImageUploadModel;
