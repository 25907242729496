import React, { useEffect, useState } from "react";
import { MyProfileInfoStyled } from "./MyProfileInfo.styled";
// import "./styles.css";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import editIcon from "../../../images/edit.svg";

import EditProfile from "./EditProfile";
import { getUserDetailsById } from "../../../redux/slices/auth/authService";
const MyProfileInfo = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [signUpData, setSignUpData] = useState({});

  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector(({ auth }) => auth);
  useEffect(() => {
    getUserDetailsByIdD();
  }, []);
  useEffect(() => {
    updateUSerDetailstoState();
  }, [user]);

  const updateUSerDetailstoState = () => {
    const addr = { ...signUpData };
    addr.fullName = user?.fullName;
    addr.influenceCode = user?.influenceCode;
    addr.phoneNumber = user?.phoneNumber;
    addr.email = user?.email;
    setSignUpData(addr);
    // user
  };

  const getUserDetailsByIdD = () => {
    dispatch(getUserDetailsById({ id: user?._id }));
  };
  const handleEdit = () => {
    setIsEdit(true);
  };
  const handleCancelEdit = () => {
    setIsEdit(false);
  };

  const handleOpenPassword = () => {
    history.push("/changePassword")
  }



  return (
    <>
      <MyProfileInfoStyled>
        <h2>My Account</h2>
        {!isEdit && (
          <div className="main_section">
            <div className="top_btn_wrp">
              <button onClick={handleEdit}>
                <img src={editIcon} alt="edit" />
                edit
              </button>
            </div>
            <div className="details_sec_top">
              <div className="vendor_logo">
                {user?.fullName?.split(" ")[0][0]}
                {user?.fullName?.split(" ")?.length > 1
                  ? user?.fullName?.split(" ")[1][0]
                  : ""}
              </div>
            </div>
            <div className="name_wrap">
              <div className="vendor_name">{user?.fullName}</div>
            </div>
            {/* <div className="id_wrap">
              <div className="vendor_id">{user?.vendorId}</div>
            </div> */}
            <div className="address_details">
              <div className="inner_wrp">
                <p className="email">{user?.email}</p>
                {/* <p className="mb-0">
                  {`${user?.address1 ? `${user?.address1},` : ``} ${
                    user?.address2 ? `${user?.address2},` : ``
                  } ${user?.city ? `${user?.city},` : ``} ${
                    user?.state ? `${user?.state},` : ``
                  }`}
                </p> */}
                {/* <p>{`${user?.zip ? `${user?.zip},` : ``} ${
                  user?.country ? `${user?.country}` : ``
                }`}</p> */}
                <p>{user?.phoneNumber}</p>
              </div>
            </div>
            <div className="change_psw">
              <button onClick={handleOpenPassword}>Change Password</button>
            </div>
          </div>
        )}
        {isEdit && (
          <EditProfile user={user} handleCancelEdit={handleCancelEdit} />
        )}
      </MyProfileInfoStyled>
    </>
  );
};

export default MyProfileInfo;
