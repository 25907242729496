import OfferCard from "../../components/Common/ConfirmationModal/OffersCard";
import { BannerStyled } from "./Banner.styled";
import { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import eyeIcon from "../../../images/whiteEye.svg";
import { useDispatch, useSelector } from "react-redux";
import deleteIcon from "../../../images/delete.svg";
import ConfirmationModal from "../../components/Common/ConfirmationModal";
import { updateBnnersList } from "../../../redux/slices/Admin/AdminSlice";
import ImageUploadModel from "../../components/ImageUploadModel/ImageUploadModel";
import {
  allImageUploadAPi,
  getAllImageApi,
  ImageUploadAPi,
} from "../../../redux/slices/Admin/AdminService";
import { S3_BUCKET } from "../../../config";
import { siteName } from "../../../Utills/Utill";

const HomeBanner = () => {
  const [showUploadImage, setShowUploadImage] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedImg, setSelectedImg] = useState(0);
  const [isValid, setISValid] = useState(true);
  const [showPopup, setshowPopup] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showImageMoodel, setShowImageMoodel] = useState(false);

  const { bannersList } = useSelector((state: any) => state?.admin);

  const dispatch = useDispatch();
  useEffect(() => {
    isValidCheck();
  }, [bannersList]);

  useEffect(() => {
    getBannerList();
  }, []);

  const getBannerList = async () => {
    const res: any = await dispatch(getAllImageApi({ type: "Banner" }));
    if (res?.payload?.data?.length > 0) {
      dispatch(updateBnnersList(res?.payload?.data));
    }
  };

  // const onSelectFile = async (img: any) => {
  //   setLoader(true);
  //   console.log("Img", img);

  //   const payload = {
  //     name: `banner_${Date.now()}.png`,
  //   };
  //   const res: any = await dispatch(signedImageUploadAPi(payload));

  //   const newArr = [...bannersList];

  //   newArr[selectedImg] = {
  //     imageUrl: res?.payload?.url,
  //     offerType: "Banner",
  //     offerSection: "Banner",
  //     brandName: "sunsetdeals",
  //     websiteName: "sunsetdeals",
  //     title: "",
  //   };
  //   dispatch(updateBnnersList(newArr));

  //   setLoader(false);
  //   setShowUploadImage(false);
  // };

  const handleImageUploadSuccess = async (imgName: any) => {
    handleCloseImage();

    const newArr = [...bannersList];
    const prevImage1 = `https://${S3_BUCKET}.s3.amazonaws.com/${imgName}`;
    newArr[selectedImg] = {
      url: prevImage1,
      offerType: "Banner",
      offerSection: "Banner",
      brandName: "sunsetdeals",
      websiteName: siteName,
      title: "",
    };
    const payload1 = {
      base64Image: prevImage1,
      mime: "image/png",
    };
    const res: any = await dispatch(ImageUploadAPi(payload1));
    dispatch(updateBnnersList(newArr));
  };

  const addMoreBanner = () => {
    if (bannersList?.length < 20) {
      dispatch(updateBnnersList([...bannersList, {}]));
    }
  };
  const handleImageClick = () => {
    // setTempImageData(obj);
    setShowImageMoodel(true);
  };
  const handleCloseImage = () => {
    setShowUploadImage(false);
  };
  const deleteFunction = (id: number) => {
    let list = [...bannersList];
    list[id] = {
      ...list[id],
      url: "",
    };
    dispatch(updateBnnersList(list));
  };

  const isValidCheck = () => {
    let newList = bannersList?.filter(
      (item: any) => Object.keys(item)?.length > 0
    );

    if (newList?.length === 0) {
      setISValid(false);
    } else {
      newList = newList?.filter((i: any) => !i?.url || !i?.redirectUrl);
      setISValid(newList?.length > 0 ? false : true);
    }
  };

  const titleChange = (id: any, value: any) => {
    let list = [...bannersList];
    let obj = list[id];
    list[id] = { ...obj, description: value };
    dispatch(updateBnnersList(list));
  };

  const linkChange = (id: any, value: any) => {
    let list = [...bannersList];
    let obj = list[id];
    list[id] = { ...obj, redirectUrl: value };
    dispatch(updateBnnersList(list));
  };

  const addBannerImages = async () => {
    setLoader(true);
    const netList = bannersList?.filter((x: any) => x.url && x?.redirectUrl);
    const payload = {
      offerSection: "Banner",
      websiteName: siteName,
      items: netList,
    };
    await dispatch(allImageUploadAPi(payload));
    setshowPopup(false);
    setShowSuccess(true);
  };

  return (
    <BannerStyled>
      <div className="main_home_banner">
        <p className="banner_header">Banner Images</p>
        <ImageUploadModel
          show={showUploadImage}
          handleClose={handleCloseImage}
          handleImageUploadSuccess={handleImageUploadSuccess}
        />

        <Button
          className="pre_view_btn"
          onClick={() => {
            localStorage.setItem(
              "localBannerList",
              JSON.stringify(bannersList)
            );
            window.open("/HomePreview", "_blank");
          }}
          disabled={!isValid}
        >
          <img src={eyeIcon} alt="eye-icon" /> Preview in New Tab
        </Button>
      </div>
      {bannersList?.map((item: any, idx: number) => (
        <div className="Main_card_wrapper">
          {item?.url && (
            <div className="del_sec" onClick={() => deleteFunction(idx)}>
              <img src={deleteIcon} alt="test" />
            </div>
          )}
          <OfferCard
            fNo={idx + 1}
            key={`card_${idx}`}
            title={"Banner"}
            setShowUploadImage={setShowUploadImage}
            setSelectedImg={setSelectedImg}
            item={item}
            titleChange={titleChange}
            linkChange={linkChange}
          />
        </div>
      ))}
      <div className="buttons_sec">
        <Button className="add_new_btn" onClick={() => addMoreBanner()}>
          <PlusOutlined />
          Add New banner
        </Button>
        <Button
          className="add_btn"
          disabled={!isValid}
          onClick={() => setshowPopup(true)}
        >
          Add
        </Button>
      </div>
      {/* <UploadImage
        crop={false}
        name="imageName"
        show={showUploadImage}
        isDataLoading={loader}
        onHide={onSelectFile}
        ratio={934 / 157}
        minWidth={934}
        minHeight={157}
        imageText={[
          "High resolution images (PNG, JPG)",
          "Image dimensions are equal to (or) greater than 1440x480px",
        ]}
      /> */}
      <ConfirmationModal
        show={showPopup}
        confirmLabel="Add"
        cancelLabel="Cnacel"
        description={"Are you sure you want to add the banner images?"}
        onHide={() => setshowPopup(false)}
        onConfirmClick={addBannerImages}
        // loading={loader}
      />
      <ConfirmationModal
        show={showSuccess}
        confirmLabel="Okay"
        description={"Your banner images have been successfully added."}
        onHide={() => setShowSuccess(false)}
        onConfirmClick={() => setShowSuccess(false)}
      />
    </BannerStyled>
  );
};

export default HomeBanner;
