import styled from "styled-components";

export const FooterStyled = styled.div`
  .extension-footer-container {
    background: var(--www-farfetch-com-mercury, #e6e6e6);
    padding: 24px 32px;
    box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.13);
  }
  .footer_ss_deal_logo {
    max-width: 230px;
    max-height: 40px;
  }
  .extension-footer-top {
    display: flex;
    justify-content: space-between;
    .row {
      .col-2 {
        flex: 0 0 20% !important;
        max-width: 20% !important;
      }
    }
  }
  .footer-top-left {
    padding-left: 0px;
    ul {
      list-style-type: none;
      display: flex;
    }
    li {
      padding: 0px 30px;
      color: var(--Shade-100, #000);
      font-size: 17px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 200% */
    }
    li:not(:last-child) {
      border-right: 1px solid #575757;
    }
  }
  .footer-top-right {
    color: var(--Shade-100, #000);
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 200% */

    display: block;
    ul {
      padding-left: 15px;
      list-style-type: none;
      display: flex;
    }
    li {
      padding: 0px 13px;
    }
  }
  .all-rights-reser {
    border-top: 1px solid #cfd3d7;
    text-align: center;
    padding-top: 20px;
    color: var(--Shade-100, #000);
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px; /* 200% */
    display: flex;
    justify-content: space-between;
    p {
      color: var(--www-farfetch-com-mine-shaft, #222);
      text-align: center;
      // font-family: "Helvetica Neue";
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      span {
        color: red;
      }
    }
  }
  .image_div {
    p {
      color: var(--www-farfetch-com-mine-shaft, #222);
      font-family: "Helvetica Neue";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 157.143% */
    }
    img {
      margin-bottom: 20px;
    }
  }
  .page_col {
    .heading {
      color: #0e0f1d;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    p {
      color: #0e0f1d;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .contact_col {
    .heading {
      color: #0e0f1d;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    p {
      color: #0e0f1d;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .flw_text {
    color: #0e0f1d;
    font-family: Poppins;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .footer-top-right {
    justify-content: space-between;
    .icon_section {
      display: flex;
      gap: 10px;
    }
  }
  .all-rights-reser {
    p {
      color: var(--Shade-100, #000);
      font-family: Poppins;
      font-size: 17px;
      font-style: normal;
      font-weight: 500;
      line-height: 34px; /* 200% */
      span {
        color: var(--Primary-Red, #ed1c24);
        font-family: Poppins;
        font-size: 17px;
        font-style: normal;
        font-weight: 500;
        line-height: 34px;
      }
    }
  }
  .TandC {
    cursor: pointer;
  }

  @media screen and (max-width: 767px) {
    .extension-footer-top {
      .row {
        .col-12 {
          margin-bottom: 30px;
        }
        .page_col .heading {
          margin-bottom: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .contact_col .heading {
          margin-bottom: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
`;
