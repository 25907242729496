import styled from "styled-components";

export const EditProfileStyled = styled.div`
  .main_wrapper {
    margin-right: 50px;
    border-radius: 16px;
    border: 1px solid #cfd3d7;
    background: #fcfcfc;
    padding: 20px;
    h1 {
      color: var(--Shade-100, #000);
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px; /* 177.778% */
    }
    .row {
      margin-left: 0px;
      margin-right: 0px;
      .input_field {
        display: grid;
        margin-bottom: 15px;
        input {
          height: 52px;
          border-radius: 18px;
          border: 1px solid var(--Neutral-400, #bfc5c9);
          background: var(--Neutral-100, #eceeef);
          background: #fff;
          padding-left: 13px;
          padding-right: 13px;
          color: var(--Shade-100, #000);
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */
        }
        input:disabled {
          background: var(--Neutral-100, #eceeef);
          color: var(--Neutral-400, #bfc5c9);
        }
        label {
          color: var(--Neutral-400, #bfc5c9);
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 125% */
        }
      }
    }
  }
  .action_btn_wrap {
    margin-right: 40px;
    display: flex;
    justify-content: end;
    margin-top: 20px;
    gap: 20px;
    .cls_btn {
      display: flex;
      width: 171px;
      height: 56px;
      padding: 14px 32px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: 18px;
      color: var(--Shade-100, #000);

      /* Button Text/Large */
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
      letter-spacing: 0.032px;
      text-transform: uppercase;
    }
    .update_btn {
      display: flex;
      width: 287px;
      height: 56px;
      padding: 14px 32px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: 18px;
      background: #000;
      color: var(--Shade-100, #000);

      /* Button Text/Large */
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
      letter-spacing: 0.032px;
      text-transform: uppercase;
      color: #fff;
    }
    .update_btn: disabled {
      opacity: 0.5;
    }
  }
`;
