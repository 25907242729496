import React, { useEffect, useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { useDispatch, useSelector } from "react-redux";
import DomainNotFound from "./../DomainNotFound";

import Sidebar from "../SideMenu/Sidebar";
import { LayoutStyled } from "./Layout.styled";
import PreFooter from "../../pages/PreviewPage/Footer";
import Header1 from "../../../components/Header";

const AdminLayout = (props) => {
  useEffect(() => {}, []);
  const { error, loading, user } = useSelector(({ auth }) => auth);
  const urlP = window.location.pathname;
  const patha = window.location.hostname;
  const [isInvalidDomain, setIsInvalidDomain] = useState(false);

  const isAdmin =
    window.location.pathname?.split("/")[1] === "admin" ? true : false;

  useEffect(() => {
    getSubdomainInfo();
  }, []);
  const allowDomains = ["plexeos", "nerdvision", "brandclick"];
  const getSubdomainInfo = async () => {
    try {
      const patha1 = window.location.hostname;

      let pathName = patha1?.split(".")[0] ?? "";
      console.log("pathName", pathName);
      if (
        pathName === "localhost" ||
        pathName === "redclub" ||
        pathName === "www"
      ) {
        pathName = "";
      }
      if (pathName && pathName !== "" && !allowDomains.includes(pathName)) {
        // setIsInvalidDomain(true);
      }
    } catch (e) {
      console.log("error", e);
    }
  };
  console.log("urlP", urlP);
  return (
    <LayoutStyled>
      <div className={"wrapper-hide-header"}>
        {isInvalidDomain ? (
          <DomainNotFound />
        ) : (
          <>
            {urlP == "/admin/HomePreview" || urlP == "/admin/offerPreview" ? (
              <Header1 />
            ) : (
              <Header />
            )}
            <div
              className={`layout-container full-content-sec-page-design ${
                urlP === "/admin/signin" || urlP === "/admin/signup"
                  ? "signin_layout_container"
                  : ""
              }`}
            >
              <div className="my-account-sec-bottom">
                {urlP !== "/admin/signin" &&
                urlP !== "/admin/signup" &&
                urlP !== "/admin/offerPreview" &&
                urlP !== "/admin/HomePreview" &&
                isAdmin ? (
                  <>
                    <div className="left-menu-side-content">
                      <Sidebar />
                    </div>
                    <div className="right-menu-side-content">
                      {" "}
                      {props.children}
                    </div>
                  </>
                ) : (
                  <div className="right-menu-side-content1">
                    {" "}
                    {props.children}
                  </div>
                )}
              </div>
            </div>
            {urlP === "/admin/HomePreview" || urlP === "/admin/offerPreview" ? (
              <PreFooter />
            ) : (
              <Footer />
            )}
          </>
        )}
      </div>
    </LayoutStyled>
  );
};

export default AdminLayout;
