import React, { useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { useDispatch, useSelector } from "react-redux";

import Sidebar from "../SideMenu/Sidebar";
const Layout = (props) => {
  useEffect(() => {}, []);
  const { error, loading, user } = useSelector(({ auth }) => auth);
  const urlP = window.location.pathname;
  console.log("urlP", urlP);
  return (
    <div className={"wrapper-hide-header"}>
      <Header />
      <div className="layout-container full-content-sec-page-design1">
        {/* {user?._id && (
          <div className="my-account-sec-top">
            <h2>Extension Manager</h2>
            <p>Manage your Extension seamless.</p>
          </div>
        )} */}

        <div className="my-account-sec-bottom">
          <div className="right-menu-side-content1"> {props.children}</div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
