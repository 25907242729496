import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP } from "../../../config";
import { post, put, get } from "../../../library/Requests/helpers";

export const handleDeviceRevenueAPI = createAsyncThunk(
  "auth/handleDeviceRevenueAPI",
  async (body: any) =>
    await post(
      `https://mms.amos-service.net/api/users/y_device/accounts/274?date=${body?.startDate}`,
      body,
      "extension"
    )
);
export const getPerformanceReportAPI = createAsyncThunk(
  "dashboard/getPerformanceReportAPI",
  async (body: any) =>
    await post(`${SERVER_IP}/home/redclub/getPerformanceReport`, body)
);
export const getEarningsReportAPI = createAsyncThunk(
  "dashboard/getEarningsReportAPI",
  async (body: any) =>
    await post(`${SERVER_IP}/home/redclub/getEarningsReport`, body)
);
export const handleSaveDailyReports = createAsyncThunk(
  "dashboard/handleSaveDailyReports",
  async (body: any) =>
    await post(`${SERVER_IP}/home/redclub/saveDailyReports`, body)
);
export const getClicksReportsAPI = createAsyncThunk(
  "dashboard/getClicksReportsAPI",
  async (body: any) =>
    await post(`${SERVER_IP}/home/redclub/getClicksReports`, body)
);
