import { useEffect, useState } from "react";
import OfferCard from "../../components/Common/ConfirmationModal/OffersCard";
import { OfferStyled } from "./Offer.styled";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import UploadImage from "../UploadImage/UploadImage";
import eyeIcon from "../../../images/whiteEye.svg";
import { useDispatch, useSelector } from "react-redux";
import deleteIcon from "../../../images/delete.svg";
import ConfirmationModal from "../../components/Common/ConfirmationModal";
import { updateOfferList } from "../../../redux/slices/Admin/AdminSlice";
import {
  allImageUploadAPi,
  getAllImageApi,
  ImageUploadAPi,
} from "../../../redux/slices/Admin/AdminService";
import ImageUploadModel from "../../components/ImageUploadModel/ImageUploadModel";
import { S3_BUCKET } from "../../../config";
import { siteName } from "../../../Utills/Utill";

const OfferBanner = () => {
  const [showUploadImage, setShowUploadImage] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedImg, setSelectedImg] = useState(0);
  const [isValid, setISValid] = useState(true);
  const [showPopup, setshowPopup] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const { offersList } = useSelector((state: any) => state?.admin);

  const dispatch = useDispatch();

  const handleCloseImage = () => {
    setShowUploadImage(false);
  };

  const onSelectFile = async (imgName: any) => {
    handleCloseImage();

    const newArr = [...offersList];
    const prevImage1 = `https://${S3_BUCKET}.s3.amazonaws.com/${imgName}`;
    newArr[selectedImg] = {
      url: prevImage1,
      offerType: "Offer",
      offerSection: "Offer",
      brandName: "sunsetdeals",
      websiteName: siteName,
      title: "",
    };
    const payload1 = {
      base64Image: prevImage1,
      mime: "image/png",
    };
    const res: any = await dispatch(ImageUploadAPi(payload1));
    dispatch(updateOfferList(newArr));
  };

  const addMoreBanner = () => {
    if (offersList?.length < 20) {
      dispatch(updateOfferList([...offersList, {}]));
    }
  };

  useEffect(() => {
    isValidCheck();
  }, [offersList]);

  useEffect(() => {
    getOfferList();
  }, []);

  const getOfferList = async () => {
    const res: any = await dispatch(getAllImageApi({ type: "Offer" }));
    if(res?.payload?.data?.length > 0){
      dispatch(updateOfferList(res?.payload?.data));
    }
    else{
      dispatch(updateOfferList([{}, {}, {}]));
    }
    
  };

  const isValidCheck = () => {
    let newList = offersList?.filter(
      (item: any) => Object.keys(item)?.length > 0
    );

    if (newList?.length === 0) {
      setISValid(false);
    } else {
      newList = newList?.filter((i: any) => !i?.url || !i?.redirectUrl);
      setISValid(newList?.length > 0 ? false : true);
    }
  };

  const titleChange = (id: any, value: any) => {
    let list = [...offersList];
    let obj = list[id];
    list[id] = { ...obj, description: value };
    dispatch(updateOfferList(list));
  };

  const linkChange = (id: any, value: any) => {
    let list = [...offersList];
    let obj = list[id];
    list[id] = { ...obj, redirectUrl: value };
    dispatch(updateOfferList(list));
  };

  const deleteFunction = (id: number) => {
    let list = [...offersList];
    list[id] = {
      ...offersList[id],
      url: "",
    };
    dispatch(updateOfferList(list));
  };

  const addBannerImages = async () => {
    setLoader(true);
    const netList = offersList?.filter((x: any) => x.url && x?.redirectUrl);
    const payload = {
      offerSection: "Offer",
      websiteName: siteName,
      items: netList,
    };
    await dispatch(allImageUploadAPi(payload));
    setshowPopup(false);
    setShowSuccess(true);
  };

  return (
    <OfferStyled>
      <div className="main_home_banner">
        <p className="banner_header">Offers</p>
        <Button
          className="pre_view_btn"
          onClick={() => {
            localStorage.setItem("localOfferList", JSON.stringify(offersList));
            window.open("/offerPreview", "_blank");
          }}
          disabled={!isValid}
        >
          <img src={eyeIcon} alt="eye-icon" /> Preview in New Tab
        </Button>
      </div>

      {offersList?.map((item: any, idx: number) => (
        <div className="Main_card_wrapper">
          {item?.url && (
            <div className="del_sec" onClick={() => deleteFunction(idx)}>
              <img src={deleteIcon} alt="test" />
            </div>
          )}
          <OfferCard
            fNo={idx + 1}
            key={`card_${idx}`}
            title={"Offer"}
            setShowUploadImage={setShowUploadImage}
            setSelectedImg={setSelectedImg}
            item={item}
            titleChange={titleChange}
            linkChange={linkChange}
          />
        </div>
      ))}

      <div className="buttons_sec">
        <Button className="add_new_btn" onClick={() => addMoreBanner()}>
          <PlusOutlined />
          Add New Offer
        </Button>
        <Button
          className="add_btn"
          disabled={!isValid}
          onClick={() => setshowPopup(true)}
        >
          Add
        </Button>
      </div>
      <ImageUploadModel
        show={showUploadImage}
        handleClose={handleCloseImage}
        handleImageUploadSuccess={onSelectFile}
      />
      <ConfirmationModal
        show={showPopup}
        heading={"Add Offers"}
        confirmLabel="Add"
        cancelLabel="Cnacel"
        description={"Are you sure you want to add offers?"}
        onHide={() => setshowPopup(false)}
        onConfirmClick={addBannerImages}
        loading={loader}
      />
      <ConfirmationModal
        show={showSuccess}
        confirmLabel="Okay"
        description={"Your offers have been successfully added."}
        onHide={() => setShowSuccess(false)}
        onConfirmClick={() => setShowSuccess(false)}
      />
    </OfferStyled>
  );
};

export default OfferBanner;
