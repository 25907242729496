import styled from "styled-components";

export const OfferCardStyled = styled.div`
  .main_offer_wrapper {
    border-radius: 16px;
    border: 1px solid #cfd3d7;
    background: #fcfcfc;
    padding: 24px;
    margin-bottom: 30px;

    .name_sec {
      color: var(--Shade-100, #000);
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px; /* 177.778% */
      margin-bottom: 16px;
    }

    .upload_sec {
      display: flex;
      width: 100%;
      height: auto;
      padding: 51px 0px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: 5px;
      border: 1px solid var(--Neutral-100, #eceeef);
      background: var(--Neutral-50, #f8f7fa);
      aspect-ratio: 6 / 1;
      margin-bottom: 20px;

      .pre_upload_sec {
        text-align: center;
        img {
          cursor: pointer;
        }
      }
    }
    .img_wrapper {
      width: 100%;
      margin-bottom: 24px;
      img {
        max-width: 100%;
        // aspect-ratio: 6 / 1;
        object-fit: cover;
        border-radius: 5px;
      }
    }
    .title_sec {
      margin-bottom: 20px;
      label {
        color: var(--Neutral-700, #7f8b93);
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        margin-bottom: 5px;
      }
      input {
        height: 52px;
        border-radius: 18px;
        border: 1px solid #cfd3d7;
        background: #fff;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        color: #000;
      }
    }
    .link_sec {
      label {
        color: var(--Neutral-700, #7f8b93);
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        margin-bottom: 5px;
      }
      input {
        height: 52px;
        border-radius: 18px;
        border: 1px solid #cfd3d7;
        background: #fff;
        color: color: #000;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
      }
    }
  }
  .carousel_page{
  .upload_sec{
    aspect-ratio: 1312 / 477;
  }
    .img_wrapper{
      img{
        aspect-ratio: 1312 / 477;
      }
    }
}
`;
