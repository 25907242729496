import { ProductCardStyled } from "./ProductCard.styled";
import shoe from "../../images/shoe.svg";
import arrow from "../../images/arrowRight.svg";
import { useHistory } from "react-router-dom";
import { getBrandLogo, v_id } from "../../Utills/Utill";
import { useSelector } from "react-redux";
import { InspectionTimer } from "../Timer/CountDownTimer";
import moment from "moment";

const ProductCard = ({ product }: any) => {
  const history = useHistory();
  const { allMerchantsList }: any = useSelector(
    // @ts-ignore
    (reduxState): any => reduxState.app
  );
  const { themeDetails } = useSelector((state: any) => state?.admin);

  console.log("themeDetails", themeDetails);

  const grabDeal = () => {
    window.open(`${product?.url?.value}&af_campaign_id=${v_id}`, "_blank");
  };

  const getBadge = () => {
    const currentDate = new Date();

    const newDate = new Date(currentDate);
    newDate.setHours(currentDate.getHours() + 12);
    return (
      <div className="content-badge">
        <div className="animated-background" />
        <span>
          {<InspectionTimer targetDate={moment.utc(newDate).local()} />}
        </span>
      </div>
    );
  };

  return (
    <ProductCardStyled className="pro_card_styled">
      <div className="main_sec">
        {/* {getBadge()} */}
        <div className="img_wrp">
          <img src={product?.images?.image[0]?.value} alt="img" />
        </div>
        <div className="brand_logo">
          <img
            src={getBrandLogo(allMerchantsList, product?.merchantId)}
            alt="new"
          />
        </div>
        <div className="price_sec">
          <span className="main_price">{product?.price?.value}</span>
          <span className="sale_txt">Sale</span>
        </div>
        {product?.originalPrice?.value && (
          <div className="acc_price_sec">
            <span className="text">Reg. Price</span>
            {<span className="price">{product?.originalPrice?.value}</span>}
          </div>
        )}
        {/* {product?.manufacturer && (
          <div className="tag_sec">
            <div>{product?.manufacturer}</div>
          </div>
        )} */}
        <div className="title_sec">
          <div>{product?.title}</div>
        </div>
        <div className="grab_deal_wrp">
          <a
            className="grab_deal"
            onClick={() => grabDeal()}
            style={{
              background: themeDetails?.grabDealButtonBG
                ? themeDetails?.grabDealButtonBG
                : `#000`,
              color: themeDetails?.grabDealButtonTextColor
                ? themeDetails?.grabDealButtonTextColor
                : `#fff`,
            }}
            href={`${product?.url?.value}&af_campaign_id=${v_id}`}
          >
            Grab Deals
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_3668_15440)">
                <path
                  d="M7.68437 7.00042L4.79688 4.11292L5.62171 3.28809L9.33404 7.00042L5.62171 10.7128L4.79688 9.88792L7.68437 7.00042Z"
                  fill={
                    themeDetails?.grabDealButtonTextColor
                      ? themeDetails?.grabDealButtonTextColor
                      : `#fff`
                  }
                />
              </g>
              <defs>
                <clipPath id="clip0_3668_15440">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>
      </div>
    </ProductCardStyled>
  );
};

export default ProductCard;
