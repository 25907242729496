import styled from "styled-components";

export const HomeStyled = styled.div`
  .home-content-container {
    display: flex;
    gap: 150px;
    margin-top: 20px;
  }
  .download-app-icons-list {
    margin-top: 24px;
    display: flex;
    gap: 20px;
    > div {
      width: 50%;
    }
    img {
      width: 100%;
      cursor: pointer;
    }
  }
  .home-content-left {
    width: 55%;
    padding-top: 150px;
    h2 {
      color: #000;
      font-size: 43px;
      font-style: normal;
      font-weight: 600;
      line-height: 58px; /* 134.884% */
      margin-bottom: 16px;
    }
    p {
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 185.714% */
      margin-bottom: 0px;
    }
    button {
      margin-top: 24px;
      border-radius: 18px;
      background: #ff5733;
      color: var(--Shade-0, #fff);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
      letter-spacing: 0.032px;
      text-transform: capitalize;
      border: none;
      height: 64px;
      width: 100%;
      text-transform: uppercase;
    }
  }
  .home-content-right {
    width: 45%;

    border-radius: 16px;
    background: #feefeb;
    padding: 46px 36px;

    img {
      max-width: 100%;
    }
  }

  .slick-arrow {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #fff;
    color: #fff;
    font-size: 0px;
    content: "" !important;
    z-index: 9;
    box-shadow: 0px 0px 36px 0px rgba(0, 0, 0, 0.13);
    margin-top: -30px;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  .slick-arrow::before {
    margin-bottom: 5px;
  }
  .ant-carousel .slick-prev::before {
    content: "<" !important;
    color: #000 !important;
    font-size: 25px;
    margin-bottom: 5px;
  }
  .ant-carousel .slick-next::before {
    content: ">" !important;
    color: #000 !important;
    font-size: 25px;
    margin-bottom: 5px;
  }
  .ant-carousel .slick-arrow:hover {
    background: #fff !important;
    color: #fff !important;
  }
  .top_brands_styled {
    position: relative;
    background: var(--www-farfetch-com-mine-shaft-4, rgba(34, 34, 34, 0.04));
    padding-top: 5px;
  }

  .ofefr_sm_banner {
    padding: 0px;
  }
  .home-container {
    padding-left: 0px;
    padding-right: 0px;
  }
  .slick-dots {
    bottom: -35px !important;
  }
  .ant-carousel .slick-dots li {
    width: 12px !important;
    height: 12px !important;
    border: 1px solid #474747;
    border-radius: 50%;
  }
  .ant-carousel .slick-dots li.slick-active button {
    background: transparent !important;
    opacity: 1;
  }
  .ant-carousel .slick-dots li.slick-active {
    background: #000 !important;
  }
  .ant-carousel .slick-dots li button:hover {
    background: transparent !important;
  }

  .ant-carousel .slick-dots li button {
    background: transparent !important;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .banner_img_carsousel .slick-slide {
    height: 477px;
    div {
      height: 100%;
    }
  }
  .carousel_img_wrapper {
    img {
      width: 100%;
      object-fit: cover;
    }
  }

  // Mobile

  @media screen and (max-width: 767px) {
    .ofefr_sm_banner {
      padding: 0px;
    }
  }
`;
