import React, { Component, useEffect, useState } from "react";
import { FooterStyled } from "./Footer.styled";
// import 'bootstrap/dist/css/bootstrap.min.css';

import SDIcon from "../../../../images/SunsetdealsIcon.svg";

import FbIcon from "../../../../images/social/fbIcon.svg";
import igIcon from "../../../../images/social/IGIcon.svg";
import xIcon from "../../../../images/social/xIcon.svg";
import ytIcon from "../../../../images/social/YTIcon.svg";
import inIcon from "../../../../images/social/inIcon.svg";
import downArraow from "../../../../images/downArrow.svg";
import upArrow from "../../../../images/upArrow.svg";

import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { checkIsMobile } from "../../../module/utills";

const topBrandsList = [
  "Skechers",
  "Brooks Brothers",
  "Lucky Brand",
  "Crocs",
  "Boston Proper",
];

const categoriesList = [
  "Mature",
  "Computers & Software",
  "Pet Supplies",
  "Clothing & Accessories",
  "Clothing & Accessories",
  "Sports Equipment & Outdoor Gear",
  "Toys",
  "Gifts, Flowers & Food",
  "Appliances",
  "Books & Magazines",
  "Other Products",
];

const PreFooter = (props) => {
  const [showAbout, setShowAbout] = useState(true);
  const [showBrands, setShowBrands] = useState(true);
  const [showCates, setShowCates] = useState(true);
  useEffect(() => {}, []);
  const history = useHistory();

  return (
    <>
      <FooterStyled>
        <div className="extension-footer-container">
          <div className="extension-footer-top">
            <div className="row w-100">
              <div
                className={`${checkIsMobile() ? `col-12` : `col-2`} image_div`}
              >
                <img src={SDIcon} alt="icon" />
                <p className="add_text">
                  641 Lexington Avenue, 15th Floor New York, NY
                  10022hello@theredclub.com (+1) 858 429 9159
                </p>
              </div>
              <div
                className={`${checkIsMobile() ? `col-12` : `col-2`} page_col`}
              >
                <p className="heading">
                  <span>About Sunset Deals</span>
                  {checkIsMobile() && (
                    <span>
                      {showAbout ? (
                        <img
                          src={upArrow}
                          alt="arrow"
                          onClick={() => setShowAbout(!showAbout)}
                        />
                      ) : (
                        <img
                          src={downArraow}
                          alt="arrow"
                          onClick={() => setShowAbout(!showAbout)}
                        />
                      )}
                    </span>
                  )}
                </p>
                {showAbout && (
                  <p
                    className="TandC"
                    onClick={() => history?.push(`/aboutus`)}
                  >
                    About us
                  </p>
                )}
                {showAbout && (
                  <p
                    className="TandC"
                    onClick={() => history.push(`/contactus`)}
                  >
                    Contact Us
                  </p>
                )}
                {showAbout && (
                  <p className="TandC" onClick={() => history?.push(`/policy`)}>
                    Your Privacy Rights
                  </p>
                )}
                {showAbout && (
                  <p
                    className="TandC"
                    onClick={() => history?.push("/termscondition")}
                  >
                    Terms of Use
                  </p>
                )}
                {showAbout && (
                  <p
                    className="TandC"
                    onClick={() => history?.push(`/cookiesPolicy`)}
                  >
                    Cookie Policy
                  </p>
                )}
              </div>

              <div
                className={`${checkIsMobile() ? `col-12` : `col-2`} page_col`}
              >
                <p className="heading">
                  <span>Top Brands</span>
                  {checkIsMobile() && (
                    <span>
                      {showBrands ? (
                        <img
                          src={upArrow}
                          alt="arrow"
                          onClick={() => setShowBrands(!showBrands)}
                        />
                      ) : (
                        <img
                          src={downArraow}
                          alt="arrow"
                          onClick={() => setShowBrands(!showBrands)}
                        />
                      )}
                    </span>
                  )}
                </p>
                {showBrands &&
                  topBrandsList?.map((item) => <p className="TandC">{item}</p>)}
              </div>
              <div
                className={`${
                  checkIsMobile() ? `col-12` : `col-2`
                } contact_col`}
              >
                <p className="heading">
                  <span>Top Categories</span>
                  {checkIsMobile() && (
                    <span>
                      {showCates ? (
                        <img
                          src={upArrow}
                          alt="arrow"
                          onClick={() => setShowCates(!showCates)}
                        />
                      ) : (
                        <img
                          src={downArraow}
                          alt="arrow"
                          onClick={() => setShowCates(!showCates)}
                        />
                      )}
                    </span>
                  )}
                </p>
                {showCates &&
                  categoriesList
                    ?.slice(0, 5)
                    ?.map((item) => <p className="TandC">{item}</p>)}
              </div>
              <div className={`${checkIsMobile() ? `col-12 mb-3` : `col-2`}`}>
                <div className="footer-top-right">
                  <div className="heading mb-3">Follow us on</div>
                  <div className="icon_section">
                    <img src={FbIcon} alt="new" />
                    <img src={igIcon} alt="new" />
                    <img src={xIcon} alt="new" />
                    <img src={ytIcon} alt="new" />
                    <img src={inIcon} alt="new" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="all-rights-reser">
            <p>
              Made with <span>❤️</span> by the Redclub team
            </p>
            <p>Copyright © 2024 Sunsetdeals.com</p>
          </div>
        </div>
      </FooterStyled>
    </>
  );
};

export default PreFooter;
