import React from "react";
import MainRoutes from "./router/MainRoutes";

import Layout from "./components/Layout";
import { Switch, BrowserRouter, useLocation } from "react-router-dom";
import "@webcomponents/webcomponentsjs/custom-elements-es5-adapter.js";
import { ViewportProvider } from "./context/ViewportContext";
import "./App.css";
import "./responsive.css";
import "./mobile-app.css";
// redux
import { Provider, useSelector } from "react-redux";
import store from "./redux/store";
import AdminLayout from "./Dashboard/components/Layout";

const App: React.FC = () => {
  return (
    <ViewportProvider>
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Main />
          </Switch>
        </BrowserRouter>
      </Provider>
    </ViewportProvider>
  );
};

const Main: React.FC = () => {
  const location = useLocation();
  // const isHome = false;
  const isHome = location.pathname === "/";
  const { user } = useSelector((reduxState: any) => reduxState.auth);
  const vendorId = user?.vendorId;

  const urlP = window.location.pathname;

  const isAdmin = window.location.pathname?.split("/")[1] === "admin" ? true : false;
  

  return (
    <>
      {isAdmin || urlP === "/signin" || urlP === "/signup" ? (
        <AdminLayout>
          <MainRoutes />
        </AdminLayout>
      ) : (
        <Layout hideHeader={isHome} hideFooter={isHome}>
          <MainRoutes />
        </Layout>
      )}
    </>
  );
};

export default App;
