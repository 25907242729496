import styled from "styled-components";

export const LinksStyled = styled.div`
  .header-content-top {
    margin-top: 15px;
  }
  .header-content-top-content {
    border-radius: 8px;
    background: var(--Project-White, #fff);
    box-shadow: 2px 0px 24px 5px rgba(86, 59, 255, 0.04);

    width: auto;
    display: inline-flex;
    > div {
      width: 150px;
      text-align: center;
      padding: 12px;
    }
  }
`;
