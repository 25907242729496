import { Col, Row } from "react-bootstrap";
import { BrandStyed } from "./Brand.styled";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import arrow from "../../images/arrowRight.svg";
import arrowBlack from "../../images/arrow-right-black.svg";
import { useEffect, useState } from "react";
import { getSelectedBrandsApi } from "../../redux/slices/Admin/AdminService";

const BrandPage = () => {
  const { allMerchantsList } = useSelector((reduxState: any) => reduxState.app);
  const { themeDetails, selectedBrands } = useSelector(
    (state: any) => state?.admin
  );

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    // getSelectedBrands();
  }, []);

  const grabDeals = async (item: any) => {
    history.push(`/products?byBrand=${item?.mid}`);
  };
  return (
    <BrandStyed>
      <div className="main_wrapper">
        <header className="page-header" onClick={() => history?.goBack()}>
          <img src={arrowBlack} alt="arrow" />
          <h1 className="entry-title page-title">All Brands</h1>{" "}
        </header>
        <Row>
          {selectedBrands?.map((item: any, idx: number) => (
            <Col md={3} xs={6}>
              <div className="inner_slide">
                <div className="img_wrapper">
                  <img
                    src={item?.merchantInfo?.logoUrl}
                    alt="new"
                    className="brand_logo"
                  />
                </div>
                <div className="grab_deal_wrp" onClick={() => grabDeals(item)}>
                  <div
                    className="grab_deal"
                    style={{
                      background: themeDetails?.grabDealButtonBG
                        ? themeDetails?.grabDealButtonBG
                        : `#000`,
                      color: themeDetails?.grabDealButtonTextColor
                        ? themeDetails?.grabDealButtonTextColor
                        : `#fff`,
                    }}
                  >
                    Grab Deals
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_3668_15440)">
                        <path
                          d="M7.68437 7.00042L4.79688 4.11292L5.62171 3.28809L9.33404 7.00042L5.62171 10.7128L4.79688 9.88792L7.68437 7.00042Z"
                          fill={
                            themeDetails?.grabDealButtonTextColor
                              ? themeDetails?.grabDealButtonTextColor
                              : `#fff`
                          }
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3668_15440">
                          <rect width="14" height="14" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
                <div className="time_wrp">
                  <div
                    className="timer_sec"
                    style={{
                      background: themeDetails?.brandTagBG
                        ? themeDetails?.brandTagBG
                        : `#000`,
                      color: themeDetails?.brandTagTextColor
                        ? themeDetails?.brandTagTextColor
                        : `#fff`,
                    }}
                  >
                    {item?.merchantInfo?.name}
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </BrandStyed>
  );
};

export default BrandPage;
