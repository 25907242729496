import styled from "styled-components";

export const MAinHomeStyled = styled.div`
  .main_page_wrapper {
    h3 {
      color: var(--Shade-100, #000);
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 27px; /* 135% */
      margin-bottom: 30px;
    }
    .nav-tabs {
      border-bottom: 0px;
      margin-bottom: 30px;
      .nav-item {
        color: var(--Neutral-500, #adb4b9);
        text-align: center;
        // font-family: "Helvetica Neue";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 27px; /* 168.75% */
        border-bottom: 1px solid var(--Neutral-500, #adb4b9);
        border-left: 0px;
        border-right: 0px;
        border-top: 0px;
      }
      }
      .nav-item.active {
        color: #000;
        border-bottom: 2px solid #000;
        background-color: transparent;
        border-left: 0px;
        border-right: 0px;
        border-top: 0px;
      }
    }
  }
`;
