import { SortCanvasStyled } from "./SortCanvas.styled";
import { Button, Drawer, Space } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { checkIsMobile } from "../../Utills/Utill";
import "./Sort.css";
import { useState } from "react";
import { Input, Radio } from "antd";
import type { RadioChangeEvent } from "antd";

const SortCanvas = ({ open, setOpen, clearFilter, mobileSortApply }: any) => {
  const [value, setValue] = useState(0);
  const onClose = () => {
    setOpen(false);
  };

  const onChange = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  return (
    <>
      <Drawer
        title="Sort"
        placement={checkIsMobile() ? "bottom" : "left"}
        width={400}
        onClose={onClose}
        open={open}
        className="sort_canvas"
        extra={
          <Space>
            <CloseOutlined onClick={onClose} />
          </Space>
        }
      >
        <SortCanvasStyled>
          <Radio.Group onChange={onChange} value={value}>
            <Space direction="vertical">
              <Radio value={1}>Price: Low to High</Radio>
              <Radio value={2}>Price: High to Low</Radio>
            </Space>
          </Radio.Group>
        </SortCanvasStyled>
        <div className="footer_sec">
          <Button
            className="cancel_btn"
            onClick={() => {
              setOpen(false);
              clearFilter();
              setValue(0);
              // history.push("/products");
            }}
            // disabled={checkedList?.length === 0}
          >
            Clear All
          </Button>
          <Button
            className="apply_btn"
            // disabled={checkedList?.length === 0}
            onClick={() => mobileSortApply(value)}
          >
            Apply
          </Button>
        </div>
      </Drawer>
    </>
  );
};

export default SortCanvas;
