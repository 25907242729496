import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";

import { useHistory, useLocation } from "react-router-dom";
import eyeIcon from "../../../../../images/eyeClose.svg";
import openEyeIcon from "../../../../../images/openEye.svg";
import { SignupStyled } from "./Signup.styled";
import { signUpUser } from "../../../../../redux/slices/auth/authService";

// import "./styles.css";
const initVal = {
  fullName: undefined,
  phoneNumber: undefined,
  email: undefined,
  password: undefined,
  conformPassword: undefined,
};

const initValError = {
  fullName: false,
  phoneNumber: false,
  email: false,
  password: false,
  conformPassword: false,
};

const initValErrorData = {
  fullName: "Please Enter Full Name",
  phoneNumber: "Please Enter Phone number",
  email: "Please Enter valid email",
  password: "Please Enter Password",
  conformPassword: "Please Enter Conform Password",
};

const SignUpTab = () => {
  const [signUpData, setSignUpData] = useState(initVal);
  const [errorData, setErrorData] = useState(initValError);
  const [errorDataValue, setErrorDataValue] = useState(initValErrorData);
  const [isCheckedBox, setIsCheckedBox] = useState(false);
  const { error, loading, user } = useSelector(({ auth }) => auth);
  const history = useHistory();
  const [passError, setPassError] = useState(null);
  const [showPass, setShowPass] = useState(false);
  const dispatch = useDispatch();

  const [isTouch, setIsTouch] = useState({});

  // useEffect(() => {
  //   if (user?._id) {
  //     history.push("/homeBanner");
  //   } else {
  //     history.push("/signin");
  //   }
  // }, [user]);
  const changeCheckBox = (e) => {
    setIsCheckedBox(e.target.checked);
  };
  const handleChangeValue = (e) => {
    setPassError(null);
    let { name, value } = e.target;
    var value1 = value;
    if (value.trim() == "") {
      value1 = value.trim();
    }
    const addr = { ...signUpData };
    const addr_error = { ...errorData };
    if (name == "phoneNumber" || name == "officeNo") {
      const re = /^[0-9\b]+$/;
      if (value1 === "" || re.test(value1)) {
        setSignUpData({
          ...addr,
          [name]: value1,
        });
      }
    } else if (name === "email" || name == "officeEmail") {
      setSignUpData({
        ...addr,
        [name]: value1,
      });
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (emailRegex.test(String(value).toLowerCase())) {
        setErrorData({
          ...addr_error,
          [name]: false,
        });
      } else {
        setErrorData({
          ...addr_error,
          [name]: true,
        });
      }
    } else {
      setSignUpData({
        ...addr,
        [name]: value1,
      });
    }
  };
  const createAccountData = async () => {
    setPassError(null);
    var isValid = true;
    var errornew = { ...errorData };
    for (var key in signUpData) {
      if (signUpData[key] === "" || !signUpData[key]) {
        errornew[key] = true;
        isValid = false;
      }
    }
    setErrorData({ ...errornew });

    if (signUpData?.password !== signUpData?.conformPassword) {
      isValid = false;
      setPassError(
        "Password and Confirm Password fields value must be matched."
      );
    } else if (signUpData?.password.length < 6) {
      isValid = false;
      setPassError("Password requires 6 characters minimum.");
    }

    if (isValid) {
      await makeSignUpCall(signUpData);
      history.push("/signin");
    }
  };
  const makeSignUpCall = async (data) => {
    const resp = await dispatch(signUpUser(data));
    if (resp?.payload?.error) {
      setPassError(resp?.payload?.error);
    }
  };
  const validationCheck = () => {
    var formValid = false;
    const filteredList = delete signUpData.phoneNumber;
    for (var key in filteredList) {
      if (filteredList[key] === "" || !filteredList[key]) {
        formValid = true;
      }
    }
    if (!isCheckedBox) {
      formValid = true;
    }
    return formValid;
  };

  function isValid() {
    // Loop through the object values
    for (let key in errorData) {
      if (errorData[key] === true) {
        return false; // Return false if any value is true
      }
    }
    return true; // Return true if no true value is found
  }
  const handleBlur = (field) => {
    setIsTouch({ ...isTouch, [field]: true });
  };

  return (
    <SignupStyled>
      <div className="SignupContainer">
        <Form.Group className="signup-md1" size="lg" controlId="email">
          <Form.Label className="mb-1">Full Name*</Form.Label>
          <Form.Control
            autoFocus
            name="fullName"
            type="text"
            placeholder="Enter Full Name"
            value={signUpData?.fullName}
            onChange={(e) => handleChangeValue(e)}
            maxLength={60}
            onBlur={() => handleBlur("fullName")}
          />
          <Form.Control.Feedback type="invalid">
            {errorData?.fullName ? <>{errorDataValue?.fullName}</> : null}
            {!errorData?.fullName &&
              isTouch?.fullName &&
              !signUpData?.fullName && <>{`Please Enter Full Name`}</>}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="signup-md1-left" size="lg" controlId="email">
          <Form.Label className="mb-1">Email Address*</Form.Label>
          <Form.Control
            type="text"
            name="email"
            placeholder="Enter Email Address"
            value={signUpData?.email}
            onChange={(e) => handleChangeValue(e)}
            onBlur={() => handleBlur("email")}
          />
          <Form.Control.Feedback type="invalid">
            {errorData?.email ? <>{errorDataValue?.email}</> : null}
            {!errorData?.email && isTouch?.email && !signUpData?.email && (
              <>{`Please Enter Email`}</>
            )}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="signup-md1" size="lg" controlId="email">
          <Form.Label className="mb-1">Phone Number</Form.Label>
          <Form.Control
            type="text"
            maxLength={10}
            name="phoneNumber"
            placeholder="Enter Phone Number"
            value={signUpData?.phoneNumber}
            onChange={(e) => handleChangeValue(e)}
          />
          <Form.Control.Feedback type="invalid">
            {errorData?.phoneNumber ? <>{errorDataValue?.phoneNumber}</> : null}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group
          className="signup-md1 password_field"
          size="lg"
          controlId="password"
        >
          <Form.Label className="mb-1">Password*</Form.Label>
          {showPass ? (
            <img
              src={eyeIcon}
              alt="eye"
              className="eyeIcon"
              onClick={() => setShowPass(!showPass)}
            />
          ) : (
            <img
              src={openEyeIcon}
              alt="open-eye"
              className="eyeIcon"
              onClick={() => setShowPass(!showPass)}
            />
          )}
          <Form.Control
            type={showPass ? "text" : "password"}
            name="password"
            placeholder="Password"
            isInvalid={errorData?.password}
            value={signUpData?.password}
            onChange={(e) => handleChangeValue(e)}
            onBlur={() => handleBlur("password")}
            maxLength={30}
          />
          <Form.Control.Feedback type="invalid">
            {errorData?.password ? <>{errorDataValue?.password}</> : null}
            {!errorData?.password &&
              isTouch?.password &&
              !signUpData?.password && <>{`Please Enter Password`}</>}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="signup-md1-left" size="lg" controlId="password">
          <Form.Label className="mb-1">Confirm Password*</Form.Label>
          <Form.Control
            type="password"
            name="conformPassword"
            placeholder="Confirm Password"
            value={signUpData?.conformPassword}
            onChange={(e) => handleChangeValue(e)}
            onBlur={() => handleBlur("conformPassword")}
            maxLength={30}
          />
          {passError && (
            <p className="errorMessage errorMessage-signup sign_con_pass_error">
              {passError}
            </p>
          )}
          {!passError &&
            isTouch?.conformPassword &&
            !signUpData?.conformPassword && (
              <p className="errorMessage sign_con_pass_error">{`Please Enter Password`}</p>
            )}
        </Form.Group>
        <div className="account-set-up-sec-exten">
          <input
            type="checkbox"
            checked={isCheckedBox}
            onChange={changeCheckBox}
          />{" "}
          {` `}
          By creating an account you agree to the{" "}
          <span>
            <a onClick={() => history?.push("/termscondition")}>
              Terms & Conditions
            </a>
          </span>{" "}
          and our{" "}
          <span>
            <a onClick={() => history?.push("/policy")}>Privacy policy</a>
          </span>
        </div>

        {error && !passError && (
          <p className="errorMessage errorMessage-signup">{error}</p>
        )}
        <button
          disabled={validationCheck() || !isValid()}
          onClick={() => {
            createAccountData();
          }}
          className="sign-up-btn-sec-exten sign_up_button"
          block
          size="lg"
          type="submit"
        >
          {loading ? "Loading..." : "Sign Up"}
        </button>
        <div className="dont-have-acc-sec">
          <p>
            Already have an account?{` `}
            <span>
              <a href="/admin/signin">Log in</a>
            </span>
          </p>
        </div>
      </div>
    </SignupStyled>
  );
};

export default SignUpTab;
