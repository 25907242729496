import styled from "styled-components";

export const SortCanvasStyled = styled.div`
  .ant-radio-wrapper {
    color: var(--secondary-colour-300-main, #0b0b0b);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
`;
