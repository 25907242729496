import styled from "styled-components";

export const TopBrandStyled = styled.div`
  .header_wrp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 60px;
    .header {
      margin-bottom: 0px;
      color: var(--secondary-colour-300-main, #0b0b0b);
      // font-family: "Helvetica Neue";
      font-size: 26px;
      font-style: normal;
      font-weight: 500;
      line-height: 35px; /* 134.615% */
    }
    .view_all {
      margin-bottom: 0px;
      cursor: pointer;
      z-index: 9;
    }
  }

  .carousel_wrap {
    padding: 30px;
  }

  .slick-slide div:first-child {
    display: flex;
    justify-content: space-around;
    gap: 20px;
  }

  .main_slide {
    .inner_slide {
      display: block !important;
      width: 100%;
      height: 207px;
      border-radius: 8px;
      background: var(--Shade-0, #fff);
      box-shadow: 0px 0px 36px 0px rgba(0, 0, 0, 0.04), 0px 0px 0px 1px #d6d6d6;
      margin-bottom: 30px;
      padding: 20px;
      .img_wrapper {
        width: 100%;
        height: 75px;
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 10px;
        .brand_logo {
          display: flex;
          width: 100%;
          height: 100%;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          object-fit: scale-down;
        }
      }
      .grab_deal_wrp {
        display: flex;
        justify-content: center;
      }
      .time_wrp {
        display: flex;
        justify-content: center;
        margin-top: 10px;
      }
      .grab_deal {
        display: flex;
        width: 173px;
        padding: 8px 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 90px;
        background: var(--www-farfetch-com-mine-shaft, #222);
        color: var(--Shade-0, #fff);
        cursor: pointer;

        /* Button Text/Medium */
        font-family: "Helvetica Neue";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 171.429% */
        letter-spacing: 0.028px;
        text-transform: uppercase;
      }
      .timer_sec {
        display: inline-flex;
        padding: 6px 14px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 6px;
        background: #cc1433;
        color: var(--Shade-0, #fff);
        text-align: center;
        font-family: "Helvetica Neue";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
        text-transform: uppercase;
      }
    }
  }
  .topo {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 0;
    img {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      z-index: -1;
      object-fit: cover;
      object-position: center bottom;
      animation: 10s ease-in-out 0s infinite normal none running zoom-in-out;
      height: 100% !important;
    }
  }
  .loader_wrapper {
    width: 100%;
    gap: 1%;
    margin-top: 20px;
    margin-bottom: 20px;
    .ant-space-item {
      width: 24%;
      .ant-skeleton,
      .ant-skeleton-input {
        width: 100%;
        height: 230px;
        border-radius: 8px;
      }
    }
  }

  // Mobile

  @media screen and (max-width: 767px) {
    .header_wrp {
      margin-top: 16px;
      padding-left: 16px;
      padding-right: 16px;
      h1 {
        color: var(--www-target-com-mine-shaft, #333);
        // font-family: "Helvetica Neue";
        font-size: 16px !important;
        font-style: normal;
        font-weight: 500 !important;
        line-height: 25px !important; /* 156.25% */
      }
      .view_all {
        color: var(--secondary-colour-300-main, #0b0b0b);
        text-align: right;
        font-family: Roboto;
        font-size: 12px !important;
        font-style: normal;
        font-weight: 500 !important;
        line-height: 20px !important; /* 166.667% */
      }
    }
    .carousel_wrap {
      padding: 30px 5px 0px 5px !important;
      .mobl_brand_wrapper {
        display: flex;
        overflow: auto;
        max-width: 100%;
        flex-wrap: nowrap;
        width: 100%;
        gap: 10px;
        white-space: nowrap;
        z-index: 9;
        position: relative;
        .main_slide {
          margin-left: 0px !important;
          width: 161px;
          .inner_slide {
            height: 150px !important;
            .img_wrapper {
              height: 24.289px;
            }
            .grab_deal_wrp {
              .grab_deal {
                width: 125px;
                color: var(--Shade-0, #fff);
                font-family: Poppins !important;
                font-size: 12px !important;
                font-style: normal !important;
                font-weight: 600 !important;
                line-height: 16px; /* 133.333% */
                letter-spacing: 0.024px !important;
                text-transform: uppercase;
              }
            }
            .time_wrp {
              .timer_sec {
                color: var(--Shade-0, #fff);
                text-align: center;
                font-family: "Helvetica Neue";
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px; /* 133.333% */
                padding: 6px 6px;
              }
            }
          }
        }
      }
    }
    .loader_wrapper .ant-space-item {
      width: 49%;
    }
    .loader_wrapper .ant-space-item .ant-skeleton {
      height: 200px;
      border-radius: 8px;
      width: 100%;
      overflow: hidden;
    }
  }
`;
