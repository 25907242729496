import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getAllImageApi,
  getThemeDetailsApi,
  ImageUploadAPi,
  uploadThemeApi,
  getSelectedBrandsApi,
} from "./AdminService";

export interface AppState {
  loading: boolean;
  showBackButton: boolean;
  bannersList: any[];
  offersList: any[];
  carouselList: any[];
  selectedBrands: any[];
  error?: any;
  themeDetails?: any;
}

const initialState: AppState = {
  loading: false,
  showBackButton: false,
  error: {},
  selectedBrands: [],
  // @ts-ignore
  bannersList: JSON.parse(localStorage?.getItem("localBannerList")) || [
    {},
    {},
    {},
  ],
  // @ts-ignore
  offersList: JSON.parse(localStorage?.getItem("localOfferList")) || [
    {},
    {},
    {},
  ],
  // @ts-ignore
  carouselList: JSON.parse(localStorage?.getItem("localCarouselList")) || [
    {},
    {},
    {},
  ],
  themeDetails: {},
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setLoading: (state: AppState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setShowBackButton: (state: AppState, action: PayloadAction<boolean>) => {
      state.showBackButton = action.payload;
    },
    updateBnnersList: (state: AppState, action: PayloadAction<any>) => {
      state.bannersList = action?.payload;
    },
    updateOfferList: (state: AppState, action: PayloadAction<any>) => {
      state.offersList = action?.payload;
    },
    updateCarouselList: (state: AppState, action: PayloadAction<any>) => {
      state.carouselList = action?.payload;
    },
    updateSelectedBrands: (state: AppState, action: PayloadAction<any>) => {
      state.selectedBrands = action?.payload;
    },
    updateTheme: (state: AppState, action: PayloadAction<any>) => {
      state.themeDetails = action?.payload;
    },
  },
  extraReducers: (builder: any) => {
    // Image upload
    builder.addCase(ImageUploadAPi.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      ImageUploadAPi.fulfilled,
      (state: AppState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
      }
    );
    builder.addCase(ImageUploadAPi.rejected, (state: AppState, action: any) => {
      state.loading = false;
    });

    // get All Image
    builder.addCase(getAllImageApi.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAllImageApi.fulfilled,
      (state: AppState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
      }
    );
    builder.addCase(getAllImageApi.rejected, (state: AppState, action: any) => {
      state.loading = false;
    });

    // add Theme Api
    builder.addCase(uploadThemeApi.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      uploadThemeApi.fulfilled,
      (state: AppState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
      }
    );
    builder.addCase(uploadThemeApi.rejected, (state: AppState, action: any) => {
      state.loading = false;
    });
    // updateSelectedBrands([
    //   ...res?.payload?.data[res?.payload?.data?.length - 1]?.ids,
    // ])

    // get Theme Api
    builder.addCase(getThemeDetailsApi.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getThemeDetailsApi.fulfilled,
      (state: AppState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.themeDetails = action.payload.data[0]?.websiteData;
      }
    );
    builder.addCase(
      getThemeDetailsApi.rejected,
      (state: AppState, action: any) => {
        state.loading = false;
      }
    );
    //getSelectedBrandsApi
    builder.addCase(getSelectedBrandsApi.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getSelectedBrandsApi.fulfilled,
      (state: AppState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        console.log("action?.payload", action?.payload?.data?.ids);
        state.selectedBrands = action?.payload?.data?.ids;
      }
    );
    builder.addCase(
      getSelectedBrandsApi.rejected,
      (state: AppState, action: any) => {
        state.loading = false;
      }
    );
  },
});

export const {
  setLoading,
  setShowBackButton,
  updateBnnersList,
  updateOfferList,
  updateCarouselList,
  updateSelectedBrands,
  updateTheme,
} = adminSlice.actions;

export default adminSlice.reducer;
