import OfferCard from "../../components/Common/ConfirmationModal/OffersCard";
import { BannerStyled } from "./Banner.styled";
import { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import UploadImage from "../UploadImage/UploadImage";
import eyeIcon from "../../../images/whiteEye.svg";
import { useDispatch, useSelector } from "react-redux";
import deleteIcon from "../../../images/delete.svg";
import ConfirmationModal from "../../components/Common/ConfirmationModal";
import { updateCarouselList } from "../../../redux/slices/Admin/AdminSlice";
import {
  allImageUploadAPi,
  getAllImageApi,
  ImageUploadAPi,
} from "../../../redux/slices/Admin/AdminService";
import ImageUploadModel from "../../components/ImageUploadModel/ImageUploadModel";
import { S3_BUCKET } from "../../../config";
import { siteName } from "../../../Utills/Utill";

const CarouselImage = () => {
  const [showUploadImage, setShowUploadImage] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedImg, setSelectedImg] = useState(0);
  const [isValid, setISValid] = useState(true);
  const [showPopup, setshowPopup] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const { carouselList } = useSelector((state: any) => state?.admin);

  const dispatch = useDispatch();

  useEffect(() => {
    isValidCheck();
  }, [carouselList]);

  useEffect(() => {
    getCarouselList();
  }, []);

  const getCarouselList = async () => {
    const res: any = await dispatch(getAllImageApi({ type: "Carousel" }));
    if(res?.payload?.data?.length > 0){
      dispatch(updateCarouselList(res?.payload?.data));
    }
    
  };

  const isValidCheck = () => {
    let newList = carouselList?.filter(
      (item: any) => Object.keys(item)?.length > 0
    );

    if (newList?.length === 0) {
      setISValid(false);
    } else {
      newList = newList?.filter((i: any) => !i?.url || !i?.redirectUrl);
      setISValid(newList?.length > 0 ? false : true);
    }
  };

  const titleChange = (id: any, value: any) => {
    let list = [...carouselList];
    let obj = list[id];
    list[id] = { ...obj, description: value };
    dispatch(updateCarouselList(list));
  };

  const linkChange = (id: any, value: any) => {
    let list = [...carouselList];
    let obj = list[id];
    list[id] = { ...obj, redirectUrl: value };
    dispatch(updateCarouselList(list));
  };

  const handleCloseImage = () => {
    setShowUploadImage(false);
  };

  const onSelectFile = async (imgName: any) => {
    handleCloseImage();

    const newArr = [...carouselList];
    const prevImage1 = `https://${S3_BUCKET}.s3.amazonaws.com/${imgName}`;
    newArr[selectedImg] = {
      url: prevImage1,
      offerType: "Carousel",
      offerSection: "Carousel",
      brandName: "sunsetdeals",
      websiteName: siteName,
      title: "",
    };
    const payload1 = {
      base64Image: prevImage1,
      mime: "image/png",
    };
    const res: any = await dispatch(ImageUploadAPi(payload1));
    dispatch(updateCarouselList(newArr));
  };

  const addMoreBanner = () => {
    if (carouselList?.length < 20) {
      dispatch(updateCarouselList([...carouselList, {}]));
    }
  };

  const deleteFunction = (id: number) => {
    let list = [...carouselList];
    list[id] = {
      ...carouselList[id],
      url: "",
    };
    dispatch(updateCarouselList(list));
  };

  const addBannerImages = async () => {
    setLoader(true);
    const netList = carouselList?.filter((x: any) => x.url && x?.redirectUrl);
    const payload = {
      offerSection: "Carousel",
      websiteName: siteName,
      items: netList,
    };
    await dispatch(allImageUploadAPi(payload));
    setshowPopup(false);
    setShowSuccess(true);
  };

  return (
    <BannerStyled>
      <div className="main_home_banner">
        <p className="banner_header">Homepage Carousel Images</p>
        <Button
          className="pre_view_btn"
          onClick={() => {
            localStorage.setItem(
              "localCarouselList",
              JSON.stringify(carouselList)
            );
            window.open("/HomePreview", "_blank");
          }}
          disabled={!isValid}
        >
          <img src={eyeIcon} alt="eye-icon" /> Preview in New Tab
        </Button>
      </div>
      {carouselList?.map((item: any, idx: number) => (
        <div className="Main_card_wrapper">
          {item?.url && (
            <div className="del_sec" onClick={() => deleteFunction(idx)}>
              <img src={deleteIcon} alt="test" />
            </div>
          )}
          <OfferCard
            fNo={idx + 1}
            key={`card_${idx}`}
            title={"Carousel"}
            setShowUploadImage={setShowUploadImage}
            setSelectedImg={setSelectedImg}
            item={item}
            linkChange={linkChange}
            titleChange={titleChange}
            pageName="carousel_page"
          />
        </div>
      ))}
      <div className="buttons_sec">
        <Button className="add_new_btn" onClick={() => addMoreBanner()}>
          <PlusOutlined />
          Add New banner
        </Button>
        <Button
          className="add_btn"
          disabled={!isValid}
          onClick={() => setshowPopup(true)}
        >
          Add
        </Button>
      </div>
      <ImageUploadModel
        show={showUploadImage}
        handleClose={handleCloseImage}
        handleImageUploadSuccess={onSelectFile}
      />
      <ConfirmationModal
        show={showPopup}
        confirmLabel="Add"
        cancelLabel="Cnacel"
        description={"Are you sure you want to add the carousel images?"}
        onHide={() => setshowPopup(false)}
        onConfirmClick={addBannerImages}
        loading={loader}
      />
      <ConfirmationModal
        show={showSuccess}
        confirmLabel="Okay"
        description={"Your carousel images have been successfully added."}
        onHide={() => setShowSuccess(false)}
        onConfirmClick={() => setShowSuccess(false)}
      />
    </BannerStyled>
  );
};

export default CarouselImage;
