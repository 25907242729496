import React, { useEffect } from "react";
import notFound from "../../images/not-found.gif";
import "./styles/custom.css";

const DomainNotFound = (props: any) => {
  const gotoNewPage = () => {
    window.location.href = "https://www.redclub.org/signIn";
  };
  return (
    <>
      <div className="not-found"></div>
      <div className="not-found-section">
        <p className="not-found-content">
          Your domain server is not yet activated, Please contact support team.
        </p>
        <p className="not-found-content">
          <button onClick={gotoNewPage}>GO TO HOME PAGE</button>
        </p>
      </div>
    </>
  );
};

export default DomainNotFound;
