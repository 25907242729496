import { Input, Popover } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateTheme } from "../../../redux/slices/Admin/AdminSlice";
import { HexColorPicker } from "react-colorful";
import { Col } from "react-bootstrap";

const CardBody = ({ title, name }: any) => {
  const { themeDetails } = useSelector(
    (state: any) => state?.admin
  );

  const dispatch = useDispatch();

  const onChange = (value: any) => {
    let colors = themeDetails;
    dispatch(updateTheme({ ...colors, [name]: `${value}` }));
  };

  const onColorPick = (value: any) => {
    let colors = themeDetails;
    dispatch(updateTheme({ ...colors, [name]: `${value}` }));
  };

  console.log("colorsDetails >>", themeDetails);

  return (
    <>
      <div className="card_body">
        <div className="row">
          <Col md={5} className="left_col">
            <div className="body_title">{title}:</div>
          </Col>
          <Col md={7} className="right_col">
            <Popover
              content={
                <HexColorPicker
                  color={
                    themeDetails !== undefined &&
                    Object.keys(themeDetails)?.length > 0 &&
                    themeDetails[name]
                      ? themeDetails[name]
                      : `#000`
                  }
                  onChange={onColorPick}
                />
              }
              title="Choose Color"
              trigger="click"
            >
              <div
                className="color_display"
                style={{
                  background: `${
                    themeDetails !== undefined &&
                    Object.keys(themeDetails)?.length > 0 &&
                    themeDetails[name]
                      ? `${themeDetails[name]}`
                      : `#000`
                  }`,
                }}
              ></div>
            </Popover>
            <Input
              className="color_name"
              placeholder="Enter Color Code (ex: #FFF)"
              onChange={(e: any) => onChange(e.target.value)}
              prefix=""
              value={
                themeDetails !== undefined &&
                Object.keys(themeDetails)?.length > 0 &&
                themeDetails[name]
                  ? themeDetails[name]
                  : ``
              }
            ></Input>
          </Col>
        </div>

        {/* <div className="edit_wrap">
          <EditOutlined />
        </div> */}
      </div>
    </>
  );
};

export default CardBody;
