import { RedirectionStyled } from "./Redirection.styled";
import logo from "../../images/SunsetdealsIcon.svg";
import bbLogo from "../../images/Brooks_Brothers_logo.svg";
import redirectImg from "../../images/redirect.gif";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const RedirectionPage = () => {
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      history?.push("/");
    }, 3000);
  }, []);
  return (
    <RedirectionStyled>
      <div className="main_wrapper_re">
        <div className="text_wrapper">
          <p className="head_text">
            Please wait you are being redirected to Brook Brothers.in
          </p>
          <p className="semi_text">
            Shop normally at brookbrothers.in, Offers will be automatically
            update on the eligible products while you checking out a product.
          </p>
          <div className="rdc_wrap">
            <img src={logo} alt="Logo" />
            <img src={redirectImg} alt="redirect" />
            <img src={bbLogo} alt="other_logo" />
          </div>
        </div>
      </div>
    </RedirectionStyled>
  );
};

export default RedirectionPage;
