import styled from "styled-components";

export const AllProductStyled = styled.div`
  .main_wrapper {
    padding: 0px 30px 30px 30px;
    background: #fff;
    .header {
      color: var(--www-target-com-mine-shaft, #333);
      font-family: "Helvetica Neue";
      font-size: 26px;
      font-style: normal;
      font-weight: 500;
      line-height: 35px; /* 134.615% */
      margin-bottom: 12px;
    }
    .bread_crumb {
      color: var(--Secondary-Colour-100, #757575);
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 125% */
      .bdc_Home {
        cursor: pointer;
      }
      .bdc_Home:hover {
        text-decoration: underline;
      }
    }

    .result_sec {
      color: #000;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 21px */
      margin-bottom: 20px;
    }

    .filter_sec {
      margin-top: 30px;
      margin-bottom: 30px;
      display: flex;
      justify-content: space-between;
      .filter_btn {
        display: flex;
        width: 112px;
        height: 40px;
        padding: 8px 16px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 36px;
        border: 1px solid var(--Neutral-300, #cfd3d7);
        background: var(--Shade-0, #fff);
      }
      .filter_btn:hover,
      .filter_btn:focus {
        color: #000;
      }
    }

    .pro_card_wrp {
      margin-bottom: 25px;
      .pro_card_styled {
        height: 100%;
        .main_sec {
          height: 100%;
        }
        .grab_deal_wrp {
          width: 90%;
        }
      }
    }
  }

  .redclub-select {
    width: 200px;
    height: 40px;
    border-radius: 20px;
    .redclub-select__control {
      height: 40px;
      border-radius: 20px;
    }
  }

  .pro_card_styled {
    width: fit-content;
  }
  .products-pagination-sec {
    display: flex;
    justify-content: center;
  }

  .main_product_wrap {
    .wrapper_row {
      gap: 1%;
      margin-right: 0px;
      margin-left: 0px;

      .pro_card_wrp {
        flex: 0 0 24%;
        max-width: 24%;
        padding-left: 5px;
        padding-right: 5px;
        .pro_card_styled {
          width: 100%;
          .main_sec {
            width: 100%;
          }
        }
      }
    }
  }

  .loader_wrapper {
    width: 100%;
    gap: 1%;
    margin-top: 20px;
    margin-bottom: 20px;
    .ant-space-item {
      width: 24%;
      .ant-skeleton,
      .ant-skeleton-input {
        width: 100%;
        height: 300px;
        border-radius: 8px;
      }
    }
  }

  .no_products_wrp {
    display: flex;
    justify-content: center;
    .no_pro_inner {
      width: 25%;
      height: 20vh;
      background: black;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      flex-wrap: nowrap;
      flex-direction: column;
      border-radius: 16px;

      /* Drop shadow/Medium */
      box-shadow: 1px 3px 16px 0px rgba(32, 32, 32, 0.12);
      p {
        margin-bottom: 0px;
        color: #fff;
      }
      .see_all {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .selected_filter_item {
    display: flex;
    margin-bottom: 24px;
    margin-top: 16px;
    gap: 20px;
    .each_sec {
      display: flex;
      min-height: 30px;
      border-radius: 36px;
      background: #f9f9f9;
      justify-content: space-between;
      padding: 8px 16px;
      align-items: center;
      gap: 15px;
      p {
        margin-bottom: 0px;
        color: var(--FARFETCH-COLORS-Mine-Shaft, #222);
        text-align: center;
        // font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      span {
        svg {
          width: 14px;
          height: 14px;
        }
      }
    }
  }

  // Mobile Css;

  @media screen and (max-width: 767px) {
    .main_wrapper {
      padding: 0px 0px 30px 0px;
      .header {
        color: var(--www-target-com-mine-shaft, #333);
        // font-family: "Helvetica Neue";
        font-size: 18px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: normal !important;
      }
      .bread_crumb {
        color: var(--Secondary-Colour-100, #757575);
        font-family: Roboto !important;
        font-size: 14px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: normal !important;
      }
      .main_product_wrap {
        .wrapper_row {
          gap: 2%;
        }
        .pro_card_wrp {
          flex: 0 0 49%;
          max-width: 49%;
        }
        .pro_card_wrp.list_pro_card_wrp {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }
    .no_products_wrp {
      .no_pro_inner {
        width: 75%;
      }
    }
    .filter_sec {
      align-items: center !important;
      .filter_btn {
        width: 39% !important;
      }
      .view_icons {
        width: 24px;
        height: 24px;
      }
    }
    .loader_wrapper {
      width: 100%;
      gap: 1%;
      margin-top: 20px;
      margin-bottom: 20px;
      .ant-space-item {
        width: 49%;
        .ant-skeleton,
        .ant-skeleton-input {
          width: 100%;
          height: 300px;
          border-radius: 8px;
        }
      }
    }
    .loader_wrapper .ant-space-item .ant-skeleton {
      border-radius: 8px;
      width: 100%;
      overflow: hidden;
    }
  }
`;
