import styled from "styled-components";

export const SideMenuStyled = styled.div`
  .menu-sec-add-list {
    // margin-top: 50px;
  }
  .menu-sec-add-list > div {
    padding: 16px;
    // border-radius: 8px;
    border: 1px solid var(--Neutral-100, #eceeef);
    background: #fff;
    // border-bottom: 1px solid #c4c4c4;
    img {
      margin-right: 10px;
    }
    span {
      cursor: pointer;
      color: #111;
      // font-family: Noto Serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .menu-sec-add-list .selected-link-item {
    // font-weight: 700;
    background: var(--Primary-Red, #ed1c24);
    span {
      color: #fff;
    }
    img {
      width: 24px;
    }
  }
  .row-link-item-logout {
    svg {
      width: 40px;
    }
  }
  .row-link-item {
    img {
      width: 24px;
    }
  }
`;
