import styled from "styled-components";

export const SigninStyled = styled.div`
  .password_field {
    position: relative;
    .eyeIcon {
      position: absolute;
      right: 10px;
      top: 44px;
      cursor: pointer;
    }
  }
  .sign_in_btn {
    border-radius: 18px;
    background: var(--FARFETCH-COLORS-Mine-Shaft, #222);
  }
  .sign_in_btn:hover {
    border-radius: 18px;
    background: var(--FARFETCH-COLORS-Mine-Shaft, #222);
  }
`;
