import React, { useEffect, useState } from "react";

import { Row, Col, Button, Form, CardDeck, CardColumns } from "react-bootstrap";
import "./styles.css";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { HomeStyled } from "./HomeStyled";

import { Carousel } from "antd";
import Categories from "../../components/Categories/Categories";
import TopBrandDeals from "../../components/TopBrandDeals";
import OfferCardSlider from "../../components/OfferCardSlider";
import { getAllImageApi } from "../../redux/slices/Admin/AdminService";

// import {setallProductsData } from "../../redux/slices/productCart/productCartSlice";
const Home = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [carouselList, setCarouselList]: any = useState([]);
  const [bannerList, setBannerList]: any = useState([]);
  const { themeDetails, selectedBrands } = useSelector(
    (state: any) => state?.admin
  );

  useEffect(() => {
    getBannerList();
    getCarouselList();
  }, []);

  const getBannerList = async () => {
    const res: any = await dispatch(getAllImageApi({ type: "Banner" }));
    setBannerList(res?.payload?.data);
  };

  const getCarouselList = async () => {
    const res: any = await dispatch(getAllImageApi({ type: "Carousel" }));
    setCarouselList(res?.payload?.data);
  };
  return (
    <>
      <HomeStyled>
        <div className="home-container carousel-wrapper">
          <Carousel
            autoplay
            autoplaySpeed={2000}
            infinite
            speed={1000}
            dots
            dotPosition="bottom"
            className="banner_img_carsousel"
          >
            {carouselList?.map((item: any) => (
              <div className="carouse_image_wrapper">
                <img src={item?.url} alt="banner1" className="h-100" />
              </div>
            ))}
          </Carousel>
        </div>
        <Categories />
        <TopBrandDeals />
        {bannerList?.length > 0 && bannerList[0]?.url && (
          <div className="ofefr_sm_banner mt-4 mb-4">
            <img src={bannerList[0]?.url} alt="offer_bnner" className="w-100" />
          </div>
        )}
        {selectedBrands?.map((item: any) => {
          console.log("itemitem", item);
          return (
            <OfferCardSlider
              heading={`${item?.merchantInfo?.name} Deals`}
              brand={item?.mid}
            />
          );
        })}

        {/* <OfferCardSlider
          heading={"Labour Day Sale Top Deals"}
          q_param="deals"
        />
        <OfferCardSlider heading={"Crocs Top Deals"} brand="199042" />
        {bannerList?.length > 0 && bannerList[1]?.url && (
          <div className="ofefr_sm_banner mt-4 mb-4">
            <img src={bannerList[1]?.url} alt="offer_bnner" className="w-100" />
          </div>
        )}
        <OfferCardSlider heading={"Epson Top Deals"} brand="27433" />

        <OfferCardSlider
          heading={"Clothing & Accessories Deals"}
          q_param="accessories"
          cardNo={2}
        />
        {bannerList?.length > 0 && bannerList[2]?.url && (
          <div className="ofefr_sm_banner mt-4 mb-4">
            <img src={bannerList[2]?.url} alt="offer_bnner" className="w-100" />
          </div>
        )}
        <OfferCardSlider
          heading={"Pet Supplies Deals"}
          q_param="pet"
          cardNo={2}
        />
        <OfferCardSlider
          heading={"Sports Equipment & Outdoor Gear Deals"}
          q_param="sport"
          cardNo={2}
        />
        {bannerList?.length > 0 && bannerList[3]?.url && (
          <div className="ofefr_sm_banner mt-4 mb-4">
            <img src={bannerList[3]?.url} alt="offer_bnner" className="w-100" />
          </div>
        )}
        <OfferCardSlider heading={"Toys Deals"} q_param="toy" cardNo={2} />
        <OfferCardSlider heading={"Brook Brothers Deals"} brand="19246" />
        {bannerList?.length > 0 && bannerList[4]?.url && (
          <div className="ofefr_sm_banner mt-4 mb-4">
            <img src={bannerList[4]?.url} alt="offer_bnner" className="w-100" />
          </div>
        )}
        <OfferCardSlider heading={"Aspen Pet Deals"} q_param="pet" />
        <OfferCardSlider heading={"Ortovox Deals"} q_pqram="games" />
        {bannerList?.length > 0 && bannerList[5]?.url && (
          <div className="ofefr_sm_banner mt-4 mb-4">
            <img src={bannerList[5]?.url} alt="offer_bnner" className="w-100" />
          </div>
        )}
        <OfferCardSlider
          heading={"Green toys Deals"}
          q_param="toy"
          cardNo={2}
        /> */}
        {bannerList?.length > 0 &&
          bannerList?.slice(6, bannerList?.length)?.map((item: any) => (
            <div className="ofefr_sm_banner mt-4 mb-4">
              <img src={item?.url} alt="offer_bnner" className="w-100" />
            </div>
          ))}
      </HomeStyled>
    </>
  );
};

export default Home;
