import React, { Component, useEffect } from "react";
import PropTypes from "prop-types";
import { HandleClickStyled } from "./HandleClick.styled";
import { bindActionCreators } from "redux";
import { Row, Col, Button, CardDeck, CardColumns } from "react-bootstrap";
// import 'bootstrap/dist/css/bootstrap.min.css';
import facebook_fill from "../../img/facebook-box-fill.png";
import instagram_fill from "../../img/instagram-fill.png";
import twitter_fill from "../../img/twitter-x-fill.png";
import linkedin_fill from "../../img/linkedin-box-fill.png";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  // createUserClicksApi,
  createUserClicksApi1,
  createUserClicksApi2,
} from "./../../redux/slices/auth/authService";

import { Link, withRouter } from "react-router-dom";
const Footer = (props: any) => {
  const dispatch = useDispatch();
  //   const link2 =
  //     "https://api-prod.getfeedz.com/ad/sDeDArAkov?clickId=56789&checkIn=20240310&checkOut=20240313&city=new york";
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const checkIn = query.get("checkIn");
  const checkOut = query.get("checkOut");
  const chanel = query.get("chanel");
  const city = query.get("city");

  //   localhost:3000/handleclick?checkIn=20240310&checkOut=20240313&city=new%20york&chanel=times of india

  // http: useEffect(() => {}, []);
  // useEffect(() => {
  //   console.log("vinod");
  //   createUserClicks();
  // }, []);
  // const createUserClicks = async () => {
  //   if (chanel) {
  //     const body = {
  //       checkIn: checkIn,
  //       chanel: chanel,
  //       checkOut: checkOut,
  //       city: city,
  //     };
  //     const resp1 = (await dispatch(createUserClicksApi(body))) as any;
  //     console.log("respresp", resp1);
  //     if (resp1?.payload?.resp?._id) {
  //       const reUrl = `https://api-prod.getfeedz.com/ad/sDeDArAkov?clickId=${resp1?.payload?.resp?._id}&checkIn=${checkIn}&checkOut=${checkOut}&city=${city}`;

  //       window.location.href = reUrl;
  //     }
  //   }
  // };
  return (
    <>
      <HandleClickStyled>
        <div className="extension-footer-container">
          Re directing to price line
        </div>
      </HandleClickStyled>
    </>
  );
};

export default Footer;
