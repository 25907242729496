import { useDispatch, useSelector } from "react-redux";
import { BrandUpdateStyled } from "./BrandUpdate.styled";
import { Button, Checkbox } from "antd";
import { useEffect, useState } from "react";
import { getAllMerchantsAPI } from "../../../redux/slices/app/appService";
import deleteIcon from "../../../images/delete.svg";
import { updateSelectedBrands } from "../../../redux/slices/Admin/AdminSlice";
import {
  getSelectedBrandsApi,
  updateSelectedBrandsApi,
} from "../../../redux/slices/Admin/AdminService";
import { siteName } from "../../../Utills/Utill";

const BrandsUpdate = () => {
  const {
    app: { allMerchantsList },
    admin: { selectedBrands },
  } = useSelector((reduxState: any) => reduxState);

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllMerchantsAPI());
    selectedBrandAPiCall();
  }, []);

  const selectedBrandAPiCall = async () => {
    const res: any = await dispatch(getSelectedBrandsApi({}));
    dispatch(updateSelectedBrands(res?.payload?.data?.ids));
  };

  const onChange = (item: any) => {
    const checked = isChecked(item);
    if (checked) {
      const filterList = selectedBrands?.filter(
        (i: any) => i?.mid !== item?.mid
      );
      dispatch(updateSelectedBrands(filterList));
    } else {
      dispatch(updateSelectedBrands([...selectedBrands, item]));
    }
  };

  const deleteItem = (item: any) => {
    const filteredList = selectedBrands?.filter(
      (i: any) => i?.mid !== item?.mid
    );
    dispatch(updateSelectedBrands(filteredList));
  };

  const isChecked = (val: any) => {
    const list = selectedBrands?.filter((item: any) => item?.mid === val?.mid);
    return list?.length > 0 ? true : false;
  };

  const updateSelectedBrandsCall = async () => {
    setLoading(true);
    const payload = {
      websiteName: siteName,
      ids: [...selectedBrands],
    };
    await dispatch(updateSelectedBrandsApi(payload));
    await selectedBrandAPiCall();
    setLoading(false);
  };

  const selectAllFunc = (e: any) => {
    if (e?.target?.checked) {
      dispatch(updateSelectedBrands(allMerchantsList));
    } else {
      dispatch(updateSelectedBrands([]));
    }
  };
  console.log("selectedBrands", selectedBrands);
  return (
    <BrandUpdateStyled>
      <div className="main_brand_wrp">
        <div className="heading">Brands</div>

        <div className="middle_wrapper">
          <div className="sec_top">Brands List</div>
          <div className="two_sec_wrapper">
            <div className="left_sec">
              <div className="sec_head">All Brands</div>
              <div className="sec_body">
                <div className="each_item">
                  <div className="left_side">Select All</div>
                  <div className="left_side">
                    <Checkbox
                      onChange={(e: any) => selectAllFunc(e)}
                      checked={
                        selectedBrands?.length === allMerchantsList?.length
                      }
                    ></Checkbox>
                  </div>
                </div>
                {allMerchantsList?.map((item: any, idx: number) => (
                  <div key={`brand_1_${idx}`} className="each_item">
                    <div className="left_side">
                      <div className="img_wrapper">
                        <img src={item?.merchantInfo?.logoUrl} alt="brnd_img" />
                      </div>
                      <div className="m_name">{item?.merchantInfo?.name}</div>
                    </div>
                    <div className="left_side">
                      <Checkbox
                        checked={isChecked(item)}
                        onChange={() => onChange(item)}
                      ></Checkbox>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="right_sec">
              <div className="sec_head">Selected Brands</div>
              <div className="sec_body">
                {selectedBrands?.length > 0 &&
                  selectedBrands?.map((item: any, idx: number) => (
                    <div key={`selc_brand_1_${idx}`} className="each_item">
                      <div className="left_side">
                        <div className="img_wrapper">
                          <img
                            src={item?.merchantInfo?.logoUrl}
                            alt="brnd_img"
                          />
                        </div>
                        <div className="m_name">{item?.merchantInfo?.name}</div>
                      </div>
                      <div className="right_side">
                        <img
                          src={deleteIcon}
                          alt="delete"
                          onClick={() => deleteItem(item)}
                        />
                      </div>
                    </div>
                  ))}
                {selectedBrands?.length === 0 && (
                  <div className="no_selected_brands">
                    <div className="inner">
                      <p className="top">No Brands Selected</p>
                      <p className="bottom">
                        Please select Brands from the left side list
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="button_sec">
          <Button
            disabled={selectedBrands?.length === 0}
            onClick={() => updateSelectedBrandsCall()}
            loading={loading}
          >
            Update
          </Button>
        </div>
      </div>
    </BrandUpdateStyled>
  );
};

export default BrandsUpdate;
