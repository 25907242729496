import styled from "styled-components";

export const MyProfileInfoStyled = styled.div`
  .profile-top-sec {
    display: flex;
    justify-content: space-between;
    .profile-top-sec-left {
      display: flex;
      gap: 40px;
    }
    img {
      width: 80px;
      height: 80px;
    }
    button {
      border-radius: 18px;
      border: 1px solid #b83830;

      background: #fff;

      box-shadow: 0px 0px 4px 0.6px rgba(0, 0, 0, 0.25);
      color: #b83830;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 156px;
      height: 36px;
    }
    h5 {
      color: #181818;
      margin-top: 20px;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  .profile-middle {
    margin-top: 30px;
    border-radius: 5px;
    border: 1px solid #b83830;
    padding: 20px;
    background: #fffbfb;

    box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.15);
    .profile-middle-content {
      display: flex;
      gap: 40px;
    }
    .error_verify {
      color: #181818;
      font-feature-settings: "liga" off, "clig" off;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px; /* 110% */
      letter-spacing: 0.2px;
    }
    p {
      color: #181818;

      font-feature-settings: "liga" off, "clig" off;

      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
      letter-spacing: 0.14px;
    }
    button {
      border-radius: 18px;
      background: var(--Accent-Main, #fdd62d);
      box-shadow: 0px 0px 4px 0.6px rgba(0, 0, 0, 0.25);
      color: var(--Secondary-Main, #181818);

      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      border: none;
      width: 240px;
      height: 36px;
    }
  }
  .profile-items-all {
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    .profile-item-row {
      padding: 22px;
      border-radius: 5px;
      background: #fff;
      display: flex;
      justify-content: space-between;
      box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.15);
    }
    .profile-item-row-left {
      display: flex;
      gap: 40px;
    }
    p.bold {
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 5px;
    }
    p {
      color: #181818;

      font-feature-settings: "liga" off, "clig" off;

      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 110% */
      letter-spacing: 0.2px;
      margin-bottom: 0px;
    }
    .profile-item-arrow {
      padding-top: 10px;
    }
  }
  h2 {
    color: var(--Shade-100, #000);

    /* Heading 06/H6/Small/Semibold */
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px; /* 135% */
    margin-bottom: 20px;
  }
  .main_section {
    margin-right: 50px;
    border-radius: 16px;
    border: 1px solid #cfd3d7;
    background: #fcfcfc;
    padding: 20px;
    .top_btn_wrp {
      display: flex;
      justify-content: end;
      button {
        display: flex;
        width: 108px;
        height: 40px;
        padding: 8px 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex-shrink: 0;
        border-radius: 10px;
        border: 1px solid var(--Shade-100, #000);
        background: #fcfcfc;
      }
    }
    .details_sec_top {
      display: flex;
      justify-content: center;
      .vendor_logo {
        display: flex;
        width: 80px;
        height: 80px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 80px;
        border: 4px solid #ebebeb;
        background: #fff;
        color: #000;
        text-align: center;
        font-family: Poppins;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px; /* 114.286% */
        letter-spacing: 2.24px;
      }
    }
    .id_wrap {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      .vendor_id {
        display: flex;
        width: 114px;
        padding: 4px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 40px;
        background: var(--Colour01-Black, #1f2028);
        color: var(--Shade-0, #fff);
        text-align: center;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 166.667% */
      }
    }
    .name_wrap {
      display: flex;
      justify-content: center;
      margin-top: 5px;
      .vendor_name {
        color: var(--Shade-100, #000);
        text-align: center;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px; /* 160% */
      }
    }
    .address_details {
      display: flex;
      justify-content: center;
      flex-wrap: nowrap;
      margin-top: 20px;
      .inner_wrp {
        p {
          margin-bottom: 8px;
          color: var(--Shade-100, #000);
          font-family: Poppins;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 133.333% */
          text-align: center;
        }
      }
    }
    .change_psw {
      display: flex;
      justify-content: center;
      margin-top: 30px;
      button {
        display: flex;
        width: 345px;
        height: 56px;
        padding: 14px 32px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 18px;
        background: #000;
        color: var(--Shade-0, #fff);

        /* Button Text/Large */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
        letter-spacing: 0.032px;
        text-transform: uppercase;
        border: none;
      }
      margin-bottom: 30px;
    }
  }
`;
