import { OfferCardStyled } from "./OfferCard.styled";
import cameraIcon from "../../../../../images/camera.svg";
import { Input } from "antd";

const OfferCard = ({
  fNo,
  title,
  setShowUploadImage,
  setSelectedImg,
  item,
  titleChange,
  linkChange,
  pageName,
}: any) => {
  return (
    <OfferCardStyled>
      <div className={`main_offer_wrapper ${pageName}`}>
        <p className="name_sec">
          {title} {fNo}
        </p>
        {!item?.url && (
          <div className="upload_sec">
            <div className="pre_upload_sec">
              <img
                src={cameraIcon}
                alt={`camera`}
                onClick={() => {
                  setSelectedImg(fNo - 1);
                  setShowUploadImage(true);
                }}
              />
              <p className="mt-2">
                Upload {title} Image<span className="required">*</span>
              </p>
            </div>
          </div>
        )}
        {item?.url && (
          <div className="img_wrapper">
            <img src={item?.url} alt={`img`} />
          </div>
        )}
        <div className="title_sec">
          <label>
            {title} Title
          </label>
          <Input
            placeholder="Enter Banner Title"
            onChange={(e) => titleChange(fNo - 1, e?.target?.value)}
            value={item?.description}
          />
        </div>
        <div className="link_sec">
          <label>
            {title} Link<span className="required">*</span>
          </label>
          <Input
            placeholder="Add Banner Link"
            onChange={(e: any) => linkChange(fNo - 1, e?.target?.value)}
            value={item?.redirectUrl}
          />
        </div>
      </div>
    </OfferCardStyled>
  );
};

export default OfferCard;
