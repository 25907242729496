import styled from "styled-components";

export const RedirectionStyled = styled.div`
  .main_wrapper_re {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;

    .text_wrapper {
      text-align: center;
      .head_text {
        color: #181818;
        font-family: "Helvetica Neue";
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .semi_text {
        color: #181818;
        text-align: center;
        font-family: "Helvetica Neue";
        font-size: 15px;
        font-style: normal;
        font-weight: 300;
        line-height: 147%; /* 22.05px */
      }

      .rdc_wrap {
        display: flex;
        justify-content: center;
        gap: 15px;
        img {
          height: 25px;
        }
      }
    }
  }
`;
