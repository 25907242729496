import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { HeaderStyled } from "./Header.styled";
import "./styles.css";
import "antd/dist/antd.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { ReactComponent as ProjLogo } from "../../images/SunsetdealsIcon.svg";
import { Dropdown, Space } from "antd";
import Search from "antd/lib/input/Search";
import downArrow from "../../images/downArrow.svg";
import percentageIcon from "../../images/percentage.svg";
import {
  getAllCategoriesAPI,
  getAllMerchantsAPI,
} from "../../redux/slices/app/appService";
import { checkIsMobile, siteName } from "../../Utills/Utill";
import { MenuOutlined } from "@ant-design/icons";
import HomeMobileCanvas from "../../pages/Home/HomeMobileCanvas";
import { getThemeDetailsApi } from "../../redux/slices/Admin/AdminService";

import { getSelectedBrandsApi } from "../../redux/slices/Admin/AdminService";

const Header = (props) => {
  const { categoriesList } = useSelector((reduxState) => reduxState.app);
  const { themeDetails, selectedBrands } = useSelector((state) => state?.admin);

  const history = useHistory();
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [brandList, setBrandList] = useState([{}]);
  const [cateGList, setCateGList] = useState([{}]);

  useEffect(() => {
    dispatch(getAllMerchantsAPI());
    dispatch(getAllCategoriesAPI());
    getMasterDataCall();
    dispatch(
      getThemeDetailsApi({
        websiteName: siteName,
      })
    );
  }, []);
  const getMasterDataCall = () => {
    console.log("siteName siteName", siteName);
    if (siteName) {
      dispatch(getSelectedBrandsApi({ siteName }));
    }
  };
  const gotoCartPage = (url) => {
    history.push(url);
  };
  console.log("selectedBrands", selectedBrands);
  // useEffect(() => {
  //   if (searchText === "") {
  //     history?.push("/products");
  //   }
  // }, [searchText]);

  useEffect(() => {
    const createBrandList = () => {
      const list = selectedBrands?.slice(0, 10)?.map((item, idx) => ({
        label: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => history?.push(`/products?byBrand=${item?.mid}`)}
            key={`anchor_${item.mid}`}
          >
            {item?.merchantInfo?.name} {/* Set a label for each brand */}
          </a>
        ),
        key: `card_${idx}`,
      }));
      list?.push({
        label: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => history?.push(`/brands`)}
            key={`anchor_11`}
          >
            {"All Brands"} {/* Set a label for each brand */}
          </a>
        ),
        key: `card_${11}`,
      });
      setBrandList(list);
    };

    createBrandList();
  }, [selectedBrands]);

  useEffect(() => {
    const createCateList = () => {
      const list = categoriesList?.slice(0, 10)?.map((item, idx) => ({
        label: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => history?.push(`/products?byCate=${item?.id}`)}
            key={`anchor_${item.id}`}
          >
            {item?.name} {/* Set a label for each brand */}
          </a>
        ),
        key: `card_${idx}`,
      }));
      list?.push({
        label: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => history?.push(`/products`)}
            key={`anchor_11`}
          >
            {"All Products"} {/* Set a label for each brand */}
          </a>
        ),
        key: `card_${11}`,
      });
      setCateGList(list);
    };

    createCateList();
  }, [categoriesList]);

  const onSearch = () => {
    history.push(`/products?search=${searchText}`);
  };

  return (
    <>
      <HeaderStyled
        className={`${checkIsMobile() ? `mobl_header_styled` : ``}`}
      >
        <div
          className={`header-wrapper ${
            checkIsMobile() ? `mobl_header_wrapper` : ``
          }`}
          id="headerWrapper"
        >
          <div className="headerSection">
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <div className="allItemsSection">
                <div className="row-item-secion">
                  {/* <ProjLogo
                    onClick={() => {
                      gotoCartPage("/");
                    }}
                    className="ss_deal_logo"
                  /> */}
                  {themeDetails?.logo ? (
                    <img
                      src={themeDetails?.logo}
                      alt={siteName}
                      onClick={() => {
                        gotoCartPage("/");
                      }}
                      className="ss_deal_logo"
                    />
                  ) : (
                    <span
                      className="header-logo-main0sec"
                      onClick={() => {
                        gotoCartPage("/");
                      }}
                    >
                      {siteName}
                    </span>
                  )}

                  {/* {!checkIsMobile() && (
                    <Dropdown
                      menu={{ items: cateGList }}
                      placement="bottom"
                      className="header_drop_down ml-5"
                    >
                      <a onClick={(e) => e.preventDefault()}>
                        <Space>
                          Categories <img src={downArrow} alt="arrow" />
                        </Space>
                      </a>
                    </Dropdown>
                  )} */}
                  {!checkIsMobile() && (
                    <Dropdown
                      menu={{ items: brandList }}
                      placement="bottom"
                      className="header_drop_down ml-4"
                      // trigger={['click']}
                    >
                      <a onClick={(e) => e.preventDefault()}>
                        <Space>
                          Brands <img src={downArrow} alt="arrow" />
                        </Space>
                      </a>
                    </Dropdown>
                  )}
                  {!checkIsMobile() && (
                    <div
                      className="header_offer_btn"
                      onClick={() => history.push("/offers")}
                    >
                      Offers
                      <svg
                        width="17"
                        height="17"
                        viewBox="0 0 17 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ml-1"
                      >
                        <rect
                          x="0.5"
                          y="0.820068"
                          width="16"
                          height="16"
                          rx="8"
                          fill={
                            themeDetails?.headerOfferIconColor
                              ? themeDetails?.headerOfferIconColor
                              : "#6729FF"
                          }
                        />
                        <g clip-path="url(#clip0_3716_3307)">
                          <path
                            d="M10.3333 11.8201C10.0239 11.8201 9.72717 11.6972 9.50838 11.4784C9.28958 11.2596 9.16667 10.9628 9.16667 10.6534C9.16667 10.344 9.28958 10.0472 9.50838 9.82844C9.72717 9.60965 10.0239 9.48674 10.3333 9.48674C10.6428 9.48674 10.9395 9.60965 11.1583 9.82844C11.3771 10.0472 11.5 10.344 11.5 10.6534C11.5 10.9628 11.3771 11.2596 11.1583 11.4784C10.9395 11.6972 10.6428 11.8201 10.3333 11.8201ZM10.3333 11.1534C10.4659 11.1534 10.5931 11.1007 10.6869 11.007C10.7807 10.9132 10.8333 10.786 10.8333 10.6534C10.8333 10.5208 10.7807 10.3936 10.6869 10.2998C10.5931 10.2061 10.4659 10.1534 10.3333 10.1534C10.2007 10.1534 10.0735 10.2061 9.97978 10.2998C9.88601 10.3936 9.83333 10.5208 9.83333 10.6534C9.83333 10.786 9.88601 10.9132 9.97978 11.007C10.0735 11.1007 10.2007 11.1534 10.3333 11.1534ZM6.66667 8.1534C6.51346 8.1534 6.36175 8.12323 6.2202 8.06459C6.07866 8.00596 5.95004 7.92003 5.84171 7.81169C5.73337 7.70336 5.64744 7.57475 5.58881 7.4332C5.53018 7.29165 5.5 7.13994 5.5 6.98674C5.5 6.83353 5.53018 6.68182 5.58881 6.54027C5.64744 6.39872 5.73337 6.27011 5.84171 6.16178C5.95004 6.05344 6.07866 5.96751 6.2202 5.90888C6.36175 5.85025 6.51346 5.82007 6.66667 5.82007C6.97609 5.82007 7.27283 5.94298 7.49162 6.16178C7.71042 6.38057 7.83333 6.67732 7.83333 6.98674C7.83333 7.29615 7.71042 7.5929 7.49162 7.81169C7.27283 8.03049 6.97609 8.1534 6.66667 8.1534ZM6.66667 7.48674C6.79927 7.48674 6.92645 7.43406 7.02022 7.34029C7.11399 7.24652 7.16667 7.11934 7.16667 6.98674C7.16667 6.85413 7.11399 6.72695 7.02022 6.63318C6.92645 6.53941 6.79927 6.48674 6.66667 6.48674C6.53406 6.48674 6.40688 6.53941 6.31311 6.63318C6.21935 6.72695 6.16667 6.85413 6.16667 6.98674C6.16667 7.11934 6.21935 7.24652 6.31311 7.34029C6.40688 7.43406 6.53406 7.48674 6.66667 7.48674ZM10.857 5.99174L11.3283 6.46307L6.14333 11.6484L5.672 11.1771L10.8567 5.99174H10.857Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_3716_3307">
                            <rect
                              width="8"
                              height="8"
                              fill="white"
                              transform="translate(4.5 4.82007)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  )}
                </div>
                <div className="search_sec">
                  {!checkIsMobile() ? (
                    <Space.Compact>
                      <Search
                        placeholder="What can we help you find?"
                        allowClear
                        onChange={(e) => setSearchText(e.target.value)}
                        onSearch={onSearch}
                      />
                    </Space.Compact>
                  ) : (
                    <MenuOutlined
                      onClick={() => setIsOpen(true)}
                      className="mobl_home_menu"
                    />
                  )}
                </div>
                {checkIsMobile() && (
                  <div className="search_sec mobl_search_sec">
                    <Space.Compact>
                      <Search
                        placeholder="What can we help you find?"
                        allowClear
                        onChange={(e) => setSearchText(e.target.value)}
                        onSearch={onSearch}
                      />
                    </Space.Compact>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <HomeMobileCanvas open={isOpen} onClose={() => setIsOpen(false)} />
      </HeaderStyled>
    </>
  );
};

export default Header;
