import { Button } from "antd";
import { AllProductStyled } from "./AllProduct.styled";
import ProductCard from "../../components/ProductCard";
import { Col, Row } from "react-bootstrap";
import FilterIcon from "../../images/filterIcon.svg";
import BrandCategories from "./BrandCategories";
import Select from "react-select";
import { useEffect, useState } from "react";
import CanvasFilter from "../../components/CanvasFilter";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllProductsAPI } from "../../redux/slices/app/appService";
import { checkIsMobile, v_id } from "../../Utills/Utill";
import { Pagination } from "antd";
import { Skeleton, Space } from "antd";
import { NumericFormat } from "react-number-format";
import { CloseOutlined } from "@ant-design/icons";
import sortIcon from "../../images/sortIcon.svg";
import listIcon from "../../images/listView.svg";
import girdIcon from "../../images/girdView.svg";
import ListCard from "../../components/ProductCard/ListCard";
import SortCanvas from "../../components/SortCanvas";

const allMerchants = [
  // { label: "Sort By: Featured", value: "F" },
  // { label: "Sort By: Newest", value: "N" },
  { label: "Price: Low to High", value: "priceLtoH" },
  { label: "Price: High to Low", value: "priceHtoL" },
  // { label: "Sort By: Best Seller", value: "B" },
];

const AllProduct = () => {
  const { categoriesList, allMerchantsList }: any = useSelector(
    // @ts-ignore
    (reduxState): any => reduxState.app
  );
  const [merchantValue, setMerchantValue] = useState({} as any);
  const [productList, setProductList]: any = useState([]);
  const [open, setOpen] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  const [pageNo, setPageNo] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [brandId, setBrandId] = useState(0);
  const [searchDate, setSearchData] = useState("");
  const [cateId, setCatId] = useState(0);
  const [priceVal, setPriceVal] = useState([30, 10000]);
  const [listView, setListView] = useState(false);
  const [sortModalOpen, setSortModalOpen] = useState(false);

  const [checkedList, setCheckedList]: any = useState([]);
  const [checkedCategories, setCheckedCategories]: any = useState([]);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const history = useHistory();
  const dispatch = useDispatch();

  const query = useQuery();

  const byBrand: any = query?.get("byBrand");
  const qSearch: any = query?.get("search");
  const byCate: any = query?.get("byCate");

  useEffect(() => {
    if (byBrand) {
      setBrandId(byBrand);
      getProductData(byBrand, 0);
    }
    if (qSearch) {
      setSearchData(qSearch);
    }
    if (byCate) {
      setCatId(byCate);
      getProductData(byBrand, byCate, qSearch);
    }
  }, [byBrand, qSearch, byCate]);

  useEffect(() => {
    getProductData(byBrand, byCate, qSearch);
  }, [pageNo, pageSize, merchantValue, searchDate]);

  const getProductData = async (
    brand?: any,
    cateG?: any,
    searchTxt?: any,
    pgN?: any
  ) => {
    setLoading(true);
    const payload = {
      ...((brand || brandId) && brand !== 0 ? { mid: brand || brandId } : {}),
      ...(searchDate || searchTxt
        ? { q: searchTxt || searchDate || qSearch }
        : {}),
      ...(v_id ? { vendorId: v_id } : {}),
      ...(pageNo ? { pageNo: pgN ? pgN : pageNo } : {}),
      ...(pageSize ? { pageSize: pageSize } : {}),
      ...(merchantValue?.value ? { sortBy: merchantValue.value } : {}),
      ...((cateG || cateId) && cateG !== 0
        ? { categoryId: cateG || cateId }
        : {}),
      ...(priceVal[0] !== undefined ? { minPrice: priceVal[0] } : {}),
      ...(priceVal[1] !== undefined ? { maxPrice: priceVal[1] } : {}),

      showOfferedProducts: false,
    };

    let res: any = {};
    if (Object.keys(payload).length > 0) {
      res = await dispatch(getAllProductsAPI(payload));
    }
    setTotalCount(res?.payload?.count);
    setProductList(res?.payload?.products);
    setLoading(false);
  };

  const handlePageChange = (val: any, val1: any) => {
    setPageNo(val);
    setPageSize(val1);
    window.scroll({ top: 100, behavior: "smooth" });
  };

  const changeSort = (selectedOption: any) => {
    setMerchantValue(selectedOption);
  };

  const applyFilterCall = (br: any, ct: any) => {
    setBrandId(br);
    setCatId(ct);
    setPageNo(1);
    getProductData(br, ct, searchDate, 1);
    history.push("/products");
  };

  const clearFilter = () => {
    setBrandId(0);
    setCatId(0);
    setPriceVal([30, 10000]);
    setPageNo(1);
    setCheckedList([]);
    setCheckedCategories([]);
    getProductData(0, 0, searchDate, 1);
    history.push("/products");
  };

  const clearSortFilter = () => {
    setMerchantValue({});
    setSortModalOpen(false);
  };

  const getCateGoryName = () => {
    const data = categoriesList?.filter((i: any) => i?.id == cateId);
    return data[0]?.name;
  };

  const getBrandName = () => {
    const data = allMerchantsList?.filter((i: any) => i?.mid == brandId);

    return data[0]?.merchantInfo?.name;
  };

  const mobileSortApply = (val: any) => {
    setMerchantValue(allMerchants[Number(val - 1)]);
    setSortModalOpen(false);
  };
  const removeBrands = () => {
    const data = checkedList?.filter((item: any) => brandId !== item?.mid);
    setCheckedList([...data]);
  };

  const removeCategory = () => {
    const data = checkedCategories?.filter((item: any) => cateId !== item);
    setCheckedCategories(data);
  };

  return (
    <AllProductStyled>
      <div className="main_wrapper">
        {
          <div className="header">
            {brandId ? productList[0]?._source?.manufacturer : "All Products"}{" "}
            Deals
          </div>
        }
        {
          <div className="bread_crumb">
            <span className="bdc_Home" onClick={() => history.push("/")}>
              Home
            </span>
            {brandId ? (
              <span>{`/${productList[0]?._source?.manufacturer}`}</span>
            ) : (
              ""
            )}
            {cateId &&
            categoriesList?.filter((item: any) => item?.id === cateId)?.length >
              0 ? (
              <span>{`/${
                categoriesList?.filter((item: any) => item?.id === cateId)[0]
                  ?.name
              }`}</span>
            ) : (
              ""
            )}
          </div>
        }
        {/* <BrandCategories /> */}
        <div className="filter_sec">
          <Button className="filter_btn" onClick={() => setOpen(true)}>
            Filter <img src={FilterIcon} alt="filter" />
          </Button>
          {!checkIsMobile() && (
            <Select
              // value={merchantValue}
              placeholder="Sort by"
              onChange={changeSort}
              options={allMerchants}
              className="redclub-select"
              classNamePrefix="redclub-select"
            />
          )}
          {checkIsMobile() && (
            <Button
              className="filter_btn"
              onClick={() => setSortModalOpen(true)}
            >
              Sort <img src={sortIcon} alt="filter" />
            </Button>
          )}
          {checkIsMobile() && (
            <>
              {listView ? (
                <img
                  src={listIcon}
                  alt="list"
                  className="view_icons"
                  onClick={() => setListView(!listView)}
                />
              ) : (
                <img
                  src={girdIcon}
                  alt="list"
                  className="view_icons"
                  onClick={() => setListView(!listView)}
                />
              )}
            </>
          )}
        </div>
        {(searchDate !== "" || cateId !== 0 || brandId !== 0) && (
          <div className="selected_filter_item">
            {searchDate && (
              <div className="each_sec s_each_sec">
                <p>{searchDate}</p>
                <CloseOutlined
                  onClick={() => {
                    setSearchData("");
                    getProductData(brandId, cateId, "");
                    history?.push("/products");
                  }}
                />
              </div>
            )}
            {cateId !== 0 && (
              <div className="each_sec c_each_sec">
                <p>{getCateGoryName()}</p>
                <CloseOutlined
                  onClick={() => {
                    setCatId(0);
                    removeCategory();
                    getProductData(brandId, 0, searchDate);
                    history?.push("/products");
                  }}
                />
              </div>
            )}
            {brandId !== 0 && (
              <div className="each_sec b_each_sec">
                <p>{getBrandName()}</p>
                <CloseOutlined
                  onClick={() => {
                    setBrandId(0);
                    getProductData(0, cateId, searchDate);
                    removeBrands();
                    history?.push("/products");
                  }}
                />
              </div>
            )}
          </div>
        )}
        {productList?.length > 0 && (
          <div className="result_sec">
            <NumericFormat
              value={totalCount ?? 0}
              displayType={"text"}
              thousandSeparator=","
            />{" "}
            Products
          </div>
        )}
        {productList?.length === 0 && !loading && (
          <div className="no_products_wrp">
            <div className="no_pro_inner">
              <p>No Products Available</p>
              <br />
              <p className="see_all" onClick={() => clearFilter()}>
                See All Products
              </p>
            </div>
          </div>
        )}
        <div className="main_product_wrap">
          {!loading && productList?.length > 0 && (
            <Row className="wrapper_row">
              {productList?.map((item: any, idx: number) => {
                const i = item?._source;
                return (
                  <Col
                    md={checkIsMobile() ? 0 : 3}
                    xs={listView ? 12 : 6}
                    key={`pro_card_${idx}`}
                    className={`pro_card_wrp ${
                      checkIsMobile() && listView && `list_pro_card_wrp`
                    }`}
                  >
                    {listView ? (
                      <ListCard product={i} />
                    ) : (
                      <ProductCard product={i} />
                    )}
                  </Col>
                );
              })}
            </Row>
          )}
          {loading && (
            <>
              <Space className="loader_wrapper">
                <Skeleton.Input active={true} size={"large"} />
                <Skeleton.Input active={true} size={"large"} />
                {!checkIsMobile() && (
                  <Skeleton.Input active={true} size={"large"} />
                )}
                {!checkIsMobile() && (
                  <Skeleton.Input active={true} size={"large"} />
                )}
              </Space>
              <Space className="loader_wrapper">
                <Skeleton.Input active={true} size={"large"} />
                <Skeleton.Input active={true} size={"large"} />
                {!checkIsMobile() && (
                  <Skeleton.Input active={true} size={"large"} />
                )}
                {!checkIsMobile() && (
                  <Skeleton.Input active={true} size={"large"} />
                )}
              </Space>
            </>
          )}
        </div>
        {productList?.length > 0 && !loading && (
          <div className="products-pagination-sec">
            <br />
            <Pagination
              onChange={handlePageChange}
              pageSize={pageSize}
              // defaultCurrent={6}
              current={pageNo}
              // onShowSizeChange={onShowSizeChange}
              total={totalCount}
            />
            <br />
          </div>
        )}
      </div>
      {/* @ts-ignore */}
      <CanvasFilter
        // @ts-ignore
        open={open}
        setOpen={setOpen}
        priceVal={priceVal}
        setPriceVal={setPriceVal}
        applyFilterCall={applyFilterCall}
        clearFilter={clearFilter}
        checkedList={checkedList}
        setCheckedList={setCheckedList}
        checkedCategories={checkedCategories}
        setCheckedCategories={setCheckedCategories}
      />
      <SortCanvas
        open={sortModalOpen}
        setOpen={setSortModalOpen}
        mobileSortApply={mobileSortApply}
        clearFilter={clearSortFilter}
      />
    </AllProductStyled>
  );
};

export default AllProduct;
