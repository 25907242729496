import { useEffect } from "react";
import { FooterStyled } from "./Footer.styled";
// import 'bootstrap/dist/css/bootstrap.min.css';
import facebook_fill from "../../../img/facebook-box-fill.png";
import instagram_fill from "../../../img/instagram-fill.png";
import twitter_fill from "../../../img/twitter-x-fill.png";
import linkedin_fill from "../../../img/linkedin-box-fill.png";

import { useHistory } from "react-router-dom";
import { siteName } from "../../../Utills/Utill";
const Footer = (props) => {
  useEffect(() => {}, []);
  const history = useHistory();

  return (
    <>
      <FooterStyled>
        <div className="extension-footer-container">
          <div className="extension-footer-top">
            <div className="footer_main_container w-100">
              <div className="footer_left_sec image_div">
                {/* <img src={redClubIcon} alt="icon" /> */}
                <p
                  className="TandC"
                  onClick={() => history?.push("/termscondition")}
                >
                  Terms and Conditions
                </p>
                <p
                  className="TandC TandC_boder"
                  onClick={() => history.push("/policy")}
                >
                  Privacy policy
                </p>
                <p
                  className="TandC TandC_boder"
                  onClick={() => history.push("/contactus")}
                >
                  Contact Us
                </p>
              </div>

              <div className="footer_right_sec">
                <div className="footer-top-right">
                  <div className="flw_text">Follow us on :</div>
                  <div className="icon_section">
                    <img src={facebook_fill} alt="new" />

                    <img src={instagram_fill} alt="new" />

                    <img src={twitter_fill} alt="new" />

                    <img src={linkedin_fill} alt="new" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="all-rights-reser">
            <p>
              © 2024 {siteName} All Rights Reserved
            </p>
          </div>
        </div>
      </FooterStyled>
    </>
  );
};

export default Footer;
