import { Tab, Tabs } from "react-bootstrap";
import { MAinHomeStyled } from "./MainPage.styled";
import HomeBanner from "../HomeBanner";
import CarouselImage from "../CarouselImage";

const MainHomePage = () => {
  return (
    <MAinHomeStyled>
      <div className="main_page_wrapper">
        <h3>Homepage Offers</h3>
        <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
          <Tab eventKey={1} title="Banners Images">
            <HomeBanner />
          </Tab>
          <Tab eventKey={2} title="Homepage Carousel Images">
            <CarouselImage />
          </Tab>
        </Tabs>
      </div>
    </MAinHomeStyled>
  );
};

export default MainHomePage;
