import { TermsStyled } from "./Terms.styled";
import arrow from "../../images/arrow-right-black.svg";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const TermsCondition = () => {
  const history = useHistory();
  const { themeDetails } = useSelector((state: any) => state?.admin);
  return (
    <TermsStyled>
      <div
        id="main-content"
        className="mh-content"
        role="main"
        // itemprop="mainContentOfPage"
      >
        <div className="entry-content clearfix">
          <article id="page-736" className="post-736 page type-page status-publish">
            <header className="page-header" onClick={() => history?.goBack()}>
            <img src={arrow} alt="arrow" />
              <h1 className="entry-title page-title">Terms of Use</h1>
            </header>
            <div className="entry-content clearfix">
              <p>
                <em>The effective date is 01/7/2021</em>
              </p>
              <p>
                <strong>
                  IMPORTANT! PLEASE READ CAREFULLY THESE TERMS OF USE IN THEIR
                  ENTIRETY BEFORE USING THE SERVICE.
                </strong>
              </p>
              <p>
                <strong>ACCEPTANCE OF TERMS</strong>
              </p>
              <p>
                The following terms and conditions (collectively with the
                Privacy Policy at
                <a href="https://www.Sunsetdeals.com/policy" style={{color: themeDetails?.linkTextColor}}>
                  https://www.Sunsetdeals.com/your-privacy-rights
                </a>
                , the “Terms of Use”) are a legally binding agreement between
                each user (“you” or “your”) and www.Sunsetdeals.com, a wholly
                owned and operated site of Sunsetdeals Inc. (“SUNSETDEALS ,”
                “we,” “us,” “our” or “ours”) and apply to
                https://www.Sunsetdeals.com and&nbsp;
                <a href="https://www.Sunsetdeals.com" style={{color: themeDetails?.linkTextColor}}>
                  www.www.Sunsetdeals.com
                </a>
                &nbsp;and the content, features, services, social media channels
                and applications offered by Sunsetdeals (collectively
                “Service”). By accessing or using the Service, you are
                indicating that you have read and understand these Terms of Use,
                that you will abide by all of their terms and conditions and
                that you have reached the age of majority in your jurisdiction
                of residence. If you do not agree to any of these terms and
                conditions, please do not use the Service.
              </p>
              <p>
                <strong>UPDATES TO TERMS</strong>
              </p>
              <p>
                We reserve the right to change any terms of these Terms of Use
                at any time without prior notice to you. You agree that our
                posting of revised Terms of Use on our Service will constitute
                notice to you of such revised Terms of Use and that your access
                or use of the Service after such posting constitutes your
                agreement to be bound by such changes and the revised Terms of
                Use. Consequently, please review these Terms of Use before each
                and any access or use of our Service. Any changes to these Terms
                of Use will become a part of these Terms of Use and will apply
                as soon as they are posted or such later date as may be
                specified in the posted Terms of Use. Unless explicitly stated
                otherwise, any new features or functionality that augment or
                enhance the Service will be subject to these Terms of Use.
              </p>
              <p>
                <strong>SPECIAL PROMOTION TERMS</strong>
              </p>
              <p>
                Occasionally, we may offer the chance to participate in
                sweepstakes, contests, surveys and/or special offerings
                (“Special Promotion(s)”) through the Service. Special Promotions
                may be governed by terms and conditions that are separate from
                these Terms of Use. If the provisions of a Special Promotion’s
                terms and conditions conflict with these Terms of Use, those
                separate terms and conditions will prevail with respect to such
                Special Promotion, unless otherwise stated.
              </p>
              <p>
                <strong>SUNSETDEALS  CONTENT DEFINED</strong>
              </p>
              <p>
                The “SUNSETDEALS  Content” includes all materials that are
                included in, made available on or are otherwise a part of the
                Service (including past, present and future versions of the
                Service). For example, our SUNSETDEALS  Content includes any and
                all domain names, text, blogs, articles, emails, photos, images,
                illustrations, videos, application software, technologies,
                source and object codes, designs, graphics, layouts, artwork and
                the “look and feel” of the Service.
              </p>
              <p>
                <strong>TRADEMARKS</strong>
              </p>
              <p>
                The SUNSETDEALS  Content also includes SUNSETDEALS  marks and
                logos that are trademarks or service marks of ours. All other
                trademarks, service marks and logos used on or through the
                Service are the trademarks, service marks or logos of their
                respective owners. You are not granted any rights to use any
                trademarks, service marks or logos through your to access the
                Service.
              </p>
              <p>
                <strong>COPYRIGHT PROTECTION</strong>
              </p>
              <p>
                The SUNSETDEALS  Content and the Service are protected by
                copyright as a collective work or compilation under the
                copyright laws of the United States, Canada, and other
                countries. Further, each individual element of the SUNSETDEALS 
                Content constitutes a copyrighted work, and you must abide by
                all additional copyright notices or restrictions contained in
                this Service. Unless specifically and expressly permitted, you
                may not copy, reproduce, distribute, publish, enter into a
                database, display, perform, modify, translate, broadcast, create
                derivative works from, transmit, sell, offer to sell or in any
                way exploit any SUNSETDEALS  Content, in whole or in part. In
                other words, the Service and each individual element of
                SUNSETDEALS  Content are protected from unauthorized copying,
                distribution, publication, dissemination and other use by
                copyright, trademark, patent and other laws, rules, regulations
                and international treaties. Except as specifically and expressly
                stated in these Terms of Use or otherwise granted to you in
                writing, no rights (by implication, estoppel or otherwise) are
                granted to you. Any and all rights not expressly and
                specifically granted are hereby reserved. Nothing in these Terms
                of Use will affect, impair or limit in any way our rights to
                fully exploit any and all of the SUNSETDEALS  Content and
                Service. To the extent the SUNSETDEALS  Content and Service, or
                any element thereof, are not protected by copyright laws, all
                such SUNSETDEALS  Content and/or elements of the Service are
                proprietary to SUNSETDEALS  and are similarly protected from
                unauthorized copying, distribution, publication, dissemination
                and other use.
              </p>
              <p>
                <strong>PERSONAL USE</strong>
              </p>
              <p>
                We grant you a limited, personal, non-exclusive, non-commercial,
                revocable, non-sub licensable and non-transferable license to
                use, view and/or play the SUNSETDEALS  Content. This license is
                subject to your full compliance with these Terms of Use. You may
                also make one (1) occasional print copy of articles of personal
                interest to you. You may not distribute, sell or offer for sale
                the Service, SUNSETDEALS  Content, or any element thereof. In
                addition, SUNSETDEALS  Content may not be used to construct any
                kind of database.
              </p>
              <p>
                <strong>USER GENERATED CONTENT</strong>
              </p>
              <p>
                You understand that all information, including your name and
                email address, data, including your IP address and other
                personally and non-personally identifiable information you
                publicly post while using the Service, including information we
                collect from your social media profiles if you access the
                Service through our social media channels; or other content of
                any kind, uploaded, posted, made available, shared or
                transmitted by you or another user to or through the Service
                (each and all of the foregoing, “User Content”) is the sole
                responsibility of the person from whom such User Content
                originated (please see our Privacy Policy for further
                information about how we treat your personally-identifiable
                information). We do not control such User Content and do not
                make any guarantee whatsoever related to such User Content. By
                using the Service, you may be exposed to User Content, as well
                as material uploaded, posted, made available, shared or
                transmitted to or through the Service by us, that is offensive,
                indecent or objectionable. Under no circumstances will we be
                liable or responsible in any way for any claim related to such
                User Content or material.
              </p>
              <p>
                While we have no obligation to review, monitor, display, accept
                or exploit User Content, we may, in our sole discretion, delete,
                move, re-format, edit, alter, distort, remove or refuse to
                exploit User Content at any time, for any reason, without notice
                or liability to you or any other party.
              </p>
              <p>
                By publicly posting your User Content to or on the Service, you
                grant us, and our affiliates and agents, a non-exclusive,
                irrevocable and royalty-free license in perpetuity throughout
                the universe to host, display, transmit, distribute, reproduce,
                modify, edit, translate, create derivative works from, store,
                archive, cache, sell, sublicense and otherwise use your User
                Content on the Service, and you release all moral rights and
                similar rights in and to your User Content. You further
                perpetually and irrevocably grant SUNSETDEALS  the unconditional
                right to use and exploit your name, persona and likeness
                included in any User Content and in connection with any User
                Content, without any obligation, remuneration or attribution of
                any kind to you or anyone else.
              </p>
              <p>
                You agree that you either: (i) own the rights to the User
                Content you submit and the right to grant all of the rights and
                licenses in these Terms of Use; or (ii) you have all necessary
                rights and licenses from the owner(s) of these rights to enter
                into these Terms of Use and grant SUNSETDEALS  these licenses.
                Upon SUNSETDEALS ’s request, you will furnish SUNSETDEALS  any
                documentation, substantiation or releases necessary to verify
                your compliance with these Terms of Use. SUNSETDEALS  reserves
                the right to remove any User Content from the Service or our
                social media channels at any time in our sole discretion.
              </p>
              <p>
                <strong>
                  DESIGNATED AGENT FOR COPYRIGHT INFRINGEMENT CLAIM
                </strong>
              </p>
              <p>
                If you have a good faith belief that your copyright is being
                infringed by any content on the Service, please send a notice
                that includes information listed below (the “Notice of Claimed
                Infringement”) to our Designated Copyright Agent:
              </p>
              <p>
                Designated Copyright Agent:
                <br />
                address: 2049 Port Cardiff, Chula Vista, California 91914-3561
                <br />
                email:
                <a href="mailto:admin@www.Sunsetdeals.com" style={{color: themeDetails?.linkTextColor}}>
                  admin@www.Sunsetdeals.com
                </a>
              </p>
              <p>
                Notice of Claimed Infringement must include at a minimum the
                following information:
              </p>
              <ul>
                <li>
                  a physical or electronic signature of the owner or a person
                  authorized to act on behalf of the owner of an exclusive right
                  that is allegedly infringed;
                </li>
                <li>
                  an identification of the copyrighted work claimed to have been
                  infringed, or, if multiple copyrighted works are covered by a
                  single notification, a representative list of such works;
                </li>
                <li>
                  an identification of the material that is claimed to be
                  infringing or to be the subject of infringing activity that is
                  to be removed or access to which is to be disabled, and
                  information reasonably sufficient to permit SUNSETDEALS  to
                  locate such material;
                </li>
                <li>
                  information reasonably sufficient to permit SUNSETDEALS  to
                  contact you, such as an address, telephone number, and email
                  address at which the you may be contacted;
                </li>
                <li>
                  a statement that the you have a good faith belief that use of
                  the material in the manner complained of is not authorized by
                  the copyright owner, its agent or the law; and
                </li>
                <li>
                  a statement that the information in the notification is
                  accurate, and under penalty of perjury, that you are the owner
                  or authorized to act on behalf of the owner of an exclusive
                  right that is allegedly infringed.
                </li>
              </ul>
              <p>
                We have instituted a policy designed to enable, at our sole
                discretion, the expeditious removal of infringing material and
                the termination of the usernames and access of users who qualify
                as repeat infringers, although no assurances can be given that
                our policy will achieve such result in all circumstances.
                <br />
                If you believe that any material has been removed as a result of
                such mistake of misidentification, send a notice to our
                Designated Copyright Agent that includes the following
                information:
              </p>
              <ul>
                <li>your physical or electronic signature;</li>
                <li>
                  an identification of the material that has been removed or to
                  which access has been disabled and the location at which such
                  material appeared before it was removed or access to it was
                  disabled;
                </li>
                <li>
                  a statement under penalty of perjury that you have a good
                  faith belief that the material was removed or disabled as a
                  result of mistake or misidentification of the material that
                  was removed or disabled; and
                </li>
                <li>
                  your name, address, and telephone number, and a statement that
                  you consent to the jurisdiction of Federal District Court for
                  the judicial district in which your address is located, or if
                  your address is outside of the United States, for any judicial
                  district in which SUNSETDEALS  may be found and that you will
                  accept service of process from the party who provided the
                  Notice of Claimed Infringement or an agent of such party.
                </li>
              </ul>
              <p>
                If a counter-notice is received by the Designated Agent,
                SUNSETDEALS  may send a copy of the counter-notice to the
                original complaining party informing such party that SUNSETDEALS 
                may replace the removed material or cease disabling it in 10
                business days. Unless the original complaining party files an
                action seeking a court order against us or the party contesting
                the original complaint, the removed material may be replaced or
                access to it restored in 10 to 14 business days or more after
                receipt of the counter-notice, at SUNSETDEALS ’s discretion.
              </p>
              <p>
                <strong>YOUR CONDUCT</strong>
              </p>
              <p>
                You will not use the Service to: violate any law or encourage or
                provide instructions to another to do so; upload, post, make
                available or otherwise transmit any User Content that is
                unlawful (including, without limitation, User Content that
                infringes or violates any patent, trademark, trade secret,
                copyright, right of publicity or other right) or User Content
                that is confidential, harmful, threatening, harassing, abusive,
                inflammatory, tortious, defamatory, libelous, vulgar, obscene,
                pornographic, invasive of another’s privacy or racially,
                ethnically or otherwise objectionable; misrepresent your
                identity; upload, post, make available or otherwise transmit
                that User Content containing unsolicited or unauthorized
                advertising, promotional materials, spam, junk mail, chain
                letters, pyramid schemes, or any other form of solicitations;
                upload, post, make available or otherwise transmit any User
                Content that contains software viruses or any other computer
                code, files or programs designed to interrupt, destroy or limit
                the functionality of any software, hardware or
                telecommunications equipment; act in a manner that negatively
                affects other users’ ability to use the Service; collect or
                store personal data about anyone; display, transmit, distribute,
                reproduce, modify, edit, create derivative works from, store,
                archive, cache, sell or offer for sale the Service, SUNSETDEALS 
                Content or any element thereof.
              </p>
              <p>
                <strong>DISCLOSURE OF MATERIAL CONNECTIONS</strong>
              </p>
              <p>
                You acknowledge that: (i) we may receive from marketers free
                products that we review or discuss in our content and (ii) we
                may receive compensation in connection with advertisement or
                stories regarding run advertisements or stories on our sites
                concerning some of those products or companies that sell them
                (and other products sold by such companies) for which we
                sometimes receive compensation.
              </p>
              <p>
                <strong>LINKS TO THE SERVICE</strong>
              </p>
              <p>
                We reserve the right to revoke any link to any page of the
                Service or our social media channels in our sole discretion
                without notice. We also reserve the right to require that you
                obtain our prior written consent before you provide any links to
                any page or social media page of the Service.
              </p>
              <p>
                <strong>THIRD PARTY LINKS</strong>
              </p>
              <p>
                We may provide, or third parties may provide, on or through the
                Service, links to other websites or resources. You acknowledge
                and agree that we have no control over such sites and resources,
                are not responsible for the availability of such external sites
                or resources, and do not endorse nor are we responsible for or
                liable for any losses, expenses or damages, to you or your
                software, hardware or data, arising out of the operations of
                such external sites or resources. These links sites are for your
                convenience only, and you access them at your own risk,
                regardless of whether or not we receive compensation, commission
                or share of revenues generated by purchases via such links. YOUR
                COMMUNICATIONS AND DEALINGS WITH THIRD PARTIES THROUGH SUCH LINK
                INCLUDING, WITHOUT LIMITATION, THE PAYMENT AND DELIVERY OF
                PRODUCTS AND SERVICES, AND ANY TERMS, CONDITIONS, WARRANTIES AND
                REPRESENTATIONS ASSOCIATED WITH ANY SUCH COMMUNICATIONS AND
                DEALINGS, ARE SOLELY BETWEEN YOU AND THE THIRD PARTY.
              </p>
              <p>
                <strong>SERVICE MODIFICATION OR TERMINATION</strong>
              </p>
              <p>
                We reserve the right at any time and from time to time to modify
                or discontinue, temporarily or permanently, the Service (or any
                part thereof) with or without notice. We will not be liable to
                you or to any third party for any such modification, suspension
                or discontinuance of the Service.
                <br />
                We may at our sole discretion, under any circumstances, for any
                or no reason whatsoever and without prior notice to you,
                immediately terminate your access to the Service. We will not be
                liable or responsible to you or any third party for such
                termination.
              </p>
              <p>
                <strong>DISCLAIMERS</strong>
              </p>
              <p>
                THIS SERVICE IS AVAILABLE “AS IS” AND “AS AVAILABLE” AND “WITH
                ALL FAULTS.” YOU USE THE SERVICE AT YOUR SOLE RISK.
                <br />
                TO THE FULLEST EXTENT PERMISSIBLE BY LAW, SUNSETDEALS  AND ITS
                OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, LICENSORS, SUPPLIERS,
                PARTNERS AND AFFILIATES:
                <br />
                (A) DO NOT WARRANT THAT THIS SERVICE WILL BE UNINTERRUPTED OR
                ERROR-FREE. THERE MAY BE DELAYS, OMISSIONS, INTERRUPTIONS AND
                INACCURACIES IN THE NEWS, INFORMATION OR OTHER MATERIALS
                AVAILABLE THROUGH THIS SERVICE;
                <br />
                (B) DO NOT MAKE ANY WARRANTIES, EXPRESS OR IMPLIED, INCLUDING,
                WITHOUT LIMITATION, THOSE OF MERCHANTABILITY AND FITNESS FOR A
                PARTICULAR PURPOSE, WITH RESPECT TO THIS SERVICE OR ANY
                INFORMATION OR GOODS THAT ARE AVAILABLE OR ADVERTISED OR SOLD
                THROUGH THIS SERVICE (REGARDLESS OF WHETHER SUNSETDEALS  HAS
                RECEIVED PAYMENT IN CONNECTION THEREWITH);
                <br />
                (C) DO NOT MAKE ANY REPRESENTATIONS, NOR DO WE ENDORSE THE
                ACCURACY, COMPLETENESS, TIMELINESS OR RELIABILITY OF ANY ADVICE,
                OPINION, STATEMENT OR OTHER MATERIAL OR DATABASE DISPLAYED,
                UPLOADED OR DISTRIBUTED IN THIS SERVICE OR AVAILABLE THOUGH
                LINKS IN THIS SERVICE;
                <br />
                (D) THE CONTENT AVAILABLE ON OR THROUGH THIS SERVICE IS IN NO
                WAY INTENDED TO AND WILL NOT BE CONSTRUED TO: (i) CONSTITUTE
                PROFESSIONAL MEDICAL, HEALTH, LEGAL, TAX, OR FINANCIAL ADVICE;
                (ii) DIAGNOSE, CURE, OR TREAT ANY MEDICAL, HEALTH OR OTHER
                CONDITION. ALWAYS SEEK THE ADVICE OF A QUALIFIED PROFESSIONAL,
                OR (C) RECOMMEND, ENDORSE, OR ADVICE REGARDING ANY MEDICAL OR
                FITNESS PRODUCT, PROGRAM OR TREATMENT.
                <br />
                FURTHER, SUNSETDEALS  RESERVES THE RIGHT TO CORRECT ANY ERRORS OR
                OMISSIONS IN THIS SERVICE. ALTHOUGH SUNSETDEALS  INTENDS TO TAKE
                REASONABLE STEPS TO PREVENT THE INTRODUCTION OF VIRUSES, WORMS,
                “TROJAN HORSES” OR OTHER DESTRUCTIVE MATERIALS TO THIS SERVICE,
                SUNSETDEALS  DOES NOT GUARANTEE OR WARRANT THAT THIS SERVICE OR
                MATERIALS THAT MAY BE DOWNLOADED FROM THIS SERVICE DO NOT
                CONTAIN SUCH DESTRUCTIVE FEATURES. SUNSETDEALS  IS NOT LIABLE FOR
                ANY DAMAGES OR HARM ATTRIBUTABLE TO SUCH FEATURES.
              </p>
              <p>
                <strong>LIMITATION OF LIABILITY</strong>
              </p>
              <p>
                SUNSETDEALS  IS NOT AND WILL NOT LIABLE TO YOU FOR ANY DIRECT,
                INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE OR
                EXEMPLARY DAMAGES OF ANY KIND, INCLUDING, DAMAGES FOR LOSS OF
                PROFITS, REVENUES, BUSINESS, GOODWILL, USE, DATA OR OTHER
                INTANGIBLE LOSSES, DIRECTLY OR INDIRECTLY RELATED TO: (A) THE
                USE OR THE INABILITY TO USE THE SERVICE; (B) THE COST OF
                PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY
                GOODS, DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR
                MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM
                THE SERVICE; (C) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR
                TRANSMISSIONS, DATA OR INFORMATION; (D) STATEMENTS OR CONDUCT OF
                ANY THIRD PARTY ON THE SERVICE; OR (E) ANY OTHER MATTER RELATING
                TO THE SERVICE (INCLUDING, AS A RESULT OF BREACH ANY TERMS OF
                THESE TERMS OF USE), INCLUDING GOODS AND SERVICES PROVIDED BY
                THIRD PARTIES, INCLUDING ADVERTISERS, THAT ARE MADE AVAILABLE OR
                ADVERTISED OR SOLD THROUGH THIS SERVICE.
                <br />
                The Service is controlled and offered by SUNSETDEALS  from its
                facilities in the United States of America for use by
                individuals located in the United States of America and Canada.
                SUNSETDEALS  makes no representations that the Service is
                appropriate or available for use in other locations. Those who
                access or use the Service from other jurisdictions do so at
                their own volition and are responsible for compliance with local
                law.
              </p>
              <p>
                <strong>INDEMNIFICATION</strong>
              </p>
              <p>
                To the extent permitted by applicable law, you agree to
                indemnify, defend and hold harmless SUNSETDEALS , its officers,
                directors, employees, agents, licensors, suppliers, partners and
                affiliates from and against all losses, liabilities, expenses,
                damages, and costs, including reasonable attorneys’ fees,
                resulting from any violation of these Terms of Use or any
                activity related to use of the Service (including negligent or
                wrongful conduct) by you or any other person accessing the
                Service via your Sunsetdeals account, email account(s), social
                media account(s) or ISP address. We reserve the right to take
                over the exclusive defense of any claim. This section will
                survive termination of your access to the Service.
              </p>
              <p>
                <strong>RELEASE</strong>
              </p>
              <p>
                Not applicable in all jurisdictions; void where prohibited: If
                you have a dispute with one or more users of the Service, you
                release us from claims, demands and damages (actual and
                consequential) of every kind and nature, known and unknown,
                arising out of or in any way connected with such disputes. You
                also waive California Civil Code §1542, which says: “A general
                release does not extend to claims which the creditor does not
                know or suspect to exist in his favor at the time of executing
                the release, which if known by him must have materially affected
                his settlement with the debtor.” You also waive any and all
                benefits and rights that would otherwise accrue to you by reason
                of the provisions of any federal or state/provincial statute or
                principle of common law of any jurisdiction in Canada or the
                United States, or any political entity, nation, or province or
                local law or regulation that may govern this release, which
                statute, regulation, law or principle provides in substance
                something similar to California Civil Code § 1542.
              </p>
              <p>
                <strong>GOVERNING LAW</strong>
              </p>
              <p>
                These Terms of Use and the relationship between you and
                SUNSETDEALS  will be governed by the laws of the United States of
                America and the State of California as an agreement wholly
                performed therein without regard to its conflict of law
                provisions and the United Nations Conventions on Contracts (if
                applicable).
                <br />
                Any dispute relating in any way to your use of the Service will
                be submitted to confidential arbitration in California, except
                that, to the extent you have in any manner violated or
                threatened to violate our intellectual property rights, we may
                seek injunctive or other appropriate relief in any state or
                Federal court in the state of California, and you consent to
                exclusive jurisdiction and venue in such courts. Arbitration
                under these Terms of Use will be conducted under the rules then
                prevailing of the American Arbitration Association. The
                arbitrator’s award will be binding and may be entered as a
                judgment in any court of competent jurisdiction. To the fullest
                extent permitted by applicable law, no arbitration under these
                Terms of Use will be joined to an arbitration involving any
                other party subject to these Terms of Use, whether through className
                arbitration proceedings or otherwise.
                <br />
                Any claim or cause of action arising out of or related to use of
                the Service or these Terms of Use must be filed within one year
                after such claim or cause of action arose or be forever barred.
                Any claim by you that may arise in connection with these Terms
                of Use will be compensable by monetary damages and you will in
                no event be entitled to injunctive or other equitable relief.
                <br />
                You also hereby expressly waive any right to resort to any form
                of className action.
              </p>
              <p>
                <strong>MISCELLANEOUS</strong>
              </p>
              <p>
                These Terms of Use constitute the entire agreement between you
                and us and govern your use of the Service. These Terms of Use
                supersede any prior agreements between you and us with respect
                to the Service. You also may be subject to additional terms and
                conditions that may apply when you use other services, affiliate
                services, or third-party services. These Terms of Use are fully
                assignable by us and will be binding upon and inure to the
                benefit of our successors and assigns. No agency, partnership,
                joint venture, employee-employer or franchiser-franchisee
                relationship is intended or created by these Terms of Use. Our
                failure to exercise or enforce any right or provision of these
                Terms of Use will not constitute a waiver of such right or
                provision. If any provision of these Terms of Use is found by a
                court of competent jurisdiction to be invalid, the court should
                nevertheless endeavor to give effect to the parties’ intentions
                as reflected in the provision, and the other provisions of these
                Terms of Use will remain in full force and effect. The headings
                in these Terms of Use are for convenience only and have no legal
                or contractual effect.
              </p>
              <p>
                <strong>Contact Us</strong>
              </p>
              <p>
                If you have questions about this privacy policy, or about the
                Website, please contact us at
                <a href="mailto:admin@www.Sunsetdeals.com" style={{color: themeDetails?.linkTextColor}}>
                  admin@www.Sunsetdeals.com
                </a>
                .&nbsp;For more information about
                <a href="/your-privacy-rights" style={{color: themeDetails?.linkTextColor}}>Your Privacy Rights</a>&nbsp;or
                our
                <a href="https://www.Sunsetdeals.com/cookie-policy" style={{color: themeDetails?.linkTextColor}}>
                  Cookie Policy
                </a>
                , please click their respective links.
              </p>
            </div>
          </article>
          <p>
            <em>The effective date is 01/7/2021</em>
          </p>
          <p>
            <strong>
              IMPORTANT! PLEASE READ CAREFULLY THESE TERMS OF USE IN THEIR
              ENTIRETY BEFORE USING THE SERVICE.
            </strong>
          </p>
          <p>
            <strong>ACCEPTANCE OF TERMS</strong>
          </p>
          <p>
            The following terms and conditions (collectively with the Privacy
            Policy at
            <a href="https://www.Sunsetdeals.com/your-privacy-rights" style={{color: themeDetails?.linkTextColor}}>
              https://www.Sunsetdeals.com/your-privacy-rights
            </a>
            , the “Terms of Use”) are a legally binding agreement between each
            user (“you” or “your”) and www.Sunsetdeals.com, a wholly owned and
            operated site of Sunsetdeals Inc. (“SUNSETDEALS ,” “we,” “us,” “our”
            or “ours”) and apply to https://www.Sunsetdeals.com and&nbsp;
            <a href="https://www.Sunsetdeals.com" style={{color: themeDetails?.linkTextColor}}>www.www.Sunsetdeals.com</a>
            &nbsp;and the content, features, services, social media channels and
            applications offered by Sunsetdeals (collectively “Service”). By
            accessing or using the Service, you are indicating that you have
            read and understand these Terms of Use, that you will abide by all
            of their terms and conditions and that you have reached the age of
            majority in your jurisdiction of residence. If you do not agree to
            any of these terms and conditions, please do not use the Service.
          </p>
          <p>
            <strong>UPDATES TO TERMS</strong>
          </p>
          <p>
            We reserve the right to change any terms of these Terms of Use at
            any time without prior notice to you. You agree that our posting of
            revised Terms of Use on our Service will constitute notice to you of
            such revised Terms of Use and that your access or use of the Service
            after such posting constitutes your agreement to be bound by such
            changes and the revised Terms of Use. Consequently, please review
            these Terms of Use before each and any access or use of our Service.
            Any changes to these Terms of Use will become a part of these Terms
            of Use and will apply as soon as they are posted or such later date
            as may be specified in the posted Terms of Use. Unless explicitly
            stated otherwise, any new features or functionality that augment or
            enhance the Service will be subject to these Terms of Use.
          </p>
          <p>
            <strong>SPECIAL PROMOTION TERMS</strong>
          </p>
          <p>
            Occasionally, we may offer the chance to participate in sweepstakes,
            contests, surveys and/or special offerings (“Special Promotion(s)”)
            through the Service. Special Promotions may be governed by terms and
            conditions that are separate from these Terms of Use. If the
            provisions of a Special Promotion’s terms and conditions conflict
            with these Terms of Use, those separate terms and conditions will
            prevail with respect to such Special Promotion, unless otherwise
            stated.
          </p>
          <p>
            <strong>SUNSETDEALS  CONTENT DEFINED</strong>
          </p>
          <p>
            The “SUNSETDEALS  Content” includes all materials that are included
            in, made available on or are otherwise a part of the Service
            (including past, present and future versions of the Service). For
            example, our SUNSETDEALS  Content includes any and all domain names,
            text, blogs, articles, emails, photos, images, illustrations,
            videos, application software, technologies, source and object codes,
            designs, graphics, layouts, artwork and the “look and feel” of the
            Service.
          </p>
          <p>
            <strong>TRADEMARKS</strong>
          </p>
          <p>
            The SUNSETDEALS  Content also includes SUNSETDEALS  marks and logos
            that are trademarks or service marks of ours. All other trademarks,
            service marks and logos used on or through the Service are the
            trademarks, service marks or logos of their respective owners. You
            are not granted any rights to use any trademarks, service marks or
            logos through your to access the Service.
          </p>
          <p>
            <strong>COPYRIGHT PROTECTION</strong>
          </p>
          <p>
            The SUNSETDEALS  Content and the Service are protected by copyright
            as a collective work or compilation under the copyright laws of the
            United States, Canada, and other countries. Further, each individual
            element of the SUNSETDEALS  Content constitutes a copyrighted work,
            and you must abide by all additional copyright notices or
            restrictions contained in this Service. Unless specifically and
            expressly permitted, you may not copy, reproduce, distribute,
            publish, enter into a database, display, perform, modify, translate,
            broadcast, create derivative works from, transmit, sell, offer to
            sell or in any way exploit any SUNSETDEALS  Content, in whole or in
            part. In other words, the Service and each individual element of
            SUNSETDEALS  Content are protected from unauthorized copying,
            distribution, publication, dissemination and other use by copyright,
            trademark, patent and other laws, rules, regulations and
            international treaties. Except as specifically and expressly stated
            in these Terms of Use or otherwise granted to you in writing, no
            rights (by implication, estoppel or otherwise) are granted to you.
            Any and all rights not expressly and specifically granted are hereby
            reserved. Nothing in these Terms of Use will affect, impair or limit
            in any way our rights to fully exploit any and all of the
            SUNSETDEALS  Content and Service. To the extent the SUNSETDEALS 
            Content and Service, or any element thereof, are not protected by
            copyright laws, all such SUNSETDEALS  Content and/or elements of the
            Service are proprietary to SUNSETDEALS  and are similarly protected
            from unauthorized copying, distribution, publication, dissemination
            and other use.
          </p>
          <p>
            <strong>PERSONAL USE</strong>
          </p>
          <p>
            We grant you a limited, personal, non-exclusive, non-commercial,
            revocable, non-sub licensable and non-transferable license to use,
            view and/or play the SUNSETDEALS  Content. This license is subject to
            your full compliance with these Terms of Use. You may also make one
            (1) occasional print copy of articles of personal interest to you.
            You may not distribute, sell or offer for sale the Service,
            SUNSETDEALS  Content, or any element thereof. In addition,
            SUNSETDEALS  Content may not be used to construct any kind of
            database.
          </p>
          <p>
            <strong>USER GENERATED CONTENT</strong>
          </p>
          <p>
            You understand that all information, including your name and email
            address, data, including your IP address and other personally and
            non-personally identifiable information you publicly post while
            using the Service, including information we collect from your social
            media profiles if you access the Service through our social media
            channels; or other content of any kind, uploaded, posted, made
            available, shared or transmitted by you or another user to or
            through the Service (each and all of the foregoing, “User Content”)
            is the sole responsibility of the person from whom such User Content
            originated (please see our Privacy Policy for further information
            about how we treat your personally-identifiable information). We do
            not control such User Content and do not make any guarantee
            whatsoever related to such User Content. By using the Service, you
            may be exposed to User Content, as well as material uploaded,
            posted, made available, shared or transmitted to or through the
            Service by us, that is offensive, indecent or objectionable. Under
            no circumstances will we be liable or responsible in any way for any
            claim related to such User Content or material.
          </p>
          <p>
            While we have no obligation to review, monitor, display, accept or
            exploit User Content, we may, in our sole discretion, delete, move,
            re-format, edit, alter, distort, remove or refuse to exploit User
            Content at any time, for any reason, without notice or liability to
            you or any other party.
          </p>
          <p>
            By publicly posting your User Content to or on the Service, you
            grant us, and our affiliates and agents, a non-exclusive,
            irrevocable and royalty-free license in perpetuity throughout the
            universe to host, display, transmit, distribute, reproduce, modify,
            edit, translate, create derivative works from, store, archive,
            cache, sell, sublicense and otherwise use your User Content on the
            Service, and you release all moral rights and similar rights in and
            to your User Content. You further perpetually and irrevocably grant
            SUNSETDEALS  the unconditional right to use and exploit your name,
            persona and likeness included in any User Content and in connection
            with any User Content, without any obligation, remuneration or
            attribution of any kind to you or anyone else.
          </p>
          <p>
            You agree that you either: (i) own the rights to the User Content
            you submit and the right to grant all of the rights and licenses in
            these Terms of Use; or (ii) you have all necessary rights and
            licenses from the owner(s) of these rights to enter into these Terms
            of Use and grant SUNSETDEALS  these licenses. Upon SUNSETDEALS ’s
            request, you will furnish SUNSETDEALS  any documentation,
            substantiation or releases necessary to verify your compliance with
            these Terms of Use. SUNSETDEALS  reserves the right to remove any
            User Content from the Service or our social media channels at any
            time in our sole discretion.
          </p>
          <p>
            <strong>DESIGNATED AGENT FOR COPYRIGHT INFRINGEMENT CLAIM</strong>
          </p>
          <p>
            If you have a good faith belief that your copyright is being
            infringed by any content on the Service, please send a notice that
            includes information listed below (the “Notice of Claimed
            Infringement”) to our Designated Copyright Agent:
          </p>
          <p>
            Designated Copyright Agent:
            <br />
            address: 2049 Port Cardiff, Chula Vista, California 91914-3561
            <br />
            email:{" "}
            <a href="mailto:admin@www.Sunsetdeals.com" style={{color: themeDetails?.linkTextColor}}>
              admin@www.Sunsetdeals.com
            </a>
          </p>
          <p>
            Notice of Claimed Infringement must include at a minimum the
            following information:
          </p>
          <ul>
            <li>
              a physical or electronic signature of the owner or a person
              authorized to act on behalf of the owner of an exclusive right
              that is allegedly infringed;
            </li>
            <li>
              an identification of the copyrighted work claimed to have been
              infringed, or, if multiple copyrighted works are covered by a
              single notification, a representative list of such works;
            </li>
            <li>
              an identification of the material that is claimed to be infringing
              or to be the subject of infringing activity that is to be removed
              or access to which is to be disabled, and information reasonably
              sufficient to permit SUNSETDEALS  to locate such material;
            </li>
            <li>
              information reasonably sufficient to permit SUNSETDEALS  to contact
              you, such as an address, telephone number, and email address at
              which the you may be contacted;
            </li>
            <li>
              a statement that the you have a good faith belief that use of the
              material in the manner complained of is not authorized by the
              copyright owner, its agent or the law; and
            </li>
            <li>
              a statement that the information in the notification is accurate,
              and under penalty of perjury, that you are the owner or authorized
              to act on behalf of the owner of an exclusive right that is
              allegedly infringed.
            </li>
          </ul>
          <p>
            We have instituted a policy designed to enable, at our sole
            discretion, the expeditious removal of infringing material and the
            termination of the usernames and access of users who qualify as
            repeat infringers, although no assurances can be given that our
            policy will achieve such result in all circumstances.
            <br />
            If you believe that any material has been removed as a result of
            such mistake of misidentification, send a notice to our Designated
            Copyright Agent that includes the following information:
          </p>
          <ul>
            <li>your physical or electronic signature;</li>
            <li>
              an identification of the material that has been removed or to
              which access has been disabled and the location at which such
              material appeared before it was removed or access to it was
              disabled;
            </li>
            <li>
              a statement under penalty of perjury that you have a good faith
              belief that the material was removed or disabled as a result of
              mistake or misidentification of the material that was removed or
              disabled; and
            </li>
            <li>
              your name, address, and telephone number, and a statement that you
              consent to the jurisdiction of Federal District Court for the
              judicial district in which your address is located, or if your
              address is outside of the United States, for any judicial district
              in which SUNSETDEALS  may be found and that you will accept service
              of process from the party who provided the Notice of Claimed
              Infringement or an agent of such party.
            </li>
          </ul>
          <p>
            If a counter-notice is received by the Designated Agent, SUNSETDEALS 
            may send a copy of the counter-notice to the original complaining
            party informing such party that SUNSETDEALS  may replace the removed
            material or cease disabling it in 10 business days. Unless the
            original complaining party files an action seeking a court order
            against us or the party contesting the original complaint, the
            removed material may be replaced or access to it restored in 10 to
            14 business days or more after receipt of the counter-notice, at
            SUNSETDEALS ’s discretion.
          </p>
          <p>
            <strong>YOUR CONDUCT</strong>
          </p>
          <p>
            You will not use the Service to: violate any law or encourage or
            provide instructions to another to do so; upload, post, make
            available or otherwise transmit any User Content that is unlawful
            (including, without limitation, User Content that infringes or
            violates any patent, trademark, trade secret, copyright, right of
            publicity or other right) or User Content that is confidential,
            harmful, threatening, harassing, abusive, inflammatory, tortious,
            defamatory, libelous, vulgar, obscene, pornographic, invasive of
            another’s privacy or racially, ethnically or otherwise
            objectionable; misrepresent your identity; upload, post, make
            available or otherwise transmit that User Content containing
            unsolicited or unauthorized advertising, promotional materials,
            spam, junk mail, chain letters, pyramid schemes, or any other form
            of solicitations; upload, post, make available or otherwise transmit
            any User Content that contains software viruses or any other
            computer code, files or programs designed to interrupt, destroy or
            limit the functionality of any software, hardware or
            telecommunications equipment; act in a manner that negatively
            affects other users’ ability to use the Service; collect or store
            personal data about anyone; display, transmit, distribute,
            reproduce, modify, edit, create derivative works from, store,
            archive, cache, sell or offer for sale the Service, SUNSETDEALS 
            Content or any element thereof.
          </p>
          <p>
            <strong>DISCLOSURE OF MATERIAL CONNECTIONS</strong>
          </p>
          <p>
            You acknowledge that: (i) we may receive from marketers free
            products that we review or discuss in our content and (ii) we may
            receive compensation in connection with advertisement or stories
            regarding run advertisements or stories on our sites concerning some
            of those products or companies that sell them (and other products
            sold by such companies) for which we sometimes receive compensation.
          </p>
          <p>
            <strong>LINKS TO THE SERVICE</strong>
          </p>
          <p>
            We reserve the right to revoke any link to any page of the Service
            or our social media channels in our sole discretion without notice.
            We also reserve the right to require that you obtain our prior
            written consent before you provide any links to any page or social
            media page of the Service.
          </p>
          <p>
            <strong>THIRD PARTY LINKS</strong>
          </p>
          <p>
            We may provide, or third parties may provide, on or through the
            Service, links to other websites or resources. You acknowledge and
            agree that we have no control over such sites and resources, are not
            responsible for the availability of such external sites or
            resources, and do not endorse nor are we responsible for or liable
            for any losses, expenses or damages, to you or your software,
            hardware or data, arising out of the operations of such external
            sites or resources. These links sites are for your convenience only,
            and you access them at your own risk, regardless of whether or not
            we receive compensation, commission or share of revenues generated
            by purchases via such links. YOUR COMMUNICATIONS AND DEALINGS WITH
            THIRD PARTIES THROUGH SUCH LINK INCLUDING, WITHOUT LIMITATION, THE
            PAYMENT AND DELIVERY OF PRODUCTS AND SERVICES, AND ANY TERMS,
            CONDITIONS, WARRANTIES AND REPRESENTATIONS ASSOCIATED WITH ANY SUCH
            COMMUNICATIONS AND DEALINGS, ARE SOLELY BETWEEN YOU AND THE THIRD
            PARTY.
          </p>
          <p>
            <strong>SERVICE MODIFICATION OR TERMINATION</strong>
          </p>
          <p>
            We reserve the right at any time and from time to time to modify or
            discontinue, temporarily or permanently, the Service (or any part
            thereof) with or without notice. We will not be liable to you or to
            any third party for any such modification, suspension or
            discontinuance of the Service.
            <br />
            We may at our sole discretion, under any circumstances, for any or
            no reason whatsoever and without prior notice to you, immediately
            terminate your access to the Service. We will not be liable or
            responsible to you or any third party for such termination.
          </p>
          <p>
            <strong>DISCLAIMERS</strong>
          </p>
          <p>
            THIS SERVICE IS AVAILABLE “AS IS” AND “AS AVAILABLE” AND “WITH ALL
            FAULTS.” YOU USE THE SERVICE AT YOUR SOLE RISK.
            <br />
            TO THE FULLEST EXTENT PERMISSIBLE BY LAW, SUNSETDEALS  AND ITS
            OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, LICENSORS, SUPPLIERS,
            PARTNERS AND AFFILIATES:
            <br />
            (A) DO NOT WARRANT THAT THIS SERVICE WILL BE UNINTERRUPTED OR
            ERROR-FREE. THERE MAY BE DELAYS, OMISSIONS, INTERRUPTIONS AND
            INACCURACIES IN THE NEWS, INFORMATION OR OTHER MATERIALS AVAILABLE
            THROUGH THIS SERVICE;
            <br />
            (B) DO NOT MAKE ANY WARRANTIES, EXPRESS OR IMPLIED, INCLUDING,
            WITHOUT LIMITATION, THOSE OF MERCHANTABILITY AND FITNESS FOR A
            PARTICULAR PURPOSE, WITH RESPECT TO THIS SERVICE OR ANY INFORMATION
            OR GOODS THAT ARE AVAILABLE OR ADVERTISED OR SOLD THROUGH THIS
            SERVICE (REGARDLESS OF WHETHER SUNSETDEALS  HAS RECEIVED PAYMENT IN
            CONNECTION THEREWITH);
            <br />
            (C) DO NOT MAKE ANY REPRESENTATIONS, NOR DO WE ENDORSE THE ACCURACY,
            COMPLETENESS, TIMELINESS OR RELIABILITY OF ANY ADVICE, OPINION,
            STATEMENT OR OTHER MATERIAL OR DATABASE DISPLAYED, UPLOADED OR
            DISTRIBUTED IN THIS SERVICE OR AVAILABLE THOUGH LINKS IN THIS
            SERVICE;
            <br />
            (D) THE CONTENT AVAILABLE ON OR THROUGH THIS SERVICE IS IN NO WAY
            INTENDED TO AND WILL NOT BE CONSTRUED TO: (i) CONSTITUTE
            PROFESSIONAL MEDICAL, HEALTH, LEGAL, TAX, OR FINANCIAL ADVICE; (ii)
            DIAGNOSE, CURE, OR TREAT ANY MEDICAL, HEALTH OR OTHER CONDITION.
            ALWAYS SEEK THE ADVICE OF A QUALIFIED PROFESSIONAL, OR (C)
            RECOMMEND, ENDORSE, OR ADVICE REGARDING ANY MEDICAL OR FITNESS
            PRODUCT, PROGRAM OR TREATMENT.
            <br />
            FURTHER, SUNSETDEALS  RESERVES THE RIGHT TO CORRECT ANY ERRORS OR
            OMISSIONS IN THIS SERVICE. ALTHOUGH SUNSETDEALS  INTENDS TO TAKE
            REASONABLE STEPS TO PREVENT THE INTRODUCTION OF VIRUSES, WORMS,
            “TROJAN HORSES” OR OTHER DESTRUCTIVE MATERIALS TO THIS SERVICE,
            SUNSETDEALS  DOES NOT GUARANTEE OR WARRANT THAT THIS SERVICE OR
            MATERIALS THAT MAY BE DOWNLOADED FROM THIS SERVICE DO NOT CONTAIN
            SUCH DESTRUCTIVE FEATURES. SUNSETDEALS  IS NOT LIABLE FOR ANY DAMAGES
            OR HARM ATTRIBUTABLE TO SUCH FEATURES.
          </p>
          <p>
            <strong>LIMITATION OF LIABILITY</strong>
          </p>
          <p>
            SUNSETDEALS  IS NOT AND WILL NOT LIABLE TO YOU FOR ANY DIRECT,
            INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE OR EXEMPLARY
            DAMAGES OF ANY KIND, INCLUDING, DAMAGES FOR LOSS OF PROFITS,
            REVENUES, BUSINESS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES,
            DIRECTLY OR INDIRECTLY RELATED TO: (A) THE USE OR THE INABILITY TO
            USE THE SERVICE; (B) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND
            SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES
            PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED
            INTO THROUGH OR FROM THE SERVICE; (C) UNAUTHORIZED ACCESS TO OR
            ALTERATION OF YOUR TRANSMISSIONS, DATA OR INFORMATION; (D)
            STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SERVICE; OR (E) ANY
            OTHER MATTER RELATING TO THE SERVICE (INCLUDING, AS A RESULT OF
            BREACH ANY TERMS OF THESE TERMS OF USE), INCLUDING GOODS AND
            SERVICES PROVIDED BY THIRD PARTIES, INCLUDING ADVERTISERS, THAT ARE
            MADE AVAILABLE OR ADVERTISED OR SOLD THROUGH THIS SERVICE.
            <br />
            The Service is controlled and offered by SUNSETDEALS  from its
            facilities in the United States of America for use by individuals
            located in the United States of America and Canada. SUNSETDEALS 
            makes no representations that the Service is appropriate or
            available for use in other locations. Those who access or use the
            Service from other jurisdictions do so at their own volition and are
            responsible for compliance with local law.
          </p>
          <p>
            <strong>INDEMNIFICATION</strong>
          </p>
          <p>
            To the extent permitted by applicable law, you agree to indemnify,
            defend and hold harmless SUNSETDEALS , its officers, directors,
            employees, agents, licensors, suppliers, partners and affiliates
            from and against all losses, liabilities, expenses, damages, and
            costs, including reasonable attorneys’ fees, resulting from any
            violation of these Terms of Use or any activity related to use of
            the Service (including negligent or wrongful conduct) by you or any
            other person accessing the Service via your Sunsetdeals account,
            email account(s), social media account(s) or ISP address. We reserve
            the right to take over the exclusive defense of any claim. This
            section will survive termination of your access to the Service.
          </p>
          <p>
            <strong>RELEASE</strong>
          </p>
          <p>
            Not applicable in all jurisdictions; void where prohibited: If you
            have a dispute with one or more users of the Service, you release us
            from claims, demands and damages (actual and consequential) of every
            kind and nature, known and unknown, arising out of or in any way
            connected with such disputes. You also waive California Civil Code
            §1542, which says: “A general release does not extend to claims
            which the creditor does not know or suspect to exist in his favor at
            the time of executing the release, which if known by him must have
            materially affected his settlement with the debtor.” You also waive
            any and all benefits and rights that would otherwise accrue to you
            by reason of the provisions of any federal or state/provincial
            statute or principle of common law of any jurisdiction in Canada or
            the United States, or any political entity, nation, or province or
            local law or regulation that may govern this release, which statute,
            regulation, law or principle provides in substance something similar
            to California Civil Code § 1542.
          </p>
          <p>
            <strong>GOVERNING LAW</strong>
          </p>
          <p>
            These Terms of Use and the relationship between you and SUNSETDEALS 
            will be governed by the laws of the United States of America and the
            State of California as an agreement wholly performed therein without
            regard to its conflict of law provisions and the United Nations
            Conventions on Contracts (if applicable).
            <br />
            Any dispute relating in any way to your use of the Service will be
            submitted to confidential arbitration in California, except that, to
            the extent you have in any manner violated or threatened to violate
            our intellectual property rights, we may seek injunctive or other
            appropriate relief in any state or Federal court in the state of
            California, and you consent to exclusive jurisdiction and venue in
            such courts. Arbitration under these Terms of Use will be conducted
            under the rules then prevailing of the American Arbitration
            Association. The arbitrator’s award will be binding and may be
            entered as a judgment in any court of competent jurisdiction. To the
            fullest extent permitted by applicable law, no arbitration under
            these Terms of Use will be joined to an arbitration involving any
            other party subject to these Terms of Use, whether through className
            arbitration proceedings or otherwise.
            <br />
            Any claim or cause of action arising out of or related to use of the
            Service or these Terms of Use must be filed within one year after
            such claim or cause of action arose or be forever barred. Any claim
            by you that may arise in connection with these Terms of Use will be
            compensable by monetary damages and you will in no event be entitled
            to injunctive or other equitable relief.
            <br />
            You also hereby expressly waive any right to resort to any form of
            className action.
          </p>
          <p>
            <strong>MISCELLANEOUS</strong>
          </p>
          <p>
            These Terms of Use constitute the entire agreement between you and
            us and govern your use of the Service. These Terms of Use supersede
            any prior agreements between you and us with respect to the Service.
            You also may be subject to additional terms and conditions that may
            apply when you use other services, affiliate services, or
            third-party services. These Terms of Use are fully assignable by us
            and will be binding upon and inure to the benefit of our successors
            and assigns. No agency, partnership, joint venture,
            employee-employer or franchiser-franchisee relationship is intended
            or created by these Terms of Use. Our failure to exercise or enforce
            any right or provision of these Terms of Use will not constitute a
            waiver of such right or provision. If any provision of these Terms
            of Use is found by a court of competent jurisdiction to be invalid,
            the court should nevertheless endeavor to give effect to the
            parties’ intentions as reflected in the provision, and the other
            provisions of these Terms of Use will remain in full force and
            effect. The headings in these Terms of Use are for convenience only
            and have no legal or contractual effect.
          </p>
          <p>
            <strong>Contact Us</strong>
          </p>
          <p>
            If you have questions about this privacy policy, or about the
            Website, please contact us at
            <a href="mailto:admin@www.Sunsetdeals.com" style={{color: themeDetails?.linkTextColor}}>
              admin@www.Sunsetdeals.com
            </a>
            .&nbsp;For more information about
            <a href="/your-privacy-rights" style={{color: themeDetails?.linkTextColor}}>Your Privacy Rights</a>&nbsp;or our 
            <a href="https://www.Sunsetdeals.com/cookie-policy" style={{color: themeDetails?.linkTextColor}}>
              {" "}Cookie Policy
            </a>
            , please click their respective links.
          </p>
        </div>
      </div>
    </TermsStyled>
  );
};

export default TermsCondition;
