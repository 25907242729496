import { Button, Carousel, Skeleton, Space } from "antd";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import rightArrow from "../../../../../images/arrowRight.svg";
import leftArrow from "../../../../../images/arrow-left.svg";
import { OfferCardSliderStyled } from "./OfferCardSlider.styled";
import { checkIsMobile, v_id } from "../../../../module/utills";
import ProductCard from "../OfferCard";
import { getAllProductsAPI } from "../../../../../redux/slices/app/appService";

const OfferCardSlider = ({ heading, q_param, brand, cardNo = 1 }: any) => {
  const [productList, setProductList] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    getProductData();
  }, []);

  const getProductData = async () => {
    setLoading(true);
    const res: any = await dispatch(
      getAllProductsAPI({
        ...(brand && brand !== 0 ? { mid: brand } : {}),
        q: q_param || "",
        vendorId: v_id,
        pageNo: 1,
        pageSize: 12,
        showOfferedProducts: false,
        sortBy: "",
        minPrice: 30,
        maxPrice: 1000,
      })
    );
    setLoading(false);
    setProductList(res?.payload?.products);
  };

  const carouselRef: any = React.createRef();

  return (
    <OfferCardSliderStyled className="card_slider_styled">
      {productList?.length > 0 && (
        <div
          className={`main_wrapper ${
            checkIsMobile() ? `mobl_card_slid_main_wrapper` : ``
          }`}
        >
          <div className="heading">
            <p>{heading}</p>
            <p
              className="shop_all"
              onClick={() =>
                history.push(
                  `/products?byBrand=${brand ? brand : ""}&search=${
                    q_param ? q_param : ""
                  }`
                )
              }
            >
              Shop All
            </p>
          </div>
          <div className="w-100 carousel_wrap">
            {loading && (
              <Space className="loader_wrapper">
                <Skeleton.Input active={true} size={"large"} />
                <Skeleton.Input active={true} size={"large"} />
                {!checkIsMobile() && (
                  <Skeleton.Input active={true} size={"large"} />
                )}
                {!checkIsMobile() && (
                  <Skeleton.Input active={true} size={"large"} />
                )}
              </Space>
            )}
            {!loading && (
              <div className="card_carousel_wrp">
                {!checkIsMobile() && (
                  <Button
                    className="bg-dark left_arrow_btn"
                    onClick={() => {
                      carouselRef.current.prev();
                    }}
                  >
                    <img src={leftArrow} alt="arrow" />
                  </Button>
                )}
                {!checkIsMobile() && (
                  <Button
                    className="bg-dark right_arrow_btn"
                    onClick={() => {
                      carouselRef.current.next();
                    }}
                  >
                    <img src={rightArrow} alt="arrow" />
                  </Button>
                )}

                {!checkIsMobile() && (
                  <Carousel
                    // arrows={checkIsMobile () ? false : true}
                    infinite={true}
                    slidesPerRow={checkIsMobile() ? 1 : 4}
                    // @ts-ignore
                    arrowOffset={10}
                    arrowSize={30}
                    dotWidth={20}
                    // @ts-ignore
                    ref={carouselRef}
                    dots={false}
                  >
                    {productList?.map((item: any, idx: number) => {
                      const i = item?._source;
                      return (
                        <div className="main_slide" key={`pro_card_${idx}`}>
                          <ProductCard key={`card_${idx}`} product={i} />
                        </div>
                      );
                    })}
                  </Carousel>
                )}
                {checkIsMobile() && (
                  <div className="mobl_slider_wrapper">
                    {productList?.map((item: any, idx: number) => {
                      const i = item?._source;
                      return (
                        <div
                          className={`main_slide multi_card${cardNo}`}
                          key={`pro_card_${idx}`}
                        >
                          <ProductCard key={`card_${idx}`} product={i} />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </OfferCardSliderStyled>
  );
};

export default OfferCardSlider;
