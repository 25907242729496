import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { HeaderStyled } from "./Header.styled";
import "./styles.css";
import { ReactComponent as ProjLogo } from "../../../images/SunsetdealsIcon.svg";
import DownArrow from "../../../images/downArrow.svg";
import percentIcon from "../../../images/percentage.svg";
import Search from "antd/lib/input/Search";
import { Space } from "antd";
import { siteName } from "../../../Utills/Utill";

import { getSelectedBrandsApi } from "../../../redux/slices/Admin/AdminService";
const Header = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector(({ auth }) => auth);
  const urlP = window.location.pathname;

  useEffect(() => {
    getMasterDataCall();
  }, []);
  const getMasterDataCall = () => {
    console.log("siteName siteName", siteName);
    if (siteName) {
      dispatch(getSelectedBrandsApi({ siteName }));
    }
  };
  const gotoCartPage = (url) => {
    history.push(url);
  };

  return (
    <>
      <HeaderStyled>
        <div className="header-wrapper" id="headerWrapper">
          <div className="headerSection">
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <div className="allItemsSection">
                <div className="row-item-secion row-item-secion_top">
                  <a href="/">
                    <ProjLogo
                      onClick={() => {
                        gotoCartPage("/");
                      }}
                    />
                  </a>
                  {urlP !== "/HomePreview" && urlP !== "/offerPreview" && (
                    <div className="site_mnger_text">Site Manager</div>
                  )}
                  {(urlP === "/HomePreview" || urlP === "/offerPreview") && (
                    <div className="pre_view_menu">
                      <p>
                        Categories
                        <img src={DownArrow} alt="arrow" />{" "}
                      </p>
                      <p>
                        Brands
                        <img src={DownArrow} alt="arrow" />{" "}
                      </p>
                      <p>
                        Offers
                        <img src={percentIcon} alt="arrow" />{" "}
                      </p>
                    </div>
                  )}
                </div>

                {(urlP === "/HomePreview" || urlP === "/offerPreview") && (
                  <div className="">
                    <div className="search_sec">
                      <Space.Compact>
                        <Search
                          placeholder="What can we help you find?"
                          allowClear
                        />
                      </Space.Compact>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </HeaderStyled>
    </>
  );
};

export default Header;
