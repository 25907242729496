import MatureImg from "../../images/categories/leaf.png";
import ComputersSoftwareImg from "../../images/categories/Computer.svg";
import PetSupImg from "../../images/categories/Petsupplies.svg";
import clothImg from "../../images/categories/ClothingAccessories.svg";
import sportSImg from "../../images/categories/SportsEquipment.svg";
import toyImg from "../../images/categories/Toys.svg";
import giftImg from "../../images/categories/FlowesFoodsGift.svg";
import applianceImg from "../../images/categories/Applinces.svg";
import otherImg from "../../images/categories/Other.svg";
import booksImg from "../../images/categories/Books.svg";
import autoMotiveImg from "../../images/categories/autoMotive.svg";
import healthIcon from "../../images/categories/health.svg";
import dvdIcon from "../../images/categories/dvd.svg";
import musicIcon from "../../images/categories/Music.svg";
import jewelryIcon from "../../images/categories/jewelry.svg";
import officeSp from "../../images/categories/officeSp.svg";
import electronicIcon from "../../images/categories/Electronics.svg";
import homeGarden from "../../images/categories/HomeGarden.svg";
import videoGameIcon from "../../images/categories/VideoGames.svg";
import babiesKidIcon from "../../images/categories/babiesKids.svg";
import musicInsIcon from "../../images/categories/musicalIns.svg";

export const Categoriesimages = {
  23000000: MatureImg,
  4: ComputersSoftwareImg,
  31000000: PetSupImg,
  10000000: clothImg,
  12000000: sportSImg,
  14000000: toyImg,
  16000000: giftImg,
  18000000: applianceImg,
  20000000: otherImg,
  80: booksImg,
  22000000: autoMotiveImg,
  50: healthIcon,
  51: dvdIcon,
  52: musicIcon,
  30000000: jewelryIcon,
  24: officeSp,
  11000000: electronicIcon,
  13000000: homeGarden,
  15000000: videoGameIcon,
  17000000: babiesKidIcon,
  21000000: musicInsIcon,
};

export const categoriesList = [
  {
    name: "Mature",
    probability: 0,
    url: "https://www.bizrate.com/mature/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
    children: {
      category: [
        {
          name: "Erotic Art, Books, and Videos",
          probability: 0,
          url: "https://www.bizrate.com/eroticartbooksandvideos/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Erotic Art, Books, and Videos",
                probability: 0,
                id: 10690,
              },
            ],
          },
          id: 23000400,
          mature: true,
        },
        {
          name: "Miscellaneous Adult",
          probability: 0,
          url: "https://www.bizrate.com/miscellaneousadult/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Miscellaneous Adult",
                probability: 0,
                id: 10694,
              },
            ],
          },
          id: 23000800,
          mature: true,
        },
        {
          name: "Condoms & Dental Dams",
          probability: 0,
          url: "https://www.bizrate.com/condoms_dentaldams/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Condoms & Dental Dams",
                probability: 0,
                id: 10693,
              },
            ],
          },
          id: 23000700,
          mature: true,
        },
        {
          name: "Erotic Lingerie",
          probability: 0,
          url: "https://www.bizrate.com/eroticlingerie/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Erotic Lingerie",
                probability: 0,
                id: 10689,
              },
            ],
          },
          id: 23000300,
          mature: true,
        },
        {
          name: "Adult Novelty",
          probability: 0,
          url: "https://www.bizrate.com/adultnovelty/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Adult Novelty",
                probability: 0,
                id: 10696,
              },
            ],
          },
          id: 23001000,
          mature: true,
        },
        {
          name: "Lubricants",
          probability: 0,
          url: "https://www.bizrate.com/lubricants/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Lubricants & Sensory Stimulation",
                probability: 0,
                id: 10692,
              },
            ],
          },
          id: 23000600,
          mature: true,
        },
        {
          name: "Sex Toys",
          probability: 0,
          url: "https://www.bizrate.com/sextoys/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Sex Toys",
                probability: 0,
                id: 10688,
              },
            ],
          },
          id: 23000200,
          mature: true,
        },
        {
          name: "Mature DVDs & Videos",
          probability: 0,
          url: "https://www.bizrate.com/maturedvds_videos/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Mature DVDs & Videos",
                probability: 0,
                id: 10084,
              },
            ],
          },
          id: 5117,
          mature: true,
        },
        {
          name: "Adult Games",
          probability: 0,
          url: "https://www.bizrate.com/adultgames/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Adult Games",
                probability: 0,
                id: 10687,
              },
            ],
          },
          id: 23000100,
          mature: true,
        },
        {
          name: "Herbal Sex Enhancers",
          probability: 0,
          url: "https://www.bizrate.com/herbalsexenhancers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Herbal Sex Enhancers",
                probability: 0,
                id: 10691,
              },
            ],
          },
          id: 23000500,
          mature: true,
        },
      ],
    },
    id: 23000000,
    mature: true,
  },
  {
    name: "Computers & Software",
    probability: 0,
    url: "https://www.bizrate.com/computers_software/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
    children: {
      category: [
        {
          name: "Headsets & Microphones",
          probability: 0,
          url: "https://www.bizrate.com/headsets_microphones/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Headsets & Microphones",
                probability: 0,
                id: 9402,
              },
            ],
          },
          id: 482,
          mature: false,
        },
        {
          name: "Computer Speakers",
          probability: 0,
          url: "https://www.bizrate.com/computerspeakers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Computer Speakers",
                probability: 0,
                id: 10048,
              },
            ],
          },
          id: 483,
          mature: false,
        },
        {
          name: "System Cooling",
          probability: 0,
          url: "https://www.bizrate.com/systemcooling/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "System Cooling",
                probability: 0,
                id: 10049,
              },
            ],
          },
          id: 491,
          mature: false,
        },
        {
          name: "Computer Game Controllers",
          probability: 0,
          url: "https://www.bizrate.com/game-controllers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Computer Game Controllers",
                probability: 0,
                id: 10050,
              },
            ],
          },
          id: 492,
          mature: false,
        },
        {
          name: "Computer Keyboards",
          probability: 0,
          url: "https://www.bizrate.com/computer-keyboards/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Computer Keyboards",
                probability: 0,
                id: 10051,
              },
            ],
          },
          id: 493,
          mature: false,
        },
        {
          name: "Network Hub & Switches",
          probability: 0,
          url: "https://www.bizrate.com/networkhub_switches/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Network Switches",
                probability: 0,
                id: 9334,
              },
              {
                name: "Network Hubs",
                probability: 0,
                id: 9335,
              },
              {
                name: "USB & Firewire Hubs",
                probability: 0,
                id: 9338,
              },
            ],
          },
          id: 494,
          mature: false,
        },
        {
          name: "Networking Products",
          probability: 0,
          url: "https://www.bizrate.com/networkingproducts/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Media Converters",
                probability: 0,
                id: 9329,
              },
              {
                name: "Print Servers",
                probability: 0,
                id: 9330,
              },
              {
                name: "Network Transceivers",
                probability: 0,
                id: 9331,
              },
              {
                name: "Server Appliances",
                probability: 0,
                id: 9332,
              },
              {
                name: "Other Networking Products",
                probability: 0,
                id: 9339,
              },
            ],
          },
          id: 495,
          mature: false,
        },
        {
          name: "Cable, DSL & Wireless Modems",
          probability: 0,
          url: "https://www.bizrate.com/cabledsl_wirelessmodems/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Cable & DSL Modems",
                probability: 0,
                id: 9333,
              },
            ],
          },
          id: 496,
          mature: false,
        },
        {
          name: "NIC",
          probability: 0,
          url: "https://www.bizrate.com/nic/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "NIC",
                probability: 0,
                id: 10055,
              },
            ],
          },
          id: 497,
          mature: false,
        },
        {
          name: "MP3 & Media Players",
          probability: 0,
          url: "https://www.bizrate.com/mp3-media-players/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "MP3 & Media Players",
                probability: 0,
                id: 10056,
              },
            ],
          },
          id: 499,
          mature: false,
        },
        {
          name: "Digital Cameras",
          probability: 0,
          url: "https://www.bizrate.com/digitalcameras/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Digital Cameras",
                probability: 0,
                id: 10020,
              },
            ],
          },
          id: 402,
          mature: false,
        },
        {
          name: "CPUs & Computer Processor Upgrades",
          probability: 0,
          url: "https://www.bizrate.com/cpus_computerprocessorupgrades/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "CPUs & Computer Processor Upgrades",
                probability: 0,
                id: 10021,
              },
            ],
          },
          id: 403,
          mature: false,
        },
        {
          name: "WebCams",
          probability: 0,
          url: "https://www.bizrate.com/webcams/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "WebCams",
                probability: 0,
                id: 10022,
              },
            ],
          },
          id: 404,
          mature: false,
        },
        {
          name: "Graphics Cards",
          probability: 0,
          url: "https://www.bizrate.com/graphicscards/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Graphics Cards",
                probability: 0,
                id: 10023,
              },
            ],
          },
          id: 405,
          mature: false,
        },
        {
          name: "CD & DVD Drives",
          probability: 0,
          url: "https://www.bizrate.com/cd-dvddrives/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "CD & DVD Drives",
                probability: 0,
                id: 10024,
              },
            ],
          },
          id: 406,
          mature: false,
        },
        {
          name: "RAM & Memory Upgrades",
          probability: 0,
          url: "https://www.bizrate.com/ram_memoryupgrades/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "RAM & Memory Upgrades",
                probability: 0,
                id: 10025,
              },
            ],
          },
          id: 407,
          mature: false,
        },
        {
          name: "Hard Drives",
          probability: 0,
          url: "https://www.bizrate.com/harddrives/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Hard Drives",
                probability: 0,
                id: 10026,
              },
            ],
          },
          id: 410,
          mature: false,
        },
        {
          name: "Computer Monitors",
          probability: 0,
          url: "https://www.bizrate.com/computer-monitors/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Computer Monitors",
                probability: 0,
                id: 10027,
              },
            ],
          },
          id: 416,
          mature: false,
        },
        {
          name: "Motherboards",
          probability: 0,
          url: "https://www.bizrate.com/motherboards/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Motherboards",
                probability: 0,
                id: 10028,
              },
            ],
          },
          id: 419,
          mature: false,
        },
        {
          name: "Printers",
          probability: 0,
          url: "https://www.bizrate.com/printers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Printers",
                probability: 0,
                id: 10029,
              },
            ],
          },
          id: 420,
          mature: false,
        },
        {
          name: "Scanners",
          probability: 0,
          url: "https://www.bizrate.com/scanners/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Scanners",
                probability: 0,
                id: 10030,
              },
            ],
          },
          id: 422,
          mature: false,
        },
        {
          name: "Video Editing / Capture Cards",
          probability: 0,
          url: "https://www.bizrate.com/videoeditingcapturecards/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Video Editing/Capture Cards",
                probability: 0,
                id: 10031,
              },
            ],
          },
          id: 423,
          mature: false,
        },
        {
          name: "Media Hubs",
          probability: 0,
          url: "https://www.bizrate.com/mediahubs/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Media Hubs",
                probability: 0,
                id: 10032,
              },
            ],
          },
          id: 424,
          mature: false,
        },
        {
          name: "Tablet Computers",
          probability: 0,
          url: "https://www.bizrate.com/tablet-computers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Tablet Computers",
                probability: 0,
                id: 9262,
              },
            ],
          },
          id: 9257,
          mature: false,
        },
        {
          name: "Multimedia Software",
          probability: 0,
          url: "https://www.bizrate.com/multimediasoftware/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Multimedia Software",
                probability: 0,
                id: 10010,
              },
            ],
          },
          id: 301,
          mature: false,
        },
        {
          name: "Educational Software",
          probability: 0,
          url: "https://www.bizrate.com/educational-software/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Educational Software",
                probability: 0,
                id: 10011,
              },
            ],
          },
          id: 302,
          mature: false,
        },
        {
          name: "Computer Gaming Software",
          probability: 0,
          url: "https://www.bizrate.com/computergamingsoftware/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Computer Gaming Software",
                probability: 0,
                id: 10012,
              },
            ],
          },
          id: 303,
          mature: false,
        },
        {
          name: "Hobbies & Home Software",
          probability: 0,
          url: "https://www.bizrate.com/hobbies-home-software/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Hobbies & Home Software",
                probability: 0,
                id: 10013,
              },
            ],
          },
          id: 304,
          mature: false,
        },
        {
          name: "Miscellaneous Storage Peripherals",
          probability: 0,
          url: "https://www.bizrate.com/miscellaneousstorageperipherals/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Miscellaneous Storage Peripherals",
                probability: 0,
                id: 10033,
              },
            ],
          },
          id: 433,
          mature: false,
        },
        {
          name: "Internet & Network Software",
          probability: 0,
          url: "https://www.bizrate.com/internet_networksoftware/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Internet & Network Software",
                probability: 0,
                id: 10014,
              },
            ],
          },
          id: 305,
          mature: false,
        },
        {
          name: "Computer Cables & Adapters",
          probability: 0,
          url: "https://www.bizrate.com/computercables_adapters/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Computer Cables & Adapters",
                probability: 0,
                id: 10231,
              },
            ],
          },
          id: 46002,
          mature: false,
        },
        {
          name: "PDA & Handheld Computers",
          probability: 0,
          url: "https://www.bizrate.com/pda_handheldcomputers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "PDA & Handheld Computers",
                probability: 0,
                id: 10034,
              },
            ],
          },
          id: 435,
          mature: false,
        },
        {
          name: "Operating Systems",
          probability: 0,
          url: "https://www.bizrate.com/operatingsystems/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Operating Systems",
                probability: 0,
                id: 10015,
              },
            ],
          },
          id: 307,
          mature: false,
        },
        {
          name: "Office, Tax & Accounting Software",
          probability: 0,
          url: "https://www.bizrate.com/officetax_accountingsoftware/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Office, Tax & Accounting Software",
                probability: 0,
                id: 10016,
              },
            ],
          },
          id: 308,
          mature: false,
        },
        {
          name: "Programming Software",
          probability: 0,
          url: "https://www.bizrate.com/programmingsoftware/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Programming Software",
                probability: 0,
                id: 10017,
              },
            ],
          },
          id: 309,
          mature: false,
        },
        {
          name: "PDA Accessories",
          probability: 0,
          url: "https://www.bizrate.com/pdaaccessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "PDA Sync Cables",
                probability: 0,
                id: 9123,
              },
              {
                name: "PDA Accessories",
                probability: 0,
                id: 10234,
              },
            ],
          },
          id: 46006,
          mature: false,
        },
        {
          name: "Blank Computer Media",
          probability: 0,
          url: "https://www.bizrate.com/blank-computer-media/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Blank Computer Media",
                probability: 0,
                id: 10035,
              },
            ],
          },
          id: 438,
          mature: false,
        },
        {
          name: "UPS & Power Adapters",
          probability: 0,
          url: "https://www.bizrate.com/ups-power-adapters/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Surge Protectors",
                probability: 0,
                id: 9169,
              },
              {
                name: "UPSs",
                probability: 0,
                id: 9314,
              },
            ],
          },
          id: 46007,
          mature: false,
        },
        {
          name: "Utility Software & Tools",
          probability: 0,
          url: "https://www.bizrate.com/utilitysoftware_tools/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Utility Software & Tools",
                probability: 0,
                id: 10018,
              },
            ],
          },
          id: 312,
          mature: false,
        },
        {
          name: "Memory Card Readers",
          probability: 0,
          url: "https://www.bizrate.com/memorycardreaders/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Memory Card Readers",
                probability: 0,
                id: 10236,
              },
            ],
          },
          id: 46009,
          mature: false,
        },
        {
          name: "Power Supplies",
          probability: 0,
          url: "https://www.bizrate.com/powersupplies/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Computer Power Supplies",
                probability: 0,
                id: 9046,
              },
            ],
          },
          id: 46010,
          mature: false,
        },
        {
          name: "Digital Camera Accessories",
          probability: 0,
          url: "https://www.bizrate.com/digitalcameraaccessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          children: {
            category: [
              {
                name: "Camera Lenses",
                probability: 0,
                url: "https://www.bizrate.com/digital-camera-lenses/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Camera Lenses",
                      probability: 0,
                      id: 9029,
                    },
                  ],
                },
                id: 9027,
                mature: false,
              },
              {
                name: "Other Camera Accessories",
                probability: 0,
                url: "https://www.bizrate.com/other-camera-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Other Camera Accessories",
                      probability: 0,
                      id: 9269,
                    },
                  ],
                },
                id: 9043,
                mature: false,
              },
              {
                name: "Tripods & Monopods",
                probability: 0,
                url: "https://www.bizrate.com/tripods-monopods/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Tripods & Monopods",
                      probability: 0,
                      id: 9168,
                    },
                  ],
                },
                id: 9028,
                mature: false,
              },
              {
                name: "Camera Lens Filters",
                probability: 0,
                url: "https://www.bizrate.com/camera-lens-filters/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Camera Lens Filters",
                      probability: 0,
                      id: 9031,
                    },
                  ],
                },
                id: 9030,
                mature: false,
              },
              {
                name: "Camera Battery Grips",
                probability: 0,
                url: "https://www.bizrate.com/camera-battery-grips/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Camera Battery Grips",
                      probability: 0,
                      id: 100001507,
                    },
                  ],
                },
                id: 100001508,
                mature: false,
              },
              {
                name: "Digital Camera Chargers",
                probability: 0,
                url: "https://www.bizrate.com/digital-camera-chargers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Digital Camera Chargers",
                      probability: 0,
                      id: 9070,
                    },
                  ],
                },
                id: 9032,
                mature: false,
              },
              {
                name: "Camera Lens Caps",
                probability: 0,
                url: "https://www.bizrate.com/camera-lens-caps/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Camera Lens Caps",
                      probability: 0,
                      id: 9071,
                    },
                  ],
                },
                id: 9033,
                mature: false,
              },
              {
                name: "Camera Flashes",
                probability: 0,
                url: "https://www.bizrate.com/camera-flashes/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Camera Flashes",
                      probability: 0,
                      id: 9072,
                    },
                  ],
                },
                id: 9034,
                mature: false,
              },
              {
                name: "Camera Underwater Housings",
                probability: 0,
                url: "https://www.bizrate.com/camera-underwater-housings/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Camera Underwater Housing",
                      probability: 0,
                      id: 9073,
                    },
                  ],
                },
                id: 9035,
                mature: false,
              },
              {
                name: "Camera Accessory Kits",
                probability: 0,
                url: "https://www.bizrate.com/camera-accessory-kits/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Camera Accessory Kits",
                      probability: 0,
                      id: 100000494,
                    },
                  ],
                },
                id: 100000495,
                mature: false,
              },
              {
                name: "Camera Cases",
                probability: 0,
                url: "https://www.bizrate.com/camera-cases/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Camera Cases",
                      probability: 0,
                      id: 9074,
                    },
                  ],
                },
                id: 9036,
                mature: false,
              },
              {
                name: "Digital Camera Cables",
                probability: 0,
                url: "https://www.bizrate.com/digital-camera-cables/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Digital Camera Cables",
                      probability: 0,
                      id: 9076,
                    },
                  ],
                },
                id: 9038,
                mature: false,
              },
            ],
          },
          id: 46011,
          mature: false,
        },
        {
          name: "Ink, Toner & Inkjet Cartridges",
          probability: 0,
          url: "https://www.bizrate.com/inktoner_inkjetcartridges/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Ink, Toner & Inkjet Cartridges",
                probability: 0,
                id: 10036,
              },
            ],
          },
          id: 444,
          mature: false,
        },
        {
          name: "Printer Accessories",
          probability: 0,
          url: "https://www.bizrate.com/printeraccessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Printer Accessories",
                probability: 0,
                id: 10239,
              },
            ],
          },
          id: 46012,
          mature: false,
        },
        {
          name: "Projector Accessories",
          probability: 0,
          url: "https://www.bizrate.com/projectoraccessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Projector Accessories",
                probability: 0,
                id: 10240,
              },
            ],
          },
          id: 46013,
          mature: false,
        },
        {
          name: "Scanner Accessories",
          probability: 0,
          url: "https://www.bizrate.com/scanneraccessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Scanner Accessories",
                probability: 0,
                id: 10241,
              },
            ],
          },
          id: 46014,
          mature: false,
        },
        {
          name: "MP3 Player Accessories",
          probability: 0,
          url: "https://www.bizrate.com/mp3playeraccessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "MP3 Player Accessories",
                probability: 0,
                id: 10242,
              },
            ],
          },
          id: 46015,
          mature: false,
        },
        {
          name: "Laptop & Tablet Computer Accessories",
          probability: 0,
          url: "https://www.bizrate.com/laptop-tablet-computer-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          children: {
            category: [
              {
                name: "Laptop Power Adapters",
                probability: 0,
                url: "https://www.bizrate.com/laptop-power-adapters/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Laptop Power Adapters",
                      probability: 0,
                      id: 9181,
                    },
                  ],
                },
                id: 9177,
                mature: false,
              },
              {
                name: "Other Laptop Accessories",
                probability: 0,
                url: "https://www.bizrate.com/other-laptop-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Other Laptop Accessories",
                      probability: 0,
                      id: 9263,
                    },
                  ],
                },
                id: 9258,
                mature: false,
              },
              {
                name: "Laptop Stands",
                probability: 0,
                url: "https://www.bizrate.com/laptop-stands/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Laptop Stands",
                      probability: 0,
                      id: 9264,
                    },
                  ],
                },
                id: 9259,
                mature: false,
              },
              {
                name: "Laptop Coolers",
                probability: 0,
                url: "https://www.bizrate.com/laptop-coolers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Laptop Coolers",
                      probability: 0,
                      id: 9265,
                    },
                  ],
                },
                id: 9260,
                mature: false,
              },
              {
                name: "Port Replicators",
                probability: 0,
                url: "https://www.bizrate.com/port-replicators/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Port Replicators",
                      probability: 0,
                      id: 9266,
                    },
                  ],
                },
                id: 9261,
                mature: false,
              },
            ],
          },
          id: 46016,
          mature: false,
        },
        {
          name: "Miscellaneous Software",
          probability: 0,
          url: "https://www.bizrate.com/miscellaneoussoftware/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Miscellaneous Software",
                probability: 0,
                id: 10019,
              },
              {
                name: "Extended Warranty Agreements",
                probability: 0,
                id: 100001561,
              },
            ],
          },
          id: 320,
          mature: false,
        },
        {
          name: "Desktop Computer Accessories",
          probability: 0,
          url: "https://www.bizrate.com/desktopcomputeraccessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Keyboard Trays",
                probability: 0,
                id: 9400,
              },
              {
                name: "Desktop Computer Cleaning Supplies",
                probability: 0,
                id: 9308,
              },
              {
                name: "Other Desktop Computer Accessories",
                probability: 0,
                id: 9309,
              },
              {
                name: "Mouse Pads",
                probability: 0,
                id: 9310,
              },
              {
                name: "Wrist Rests",
                probability: 0,
                id: 9311,
              },
            ],
          },
          id: 46017,
          mature: false,
        },
        {
          name: "Monitor Accessories",
          probability: 0,
          url: "https://www.bizrate.com/monitoraccessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Monitor Accessories",
                probability: 0,
                id: 9079,
              },
            ],
          },
          id: 46018,
          mature: false,
        },
        {
          name: "PDA Software",
          probability: 0,
          url: "https://www.bizrate.com/pda-software/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "PDA Software",
                probability: 0,
                id: 10246,
              },
            ],
          },
          id: 46020,
          mature: false,
        },
        {
          name: "Desktop Computers",
          probability: 0,
          url: "https://www.bizrate.com/desktopcomputers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Desktop Computers",
                probability: 0,
                id: 10038,
              },
            ],
          },
          id: 461,
          mature: false,
        },
        {
          name: "Laptop Computers",
          probability: 0,
          url: "https://www.bizrate.com/laptopcomputers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Laptop Computers",
                probability: 0,
                id: 10039,
              },
            ],
          },
          id: 462,
          mature: false,
        },
        {
          name: "Sound Cards",
          probability: 0,
          url: "https://www.bizrate.com/soundcards/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Sound Cards",
                probability: 0,
                id: 10040,
              },
            ],
          },
          id: 463,
          mature: false,
        },
        {
          name: "Tape / Cartridge Drives",
          probability: 0,
          url: "https://www.bizrate.com/tapecartridgedrives/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Tape Drives",
                probability: 0,
                id: 9186,
              },
              {
                name: "Disk Drives",
                probability: 0,
                id: 9187,
              },
            ],
          },
          id: 464,
          mature: false,
        },
        {
          name: "Memory Cards",
          probability: 0,
          url: "https://www.bizrate.com/memorycards/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Memory Cards",
                probability: 0,
                id: 10042,
              },
            ],
          },
          id: 465,
          mature: false,
        },
        {
          name: "Tower & Drive Cases",
          probability: 0,
          url: "https://www.bizrate.com/tower_drivecases/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Computer Towers",
                probability: 0,
                id: 9420,
              },
              {
                name: "Hard Drive Cases",
                probability: 0,
                id: 9421,
              },
              {
                name: "Server Racks",
                probability: 0,
                id: 9422,
              },
            ],
          },
          id: 466,
          mature: false,
        },
        {
          name: "Bridges & Routers",
          probability: 0,
          url: "https://www.bizrate.com/bridges_routers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Network Routers & Bridges",
                probability: 0,
                id: 9336,
              },
            ],
          },
          id: 467,
          mature: false,
        },
        {
          name: "Pointing Devices",
          probability: 0,
          url: "https://www.bizrate.com/pointingdevices/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Pointing Devices",
                probability: 0,
                id: 10045,
              },
            ],
          },
          id: 468,
          mature: false,
        },
        {
          name: "IO Controllers",
          probability: 0,
          url: "https://www.bizrate.com/iocontrollers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "IO Controllers",
                probability: 0,
                id: 10046,
              },
            ],
          },
          id: 480,
          mature: false,
        },
      ],
    },
    id: 4,
    mature: false,
  },
  {
    name: "Pet Supplies",
    probability: 0,
    url: "https://www.bizrate.com/petsupplies/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
    children: {
      category: [
        {
          name: "Dog Supplies",
          probability: 0,
          url: "https://www.bizrate.com/dogsupplies/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Dog Supplies",
                probability: 0,
                id: 10727,
              },
            ],
          },
          id: 31000300,
          mature: false,
        },
        {
          name: "Fish Supplies",
          probability: 0,
          url: "https://www.bizrate.com/fishsupplies/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Fish Supplies",
                probability: 0,
                id: 10728,
              },
            ],
          },
          id: 31000400,
          mature: false,
        },
        {
          name: "Small Pet Supplies",
          probability: 0,
          url: "https://www.bizrate.com/small-pet-supplies/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Small Pet Supplies",
                probability: 0,
                id: 9801,
              },
            ],
          },
          id: 9799,
          mature: false,
        },
        {
          name: "Bird Supplies",
          probability: 0,
          url: "https://www.bizrate.com/birdsupplies/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Bird Supplies",
                probability: 0,
                id: 10725,
              },
            ],
          },
          id: 31000100,
          mature: false,
        },
        {
          name: "Reptile Supplies",
          probability: 0,
          url: "https://www.bizrate.com/reptilesupplies/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Reptile Supplies",
                probability: 0,
                id: 10729,
              },
            ],
          },
          id: 31000500,
          mature: false,
        },
        {
          name: "Cat Supplies",
          probability: 0,
          url: "https://www.bizrate.com/catsupplies/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Cat Supplies",
                probability: 0,
                id: 10726,
              },
            ],
          },
          id: 31000200,
          mature: false,
        },
        {
          name: "More Pet Supplies",
          probability: 0,
          url: "https://www.bizrate.com/morepetsupplies/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "More Pet Supplies",
                probability: 0,
                id: 10730,
              },
            ],
          },
          id: 31000600,
          mature: false,
        },
      ],
    },
    id: 31000000,
    mature: false,
  },
  {
    name: "Clothing & Accessories",
    probability: 0,
    url: "https://www.bizrate.com/clothing_accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
    children: {
      category: [
        {
          name: "Specialty Apparel",
          probability: 0,
          url: "https://www.bizrate.com/specialtyapparel/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          children: {
            category: [
              {
                name: "Motorcycle Apparel",
                probability: 0,
                url: "https://www.bizrate.com/motorcycle-apparel/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Motorcycle Apparel",
                      probability: 0,
                      id: 9979,
                    },
                  ],
                },
                id: 9975,
                mature: false,
              },
              {
                name: "Specialty Apparel / Accessories",
                probability: 0,
                url: "https://www.bizrate.com/specialtyapparelaccessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Specialty Apparel/Accessories",
                      probability: 0,
                      id: 10351,
                    },
                  ],
                },
                id: 10160500,
                mature: false,
              },
            ],
          },
          id: 10160000,
          mature: false,
        },
        {
          name: "Underwear",
          probability: 0,
          url: "https://www.bizrate.com/mensunderwear/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Men's Pajamas & Loungewear ",
                probability: 0,
                id: 100001184,
              },
              {
                name: "Men's Robes",
                probability: 0,
                id: 100001183,
              },
              {
                name: "Men's Underwear",
                probability: 0,
                id: 10352,
              },
            ],
          },
          id: 10180000,
          mature: false,
        },
        {
          name: "Lingerie",
          probability: 0,
          url: "https://www.bizrate.com/womenslingerie/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          children: {
            category: [
              {
                name: "Bras",
                probability: 0,
                url: "https://www.bizrate.com/womensbras/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Women's Bras",
                      probability: 0,
                      id: 10322,
                    },
                    {
                      name: "Lingerie Sets",
                      probability: 0,
                      id: 100000045,
                    },
                  ],
                },
                id: 10100300,
                mature: false,
              },
              {
                name: "Pajamas",
                probability: 0,
                url: "https://www.bizrate.com/womenssleepwearpajamas/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Women's Chemises",
                      probability: 0,
                      id: 100001190,
                    },
                    {
                      name: "Women's Nightgowns ",
                      probability: 0,
                      id: 100001192,
                    },
                    {
                      name: "Women's Pajamas",
                      probability: 0,
                      id: 10327,
                    },
                  ],
                },
                id: 10101100,
                mature: false,
              },
              {
                name: "Shapewear",
                probability: 0,
                url: "https://www.bizrate.com/womensslimmersshapers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Women's Slimmers/Shapers",
                      probability: 0,
                      id: 10328,
                    },
                  ],
                },
                id: 10101200,
                mature: false,
              },
              {
                name: "Camisoles",
                probability: 0,
                url: "https://www.bizrate.com/womenscamisolestanks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Women's Camisoles & Sets",
                      probability: 0,
                      id: 10323,
                    },
                  ],
                },
                id: 10100500,
                mature: false,
              },
              {
                name: "Panties",
                probability: 0,
                url: "https://www.bizrate.com/womenspanties/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Women's Panties",
                      probability: 0,
                      id: 10325,
                    },
                  ],
                },
                id: 10100900,
                mature: false,
              },
              {
                name: "Robes",
                probability: 0,
                url: "https://www.bizrate.com/womensrobes/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Women's Robes",
                      probability: 0,
                      id: 10326,
                    },
                  ],
                },
                id: 10101000,
                mature: false,
              },
            ],
          },
          id: 10100000,
          mature: false,
        },
        {
          name: "Accessories",
          probability: 0,
          url: "https://www.bizrate.com/mensaccessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          children: {
            category: [
              {
                name: "Socks",
                probability: 0,
                url: "https://www.bizrate.com/menssocks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Men's Socks",
                      probability: 0,
                      id: 10334,
                    },
                  ],
                },
                id: 10120700,
                mature: false,
              },
              {
                name: "Gloves & Mittens",
                probability: 0,
                url: "https://www.bizrate.com/mensgloves_mittens/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Men's Gloves & Mittens",
                      probability: 0,
                      id: 10332,
                    },
                  ],
                },
                id: 10120400,
                mature: false,
              },
              {
                name: "Ties",
                probability: 0,
                url: "https://www.bizrate.com/ties/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Men's Ties",
                      probability: 0,
                      id: 10335,
                    },
                  ],
                },
                id: 10120800,
                mature: false,
              },
              {
                name: "Belts & Suspenders",
                probability: 0,
                url: "https://www.bizrate.com/mensbeltssuspenders/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Men's Belts/Suspenders",
                      probability: 0,
                      id: 10331,
                    },
                  ],
                },
                id: 10120100,
                mature: false,
              },
              {
                name: "Hats",
                probability: 0,
                url: "https://www.bizrate.com/menshats/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Men's Hats",
                      probability: 0,
                      id: 10333,
                    },
                  ],
                },
                id: 10120500,
                mature: false,
              },
              {
                name: "Handkerchiefs",
                probability: 0,
                url: "https://www.bizrate.com/mens-handkerchiefs/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Men's Handkerchiefs",
                      probability: 0,
                      id: 9774,
                    },
                  ],
                },
                id: 9772,
                mature: false,
              },
              {
                name: "Scarves",
                probability: 0,
                url: "https://www.bizrate.com/mens-scarves/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Men's Scarves",
                      probability: 0,
                      id: 9775,
                    },
                  ],
                },
                id: 9773,
                mature: false,
              },
              {
                name: "Miscellaneous Men's Accessories",
                probability: 0,
                url: "https://www.bizrate.com/miscellaneousmensaccessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Miscellaneous Men's Accessories",
                      probability: 0,
                      id: 10336,
                    },
                  ],
                },
                id: 10121000,
                mature: false,
              },
            ],
          },
          id: 10120000,
          mature: false,
        },
        {
          name: "Men's Clothing",
          probability: 0,
          url: "https://www.bizrate.com/mensclothing/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          children: {
            category: [
              {
                name: "Suits & Sportcoats",
                probability: 0,
                url: "https://www.bizrate.com/menssuitssportcoats/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Men's Suits ",
                      probability: 0,
                      id: 100001173,
                    },
                    {
                      name: "Men's Sportcoats & Blazers ",
                      probability: 0,
                      id: 100001172,
                    },
                  ],
                },
                id: 10060700,
                mature: false,
              },
              {
                name: "Men's Jackets & Coats",
                probability: 0,
                url: "https://www.bizrate.com/mensjackets_coats/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Men's Jackets & Coats",
                      probability: 0,
                      id: 10297,
                    },
                  ],
                },
                id: 10060300,
                mature: false,
              },
              {
                name: "Pants",
                probability: 0,
                url: "https://www.bizrate.com/menspants/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Men's Pants",
                      probability: 0,
                      id: 10298,
                    },
                  ],
                },
                id: 10060400,
                mature: false,
              },
              {
                name: "Sweaters & Vests",
                probability: 0,
                url: "https://www.bizrate.com/menssweatersvests/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Men's Sweaters/Vests",
                      probability: 0,
                      id: 10302,
                    },
                  ],
                },
                id: 10060800,
                mature: false,
              },
              {
                name: "Shirts",
                probability: 0,
                url: "https://www.bizrate.com/mensshirts/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Men's Shirts",
                      probability: 0,
                      id: 10299,
                    },
                  ],
                },
                id: 10060500,
                mature: false,
              },
              {
                name: "T-Shirts",
                probability: 0,
                url: "https://www.bizrate.com/menstshirts/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Men's T-Shirts",
                      probability: 0,
                      id: 10303,
                    },
                  ],
                },
                id: 10060900,
                mature: false,
              },
              {
                name: "Swimwear",
                probability: 0,
                url: "https://www.bizrate.com/mensswimwear/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Men's Swimwear",
                      probability: 0,
                      id: 10304,
                    },
                  ],
                },
                id: 10061000,
                mature: false,
              },
              {
                name: "Shorts",
                probability: 0,
                url: "https://www.bizrate.com/mensshorts/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Men's Shorts",
                      probability: 0,
                      id: 10300,
                    },
                  ],
                },
                id: 10060600,
                mature: false,
              },
              {
                name: "Denim",
                probability: 0,
                url: "https://www.bizrate.com/mensjeans/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Men's Jeans",
                      probability: 0,
                      id: 10296,
                    },
                  ],
                },
                id: 10060200,
                mature: false,
              },
            ],
          },
          id: 10060000,
          mature: false,
        },
        {
          name: "Activewear",
          probability: 0,
          url: "https://www.bizrate.com/womensworkout/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Women's Activewear",
                probability: 0,
                id: 10307,
              },
            ],
          },
          id: 10080000,
          mature: false,
        },
        {
          name: "Shoes",
          probability: 0,
          url: "https://www.bizrate.com/womensshoes/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Women's Shoes",
                probability: 0,
                id: 10330,
              },
            ],
          },
          id: 10110000,
          mature: false,
        },
        {
          name: "Accessories",
          probability: 0,
          url: "https://www.bizrate.com/womensaccessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          children: {
            category: [
              {
                name: "Hats",
                probability: 0,
                url: "https://www.bizrate.com/womenshats/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Women's Hats",
                      probability: 0,
                      id: 10338,
                    },
                  ],
                },
                id: 10130300,
                mature: false,
              },
              {
                name: "Socks & Tights",
                probability: 0,
                url: "https://www.bizrate.com/womenssockshosiery/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Women's Socks/Hosiery",
                      probability: 0,
                      id: 10339,
                    },
                  ],
                },
                id: 10130400,
                mature: false,
              },
              {
                name: "Belts",
                probability: 0,
                url: "https://www.bizrate.com/womensbelts/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Women's Belts",
                      probability: 0,
                      id: 10341,
                    },
                  ],
                },
                id: 10130800,
                mature: false,
              },
              {
                name: "Scarves & Shawls",
                probability: 0,
                url: "https://www.bizrate.com/womensscarvesshawls/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Women's Scarves/Shawls",
                      probability: 0,
                      id: 10340,
                    },
                  ],
                },
                id: 10130500,
                mature: false,
              },
              {
                name: "Miscellaneous Women's Accessories",
                probability: 0,
                url: "https://www.bizrate.com/miscellaneouswomensaccessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Miscellaneous Women's Accessories",
                      probability: 0,
                      id: 10342,
                    },
                    {
                      name: "Women's Handkerchiefs",
                      probability: 0,
                      id: 9542,
                    },
                  ],
                },
                id: 10130900,
                mature: false,
              },
              {
                name: "Gloves & Mittens",
                probability: 0,
                url: "https://www.bizrate.com/womensgloves_mittens/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Women's Gloves & Mittens",
                      probability: 0,
                      id: 10337,
                    },
                  ],
                },
                id: 10130200,
                mature: false,
              },
            ],
          },
          id: 10130000,
          mature: false,
        },
        {
          name: "Shoes",
          probability: 0,
          url: "https://www.bizrate.com/mensshoes/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Men's Shoes",
                probability: 0,
                id: 10348,
              },
            ],
          },
          id: 10150000,
          mature: false,
        },
        {
          name: "Sunglasses",
          probability: 0,
          url: "https://www.bizrate.com/sunglasses/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Sunglasses",
                probability: 0,
                id: 10306,
              },
            ],
          },
          id: 10070000,
          mature: false,
        },
        {
          name: "Women's Clothing",
          probability: 0,
          url: "https://www.bizrate.com/womensclothing/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          children: {
            category: [
              {
                name: "Denim",
                probability: 0,
                url: "https://www.bizrate.com/womensjeans/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Women's Jeans",
                      probability: 0,
                      id: 100000937,
                    },
                  ],
                },
                id: 10090300,
                mature: false,
              },
              {
                name: "Skirts",
                probability: 0,
                url: "https://www.bizrate.com/womensskirts/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Women's Skirts",
                      probability: 0,
                      id: 10315,
                    },
                  ],
                },
                id: 10090700,
                mature: false,
              },
              {
                name: "Shorts",
                probability: 0,
                url: "https://www.bizrate.com/womensshorts/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Women's Shorts",
                      probability: 0,
                      id: 10319,
                    },
                  ],
                },
                id: 10091100,
                mature: false,
              },
              {
                name: "Suits & Blazers",
                probability: 0,
                url: "https://www.bizrate.com/womenssuitsblazers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Women's Blazers",
                      probability: 0,
                      id: 100001178,
                    },
                    {
                      name: "Women's Suits",
                      probability: 0,
                      id: 100001179,
                    },
                  ],
                },
                id: 10090800,
                mature: false,
              },
              {
                name: "Dresses",
                probability: 0,
                url: "https://www.bizrate.com/womensdresses/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Women's Dresses",
                      probability: 0,
                      id: 10310,
                    },
                  ],
                },
                id: 10090100,
                mature: false,
              },
              {
                name: "Pants",
                probability: 0,
                url: "https://www.bizrate.com/womenspants/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Women's Pants",
                      probability: 0,
                      id: 10313,
                    },
                  ],
                },
                id: 10090500,
                mature: false,
              },
              {
                name: "Sweaters & Vests",
                probability: 0,
                url: "https://www.bizrate.com/womenssweatersvests/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                children: {
                  category: [
                    {
                      name: "Sweaters",
                      probability: 0,
                      url: "https://www.bizrate.com/womens-sweaters/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Women's Sweaters",
                            probability: 0,
                            id: 100001165,
                          },
                        ],
                      },
                      id: 100001162,
                      mature: false,
                    },
                    {
                      name: "Sweater Vests",
                      probability: 0,
                      url: "https://www.bizrate.com/womens-sweater-vests/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Women's Sweater Vests",
                            probability: 0,
                            id: 100001167,
                          },
                        ],
                      },
                      id: 100001164,
                      mature: false,
                    },
                    {
                      name: "Cardigans",
                      probability: 0,
                      url: "https://www.bizrate.com/womens-cardigans/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Women's Cardigans",
                            probability: 0,
                            id: 100001166,
                          },
                        ],
                      },
                      id: 100001163,
                      mature: false,
                    },
                  ],
                },
                id: 10090900,
                mature: false,
              },
              {
                name: "Swimwear",
                probability: 0,
                url: "https://www.bizrate.com/womensswimwear/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Original Women's Swimwear",
                      probability: 0,
                      id: 10321,
                    },
                    {
                      name: "Women's Two-Piece Swimwear",
                      probability: 0,
                      id: 100001202,
                    },
                    {
                      name: "Women's Cover-Ups",
                      probability: 0,
                      id: 100001201,
                    },
                    {
                      name: "Women's One-Piece Swimwear",
                      probability: 0,
                      id: 100001203,
                    },
                  ],
                },
                id: 10091300,
                mature: false,
              },
              {
                name: "Outerwear",
                probability: 0,
                url: "https://www.bizrate.com/womens-outerwear/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                children: {
                  category: [
                    {
                      name: "Vests",
                      probability: 0,
                      url: "https://www.bizrate.com/womens-vests/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Women's Vests",
                            probability: 0,
                            id: 100000600,
                          },
                        ],
                      },
                      id: 100000594,
                      mature: false,
                    },
                    {
                      name: "Jackets & Coats",
                      probability: 0,
                      url: "https://www.bizrate.com/womens-jackets-coats/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "ORIGINAL Women's Jackets & Coats",
                            probability: 0,
                            id: 10312,
                          },
                        ],
                      },
                      id: 10090400,
                      mature: false,
                    },
                  ],
                },
                id: 100001120,
                mature: false,
              },
              {
                name: "Shirts & Blouses",
                probability: 0,
                url: "https://www.bizrate.com/womensshirts_blouses/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Women's Shirts & Tops",
                      probability: 0,
                      id: 8729,
                    },
                    {
                      name: "ORIGINAL Women's Shirts & Blouses",
                      probability: 0,
                      id: 10314,
                    },
                  ],
                },
                id: 10090600,
                mature: false,
              },
              {
                name: "T-Shirts",
                probability: 0,
                url: "https://www.bizrate.com/womenstshirts/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Women's T-Shirts",
                      probability: 0,
                      id: 10318,
                    },
                  ],
                },
                id: 10091000,
                mature: false,
              },
            ],
          },
          id: 10090000,
          mature: false,
        },
        {
          name: "Activewear",
          probability: 0,
          url: "https://www.bizrate.com/mensworkout/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Men's Activewear",
                probability: 0,
                id: 100002084,
              },
            ],
          },
          id: 10050000,
          mature: false,
        },
        {
          name: "Handbags & Luggage",
          probability: 0,
          url: "https://www.bizrate.com/handbags_luggage/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          children: {
            category: [
              {
                name: "Computer Bags",
                probability: 0,
                url: "https://www.bizrate.com/computer-bags/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Laptop Bags",
                      probability: 0,
                      id: 10269,
                    },
                  ],
                },
                id: 10010300,
                mature: false,
              },
              {
                name: "Luggage",
                probability: 0,
                url: "https://www.bizrate.com/luggage/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Carry-On Bags",
                      probability: 0,
                      id: 100000861,
                    },
                    {
                      name: "Luggage Accessories",
                      probability: 0,
                      id: 100000858,
                    },
                    {
                      name: "Suitcases ",
                      probability: 0,
                      id: 100000857,
                    },
                    {
                      name: "Garment Bags",
                      probability: 0,
                      id: 100000860,
                    },
                    {
                      name: "Original Luggage",
                      probability: 0,
                      id: 10271,
                    },
                    {
                      name: "Luggage Sets",
                      probability: 0,
                      id: 100000859,
                    },
                  ],
                },
                id: 10010700,
                mature: false,
              },
              {
                name: "Travel Accessories",
                probability: 0,
                url: "https://www.bizrate.com/travelaccessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Lunch Boxes & Bags",
                      probability: 0,
                      id: 100000855,
                    },
                    {
                      name: "Travel Accessories",
                      probability: 0,
                      id: 10272,
                    },
                  ],
                },
                id: 10010800,
                mature: false,
              },
              {
                name: "Wallets",
                probability: 0,
                url: "https://www.bizrate.com/wallets/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Wallets",
                      probability: 0,
                      id: 10274,
                    },
                  ],
                },
                id: 10011200,
                mature: false,
              },
              {
                name: "Backpacks",
                probability: 0,
                url: "https://www.bizrate.com/backpacks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Backpacks",
                      probability: 0,
                      id: 10267,
                    },
                  ],
                },
                id: 10010100,
                mature: false,
              },
              {
                name: "Sports Bags",
                probability: 0,
                url: "https://www.bizrate.com/sportsbags/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Sports Bags",
                      probability: 0,
                      id: 10273,
                    },
                  ],
                },
                id: 10010900,
                mature: false,
              },
              {
                name: "Briefcases",
                probability: 0,
                url: "https://www.bizrate.com/briefcases/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Briefcases",
                      probability: 0,
                      id: 10268,
                    },
                  ],
                },
                id: 10010200,
                mature: false,
              },
              {
                name: "Handbags & Totes",
                probability: 0,
                url: "https://www.bizrate.com/handbagstotes/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Messenger Bags",
                      probability: 0,
                      id: 100000856,
                    },
                    {
                      name: "Handbags & Totes",
                      probability: 0,
                      id: 10270,
                    },
                  ],
                },
                id: 10010600,
                mature: false,
              },
            ],
          },
          id: 10010000,
          mature: false,
        },
      ],
    },
    id: 10000000,
    mature: false,
  },
  {
    name: "Sports Equipment & Outdoor Gear",
    probability: 0,
    url: "https://www.bizrate.com/sportsequipment_outdoorgear/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
    children: {
      category: [
        {
          name: "Lacrosse Equipment",
          probability: 0,
          url: "https://www.bizrate.com/lacrosseequipment/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Lacrosse Equipment",
                probability: 0,
                id: 10441,
              },
            ],
          },
          id: 12000600,
          mature: false,
        },
        {
          name: "Mountain Climbing Gear",
          probability: 0,
          url: "https://www.bizrate.com/mountainclimbinggear/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Mountain Climbing Gear",
                probability: 0,
                id: 10467,
              },
            ],
          },
          id: 12150106,
          mature: false,
        },
        {
          name: "Boxing Equipment & Martial Arts Supplies",
          probability: 0,
          url: "https://www.bizrate.com/boxingequipment_martialartssupplies/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Boxing Equipment & Martial Arts Supplies",
                probability: 0,
                id: 10466,
              },
            ],
          },
          id: 12150105,
          mature: false,
        },
        {
          name: "Tennis Clothing & Equipment",
          probability: 0,
          url: "https://www.bizrate.com/tennisclothing_equipment/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          children: {
            category: [
              {
                name: "Tennis Racquets",
                probability: 0,
                url: "https://www.bizrate.com/tennis-racquets/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Tennis Racquets",
                      probability: 0,
                      id: 100002021,
                    },
                  ],
                },
                id: 100002024,
                mature: false,
              },
              {
                name: "Tennis Accessories",
                probability: 0,
                url: "https://www.bizrate.com/tennis-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Tennis Accessories",
                      probability: 0,
                      id: 100002023,
                    },
                  ],
                },
                id: 100002026,
                mature: false,
              },
              {
                name: "Tennis Apparel",
                probability: 0,
                url: "https://www.bizrate.com/tennis-apparel/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Tennis Apparel",
                      probability: 0,
                      id: 100002022,
                    },
                  ],
                },
                id: 100002025,
                mature: false,
              },
            ],
          },
          id: 12110300,
          mature: false,
        },
        {
          name: "Golf Gifts & Equipment",
          probability: 0,
          url: "https://www.bizrate.com/golfgifts_equipment/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          children: {
            category: [
              {
                name: "Golf Bags",
                probability: 0,
                url: "https://www.bizrate.com/golf-bags/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Golf Bags",
                      probability: 0,
                      id: 8422,
                    },
                  ],
                },
                id: 8421,
                mature: false,
              },
              {
                name: "Golf Clubs",
                probability: 0,
                url: "https://www.bizrate.com/golf-clubs/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Golf Clubs",
                      probability: 0,
                      id: 8412,
                    },
                  ],
                },
                id: 8409,
                mature: false,
              },
              {
                name: "Golf Carts",
                probability: 0,
                url: "https://www.bizrate.com/golf-carts/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Golf Carts",
                      probability: 0,
                      id: 8426,
                    },
                  ],
                },
                id: 8425,
                mature: false,
              },
              {
                name: "Golf Gloves",
                probability: 0,
                url: "https://www.bizrate.com/golf-gloves/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Golf Gloves",
                      probability: 0,
                      id: 8406,
                    },
                  ],
                },
                id: 8396,
                mature: false,
              },
              {
                name: "Golf Accessories",
                probability: 0,
                url: "https://www.bizrate.com/golf-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Golf Accesories",
                      probability: 0,
                      id: 100002091,
                    },
                  ],
                },
                id: 8317,
                mature: false,
              },
              {
                name: "Golf Club Headcovers",
                probability: 0,
                url: "https://www.bizrate.com/golf-club-headcovers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Golf Club Headcovers",
                      probability: 0,
                      id: 8323,
                    },
                  ],
                },
                id: 8319,
                mature: false,
              },
            ],
          },
          id: 12070300,
          mature: false,
        },
        {
          name: "Binoculars & Telescopes",
          probability: 0,
          url: "https://www.bizrate.com/binoculars_telescopes/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Binoculars & Telescopes",
                probability: 0,
                id: 10470,
              },
            ],
          },
          id: 12180000,
          mature: false,
        },
        {
          name: "Skateboarding Equipment",
          probability: 0,
          url: "https://www.bizrate.com/skateboardingequipment/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Skateboarding Equipment",
                probability: 0,
                id: 10462,
              },
            ],
          },
          id: 12130400,
          mature: false,
        },
        {
          name: "Track & Field Equipment",
          probability: 0,
          url: "https://www.bizrate.com/track_fieldequipment/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Track & Field Equipment",
                probability: 0,
                id: 10443,
              },
            ],
          },
          id: 12000800,
          mature: false,
        },
        {
          name: "More Sports Equipment",
          probability: 0,
          url: "https://www.bizrate.com/moresportsequipment/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "More Sports Equipment",
                probability: 0,
                id: 10465,
              },
              {
                name: "Benches & Bleachers",
                probability: 0,
                id: 9970,
              },
              {
                name: "Rugby Equipment",
                probability: 0,
                id: 9965,
              },
              {
                name: "Cheerleading Gear",
                probability: 0,
                id: 9966,
              },
              {
                name: "Cricket Equipment",
                probability: 0,
                id: 9967,
              },
            ],
          },
          id: 12150100,
          mature: false,
        },
        {
          name: "Basketball Equipment",
          probability: 0,
          url: "https://www.bizrate.com/basketballequipment/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Basketball Equipment",
                probability: 0,
                id: 10446,
              },
            ],
          },
          id: 12030300,
          mature: false,
        },
        {
          name: "Squash & Racquetball Equipment",
          probability: 0,
          url: "https://www.bizrate.com/squash_racquetballequipment/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Squash & Racquetball Equipment",
                probability: 0,
                id: 10437,
              },
            ],
          },
          id: 12000200,
          mature: false,
        },
        {
          name: "Water Skiing Equipment",
          probability: 0,
          url: "https://www.bizrate.com/waterskiingequipment/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Water Skiing Equipment",
                probability: 0,
                id: 10476,
              },
            ],
          },
          id: 12180106,
          mature: false,
        },
        {
          name: "Wakeboarding Equipment",
          probability: 0,
          url: "https://www.bizrate.com/wakeboardingequipment/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Wakeboarding Equipment",
                probability: 0,
                id: 10475,
              },
            ],
          },
          id: 12180105,
          mature: false,
        },
        {
          name: "Game Tables",
          probability: 0,
          url: "https://www.bizrate.com/gametables/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Billiards Equipment",
                probability: 0,
                id: 9969,
              },
              {
                name: "Game Tables",
                probability: 0,
                id: 10439,
              },
            ],
          },
          id: 12000400,
          mature: false,
        },
        {
          name: "Inline & Roller Skate Equipment",
          probability: 0,
          url: "https://www.bizrate.com/inline_rollerskateequipment/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Inline & Roller Skate Equipment",
                probability: 0,
                id: 10463,
              },
            ],
          },
          id: 12130500,
          mature: false,
        },
        {
          name: "Scuba Diving & Snorkeling Equipment",
          probability: 0,
          url: "https://www.bizrate.com/scuba-diving-snorkeling-equipment/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Scuba Diving & Snorkeling Equipment",
                probability: 0,
                id: 10472,
              },
            ],
          },
          id: 12180102,
          mature: false,
        },
        {
          name: "Softball & Baseball Equipment",
          probability: 0,
          url: "https://www.bizrate.com/softball_baseballequipment/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Softball & Baseball Equipment",
                probability: 0,
                id: 10444,
              },
            ],
          },
          id: 12020300,
          mature: false,
        },
        {
          name: "Boats, Kayaks & Boating Equipment",
          probability: 0,
          url: "https://www.bizrate.com/boatskayaks_boatingequipment/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Boats, Kayaks & Boating Equipment",
                probability: 0,
                id: 10471,
              },
            ],
          },
          id: 12180101,
          mature: false,
        },
        {
          name: "Swimming Pools & Spas",
          probability: 0,
          url: "https://www.bizrate.com/swimming-pools-spas/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Swimming Pools & Spas",
                probability: 0,
                id: 10474,
              },
            ],
          },
          id: 12180104,
          mature: false,
        },
        {
          name: "Surf & Body Boarding Equipment",
          probability: 0,
          url: "https://www.bizrate.com/surf_bodyboardingequipment/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Surf & Body Boarding Equipment",
                probability: 0,
                id: 10473,
              },
            ],
          },
          id: 12180103,
          mature: false,
        },
        {
          name: "Wetsuits",
          probability: 0,
          url: "https://www.bizrate.com/wetsuits/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Wetsuits",
                probability: 0,
                id: 100002036,
              },
            ],
          },
          id: 100002037,
          mature: false,
        },
        {
          name: "Scooters & Accessories",
          probability: 0,
          url: "https://www.bizrate.com/scooters_accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Scooters & ATV Accessories",
                probability: 0,
                id: 10461,
              },
              {
                name: "Scooters & ATVs",
                probability: 0,
                id: 9981,
              },
            ],
          },
          id: 12130300,
          mature: false,
        },
        {
          name: "Winter Recreation",
          probability: 0,
          url: "https://www.bizrate.com/winterrecreation/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Sledding & Snowshoeing",
                probability: 0,
                id: 10440,
              },
            ],
          },
          id: 12000500,
          mature: false,
        },
        {
          name: "Fitness Equipment",
          probability: 0,
          url: "https://www.bizrate.com/fitnessequipment/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          children: {
            category: [
              {
                name: "Home Gym Attachments & Accessories",
                probability: 0,
                url: "https://www.bizrate.com/home-gym-attachments-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Home Gym Attachments & Accessories",
                      probability: 0,
                      id: 8642,
                    },
                  ],
                },
                id: 8641,
                mature: false,
              },
              {
                name: "Elliptical & Cross Trainer Machines",
                probability: 0,
                url: "https://www.bizrate.com/elliptical-cross-trainer-machines/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Elliptical & Cross Trainer Machines",
                      probability: 0,
                      id: 8619,
                    },
                  ],
                },
                id: 8618,
                mature: false,
              },
              {
                name: "Yoga & Pilates Equipment",
                probability: 0,
                url: "https://www.bizrate.com/yoga-pilates/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Yoga & Pilates Equipment",
                      probability: 0,
                      id: 100002094,
                    },
                  ],
                },
                id: 8683,
                mature: false,
              },
              {
                name: "Treadmills",
                probability: 0,
                url: "https://www.bizrate.com/treadmills/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Treadmills",
                      probability: 0,
                      id: 8621,
                    },
                  ],
                },
                id: 8620,
                mature: false,
              },
              {
                name: "Stair Climbers & Steppers",
                probability: 0,
                url: "https://www.bizrate.com/stair-climbers-steppers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Stair Climbers & Steppers",
                      probability: 0,
                      id: 8623,
                    },
                  ],
                },
                id: 8622,
                mature: false,
              },
              {
                name: "Exercise Bikes",
                probability: 0,
                url: "https://www.bizrate.com/upright-exercise-bikes/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Exercise Bikes",
                      probability: 0,
                      id: 8625,
                    },
                  ],
                },
                id: 8624,
                mature: false,
              },
              {
                name: "Rowing Machines",
                probability: 0,
                url: "https://www.bizrate.com/rowing-machines/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Rowing Machines",
                      probability: 0,
                      id: 9533,
                    },
                  ],
                },
                id: 9525,
                mature: false,
              },
              {
                name: "Weight Machines",
                probability: 0,
                url: "https://www.bizrate.com/weight-machines/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Weight Machines",
                      probability: 0,
                      id: 100002092,
                    },
                  ],
                },
                id: 8630,
                mature: false,
              },
              {
                name: "Cardio Equipment Accessories",
                probability: 0,
                url: "https://www.bizrate.com/cardio-equipment-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Cardio Equipment Accessories",
                      probability: 0,
                      id: 9534,
                    },
                  ],
                },
                id: 9526,
                mature: false,
              },
              {
                name: "Other Fitness Equipment",
                probability: 0,
                url: "https://www.bizrate.com/other-fitness-equipment/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Other Fitness Equipment",
                      probability: 0,
                      id: 8570,
                    },
                    {
                      name: "Gymnastics Equipment",
                      probability: 0,
                      id: 9757,
                    },
                  ],
                },
                id: 8566,
                mature: false,
              },
              {
                name: "Strength & Fitness Training",
                probability: 0,
                url: "https://www.bizrate.com/weight-training-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Strength & Fitness Training",
                      probability: 0,
                      id: 100002093,
                    },
                  ],
                },
                id: 8669,
                mature: false,
              },
              {
                name: "Home Gyms",
                probability: 0,
                url: "https://www.bizrate.com/home-gyms/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Home Gyms",
                      probability: 0,
                      id: 8640,
                    },
                  ],
                },
                id: 8639,
                mature: false,
              },
              {
                name: "Free Weight Equipment",
                probability: 0,
                url: "https://www.bizrate.com/free-weight-equipment/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Free Weight Equipment",
                      probability: 0,
                      id: 100002090,
                    },
                  ],
                },
                id: 8607,
                mature: false,
              },
            ],
          },
          id: 12050100,
          mature: false,
        },
        {
          name: "Bicycles & Equipment",
          probability: 0,
          url: "https://www.bizrate.com/bicycles_equipment/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          children: {
            category: [
              {
                name: "Bicycles",
                probability: 0,
                url: "https://www.bizrate.com/bicycles/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Bicycles ",
                      probability: 0,
                      id: 100001451,
                    },
                  ],
                },
                id: 100001448,
                mature: false,
              },
              {
                name: "Cycling Apparel",
                probability: 0,
                url: "https://www.bizrate.com/cycling-apparel/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Cycling Apparel",
                      probability: 0,
                      id: 100001453,
                    },
                  ],
                },
                id: 100001450,
                mature: false,
              },
              {
                name: "Bicycle  Parts & Accessories",
                probability: 0,
                url: "https://www.bizrate.com/bicycle-parts-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Bicycle Parts & Accessories",
                      probability: 0,
                      id: 100001452,
                    },
                  ],
                },
                id: 100001449,
                mature: false,
              },
            ],
          },
          id: 12130100,
          mature: false,
        },
        {
          name: "Snowboarding Equipment",
          probability: 0,
          url: "https://www.bizrate.com/snowboardingequipment/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Snowboarding Equipment",
                probability: 0,
                id: 10456,
              },
            ],
          },
          id: 12090100,
          mature: false,
        },
        {
          name: "Table Tennis Equipment",
          probability: 0,
          url: "https://www.bizrate.com/table-tennis-equipment/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Table Tennis Equipment",
                probability: 0,
                id: 9743,
              },
            ],
          },
          id: 9740,
          mature: false,
        },
        {
          name: "Equestrian Clothing & Equipment",
          probability: 0,
          url: "https://www.bizrate.com/equestrianclothing_equipment/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Equestrian Clothing & Equipment",
                probability: 0,
                id: 10442,
              },
            ],
          },
          id: 12000700,
          mature: false,
        },
        {
          name: "Badminton Equipment",
          probability: 0,
          url: "https://www.bizrate.com/badminton-equipment/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Badminton Equipment",
                probability: 0,
                id: 9744,
              },
            ],
          },
          id: 9741,
          mature: false,
        },
        {
          name: "Ice Skating Equipment",
          probability: 0,
          url: "https://www.bizrate.com/ice-skating-equipment/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Ice Skating Equipment",
                probability: 0,
                id: 9745,
              },
            ],
          },
          id: 9742,
          mature: false,
        },
        {
          name: "Paintball Gear",
          probability: 0,
          url: "https://www.bizrate.com/paintballgear/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Paintball Gear",
                probability: 0,
                id: 10469,
              },
            ],
          },
          id: 12150135,
          mature: false,
        },
        {
          name: "Bowling Equipment",
          probability: 0,
          url: "https://www.bizrate.com/bowlingequipment/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Bowling Equipment",
                probability: 0,
                id: 10436,
              },
            ],
          },
          id: 12000100,
          mature: false,
        },
        {
          name: "Camping & Hiking Gear",
          probability: 0,
          url: "https://www.bizrate.com/camping_hikinggear/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Camping & Hiking Gear",
                probability: 0,
                id: 10449,
              },
            ],
          },
          id: 12040100,
          mature: false,
        },
        {
          name: "Hunting & Archery Equipment",
          probability: 0,
          url: "https://www.bizrate.com/hunting_archeryequipment/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Hunting & Archery Equipment",
                probability: 0,
                id: 10468,
              },
            ],
          },
          id: 12150125,
          mature: false,
        },
        {
          name: "Roller & Ice Hockey Equipment",
          probability: 0,
          url: "https://www.bizrate.com/roller_icehockeyequipment/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Roller & Ice Hockey Equipment",
                probability: 0,
                id: 10454,
              },
            ],
          },
          id: 12080100,
          mature: false,
        },
        {
          name: "Fishing Gear",
          probability: 0,
          url: "https://www.bizrate.com/fishinggear/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Fishing Gear",
                probability: 0,
                id: 10459,
              },
            ],
          },
          id: 12120100,
          mature: false,
        },
        {
          name: "Volleyball Equipment",
          probability: 0,
          url: "https://www.bizrate.com/volleyballequipment/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Volleyball Equipment",
                probability: 0,
                id: 10438,
              },
            ],
          },
          id: 12000300,
          mature: false,
        },
        {
          name: "Skiing Equipment",
          probability: 0,
          url: "https://www.bizrate.com/skiingequipment/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Skiing Equipment",
                probability: 0,
                id: 10464,
              },
            ],
          },
          id: 12140200,
          mature: false,
        },
        {
          name: "Football Equipment",
          probability: 0,
          url: "https://www.bizrate.com/footballequipment/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Football Equipment",
                probability: 0,
                id: 10451,
              },
            ],
          },
          id: 12060200,
          mature: false,
        },
        {
          name: "Soccer Equipment",
          probability: 0,
          url: "https://www.bizrate.com/soccerequipment/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Soccer Equipment",
                probability: 0,
                id: 10457,
              },
            ],
          },
          id: 12100200,
          mature: false,
        },
      ],
    },
    id: 12000000,
    mature: false,
  },
  {
    name: "Toys",
    probability: 0,
    url: "https://www.bizrate.com/toys_games/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
    children: {
      category: [
        {
          name: "Pretend Play",
          probability: 0,
          url: "https://www.bizrate.com/pretendplay/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          children: {
            category: [
              {
                name: "Electronic Toys",
                probability: 0,
                url: "https://www.bizrate.com/electronictoys/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Electronic Toys",
                      probability: 0,
                      id: 10595,
                    },
                  ],
                },
                id: 14010213,
                mature: false,
              },
              {
                name: "Kitchen & Housekeeping Toys",
                probability: 0,
                url: "https://www.bizrate.com/kitchen_housekeepingtoys/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Kitchen & Housekeeping Toys",
                      probability: 0,
                      id: 10594,
                    },
                  ],
                },
                id: 14010212,
                mature: false,
              },
              {
                name: "Musical Toys",
                probability: 0,
                url: "https://www.bizrate.com/musicaltoys/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Musical Toys",
                      probability: 0,
                      id: 10592,
                    },
                  ],
                },
                id: 14010206,
                mature: false,
              },
              {
                name: "Playsets & Figures",
                probability: 0,
                url: "https://www.bizrate.com/playsets-figures/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Playsets & Figures",
                      probability: 0,
                      id: 100000048,
                    },
                  ],
                },
                id: 100000046,
                mature: false,
              },
              {
                name: "Play Tents & Tunnels",
                probability: 0,
                url: "https://www.bizrate.com/play-tents-tunnels/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Play Tents & Tunnels",
                      probability: 0,
                      id: 100000049,
                    },
                  ],
                },
                id: 100000047,
                mature: false,
              },
              {
                name: "Role Playing Toys",
                probability: 0,
                url: "https://www.bizrate.com/roleplayingtoys/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Role Playing Toys",
                      probability: 0,
                      id: 10591,
                    },
                  ],
                },
                id: 14010204,
                mature: false,
              },
            ],
          },
          id: 14010200,
          mature: false,
        },
        {
          name: "Learning Toys",
          probability: 0,
          url: "https://www.bizrate.com/learningtoys/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Learning Toys",
                probability: 0,
                id: 10610,
              },
            ],
          },
          id: 14011000,
          mature: false,
        },
        {
          name: "Action Figures",
          probability: 0,
          url: "https://www.bizrate.com/actionfigures/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Action Figures",
                probability: 0,
                id: 10589,
              },
            ],
          },
          id: 14010100,
          mature: false,
        },
        {
          name: "Baby Toys",
          probability: 0,
          url: "https://www.bizrate.com/babytoys/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          children: {
            category: [
              {
                name: "Crib & Stroller Toys",
                probability: 0,
                url: "https://www.bizrate.com/crib_strollertoys/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Crib & Stroller Toys",
                      probability: 0,
                      id: 10602,
                    },
                  ],
                },
                id: 14010902,
                mature: false,
              },
              {
                name: "Bath & Water Toys",
                probability: 0,
                url: "https://www.bizrate.com/bath_watertoys/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Bath & Water Toys",
                      probability: 0,
                      id: 10601,
                    },
                  ],
                },
                id: 14010901,
                mature: false,
              },
              {
                name: "Baby Musical Toys",
                probability: 0,
                url: "https://www.bizrate.com/babymusicaltoys/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Baby Musical Toys",
                      probability: 0,
                      id: 10604,
                    },
                  ],
                },
                id: 14010904,
                mature: false,
              },
              {
                name: "Gyms & Playmats",
                probability: 0,
                url: "https://www.bizrate.com/gyms_playmats/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Gyms & Playmats",
                      probability: 0,
                      id: 10603,
                    },
                  ],
                },
                id: 14010903,
                mature: false,
              },
              {
                name: "Mobiles",
                probability: 0,
                url: "https://www.bizrate.com/mobiles/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Mobiles",
                      probability: 0,
                      id: 10608,
                    },
                  ],
                },
                id: 14010910,
                mature: false,
              },
              {
                name: "Sort & Stack Toys",
                probability: 0,
                url: "https://www.bizrate.com/sort_stacktoys/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Sort & Stack Toys",
                      probability: 0,
                      id: 10607,
                    },
                  ],
                },
                id: 14010909,
                mature: false,
              },
              {
                name: "Push & Pull Toys",
                probability: 0,
                url: "https://www.bizrate.com/push_pulltoys/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Push & Pull Toys",
                      probability: 0,
                      id: 10605,
                    },
                  ],
                },
                id: 14010906,
                mature: false,
              },
              {
                name: "Baby Rattles & Teethers",
                probability: 0,
                url: "https://www.bizrate.com/rattlers_teethers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Baby Rattles & Teethers",
                      probability: 0,
                      id: 10606,
                    },
                  ],
                },
                id: 14010908,
                mature: false,
              },
            ],
          },
          id: 14010900,
          mature: false,
        },
        {
          name: "Building Sets & Models",
          probability: 0,
          url: "https://www.bizrate.com/buildingsets_models/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Building Sets",
                probability: 0,
                id: 100000806,
              },
              {
                name: "Models & Model Kits",
                probability: 0,
                id: 100000791,
              },
            ],
          },
          id: 14010400,
          mature: false,
        },
        {
          name: "Robotic & Radio Control Toys",
          probability: 0,
          url: "https://www.bizrate.com/robotic_radiocontroltoys/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Robotic & Radio Control Toys",
                probability: 0,
                id: 10611,
              },
            ],
          },
          id: 14011200,
          mature: false,
        },
        {
          name: "Costumes & Accessories",
          probability: 0,
          url: "https://www.bizrate.com/costumes-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          children: {
            category: [
              {
                name: "Halloween Decorations",
                probability: 0,
                url: "https://www.bizrate.com/halloween-decorations/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                children: {
                  category: [
                    {
                      name: "Halloween Props & Effects",
                      probability: 0,
                      url: "https://www.bizrate.com/halloween-props-effects/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Halloween Props & Effects",
                            probability: 0,
                            id: 8227,
                          },
                        ],
                      },
                      id: 8220,
                      mature: false,
                    },
                    {
                      name: "Halloween Decor",
                      probability: 0,
                      url: "https://www.bizrate.com/halloween-decor/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Halloween Decor",
                            probability: 0,
                            id: 8305,
                          },
                        ],
                      },
                      id: 8304,
                      mature: false,
                    },
                  ],
                },
                id: 8242,
                mature: false,
              },
              {
                name: "Costumes",
                probability: 0,
                url: "https://www.bizrate.com/costumes/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                children: {
                  category: [
                    {
                      name: "Baby Costumes",
                      probability: 0,
                      url: "https://www.bizrate.com/baby-costumes/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Baby Costumes",
                            probability: 0,
                            id: 8307,
                          },
                        ],
                      },
                      id: 8306,
                      mature: false,
                    },
                    {
                      name: "Pet Costumes",
                      probability: 0,
                      url: "https://www.bizrate.com/pet-costumes/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Pet Costumes",
                            probability: 0,
                            id: 8239,
                          },
                        ],
                      },
                      id: 8221,
                      mature: false,
                    },
                    {
                      name: "Adult Costumes",
                      probability: 0,
                      url: "https://www.bizrate.com/adult-costumes/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Adult Costumes",
                            probability: 0,
                            id: 8240,
                          },
                        ],
                      },
                      id: 8222,
                      mature: false,
                    },
                    {
                      name: "Kids' Costumes",
                      probability: 0,
                      url: "https://www.bizrate.com/kids-costumes/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Kids' Costumes",
                            probability: 0,
                            id: 8241,
                          },
                        ],
                      },
                      id: 8223,
                      mature: false,
                    },
                  ],
                },
                id: 8243,
                mature: false,
              },
              {
                name: "Costume Accessories",
                probability: 0,
                url: "https://www.bizrate.com/costume-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                children: {
                  category: [
                    {
                      name: "Other Costume Accessories",
                      probability: 0,
                      url: "https://www.bizrate.com/other-costume-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Other Costume Accessories",
                            probability: 0,
                            id: 8225,
                          },
                        ],
                      },
                      id: 8207,
                      mature: false,
                    },
                    {
                      name: "Costume Hats & Headbands",
                      probability: 0,
                      url: "https://www.bizrate.com/costume-hats-headbands/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Costume Hats & Headbands",
                            probability: 0,
                            id: 8226,
                          },
                        ],
                      },
                      id: 8208,
                      mature: false,
                    },
                    {
                      name: "Crowns & Tiaras",
                      probability: 0,
                      url: "https://www.bizrate.com/crowns-tiaras/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Crowns & Tiaras",
                            probability: 0,
                            id: 8228,
                          },
                        ],
                      },
                      id: 8209,
                      mature: false,
                    },
                    {
                      name: "Costume Masks",
                      probability: 0,
                      url: "https://www.bizrate.com/costume-masks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Costume Masks",
                            probability: 0,
                            id: 8229,
                          },
                        ],
                      },
                      id: 8210,
                      mature: false,
                    },
                    {
                      name: "Costume Hands & Feet",
                      probability: 0,
                      url: "https://www.bizrate.com/costume-hands-feet/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Costume Hands & Feet",
                            probability: 0,
                            id: 8230,
                          },
                        ],
                      },
                      id: 8211,
                      mature: false,
                    },
                    {
                      name: "Wings & Halos",
                      probability: 0,
                      url: "https://www.bizrate.com/wings-halos/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Wings & Halos",
                            probability: 0,
                            id: 8231,
                          },
                        ],
                      },
                      id: 8212,
                      mature: false,
                    },
                    {
                      name: "Costume Wigs & Facial Hair",
                      probability: 0,
                      url: "https://www.bizrate.com/costume-wigs-facial-hair/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Costume Wigs & Facial Hair",
                            probability: 0,
                            id: 8232,
                          },
                        ],
                      },
                      id: 8213,
                      mature: false,
                    },
                    {
                      name: "Capes & Cloaks",
                      probability: 0,
                      url: "https://www.bizrate.com/capes-cloaks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Capes & Cloaks",
                            probability: 0,
                            id: 8233,
                          },
                        ],
                      },
                      id: 8214,
                      mature: false,
                    },
                    {
                      name: "Costume Gloves",
                      probability: 0,
                      url: "https://www.bizrate.com/costume-gloves/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Costume Gloves",
                            probability: 0,
                            id: 8234,
                          },
                        ],
                      },
                      id: 8215,
                      mature: false,
                    },
                    {
                      name: "Costume Footwear & Accessories",
                      probability: 0,
                      url: "https://www.bizrate.com/costume-footwear-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Costume Footwear & Accessories",
                            probability: 0,
                            id: 8235,
                          },
                        ],
                      },
                      id: 8216,
                      mature: false,
                    },
                    {
                      name: "Halloween Makeup & Prosthetics",
                      probability: 0,
                      url: "https://www.bizrate.com/halloween-makeup-prosthetics/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Halloween Makeup & Prosthetics",
                            probability: 0,
                            id: 8236,
                          },
                        ],
                      },
                      id: 8217,
                      mature: false,
                    },
                    {
                      name: "Halloween Jewelry",
                      probability: 0,
                      url: "https://www.bizrate.com/halloween-jewelry/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Halloween Jewelry",
                            probability: 0,
                            id: 8237,
                          },
                        ],
                      },
                      id: 8218,
                      mature: false,
                    },
                    {
                      name: "Costume Armor & Weapons",
                      probability: 0,
                      url: "https://www.bizrate.com/costume-armor-weapons/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Costume Armor & Weapons",
                            probability: 0,
                            id: 8238,
                          },
                        ],
                      },
                      id: 8219,
                      mature: false,
                    },
                  ],
                },
                id: 8224,
                mature: false,
              },
            ],
          },
          id: 14010202,
          mature: false,
        },
        {
          name: "Kids' Arts & Crafts Supplies",
          probability: 0,
          url: "https://www.bizrate.com/kidsarts_craftssupplies/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Kids' Arts & Crafts Supplies",
                probability: 0,
                id: 10596,
              },
            ],
          },
          id: 14010300,
          mature: false,
        },
        {
          name: "Miscellaneous Toys & Games",
          probability: 0,
          url: "https://www.bizrate.com/miscellaneous-toys-games/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Miscellaneous Toys & Games",
                probability: 0,
                id: 338,
              },
            ],
          },
          id: 337,
          mature: false,
        },
        {
          name: "Dolls",
          probability: 0,
          url: "https://www.bizrate.com/dolls/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Dolls",
                probability: 0,
                id: 10598,
              },
            ],
          },
          id: 14010600,
          mature: false,
        },
        {
          name: "Puppets & Puppet Theater",
          probability: 0,
          url: "https://www.bizrate.com/puppets-puppet-theater/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Puppets & Puppet Theater",
                probability: 0,
                id: 246,
              },
            ],
          },
          id: 245,
          mature: false,
        },
        {
          name: "Plush Toys & Stuffed Animals",
          probability: 0,
          url: "https://www.bizrate.com/plush-toys-stuffed-animals/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Plush Toys & Stuffed Animals",
                probability: 0,
                id: 262,
              },
            ],
          },
          id: 247,
          mature: false,
        },
        {
          name: "Outdoor Play",
          probability: 0,
          url: "https://www.bizrate.com/outdoorgames_fun/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Outdoor Games & Fun",
                probability: 0,
                id: 10600,
              },
            ],
          },
          id: 14010800,
          mature: false,
        },
        {
          name: "Games & Puzzles",
          probability: 0,
          url: "https://www.bizrate.com/games_puzzles/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Games & Puzzles",
                probability: 0,
                id: 100000740,
              },
            ],
          },
          id: 14010700,
          mature: false,
        },
        {
          name: "Toy Vehicles",
          probability: 0,
          url: "https://www.bizrate.com/toyvehicles_planes/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Toy Vehicles",
                probability: 0,
                id: 100000768,
              },
            ],
          },
          id: 14011500,
          mature: false,
        },
      ],
    },
    id: 14000000,
    mature: false,
  },
  {
    name: "Gifts, Flowers & Food",
    probability: 0,
    url: "https://www.bizrate.com/giftsflowers_food/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
    children: {
      category: [
        {
          name: "Nuts",
          probability: 0,
          url: "https://www.bizrate.com/nuts/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Nuts",
                probability: 0,
                id: 10658,
              },
            ],
          },
          id: 16031700,
          mature: false,
        },
        {
          name: "Party Supplies",
          probability: 0,
          url: "https://www.bizrate.com/partysupplies/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Party Supplies",
                probability: 0,
                id: 10639,
              },
            ],
          },
          id: 16010900,
          mature: false,
        },
        {
          name: "Collectibles",
          probability: 0,
          url: "https://www.bizrate.com/collectibles/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Collectibles",
                probability: 0,
                id: 100001892,
              },
            ],
          },
          id: 16011700,
          mature: false,
        },
        {
          name: "Chocolate & Sweets",
          probability: 0,
          url: "https://www.bizrate.com/chocolate_sweets/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          children: {
            category: [
              {
                name: "Chocolate",
                probability: 0,
                url: "https://www.bizrate.com/chocolate/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Chocolate",
                      probability: 0,
                      id: 10643,
                    },
                  ],
                },
                id: 16030101,
                mature: false,
              },
              {
                name: "Candy",
                probability: 0,
                url: "https://www.bizrate.com/candy/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Candy",
                      probability: 0,
                      id: 10644,
                    },
                  ],
                },
                id: 16030102,
                mature: false,
              },
              {
                name: "Cakes & Pies",
                probability: 0,
                url: "https://www.bizrate.com/cakes_pies/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Cakes & Pies",
                      probability: 0,
                      id: 10645,
                    },
                  ],
                },
                id: 16030103,
                mature: false,
              },
              {
                name: "Cookies & Brownies",
                probability: 0,
                url: "https://www.bizrate.com/cookies_brownies/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Cookies & Brownies",
                      probability: 0,
                      id: 10646,
                    },
                  ],
                },
                id: 16030104,
                mature: false,
              },
            ],
          },
          id: 16030100,
          mature: false,
        },
        {
          name: "Liquor, Spirits & Beers",
          probability: 0,
          url: "https://www.bizrate.com/liquorspirits_beers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Liquor, Spirits & Beers",
                probability: 0,
                id: 10660,
              },
            ],
          },
          id: 16060000,
          mature: false,
        },
        {
          name: "Condiments, Seasoning & Sauces",
          probability: 0,
          url: "https://www.bizrate.com/condimentsseasoning_sauces/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Condiments, Seasoning & Sauces",
                probability: 0,
                id: 10654,
              },
            ],
          },
          id: 16031200,
          mature: false,
        },
        {
          name: "Flowers & Plants",
          probability: 0,
          url: "https://www.bizrate.com/flowers_plants/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Flowers & Plants",
                probability: 0,
                id: 10636,
              },
            ],
          },
          id: 16010400,
          mature: false,
        },
        {
          name: "Cheese",
          probability: 0,
          url: "https://www.bizrate.com/cheese/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Cheese",
                probability: 0,
                id: 10653,
              },
            ],
          },
          id: 16031100,
          mature: false,
        },
        {
          name: "Tobacco Products",
          probability: 0,
          url: "https://www.bizrate.com/tobaccoproducts/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Tobacco Products",
                probability: 0,
                id: 10635,
              },
            ],
          },
          id: 16010300,
          mature: false,
        },
        {
          name: "Wine",
          probability: 0,
          url: "https://www.bizrate.com/wine/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Wine",
                probability: 0,
                id: 10640,
              },
            ],
          },
          id: 16011100,
          mature: false,
        },
        {
          name: "Gift Baskets",
          probability: 0,
          url: "https://www.bizrate.com/giftbaskets/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Gift Baskets",
                probability: 0,
                id: 10637,
              },
            ],
          },
          id: 16010600,
          mature: false,
        },
        {
          name: "Food & Snacks",
          probability: 0,
          url: "https://www.bizrate.com/food-snacks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Food & Snacks",
                probability: 0,
                id: 10649,
              },
            ],
          },
          id: 16030600,
          mature: false,
        },
        {
          name: "Drinks & Juices",
          probability: 0,
          url: "https://www.bizrate.com/drinks_juices/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Non-Alcoholic Drinks & Beverages",
                probability: 0,
                id: 100001820,
              },
            ],
          },
          id: 16031300,
          mature: false,
        },
        {
          name: "Miscellaneous Gifts, Flowers & Food",
          probability: 0,
          url: "https://www.bizrate.com/miscellaneousgiftsflowers_food/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Miscellaneous Gifts, Flowers & Food",
                probability: 0,
                id: 10641,
              },
            ],
          },
          id: 16011300,
          mature: false,
        },
        {
          name: "Stationery",
          probability: 0,
          url: "https://www.bizrate.com/greetingcards/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Greeting Cards",
                probability: 0,
                id: 10638,
              },
            ],
          },
          id: 16010700,
          mature: false,
        },
        {
          name: "Coffee & Tea",
          probability: 0,
          url: "https://www.bizrate.com/coffee_tea/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Coffee & Tea",
                probability: 0,
                id: 10650,
              },
            ],
          },
          id: 16030700,
          mature: false,
        },
        {
          name: "Gift Cards",
          probability: 0,
          url: "https://www.bizrate.com/gift-cards/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Gift Cards",
                probability: 0,
                id: 100000041,
              },
            ],
          },
          id: 100000043,
          mature: false,
        },
      ],
    },
    id: 16000000,
    mature: false,
  },
  {
    name: "Appliances",
    probability: 0,
    url: "https://www.bizrate.com/appliances/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
    children: {
      category: [
        {
          name: "Food Dehydrators",
          probability: 0,
          url: "https://www.bizrate.com/fooddehydrators/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Food Dehydrators",
                probability: 0,
                id: 10665,
              },
            ],
          },
          id: 18004600,
          mature: false,
        },
        {
          name: "Garment Steamers",
          probability: 0,
          url: "https://www.bizrate.com/garmentsteamers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Garment Steamers",
                probability: 0,
                id: 10674,
              },
            ],
          },
          id: 18005500,
          mature: false,
        },
        {
          name: "Food Processors",
          probability: 0,
          url: "https://www.bizrate.com/foodprocessors/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Food Processors",
                probability: 0,
                id: 10525,
              },
            ],
          },
          id: 13050808,
          mature: false,
        },
        {
          name: "Toasters",
          probability: 0,
          url: "https://www.bizrate.com/toasters/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Toasters",
                probability: 0,
                id: 10524,
              },
            ],
          },
          id: 13050806,
          mature: false,
        },
        {
          name: "Mixers",
          probability: 0,
          url: "https://www.bizrate.com/mixers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Commercial Mixers",
                probability: 0,
                id: 100001361,
              },
              {
                name: "Mixers",
                probability: 0,
                id: 10523,
              },
            ],
          },
          id: 13050805,
          mature: false,
        },
        {
          name: "Juicers",
          probability: 0,
          url: "https://www.bizrate.com/juicers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Juicers",
                probability: 0,
                id: 10522,
              },
            ],
          },
          id: 13050804,
          mature: false,
        },
        {
          name: "Espresso Machines",
          probability: 0,
          url: "https://www.bizrate.com/espressomachines/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Espresso Machines",
                probability: 0,
                id: 10521,
              },
              {
                name: "Commercial Espresso Machines",
                probability: 0,
                id: 100001391,
              },
            ],
          },
          id: 13050803,
          mature: false,
        },
        {
          name: "Yogurt Makers",
          probability: 0,
          url: "https://www.bizrate.com/yogurtmakers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Yogurt Makers",
                probability: 0,
                id: 10661,
              },
            ],
          },
          id: 18004200,
          mature: false,
        },
        {
          name: "Coffee Makers",
          probability: 0,
          url: "https://www.bizrate.com/coffeemakers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Commercial Coffee Makers",
                probability: 0,
                id: 100001378,
              },
              {
                name: "Coffee Makers",
                probability: 0,
                id: 10520,
              },
            ],
          },
          id: 13050802,
          mature: false,
        },
        {
          name: "Breadmakers",
          probability: 0,
          url: "https://www.bizrate.com/breadmakers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Breadmakers",
                probability: 0,
                id: 10519,
              },
            ],
          },
          id: 13050801,
          mature: false,
        },
        {
          name: "Dehumidifiers",
          probability: 0,
          url: "https://www.bizrate.com/dehumidifiers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Dehumidifiers",
                probability: 0,
                id: 10533,
              },
            ],
          },
          id: 13050816,
          mature: false,
        },
        {
          name: "Wine Cellars",
          probability: 0,
          url: "https://www.bizrate.com/winecellars/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Wine & Beverage Coolers",
                probability: 0,
                id: 10670,
              },
            ],
          },
          id: 18005100,
          mature: false,
        },
        {
          name: "Deep Fryers",
          probability: 0,
          url: "https://www.bizrate.com/deepfryers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Deep Fryers",
                probability: 0,
                id: 10532,
              },
              {
                name: "Commercial Deep Fryers",
                probability: 0,
                id: 100001380,
              },
            ],
          },
          id: 13050815,
          mature: false,
        },
        {
          name: "Cooktops",
          probability: 0,
          url: "https://www.bizrate.com/cooktops/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Cooktops",
                probability: 0,
                id: 10531,
              },
            ],
          },
          id: 13050814,
          mature: false,
        },
        {
          name: "Coffee Grinders",
          probability: 0,
          url: "https://www.bizrate.com/coffeegrinders/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Coffee Grinders",
                probability: 0,
                id: 10529,
              },
              {
                name: "Commercial Coffee Grinders",
                probability: 0,
                id: 100001390,
              },
            ],
          },
          id: 13050812,
          mature: false,
        },
        {
          name: "Can Openers",
          probability: 0,
          url: "https://www.bizrate.com/canopeners/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Can Openers",
                probability: 0,
                id: 10528,
              },
            ],
          },
          id: 13050811,
          mature: false,
        },
        {
          name: "Air Purifiers",
          probability: 0,
          url: "https://www.bizrate.com/airpurifiers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Air Purifiers",
                probability: 0,
                id: 10527,
              },
            ],
          },
          id: 13050810,
          mature: false,
        },
        {
          name: "Blenders",
          probability: 0,
          url: "https://www.bizrate.com/blenders/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Blenders",
                probability: 0,
                id: 10526,
              },
              {
                name: "Commercial Blenders",
                probability: 0,
                id: 100001388,
              },
            ],
          },
          id: 13050809,
          mature: false,
        },
        {
          name: "Pasta Makers",
          probability: 0,
          url: "https://www.bizrate.com/pastamakers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Pasta Makers",
                probability: 0,
                id: 10541,
              },
            ],
          },
          id: 13050824,
          mature: false,
        },
        {
          name: "Electric Skillets & Woks",
          probability: 0,
          url: "https://www.bizrate.com/electricskillets_woks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Electric Skillets & Woks",
                probability: 0,
                id: 10540,
              },
            ],
          },
          id: 13050823,
          mature: false,
        },
        {
          name: "Meat Grinders",
          probability: 0,
          url: "https://www.bizrate.com/meatgrinders/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Meat Grinders",
                probability: 0,
                id: 10664,
              },
            ],
          },
          id: 18004500,
          mature: false,
        },
        {
          name: "Crock Pots & Slow Cookers",
          probability: 0,
          url: "https://www.bizrate.com/crockpots_slowcookers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Crock Pots & Slow Cookers",
                probability: 0,
                id: 10539,
              },
            ],
          },
          id: 13050822,
          mature: false,
        },
        {
          name: "Waffle Makers",
          probability: 0,
          url: "https://www.bizrate.com/wafflemakers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Waffle Makers",
                probability: 0,
                id: 10538,
              },
            ],
          },
          id: 13050821,
          mature: false,
        },
        {
          name: "Tea Kettles",
          probability: 0,
          url: "https://www.bizrate.com/teakettles/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Tea Kettles",
                probability: 0,
                id: 10537,
              },
            ],
          },
          id: 13050820,
          mature: false,
        },
        {
          name: "Electric Knife Sharpeners",
          probability: 0,
          url: "https://www.bizrate.com/electricknifesharpeners/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Electric Knife Sharpeners",
                probability: 0,
                id: 10673,
              },
            ],
          },
          id: 18005400,
          mature: false,
        },
        {
          name: "Range Hoods",
          probability: 0,
          url: "https://www.bizrate.com/rangehoods/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Range Hoods",
                probability: 0,
                id: 10536,
              },
            ],
          },
          id: 13050819,
          mature: false,
        },
        {
          name: "Indoor Grills",
          probability: 0,
          url: "https://www.bizrate.com/indoorgrills/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Indoor Grills",
                probability: 0,
                id: 10535,
              },
            ],
          },
          id: 13050818,
          mature: false,
        },
        {
          name: "Ice Cream Makers",
          probability: 0,
          url: "https://www.bizrate.com/icecreammakers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Ice Cream Makers",
                probability: 0,
                id: 10534,
              },
            ],
          },
          id: 13050817,
          mature: false,
        },
        {
          name: "Toaster Ovens",
          probability: 0,
          url: "https://www.bizrate.com/toasterovens/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Commercial Toaster Ovens",
                probability: 0,
                id: 100001389,
              },
              {
                name: "Toaster Ovens",
                probability: 0,
                id: 10544,
              },
            ],
          },
          id: 13050827,
          mature: false,
        },
        {
          name: "Rice Cookers & Steamers",
          probability: 0,
          url: "https://www.bizrate.com/ricecookers_steamers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Rice Cookers & Steamers",
                probability: 0,
                id: 10543,
              },
            ],
          },
          id: 13050826,
          mature: false,
        },
        {
          name: "Popcorn Makers",
          probability: 0,
          url: "https://www.bizrate.com/popcornmakers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Popcorn Makers",
                probability: 0,
                id: 10542,
              },
            ],
          },
          id: 13050825,
          mature: false,
        },
        {
          name: "Sewing Machines",
          probability: 0,
          url: "https://www.bizrate.com/sewingmachines/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Sewing Machines",
                probability: 0,
                id: 10576,
              },
            ],
          },
          id: 13110105,
          mature: false,
        },
        {
          name: "Vacuums",
          probability: 0,
          url: "https://www.bizrate.com/vacuums_accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Vacuums",
                probability: 0,
                id: 10577,
              },
            ],
          },
          id: 13110106,
          mature: false,
        },
        {
          name: "Miscellaneous Appliances",
          probability: 0,
          url: "https://www.bizrate.com/miscellaneousappliances/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Miscellaneous Appliances",
                probability: 0,
                id: 10578,
              },
            ],
          },
          id: 13110107,
          mature: false,
        },
        {
          name: "Heaters",
          probability: 0,
          url: "https://www.bizrate.com/heaters/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Heaters",
                probability: 0,
                id: 10579,
              },
            ],
          },
          id: 13110108,
          mature: false,
        },
        {
          name: "Trash Compactors",
          probability: 0,
          url: "https://www.bizrate.com/trashcompactors/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Trash Compactors",
                probability: 0,
                id: 10669,
              },
            ],
          },
          id: 18005000,
          mature: false,
        },
        {
          name: "Air Conditioners",
          probability: 0,
          url: "https://www.bizrate.com/airconditioners/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Industrial Air Conditioners",
                probability: 0,
                id: 100001366,
              },
              {
                name: "Air Conditioners",
                probability: 0,
                id: 10572,
              },
            ],
          },
          id: 13110101,
          mature: false,
        },
        {
          name: "Humidifiers",
          probability: 0,
          url: "https://www.bizrate.com/humidifiers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Humidifiers",
                probability: 0,
                id: 10573,
              },
            ],
          },
          id: 13110102,
          mature: false,
        },
        {
          name: "Electric Irons",
          probability: 0,
          url: "https://www.bizrate.com/electricirons/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Electric Irons",
                probability: 0,
                id: 10574,
              },
            ],
          },
          id: 13110103,
          mature: false,
        },
        {
          name: "Fans",
          probability: 0,
          url: "https://www.bizrate.com/fans/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Industrial Fans",
                probability: 0,
                id: 100001365,
              },
              {
                name: "Fans",
                probability: 0,
                id: 10575,
              },
            ],
          },
          id: 13110104,
          mature: false,
        },
        {
          name: "Electric Knives",
          probability: 0,
          url: "https://www.bizrate.com/electricknives/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Electric Knives",
                probability: 0,
                id: 10672,
              },
            ],
          },
          id: 18005300,
          mature: false,
        },
        {
          name: "Sandwich Makers",
          probability: 0,
          url: "https://www.bizrate.com/sandwichmakers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Sandwich Makers",
                probability: 0,
                id: 10667,
              },
            ],
          },
          id: 18004800,
          mature: false,
        },
        {
          name: "Food Slicers",
          probability: 0,
          url: "https://www.bizrate.com/foodslicers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Food Slicers",
                probability: 0,
                id: 10668,
              },
            ],
          },
          id: 18004900,
          mature: false,
        },
        {
          name: "Floor Cleaners",
          probability: 0,
          url: "https://www.bizrate.com/floor-cleaners/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Floor Cleaners",
                probability: 0,
                id: 100001540,
              },
            ],
          },
          id: 100001538,
          mature: false,
        },
        {
          name: "Snow Cone & Ice Shavers",
          probability: 0,
          url: "https://www.bizrate.com/snowcone_iceshavers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Snow Cone & Ice Shavers",
                probability: 0,
                id: 10677,
              },
            ],
          },
          id: 18005800,
          mature: false,
        },
        {
          name: "Egg Cookers",
          probability: 0,
          url: "https://www.bizrate.com/eggcookers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Egg Cookers",
                probability: 0,
                id: 10663,
              },
            ],
          },
          id: 18004400,
          mature: false,
        },
        {
          name: "Vacuum Food Sealers",
          probability: 0,
          url: "https://www.bizrate.com/vacuumfoodsealers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Vacuum Food Sealers",
                probability: 0,
                id: 10666,
              },
            ],
          },
          id: 18004700,
          mature: false,
        },
        {
          name: "Water Dispensers",
          probability: 0,
          url: "https://www.bizrate.com/waterdispensers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Water Dispensers",
                probability: 0,
                id: 10675,
              },
            ],
          },
          id: 18005600,
          mature: false,
        },
        {
          name: "Ovens",
          probability: 0,
          url: "https://www.bizrate.com/ovens/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Ovens",
                probability: 0,
                id: 10548,
              },
              {
                name: "Commercial Ovens & Ranges",
                probability: 0,
                id: 100001364,
              },
            ],
          },
          id: 13050904,
          mature: false,
        },
        {
          name: "Ranges",
          probability: 0,
          url: "https://www.bizrate.com/ranges/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Ranges",
                probability: 0,
                id: 10547,
              },
            ],
          },
          id: 13050903,
          mature: false,
        },
        {
          name: "Rotisseries",
          probability: 0,
          url: "https://www.bizrate.com/rotisseries/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Rotisseries",
                probability: 0,
                id: 10676,
              },
            ],
          },
          id: 18005700,
          mature: false,
        },
        {
          name: "Microwaves",
          probability: 0,
          url: "https://www.bizrate.com/microwaveovens/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Microwave Ovens",
                probability: 0,
                id: 10546,
              },
            ],
          },
          id: 13050902,
          mature: false,
        },
        {
          name: "Refrigerators",
          probability: 0,
          url: "https://www.bizrate.com/refrigerators/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Refrigerators",
                probability: 0,
                id: 10545,
              },
              {
                name: "Commercial Refrigerators",
                probability: 0,
                id: 100001362,
              },
              {
                name: "Food Display",
                probability: 0,
                id: 100001418,
              },
            ],
          },
          id: 13050901,
          mature: false,
        },
        {
          name: "Appliances Accessories",
          probability: 0,
          url: "https://www.bizrate.com/appliance-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          children: {
            category: [
              {
                name: "Refrigerator Accessories",
                probability: 0,
                url: "https://www.bizrate.com/refrigerator-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Refrigerator Accessories ",
                      probability: 0,
                      id: 100001245,
                    },
                  ],
                },
                id: 100001238,
                mature: false,
              },
              {
                name: "Dishwasher Accessories",
                probability: 0,
                url: "https://www.bizrate.com/dishwasher-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Dishwasher Accessories ",
                      probability: 0,
                      id: 100001247,
                    },
                  ],
                },
                id: 100001240,
                mature: false,
              },
              {
                name: "Range & Oven Accessories",
                probability: 0,
                url: "https://www.bizrate.com/range-oven-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Range & Oven Accessories ",
                      probability: 0,
                      id: 100001246,
                    },
                  ],
                },
                id: 100001239,
                mature: false,
              },
              {
                name: "Food Processor Accessories",
                probability: 0,
                url: "https://www.bizrate.com/food-processor-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Food Processor Accessories ",
                      probability: 0,
                      id: 100001249,
                    },
                  ],
                },
                id: 100001242,
                mature: false,
              },
              {
                name: "Range Hood Accessories",
                probability: 0,
                url: "https://www.bizrate.com/range-hood-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Range Hood Accessories ",
                      probability: 0,
                      id: 100001248,
                    },
                  ],
                },
                id: 100001241,
                mature: false,
              },
              {
                name: "Air Purifier Accessories",
                probability: 0,
                url: "https://www.bizrate.com/air-purifier-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Air Purifier Accessories ",
                      probability: 0,
                      id: 100001251,
                    },
                  ],
                },
                id: 100001244,
                mature: false,
              },
              {
                name: "Heater Accessories",
                probability: 0,
                url: "https://www.bizrate.com/heater-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Heater Accessories ",
                      probability: 0,
                      id: 100001250,
                    },
                  ],
                },
                id: 100001243,
                mature: false,
              },
              {
                name: "Mixer Accessories",
                probability: 0,
                url: "https://www.bizrate.com/mixer-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Mixer Accessories",
                      probability: 0,
                      id: 100001255,
                    },
                  ],
                },
                id: 100001254,
                mature: false,
              },
              {
                name: "Washer & Dryer Accessories",
                probability: 0,
                url: "https://www.bizrate.com/washer-dryer-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Washer & Dryer Accessories",
                      probability: 0,
                      id: 100001257,
                    },
                  ],
                },
                id: 100001256,
                mature: false,
              },
              {
                name: "Vacuum Accessories",
                probability: 0,
                url: "https://www.bizrate.com/vacuum-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Vacuum Accessories",
                      probability: 0,
                      id: 100001265,
                    },
                  ],
                },
                id: 100001264,
                mature: false,
              },
              {
                name: "Coffee Maker Accessories",
                probability: 0,
                url: "https://www.bizrate.com/coffeemakeraccessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Coffee Maker Accessories",
                      probability: 0,
                      id: 10530,
                    },
                  ],
                },
                id: 13050813,
                mature: false,
              },
              {
                name: "Air Conditioner Accessories",
                probability: 0,
                url: "https://www.bizrate.com/air-conditioner-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Air Conditioner Accessories",
                      probability: 0,
                      id: 100001259,
                    },
                  ],
                },
                id: 100001258,
                mature: false,
              },
              {
                name: "Humidifier Accessories",
                probability: 0,
                url: "https://www.bizrate.com/humidifier-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Humidifier Accessories",
                      probability: 0,
                      id: 100001261,
                    },
                  ],
                },
                id: 100001260,
                mature: false,
              },
            ],
          },
          id: 100001252,
          mature: false,
        },
        {
          name: "Water Filters",
          probability: 0,
          url: "https://www.bizrate.com/waterfilters/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Water Filters",
                probability: 0,
                id: 10662,
              },
            ],
          },
          id: 18004300,
          mature: false,
        },
        {
          name: "Freezers",
          probability: 0,
          url: "https://www.bizrate.com/freezers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Freezers",
                probability: 0,
                id: 10551,
              },
              {
                name: "Commercial Freezers",
                probability: 0,
                id: 100001363,
              },
            ],
          },
          id: 13050909,
          mature: false,
        },
        {
          name: "Washers & Dryers",
          probability: 0,
          url: "https://www.bizrate.com/washers_dryers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Washers & Dryers",
                probability: 0,
                id: 10550,
              },
            ],
          },
          id: 13050907,
          mature: false,
        },
        {
          name: "Warming Drawers",
          probability: 0,
          url: "https://www.bizrate.com/warming-drawers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Commercial Food Storage",
                probability: 0,
                id: 100001464,
              },
              {
                name: "Warming Drawers",
                probability: 0,
                id: 10671,
              },
            ],
          },
          id: 18005200,
          mature: false,
        },
        {
          name: "Dishwashers",
          probability: 0,
          url: "https://www.bizrate.com/dishwashers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Dishwashers",
                probability: 0,
                id: 10549,
              },
              {
                name: "Commercial Dishwashers",
                probability: 0,
                id: 100001379,
              },
            ],
          },
          id: 13050906,
          mature: false,
        },
      ],
    },
    id: 18000000,
    mature: false,
  },
  {
    name: "Other Products",
    probability: 0,
    url: "https://www.bizrate.com/otherproducts/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
    children: {
      category: [
        {
          name: "Miscellaneous",
          probability: 0,
          url: "https://www.bizrate.com/miscellaneous/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Internal Miscellaneous",
                probability: 0,
                id: 100001478,
              },
              {
                name: "Miscellaneous",
                probability: 0,
                id: 10678,
              },
            ],
          },
          id: 20000001,
          mature: false,
        },
      ],
    },
    id: 20000000,
    mature: false,
  },
  {
    name: "Books & Magazines",
    probability: 0,
    url: "https://www.bizrate.com/books_magazines/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
    children: {
      category: [
        {
          name: "Nature Books",
          probability: 0,
          url: "https://www.bizrate.com/naturebooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Nature Books",
                probability: 0,
                id: 10137,
              },
            ],
          },
          id: 8033,
          mature: false,
        },
        {
          name: "Body, Mind & Spirit Books",
          probability: 0,
          url: "https://www.bizrate.com/bodymind_spiritbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Body, Mind & Spirit Books",
                probability: 0,
                id: 10138,
              },
            ],
          },
          id: 8034,
          mature: false,
        },
        {
          name: "Performing Arts Books",
          probability: 0,
          url: "https://www.bizrate.com/performingartsbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Performing Arts Books",
                probability: 0,
                id: 10139,
              },
            ],
          },
          id: 8035,
          mature: false,
        },
        {
          name: "Pets Books",
          probability: 0,
          url: "https://www.bizrate.com/petsbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Pets Books",
                probability: 0,
                id: 10140,
              },
            ],
          },
          id: 8036,
          mature: false,
        },
        {
          name: "Philosophy Books",
          probability: 0,
          url: "https://www.bizrate.com/philosophybooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Philosophy Books",
                probability: 0,
                id: 10141,
              },
            ],
          },
          id: 8037,
          mature: false,
        },
        {
          name: "Photography Books",
          probability: 0,
          url: "https://www.bizrate.com/photographybooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Photography Books",
                probability: 0,
                id: 10142,
              },
            ],
          },
          id: 8038,
          mature: false,
        },
        {
          name: "Poetry Books",
          probability: 0,
          url: "https://www.bizrate.com/poetrybooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Poetry Books",
                probability: 0,
                id: 10143,
              },
            ],
          },
          id: 8039,
          mature: false,
        },
        {
          name: "Political Science Books",
          probability: 0,
          url: "https://www.bizrate.com/politicalsciencebooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Political Science Books",
                probability: 0,
                id: 10144,
              },
            ],
          },
          id: 8040,
          mature: false,
        },
        {
          name: "Psychology Books",
          probability: 0,
          url: "https://www.bizrate.com/psychologybooks1/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Psychology Books",
                probability: 0,
                id: 10145,
              },
            ],
          },
          id: 8041,
          mature: false,
        },
        {
          name: "Reference Books",
          probability: 0,
          url: "https://www.bizrate.com/referencebooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Reference Books",
                probability: 0,
                id: 10146,
              },
            ],
          },
          id: 8042,
          mature: false,
        },
        {
          name: "Religion Books",
          probability: 0,
          url: "https://www.bizrate.com/religionbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Religion Books",
                probability: 0,
                id: 10147,
              },
            ],
          },
          id: 8043,
          mature: false,
        },
        {
          name: "Science Books",
          probability: 0,
          url: "https://www.bizrate.com/sciencebooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Science Books",
                probability: 0,
                id: 10148,
              },
            ],
          },
          id: 8044,
          mature: false,
        },
        {
          name: "Self Help Books",
          probability: 0,
          url: "https://www.bizrate.com/selfhelpbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Self Help Books",
                probability: 0,
                id: 10149,
              },
            ],
          },
          id: 8045,
          mature: false,
        },
        {
          name: "Social Science Books",
          probability: 0,
          url: "https://www.bizrate.com/socialsciencebooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Social Science Books",
                probability: 0,
                id: 10150,
              },
            ],
          },
          id: 8046,
          mature: false,
        },
        {
          name: "Sports & Recreation Books",
          probability: 0,
          url: "https://www.bizrate.com/sports_recreationbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Sports & Recreation Books",
                probability: 0,
                id: 10151,
              },
            ],
          },
          id: 8047,
          mature: false,
        },
        {
          name: "Study Aids Books",
          probability: 0,
          url: "https://www.bizrate.com/studyaidsbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Study Aids Books",
                probability: 0,
                id: 10152,
              },
            ],
          },
          id: 8048,
          mature: false,
        },
        {
          name: "Technology Books",
          probability: 0,
          url: "https://www.bizrate.com/technologybooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Technology Books",
                probability: 0,
                id: 10153,
              },
            ],
          },
          id: 8049,
          mature: false,
        },
        {
          name: "Transportation Books",
          probability: 0,
          url: "https://www.bizrate.com/transportationbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Transportation Books",
                probability: 0,
                id: 10154,
              },
            ],
          },
          id: 8050,
          mature: false,
        },
        {
          name: "True Crime Books",
          probability: 0,
          url: "https://www.bizrate.com/truecrimebooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "True Crime Books",
                probability: 0,
                id: 10155,
              },
            ],
          },
          id: 8051,
          mature: false,
        },
        {
          name: "Travel Books",
          probability: 0,
          url: "https://www.bizrate.com/travelbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Travel Books",
                probability: 0,
                id: 10156,
              },
            ],
          },
          id: 8052,
          mature: false,
        },
        {
          name: "Music Books",
          probability: 0,
          url: "https://www.bizrate.com/musicbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Music Books",
                probability: 0,
                id: 10157,
              },
            ],
          },
          id: 8053,
          mature: false,
        },
        {
          name: "Current Events Books",
          probability: 0,
          url: "https://www.bizrate.com/currenteventsbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Current Events Books",
                probability: 0,
                id: 10158,
              },
            ],
          },
          id: 8054,
          mature: false,
        },
        {
          name: "Children's Non-fiction Books",
          probability: 0,
          url: "https://www.bizrate.com/childrensnonfictionbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Children's Non-fiction Books",
                probability: 0,
                id: 10159,
              },
            ],
          },
          id: 8055,
          mature: false,
        },
        {
          name: "Thrillers Books",
          probability: 0,
          url: "https://www.bizrate.com/thrillersbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Thrillers Books",
                probability: 0,
                id: 10160,
              },
            ],
          },
          id: 8056,
          mature: false,
        },
        {
          name: "Horror Books",
          probability: 0,
          url: "https://www.bizrate.com/horrorbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Horror Books",
                probability: 0,
                id: 10161,
              },
            ],
          },
          id: 8057,
          mature: false,
        },
        {
          name: "Mystery & Crime Books",
          probability: 0,
          url: "https://www.bizrate.com/mystery_crimebooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Mystery & Crime Books",
                probability: 0,
                id: 10162,
              },
            ],
          },
          id: 8058,
          mature: false,
        },
        {
          name: "Romance Books",
          probability: 0,
          url: "https://www.bizrate.com/romancebooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Romance Books",
                probability: 0,
                id: 10163,
              },
            ],
          },
          id: 8059,
          mature: false,
        },
        {
          name: "Science Fiction & Fantasy Books",
          probability: 0,
          url: "https://www.bizrate.com/sciencefiction_fantasybooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Science Fiction & Fantasy Books",
                probability: 0,
                id: 10164,
              },
            ],
          },
          id: 8060,
          mature: false,
        },
        {
          name: "Westerns Books",
          probability: 0,
          url: "https://www.bizrate.com/westernsbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Westerns Books",
                probability: 0,
                id: 10165,
              },
            ],
          },
          id: 8061,
          mature: false,
        },
        {
          name: "Arts & Entertainment Books",
          probability: 0,
          url: "https://www.bizrate.com/arts_entertainmentbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Arts & Entertainment Books",
                probability: 0,
                id: 10166,
              },
            ],
          },
          id: 8062,
          mature: false,
        },
        {
          name: "Conflicts & Dualities Books",
          probability: 0,
          url: "https://www.bizrate.com/conflicts_dualitiesbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Conflicts & Dualities Books",
                probability: 0,
                id: 10167,
              },
            ],
          },
          id: 8063,
          mature: false,
        },
        {
          name: "Disasters Books",
          probability: 0,
          url: "https://www.bizrate.com/disastersbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Disasters Books",
                probability: 0,
                id: 10168,
              },
            ],
          },
          id: 8064,
          mature: false,
        },
        {
          name: "Educational Fiction Books",
          probability: 0,
          url: "https://www.bizrate.com/educationalfictionbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Educational Fiction Books",
                probability: 0,
                id: 10169,
              },
            ],
          },
          id: 8065,
          mature: false,
        },
        {
          name: "Literature Books",
          probability: 0,
          url: "https://www.bizrate.com/literaturebooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Literature Books",
                probability: 0,
                id: 10170,
              },
            ],
          },
          id: 8066,
          mature: false,
        },
        {
          name: "Erotica Books",
          probability: 0,
          url: "https://www.bizrate.com/eroticabooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Erotica Books",
                probability: 0,
                id: 10171,
              },
            ],
          },
          id: 8067,
          mature: true,
        },
        {
          name: "Family & Friendship Books",
          probability: 0,
          url: "https://www.bizrate.com/family_friendshipbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Family & Friendship Books",
                probability: 0,
                id: 10172,
              },
            ],
          },
          id: 8068,
          mature: false,
        },
        {
          name: "Settings Books",
          probability: 0,
          url: "https://www.bizrate.com/settingsbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Settings Books",
                probability: 0,
                id: 10173,
              },
            ],
          },
          id: 8069,
          mature: false,
        },
        {
          name: "Historical Fiction Books",
          probability: 0,
          url: "https://www.bizrate.com/historicalfictionbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Historical Fiction Books",
                probability: 0,
                id: 10174,
              },
            ],
          },
          id: 8070,
          mature: false,
        },
        {
          name: "Holocaust Books",
          probability: 0,
          url: "https://www.bizrate.com/holocaustbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Holocaust Books",
                probability: 0,
                id: 10175,
              },
            ],
          },
          id: 8071,
          mature: false,
        },
        {
          name: "Human Qualities & Behavior Books",
          probability: 0,
          url: "https://www.bizrate.com/humanqualities_behaviorbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Human Qualities & Behavior Books",
                probability: 0,
                id: 10176,
              },
            ],
          },
          id: 8072,
          mature: false,
        },
        {
          name: "Character Studies Books",
          probability: 0,
          url: "https://www.bizrate.com/characterstudiesbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Character Studies Books",
                probability: 0,
                id: 10177,
              },
            ],
          },
          id: 8073,
          mature: false,
        },
        {
          name: "Humorous Fiction Books",
          probability: 0,
          url: "https://www.bizrate.com/humorousfictionbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Humorous Fiction Books",
                probability: 0,
                id: 10178,
              },
            ],
          },
          id: 8074,
          mature: false,
        },
        {
          name: "Legal & Courtroom Books",
          probability: 0,
          url: "https://www.bizrate.com/legal_courtroombooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Legal & Courtroom Books",
                probability: 0,
                id: 10179,
              },
            ],
          },
          id: 8075,
          mature: false,
        },
        {
          name: "Religions & Spirituality Books",
          probability: 0,
          url: "https://www.bizrate.com/religions_spiritualitybooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Religions & Spirituality Books",
                probability: 0,
                id: 10180,
              },
            ],
          },
          id: 8076,
          mature: false,
        },
        {
          name: "Love, Relationships & Sex Books",
          probability: 0,
          url: "https://www.bizrate.com/loverelationships_sexbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Love, Relationships & Sex Books",
                probability: 0,
                id: 10181,
              },
            ],
          },
          id: 8077,
          mature: false,
        },
        {
          name: "Health Care & Medical Books",
          probability: 0,
          url: "https://www.bizrate.com/healthcare_medicalbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Health Care & Medical Books",
                probability: 0,
                id: 10182,
              },
            ],
          },
          id: 8078,
          mature: false,
        },
        {
          name: "Money & Finance Books",
          probability: 0,
          url: "https://www.bizrate.com/money_financebooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Money & Finance Books",
                probability: 0,
                id: 10184,
              },
            ],
          },
          id: 8080,
          mature: false,
        },
        {
          name: "Animal & Nature Books",
          probability: 0,
          url: "https://www.bizrate.com/animal_naturebooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Animal & Nature Books",
                probability: 0,
                id: 10185,
              },
            ],
          },
          id: 8081,
          mature: false,
        },
        {
          name: "Philosophical Fiction Books",
          probability: 0,
          url: "https://www.bizrate.com/philosophicalfictionbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Philosophical Fiction Books",
                probability: 0,
                id: 10186,
              },
            ],
          },
          id: 8082,
          mature: false,
        },
        {
          name: "Peoples & Cultures Books",
          probability: 0,
          url: "https://www.bizrate.com/peoples_culturesbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Peoples & Cultures Books",
                probability: 0,
                id: 10187,
              },
            ],
          },
          id: 8083,
          mature: false,
        },
        {
          name: "Politics Books",
          probability: 0,
          url: "https://www.bizrate.com/politicsbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Politics Books",
                probability: 0,
                id: 10188,
              },
            ],
          },
          id: 8084,
          mature: false,
        },
        {
          name: "Society & Social Issues Books",
          probability: 0,
          url: "https://www.bizrate.com/society_socialissuesbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Society & Social Issues Books",
                probability: 0,
                id: 10189,
              },
            ],
          },
          id: 8085,
          mature: false,
        },
        {
          name: "Science & Technology Books",
          probability: 0,
          url: "https://www.bizrate.com/science_technologybooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Science & Technology Books",
                probability: 0,
                id: 10190,
              },
            ],
          },
          id: 8086,
          mature: false,
        },
        {
          name: "Hobbies & Activities Books",
          probability: 0,
          url: "https://www.bizrate.com/hobbies_activitiesbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Hobbies & Activities Books",
                probability: 0,
                id: 10191,
              },
            ],
          },
          id: 8087,
          mature: false,
        },
        {
          name: "Sports & Games Books",
          probability: 0,
          url: "https://www.bizrate.com/sports_gamesbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Sports & Games Books",
                probability: 0,
                id: 10192,
              },
            ],
          },
          id: 8088,
          mature: false,
        },
        {
          name: "Stages of Life Books",
          probability: 0,
          url: "https://www.bizrate.com/stagesoflifebooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Stages of Life Books",
                probability: 0,
                id: 10193,
              },
            ],
          },
          id: 8089,
          mature: false,
        },
        {
          name: "Travel Fiction Books",
          probability: 0,
          url: "https://www.bizrate.com/travelfictionbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Travel Fiction Books",
                probability: 0,
                id: 10194,
              },
            ],
          },
          id: 8090,
          mature: false,
        },
        {
          name: "Military Fiction Books",
          probability: 0,
          url: "https://www.bizrate.com/militaryfictionbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Military Fiction Books",
                probability: 0,
                id: 10195,
              },
            ],
          },
          id: 8091,
          mature: false,
        },
        {
          name: "Work & the Workplace Books",
          probability: 0,
          url: "https://www.bizrate.com/work_theworkplacebooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Work & the Workplace Books",
                probability: 0,
                id: 10196,
              },
            ],
          },
          id: 8092,
          mature: false,
        },
        {
          name: "Holidays Books",
          probability: 0,
          url: "https://www.bizrate.com/holidaysbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Holidays Books",
                probability: 0,
                id: 10197,
              },
            ],
          },
          id: 8093,
          mature: false,
        },
        {
          name: "Historical Personages Books",
          probability: 0,
          url: "https://www.bizrate.com/historicalpersonagesbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Historical Personages Books",
                probability: 0,
                id: 10198,
              },
            ],
          },
          id: 8094,
          mature: false,
        },
        {
          name: "Miscellaneous Fiction Books",
          probability: 0,
          url: "https://www.bizrate.com/miscellaneousfictionbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Miscellaneous Fiction Books",
                probability: 0,
                id: 10199,
              },
            ],
          },
          id: 8095,
          mature: false,
        },
        {
          name: "Miscellaneous Non-Fiction Books",
          probability: 0,
          url: "https://www.bizrate.com/miscellaneousnonfictionbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Miscellaneous Non-Fiction Books",
                probability: 0,
                id: 10200,
              },
            ],
          },
          id: 8096,
          mature: false,
        },
        {
          name: "Miscellaneous Books",
          probability: 0,
          url: "https://www.bizrate.com/miscellaneousbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Miscellaneous Books",
                probability: 0,
                id: 10201,
              },
            ],
          },
          id: 8098,
          mature: false,
        },
        {
          name: "Comic Books",
          probability: 0,
          url: "https://www.bizrate.com/comicbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Comic Books",
                probability: 0,
                id: 10202,
              },
            ],
          },
          id: 8099,
          mature: false,
        },
        {
          name: "Magazines & Newspapers",
          probability: 0,
          url: "https://www.bizrate.com/magazines_newspapers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Magazines & Newspapers",
                probability: 0,
                id: 10112,
              },
            ],
          },
          id: 8005,
          mature: false,
        },
        {
          name: "Antiques & Collectibles Books",
          probability: 0,
          url: "https://www.bizrate.com/antiques_collectiblesbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Antiques & Collectibles Books",
                probability: 0,
                id: 10113,
              },
            ],
          },
          id: 8009,
          mature: false,
        },
        {
          name: "Architecture Books",
          probability: 0,
          url: "https://www.bizrate.com/architecturebooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Architecture Books",
                probability: 0,
                id: 10114,
              },
            ],
          },
          id: 8010,
          mature: false,
        },
        {
          name: "Art Books",
          probability: 0,
          url: "https://www.bizrate.com/artbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Art Books",
                probability: 0,
                id: 10115,
              },
            ],
          },
          id: 8011,
          mature: false,
        },
        {
          name: "Biography Books",
          probability: 0,
          url: "https://www.bizrate.com/biographybooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Biography Books",
                probability: 0,
                id: 10116,
              },
            ],
          },
          id: 8012,
          mature: false,
        },
        {
          name: "Business & Economics Books",
          probability: 0,
          url: "https://www.bizrate.com/business_economicsbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Business & Economics Books",
                probability: 0,
                id: 10117,
              },
            ],
          },
          id: 8013,
          mature: false,
        },
        {
          name: "Cooking Books",
          probability: 0,
          url: "https://www.bizrate.com/cookingbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Cooking Books",
                probability: 0,
                id: 10118,
              },
            ],
          },
          id: 8014,
          mature: false,
        },
        {
          name: "Computer Books",
          probability: 0,
          url: "https://www.bizrate.com/computerbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Computer Books",
                probability: 0,
                id: 10119,
              },
            ],
          },
          id: 8015,
          mature: false,
        },
        {
          name: "Crafts & Hobbies Books",
          probability: 0,
          url: "https://www.bizrate.com/crafts_hobbiesbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Crafts & Hobbies Books",
                probability: 0,
                id: 10120,
              },
            ],
          },
          id: 8016,
          mature: false,
        },
        {
          name: "Drama Books",
          probability: 0,
          url: "https://www.bizrate.com/dramabooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Drama Books",
                probability: 0,
                id: 10121,
              },
            ],
          },
          id: 8017,
          mature: false,
        },
        {
          name: "Education Books",
          probability: 0,
          url: "https://www.bizrate.com/educationbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Education Books",
                probability: 0,
                id: 10122,
              },
            ],
          },
          id: 8018,
          mature: false,
        },
        {
          name: "Family & Relationships Books",
          probability: 0,
          url: "https://www.bizrate.com/family_relationshipsbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Family & Relationships Books",
                probability: 0,
                id: 10123,
              },
            ],
          },
          id: 8019,
          mature: false,
        },
        {
          name: "Foreign Language Study Books",
          probability: 0,
          url: "https://www.bizrate.com/foreignlanguagestudybooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Foreign Language Study Books",
                probability: 0,
                id: 10124,
              },
            ],
          },
          id: 8020,
          mature: false,
        },
        {
          name: "Games Books",
          probability: 0,
          url: "https://www.bizrate.com/gamesbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Games Books",
                probability: 0,
                id: 10125,
              },
            ],
          },
          id: 8021,
          mature: false,
        },
        {
          name: "Gardening Books",
          probability: 0,
          url: "https://www.bizrate.com/gardeningbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Gardening Books",
                probability: 0,
                id: 10126,
              },
            ],
          },
          id: 8022,
          mature: false,
        },
        {
          name: "Health & Fitness Books",
          probability: 0,
          url: "https://www.bizrate.com/health_fitnessbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Health & Fitness Books",
                probability: 0,
                id: 10127,
              },
            ],
          },
          id: 8023,
          mature: false,
        },
        {
          name: "History Books",
          probability: 0,
          url: "https://www.bizrate.com/historybooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "History Books",
                probability: 0,
                id: 10128,
              },
            ],
          },
          id: 8024,
          mature: false,
        },
        {
          name: "House & Home Books",
          probability: 0,
          url: "https://www.bizrate.com/house_homebooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "House & Home Books",
                probability: 0,
                id: 10129,
              },
            ],
          },
          id: 8025,
          mature: false,
        },
        {
          name: "Humor Books",
          probability: 0,
          url: "https://www.bizrate.com/humorbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Humor Books",
                probability: 0,
                id: 10130,
              },
            ],
          },
          id: 8026,
          mature: false,
        },
        {
          name: "Children's Fiction Books",
          probability: 0,
          url: "https://www.bizrate.com/childrensfictionbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Children's Fiction Books",
                probability: 0,
                id: 10131,
              },
            ],
          },
          id: 8027,
          mature: false,
        },
        {
          name: "Language Arts Books",
          probability: 0,
          url: "https://www.bizrate.com/languageartsbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Language Arts Books",
                probability: 0,
                id: 10132,
              },
            ],
          },
          id: 8028,
          mature: false,
        },
        {
          name: "Law Books",
          probability: 0,
          url: "https://www.bizrate.com/lawbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Law Books",
                probability: 0,
                id: 10133,
              },
            ],
          },
          id: 8029,
          mature: false,
        },
        {
          name: "Literary Criticism Books",
          probability: 0,
          url: "https://www.bizrate.com/literarycriticismbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Literary Criticism Books",
                probability: 0,
                id: 10134,
              },
            ],
          },
          id: 8030,
          mature: false,
        },
        {
          name: "Mathematics Books",
          probability: 0,
          url: "https://www.bizrate.com/mathematicsbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Mathematics Books",
                probability: 0,
                id: 10135,
              },
            ],
          },
          id: 8031,
          mature: false,
        },
        {
          name: "Medical Books",
          probability: 0,
          url: "https://www.bizrate.com/medicalbooks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Medical Books",
                probability: 0,
                id: 10136,
              },
            ],
          },
          id: 8032,
          mature: false,
        },
      ],
    },
    id: 80,
    mature: false,
  },
  {
    name: "Automotive",
    probability: 0,
    url: "https://www.bizrate.com/automotive/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
    children: {
      category: [
        {
          name: "Automotive Accessories",
          probability: 0,
          url: "https://www.bizrate.com/automotiveaccessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Automotive Accessories",
                probability: 0,
                id: 10683,
              },
            ],
          },
          id: 22000400,
          mature: false,
        },
        {
          name: "Automotive Tires",
          probability: 0,
          url: "https://www.bizrate.com/automotivetires/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Automotive Tires",
                probability: 0,
                id: 10686,
              },
            ],
          },
          id: 22000700,
          mature: false,
        },
        {
          name: "Motorcycle Parts",
          probability: 0,
          url: "https://www.bizrate.com/motorcycle-parts/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Motorcycle Parts",
                probability: 0,
                id: 9980,
              },
            ],
          },
          id: 9976,
          mature: false,
        },
        {
          name: "Automotive Parts",
          probability: 0,
          url: "https://www.bizrate.com/automotiveparts/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          children: {
            category: [
              {
                name: "Transmission & Drivetrain",
                probability: 0,
                url: "https://www.bizrate.com/transmission-drivetrain/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Clutches & Flywheels",
                      probability: 0,
                      id: 100003314,
                    },
                    {
                      name: "LSD/Differentials",
                      probability: 0,
                      id: 100003322,
                    },
                    {
                      name: "Shifters",
                      probability: 0,
                      id: 100003321,
                    },
                    {
                      name: "Transmission & Drivetrain Accessories",
                      probability: 0,
                      id: 100003323,
                    },
                  ],
                },
                id: 100003368,
                mature: false,
              },
              {
                name: "Vehicle Lighting",
                probability: 0,
                url: "https://www.bizrate.com/vehicle-lighting/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Tail Lights",
                      probability: 0,
                      id: 100003318,
                    },
                    {
                      name: "Headlights",
                      probability: 0,
                      id: 100003317,
                    },
                    {
                      name: "Foglights",
                      probability: 0,
                      id: 100003429,
                    },
                    {
                      name: "Bulbs",
                      probability: 0,
                      id: 100003320,
                    },
                    {
                      name: "Lenses",
                      probability: 0,
                      id: 100003319,
                    },
                    {
                      name: "Vehicle Lighting Accessories",
                      probability: 0,
                      id: 100003316,
                    },
                  ],
                },
                id: 100003315,
                mature: false,
              },
              {
                name: "Engine",
                probability: 0,
                url: "https://www.bizrate.com/engine/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Engine Dress-Up",
                      probability: 0,
                      id: 100003365,
                    },
                    {
                      name: "Climate Control",
                      probability: 0,
                      id: 100003346,
                    },
                    {
                      name: "Cooling",
                      probability: 0,
                      id: 100003345,
                    },
                    {
                      name: "Engine Parts",
                      probability: 0,
                      id: 100003347,
                    },
                    {
                      name: "Fuel System",
                      probability: 0,
                      id: 100003342,
                    },
                    {
                      name: "Car Batteries",
                      probability: 0,
                      id: 100003344,
                    },
                    {
                      name: "Engine Accessories",
                      probability: 0,
                      id: 100003343,
                    },
                  ],
                },
                id: 100003374,
                mature: false,
              },
              {
                name: "Exhaust",
                probability: 0,
                url: "https://www.bizrate.com/exhaust/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Mufflers & Resonators",
                      probability: 0,
                      id: 100003336,
                    },
                    {
                      name: "Catalytic Converters",
                      probability: 0,
                      id: 100003341,
                    },
                    {
                      name: "Downpipes & Test Pipes",
                      probability: 0,
                      id: 100003338,
                    },
                    {
                      name: "Exhaust Systems",
                      probability: 0,
                      id: 100003337,
                    },
                    {
                      name: "Headers & Manifolds",
                      probability: 0,
                      id: 100003340,
                    },
                    {
                      name: "Exhaust Accessories",
                      probability: 0,
                      id: 100003339,
                    },
                  ],
                },
                id: 100003373,
                mature: false,
              },
              {
                name: "Air Intakes",
                probability: 0,
                url: "https://www.bizrate.com/air-intakes/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Air Intake Accessories",
                      probability: 0,
                      id: 100003358,
                    },
                    {
                      name: "Car Air Filters",
                      probability: 0,
                      id: 100003357,
                    },
                    {
                      name: "Short Ram Intakes",
                      probability: 0,
                      id: 100003356,
                    },
                    {
                      name: "Cold Air Intakes",
                      probability: 0,
                      id: 100003355,
                    },
                  ],
                },
                id: 100003376,
                mature: false,
              },
              {
                name: "Brakes",
                probability: 0,
                url: "https://www.bizrate.com/brakes/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Calipers",
                      probability: 0,
                      id: 100003352,
                    },
                    {
                      name: "Rotors & Drums",
                      probability: 0,
                      id: 100003351,
                    },
                    {
                      name: "Brake Accessories",
                      probability: 0,
                      id: 100003354,
                    },
                    {
                      name: "Brake Pads",
                      probability: 0,
                      id: 100003353,
                    },
                  ],
                },
                id: 100003375,
                mature: false,
              },
              {
                name: "Interior Accessories",
                probability: 0,
                url: "https://www.bizrate.com/interior-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Floor Mats",
                      probability: 0,
                      id: 100003360,
                    },
                    {
                      name: "Safety Equipment",
                      probability: 0,
                      id: 100003328,
                    },
                    {
                      name: "Interior Styling",
                      probability: 0,
                      id: 100003359,
                    },
                    {
                      name: "Seats",
                      probability: 0,
                      id: 100003327,
                    },
                  ],
                },
                id: 100003370,
                mature: false,
              },
              {
                name: "Suspension & Handling",
                probability: 0,
                url: "https://www.bizrate.com/suspension-handling/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Pillow Ball Suspension Arms",
                      probability: 0,
                      id: 100003326,
                    },
                    {
                      name: "Handling",
                      probability: 0,
                      id: 100003325,
                    },
                    {
                      name: "Suspension",
                      probability: 0,
                      id: 100003324,
                    },
                  ],
                },
                id: 100003369,
                mature: false,
              },
              {
                name: "Exterior Accessories",
                probability: 0,
                url: "https://www.bizrate.com/exterior-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Body Kits",
                      probability: 0,
                      id: 100003335,
                    },
                    {
                      name: "Wipers",
                      probability: 0,
                      id: 100003362,
                    },
                    {
                      name: "Dress-Up & Others",
                      probability: 0,
                      id: 100003361,
                    },
                    {
                      name: "Truck & SUV",
                      probability: 0,
                      id: 100003364,
                    },
                    {
                      name: "Car Covers",
                      probability: 0,
                      id: 100003363,
                    },
                  ],
                },
                id: 100003372,
                mature: false,
              },
              {
                name: "Forced Induction",
                probability: 0,
                url: "https://www.bizrate.com/forced-induction/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Intercoolers",
                      probability: 0,
                      id: 100003334,
                    },
                    {
                      name: "Nitrous",
                      probability: 0,
                      id: 100003333,
                    },
                    {
                      name: "Blow Off Valves",
                      probability: 0,
                      id: 100003330,
                    },
                    {
                      name: "Forced Induction Accessories",
                      probability: 0,
                      id: 100003329,
                    },
                    {
                      name: "Superchargers",
                      probability: 0,
                      id: 100003332,
                    },
                    {
                      name: "Turbos",
                      probability: 0,
                      id: 100003331,
                    },
                  ],
                },
                id: 100003371,
                mature: false,
              },
            ],
          },
          atoms: {
            atom: [
              {
                name: "Automotive Parts",
                probability: 0,
                id: 10681,
              },
            ],
          },
          id: 22000200,
          mature: false,
        },
        {
          name: "Miscellaneous Automotive",
          probability: 0,
          url: "https://www.bizrate.com/miscellaneousautomotive/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Miscellaneous Automotive",
                probability: 0,
                id: 10685,
              },
            ],
          },
          id: 22000600,
          mature: false,
        },
        {
          name: "Automotive Care",
          probability: 0,
          url: "https://www.bizrate.com/automotivecare/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Automotive Care",
                probability: 0,
                id: 10680,
              },
            ],
          },
          id: 22000100,
          mature: false,
        },
        {
          name: "Automotive Repair Tools",
          probability: 0,
          url: "https://www.bizrate.com/automotiverepairtools/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Automotive Repair Tools",
                probability: 0,
                id: 10684,
              },
            ],
          },
          id: 22000500,
          mature: false,
        },
      ],
    },
    id: 22000000,
    mature: false,
  },
  {
    name: "Health & Beauty Supplies",
    probability: 0,
    url: "https://www.bizrate.com/health_beautysupplies/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
    children: {
      category: [
        {
          name: "Medicine & Remedies",
          probability: 0,
          url: "https://www.bizrate.com/medicine_remedies/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Feminine Hygiene",
                probability: 0,
                id: 100001734,
              },
              {
                name: "Home Diagnostic Tests",
                probability: 0,
                id: 100001733,
              },
              {
                name: "Therapy Lights",
                probability: 0,
                id: 100001736,
              },
              {
                name: "Sound Therapy Machines",
                probability: 0,
                id: 100001735,
              },
              {
                name: "Medicine & Remedies",
                probability: 0,
                id: 10216,
              },
              {
                name: "Cotton Balls & Swabs",
                probability: 0,
                id: 100001738,
              },
              {
                name: "Nebulizers & Inhalers",
                probability: 0,
                id: 100001737,
              },
              {
                name: "Incontinence Supplies",
                probability: 0,
                id: 100001739,
              },
            ],
          },
          id: 22001,
          mature: false,
        },
        {
          name: "Vision Care",
          probability: 0,
          url: "https://www.bizrate.com/visioncare/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Vision Care",
                probability: 0,
                id: 10005,
              },
            ],
          },
          id: 145,
          mature: false,
        },
        {
          name: "Lab Supplies & Equipment",
          probability: 0,
          url: "https://www.bizrate.com/lab-supplies-equipment/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Lab Supplies & Equipment",
                probability: 0,
                id: 100001228,
              },
            ],
          },
          id: 100001221,
          mature: false,
        },
        {
          name: "Medical & Orthopedic Supplies",
          probability: 0,
          url: "https://www.bizrate.com/accessibilityequipment/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Medical & Orthopedic Supplies",
                probability: 0,
                id: 10218,
              },
            ],
          },
          id: 22005,
          mature: false,
        },
        {
          name: "Biometric Monitors",
          probability: 0,
          url: "https://www.bizrate.com/biometricmonitors/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Glucose Monitors & Tests",
                probability: 0,
                id: 100001717,
              },
              {
                name: "Fever Thermometers",
                probability: 0,
                id: 100001714,
              },
              {
                name: "Fitness Monitors",
                probability: 0,
                id: 100001713,
              },
              {
                name: "Blood Pressure Monitors",
                probability: 0,
                id: 100001716,
              },
              {
                name: "Personal Scales",
                probability: 0,
                id: 100001715,
              },
            ],
          },
          id: 22006,
          mature: false,
        },
        {
          name: "Vitamins & Nutrition",
          probability: 0,
          url: "https://www.bizrate.com/vitamins_nutrition/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Vitamins & Nutrition",
                probability: 0,
                id: 10006,
              },
            ],
          },
          id: 150,
          mature: false,
        },
        {
          name: "Dental Care",
          probability: 0,
          url: "https://www.bizrate.com/dentalcare/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          children: {
            category: [
              {
                name: "Dental Supplies",
                probability: 0,
                url: "https://www.bizrate.com/dental-supplies/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Dental Supplies",
                      probability: 0,
                      id: 265,
                    },
                  ],
                },
                id: 376,
                mature: false,
              },
              {
                name: "Dental Appliances",
                probability: 0,
                url: "https://www.bizrate.com/dental-appliances/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                children: {
                  category: [
                    {
                      name: "Electric Toothbrushes",
                      probability: 0,
                      url: "https://www.bizrate.com/electric-toothbrushes/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Electric Toothbrushes",
                            probability: 0,
                            id: 242,
                          },
                        ],
                      },
                      id: 241,
                      mature: false,
                    },
                    {
                      name: "Oral Irrigators",
                      probability: 0,
                      url: "https://www.bizrate.com/oral-irrigators/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Oral Irrigators",
                            probability: 0,
                            id: 244,
                          },
                        ],
                      },
                      id: 243,
                      mature: false,
                    },
                    {
                      name: "Dental Appliance Accessories",
                      probability: 0,
                      url: "https://www.bizrate.com/dental-appliance-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Dental Appliance Accessories",
                            probability: 0,
                            id: 10220,
                          },
                        ],
                      },
                      id: 22007,
                      mature: false,
                    },
                  ],
                },
                id: 250,
                mature: false,
              },
            ],
          },
          id: 22008,
          mature: false,
        },
        {
          name: "Perfume & Cologne",
          probability: 0,
          url: "https://www.bizrate.com/perfumeandcologne/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Perfume & Cologne",
                probability: 0,
                id: 10001,
              },
            ],
          },
          id: 88,
          mature: false,
        },
        {
          name: "Massagers",
          probability: 0,
          url: "https://www.bizrate.com/massagers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Massagers",
                probability: 0,
                id: 10222,
              },
            ],
          },
          id: 22009,
          mature: false,
        },
        {
          name: "Shaving Appliances",
          probability: 0,
          url: "https://www.bizrate.com/shavingappliances/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Shaving Appliances",
                probability: 0,
                id: 10223,
              },
            ],
          },
          id: 22010,
          mature: false,
        },
        {
          name: "Hair Care",
          probability: 0,
          url: "https://www.bizrate.com/haircare/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Hair Accessories",
                probability: 0,
                id: 100000518,
              },
              {
                name: "Hair Loss Treatment",
                probability: 0,
                id: 100000545,
              },
              {
                name: "Hair Shears",
                probability: 0,
                id: 100000516,
              },
              {
                name: "Wigs & Hairpieces",
                probability: 0,
                id: 100000515,
              },
              {
                name: "Professional Salon & Spa Equipment",
                probability: 0,
                id: 100001443,
              },
              {
                name: "Hair Treatment",
                probability: 0,
                id: 100000510,
              },
              {
                name: "Perms",
                probability: 0,
                id: 100000526,
              },
              {
                name: "Hair Conditioner",
                probability: 0,
                id: 100000509,
              },
              {
                name: "Hair Relaxers",
                probability: 0,
                id: 100000525,
              },
              {
                name: "Hair Styling Products",
                probability: 0,
                id: 100000512,
              },
              {
                name: "Hair Color",
                probability: 0,
                id: 100000511,
              },
              {
                name: "Hair Shampoo",
                probability: 0,
                id: 100000508,
              },
            ],
          },
          id: 90,
          mature: false,
        },
        {
          name: "Religious Goods",
          probability: 0,
          url: "https://www.bizrate.com/religiousgoods/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Religious Goods",
                probability: 0,
                id: 10224,
              },
            ],
          },
          id: 22011,
          mature: false,
        },
        {
          name: "Tanning Beds",
          probability: 0,
          url: "https://www.bizrate.com/tanningbeds/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Tanning Beds",
                probability: 0,
                id: 10225,
              },
            ],
          },
          id: 22012,
          mature: false,
        },
        {
          name: "Skin Care Products",
          probability: 0,
          url: "https://www.bizrate.com/skincareproducts/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Skin Care Products",
                probability: 0,
                id: 8710,
              },
            ],
          },
          id: 92,
          mature: false,
        },
        {
          name: "Misc. Health & Beauty",
          probability: 0,
          url: "https://www.bizrate.com/mischealth_beauty/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Misc. Health & Beauty",
                probability: 0,
                id: 10009,
              },
            ],
          },
          id: 220,
          mature: false,
        },
        {
          name: "Trimmers",
          probability: 0,
          url: "https://www.bizrate.com/trimmers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Trimmers",
                probability: 0,
                id: 10226,
              },
            ],
          },
          id: 22013,
          mature: false,
        },
        {
          name: "Saunas",
          probability: 0,
          url: "https://www.bizrate.com/saunas/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Saunas",
                probability: 0,
                id: 10227,
              },
            ],
          },
          id: 22014,
          mature: false,
        },
        {
          name: "Hair Care Appliances",
          probability: 0,
          url: "https://www.bizrate.com/haircareappliances/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Hair Care Appliances",
                probability: 0,
                id: 10228,
              },
            ],
          },
          id: 22015,
          mature: false,
        },
        {
          name: "Cosmetics",
          probability: 0,
          url: "https://www.bizrate.com/cosmetics/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Lip Liner",
                probability: 0,
                id: 100000630,
              },
              {
                name: "Makeup Brushes & Applicators",
                probability: 0,
                id: 100000664,
              },
              {
                name: "Bronzer",
                probability: 0,
                id: 100000626,
              },
              {
                name: "Blush",
                probability: 0,
                id: 100000625,
              },
              {
                name: "Lip Gloss",
                probability: 0,
                id: 100000628,
              },
              {
                name: "Lipstick",
                probability: 0,
                id: 100000627,
              },
              {
                name: "Makeup Bags & Cases",
                probability: 0,
                id: 100000665,
              },
              {
                name: "False Eyelashes",
                probability: 0,
                id: 100000667,
              },
              {
                name: "Eyeliner",
                probability: 0,
                id: 100000646,
              },
              {
                name: "Eyeshadow",
                probability: 0,
                id: 100000645,
              },
              {
                name: "Eyebrow Makeup",
                probability: 0,
                id: 100000648,
              },
              {
                name: "Mascara",
                probability: 0,
                id: 100000647,
              },
              {
                name: "Foundations",
                probability: 0,
                id: 100000622,
              },
              {
                name: "Nail Care Tools",
                probability: 0,
                id: 100000653,
              },
              {
                name: "Concealer",
                probability: 0,
                id: 100000624,
              },
              {
                name: "Face Powder",
                probability: 0,
                id: 100000623,
              },
              {
                name: "Cosmetics",
                probability: 0,
                id: 100000650,
              },
              {
                name: "Nail Polish",
                probability: 0,
                id: 100000651,
              },
              {
                name: "Lip Treatments",
                probability: 0,
                id: 8704,
              },
            ],
          },
          id: 95,
          mature: false,
        },
        {
          name: "Skin Care Appliances",
          probability: 0,
          url: "https://www.bizrate.com/skincareappliances/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Skin Care Appliances",
                probability: 0,
                id: 10229,
              },
            ],
          },
          id: 22016,
          mature: false,
        },
      ],
    },
    id: 50,
    mature: false,
  },
  {
    name: "DVDs & Videos",
    probability: 0,
    url: "https://www.bizrate.com/dvds_videos/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
    children: {
      category: [
        {
          name: "Action & Adventure DVDs & Videos",
          probability: 0,
          url: "https://www.bizrate.com/action_adventuredvds_videos/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Action & Adventure DVDs & Videos",
                probability: 0,
                id: 10071,
              },
            ],
          },
          id: 5101,
          mature: false,
        },
        {
          name: "Children's DVDs & Videos",
          probability: 0,
          url: "https://www.bizrate.com/childrensdvds_videos/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Children's DVDs & Videos",
                probability: 0,
                id: 10072,
              },
            ],
          },
          id: 5102,
          mature: false,
        },
        {
          name: "Comedy DVDs & Videos",
          probability: 0,
          url: "https://www.bizrate.com/comedydvds_videos/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Comedy DVDs & Videos",
                probability: 0,
                id: 10073,
              },
            ],
          },
          id: 5103,
          mature: false,
        },
        {
          name: "Drama DVDs & Videos",
          probability: 0,
          url: "https://www.bizrate.com/dramadvds_videos/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Drama DVDs & Videos",
                probability: 0,
                id: 10074,
              },
            ],
          },
          id: 5104,
          mature: false,
        },
        {
          name: "Special Interest DVDs & Videos",
          probability: 0,
          url: "https://www.bizrate.com/specialinterestdvds_videos/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Special Interest DVDs & Videos",
                probability: 0,
                id: 10075,
              },
            ],
          },
          id: 5105,
          mature: false,
        },
        {
          name: "Foreign DVDs & Videos",
          probability: 0,
          url: "https://www.bizrate.com/foreigndvds_videos/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Foreign DVDs & Videos",
                probability: 0,
                id: 10076,
              },
            ],
          },
          id: 5106,
          mature: false,
        },
        {
          name: "Horror / Suspense DVDs & Videos",
          probability: 0,
          url: "https://www.bizrate.com/horrorsuspensedvds_videos/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Horror/Suspense DVDs & Videos",
                probability: 0,
                id: 10077,
              },
            ],
          },
          id: 5107,
          mature: false,
        },
        {
          name: "Music & Musicals DVDs & Videos",
          probability: 0,
          url: "https://www.bizrate.com/music_musicalsdvds_videos/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Music & Musicals DVDs & Videos",
                probability: 0,
                id: 10078,
              },
            ],
          },
          id: 5108,
          mature: false,
        },
        {
          name: "Religious DVDs & Videos",
          probability: 0,
          url: "https://www.bizrate.com/religiousdvds_videos/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Religious DVDs & Videos",
                probability: 0,
                id: 10079,
              },
            ],
          },
          id: 5109,
          mature: false,
        },
        {
          name: "Sci-Fi DVDs & Videos",
          probability: 0,
          url: "https://www.bizrate.com/scifidvds_videos/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Sci-Fi DVDs & Videos",
                probability: 0,
                id: 10080,
              },
            ],
          },
          id: 5110,
          mature: false,
        },
        {
          name: "Sports & Fitness DVDs & Videos",
          probability: 0,
          url: "https://www.bizrate.com/sports_fitnessdvds_videos/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Sports & Fitness DVDs & Videos",
                probability: 0,
                id: 10081,
              },
            ],
          },
          id: 5111,
          mature: false,
        },
        {
          name: "Miscellaneous DVDs & Videos",
          probability: 0,
          url: "https://www.bizrate.com/miscellaneousdvds_videos/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Miscellaneous DVDs & Videos",
                probability: 0,
                id: 10082,
              },
            ],
          },
          id: 5114,
          mature: false,
        },
        {
          name: "Westerns DVDs & Videos",
          probability: 0,
          url: "https://www.bizrate.com/westernsdvds_videos/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Westerns DVDs & Videos",
                probability: 0,
                id: 10083,
              },
            ],
          },
          id: 5116,
          mature: false,
        },
      ],
    },
    id: 51,
    mature: false,
  },
  {
    name: "Music",
    probability: 0,
    url: "https://www.bizrate.com/music/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
    children: {
      category: [
        {
          name: "Miscellaneous Music",
          probability: 0,
          url: "https://www.bizrate.com/miscellaneousmusic/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Miscellaneous Music",
                probability: 0,
                id: 10099,
              },
            ],
          },
          id: 5220,
          mature: false,
        },
        {
          name: "Cajun Music",
          probability: 0,
          url: "https://www.bizrate.com/cajunmusic/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Cajun Music",
                probability: 0,
                id: 10100,
              },
            ],
          },
          id: 5222,
          mature: false,
        },
        {
          name: "Electronic Music",
          probability: 0,
          url: "https://www.bizrate.com/electronicmusic/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Electronic Music",
                probability: 0,
                id: 10101,
              },
            ],
          },
          id: 5223,
          mature: false,
        },
        {
          name: "Classical Music",
          probability: 0,
          url: "https://www.bizrate.com/classical-music/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Classical Music",
                probability: 0,
                id: 100000854,
              },
            ],
          },
          id: 100000852,
          mature: false,
        },
        {
          name: "Environmental Music",
          probability: 0,
          url: "https://www.bizrate.com/environmentalmusic/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Environmental Music",
                probability: 0,
                id: 10102,
              },
            ],
          },
          id: 5224,
          mature: false,
        },
        {
          name: "Folk Music",
          probability: 0,
          url: "https://www.bizrate.com/folkmusic/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Folk Music",
                probability: 0,
                id: 10103,
              },
            ],
          },
          id: 5225,
          mature: false,
        },
        {
          name: "Hardcore & Punk Music",
          probability: 0,
          url: "https://www.bizrate.com/hardcore_punkmusic/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Hardcore & Punk Music",
                probability: 0,
                id: 10104,
              },
            ],
          },
          id: 5226,
          mature: false,
        },
        {
          name: "Heavy Metal Music",
          probability: 0,
          url: "https://www.bizrate.com/heavymetalmusic/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Heavy Metal Music",
                probability: 0,
                id: 10105,
              },
            ],
          },
          id: 5227,
          mature: false,
        },
        {
          name: "Instrumental Music",
          probability: 0,
          url: "https://www.bizrate.com/instrumentalmusic/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Instrumental Music",
                probability: 0,
                id: 10106,
              },
            ],
          },
          id: 5228,
          mature: false,
        },
        {
          name: "Jazz Vocal Music",
          probability: 0,
          url: "https://www.bizrate.com/jazzvocalmusic/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Jazz Vocal Music",
                probability: 0,
                id: 10107,
              },
            ],
          },
          id: 5229,
          mature: false,
        },
        {
          name: "New Age Music",
          probability: 0,
          url: "https://www.bizrate.com/newagemusic/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "New Age Music",
                probability: 0,
                id: 10108,
              },
            ],
          },
          id: 5230,
          mature: false,
        },
        {
          name: "Original Cast Music",
          probability: 0,
          url: "https://www.bizrate.com/originalcastmusic/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Original Cast Music",
                probability: 0,
                id: 10109,
              },
            ],
          },
          id: 5231,
          mature: false,
        },
        {
          name: "Sound Effects",
          probability: 0,
          url: "https://www.bizrate.com/soundeffects/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Sound Effects",
                probability: 0,
                id: 10110,
              },
            ],
          },
          id: 5232,
          mature: false,
        },
        {
          name: "Spoken Word",
          probability: 0,
          url: "https://www.bizrate.com/spokenword/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Spoken Word",
                probability: 0,
                id: 10111,
              },
            ],
          },
          id: 5233,
          mature: false,
        },
        {
          name: "Blues Music",
          probability: 0,
          url: "https://www.bizrate.com/bluesmusic/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Blues Music",
                probability: 0,
                id: 10085,
              },
            ],
          },
          id: 5201,
          mature: false,
        },
        {
          name: "Children's Music",
          probability: 0,
          url: "https://www.bizrate.com/childrensmusic/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Children's Music",
                probability: 0,
                id: 10086,
              },
            ],
          },
          id: 5202,
          mature: false,
        },
        {
          name: "Comedy",
          probability: 0,
          url: "https://www.bizrate.com/comedy/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Comedy",
                probability: 0,
                id: 10087,
              },
            ],
          },
          id: 5203,
          mature: false,
        },
        {
          name: "Country Music",
          probability: 0,
          url: "https://www.bizrate.com/countrymusic/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Country Music",
                probability: 0,
                id: 10088,
              },
            ],
          },
          id: 5204,
          mature: false,
        },
        {
          name: "Easy Listening Music",
          probability: 0,
          url: "https://www.bizrate.com/easylisteningmusic/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Easy Listening Music",
                probability: 0,
                id: 10089,
              },
            ],
          },
          id: 5205,
          mature: false,
        },
        {
          name: "Christian & Gospel Music",
          probability: 0,
          url: "https://www.bizrate.com/christian_gospelmusic/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Christian & Gospel Music",
                probability: 0,
                id: 10090,
              },
            ],
          },
          id: 5207,
          mature: false,
        },
        {
          name: "International Music",
          probability: 0,
          url: "https://www.bizrate.com/internationalmusic/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "International Music",
                probability: 0,
                id: 10091,
              },
            ],
          },
          id: 5208,
          mature: false,
        },
        {
          name: "Jazz Instrumental Music",
          probability: 0,
          url: "https://www.bizrate.com/jazzinstrumentalmusic/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Jazz Instrumental Music",
                probability: 0,
                id: 10092,
              },
            ],
          },
          id: 5209,
          mature: false,
        },
        {
          name: "Oldies Music",
          probability: 0,
          url: "https://www.bizrate.com/oldiesmusic/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Oldies Music",
                probability: 0,
                id: 10093,
              },
            ],
          },
          id: 5211,
          mature: false,
        },
        {
          name: "Pop Vocal Music",
          probability: 0,
          url: "https://www.bizrate.com/popvocalmusic/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Pop Vocal Music",
                probability: 0,
                id: 10094,
              },
            ],
          },
          id: 5212,
          mature: false,
        },
        {
          name: "R&B, Hip Hop & Rap Music",
          probability: 0,
          url: "https://www.bizrate.com/r_bhiphop_rapmusic/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "R&B, Hip Hop & Rap Music",
                probability: 0,
                id: 10095,
              },
            ],
          },
          id: 5213,
          mature: false,
        },
        {
          name: "Reggae Music",
          probability: 0,
          url: "https://www.bizrate.com/reggaemusic/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Reggae Music",
                probability: 0,
                id: 10096,
              },
            ],
          },
          id: 5214,
          mature: false,
        },
        {
          name: "Rock & Pop Music",
          probability: 0,
          url: "https://www.bizrate.com/rock_popmusic/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Rock & Pop Music",
                probability: 0,
                id: 10097,
              },
            ],
          },
          id: 5215,
          mature: false,
        },
        {
          name: "TV & Movie Soundtracks",
          probability: 0,
          url: "https://www.bizrate.com/tv_moviesoundtracks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "TV & Movie Soundtracks",
                probability: 0,
                id: 10098,
              },
            ],
          },
          id: 5216,
          mature: false,
        },
      ],
    },
    id: 52,
    mature: false,
  },
  {
    name: "Jewelry",
    probability: 0,
    url: "https://www.bizrate.com/jewelry_watches/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
    children: {
      category: [
        {
          name: "Loose Gemstones",
          probability: 0,
          url: "https://www.bizrate.com/loose-gemstones/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Loose Gemstones",
                probability: 0,
                id: 9960,
              },
            ],
          },
          id: 9959,
          mature: false,
        },
        {
          name: "Watches",
          probability: 0,
          url: "https://www.bizrate.com/watches/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Watches",
                probability: 0,
                id: 10724,
              },
            ],
          },
          id: 30020100,
          mature: false,
        },
        {
          name: "Charms",
          probability: 0,
          url: "https://www.bizrate.com/charms/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Charms",
                probability: 0,
                id: 10712,
              },
            ],
          },
          id: 30000200,
          mature: false,
        },
        {
          name: "Rings",
          probability: 0,
          url: "https://www.bizrate.com/rings/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Rings",
                probability: 0,
                id: 10718,
              },
            ],
          },
          id: 30010106,
          mature: false,
        },
        {
          name: "Miscellaneous Jewelry",
          probability: 0,
          url: "https://www.bizrate.com/miscellaneousjewelry/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Miscellaneous Jewelry",
                probability: 0,
                id: 10717,
              },
            ],
          },
          id: 30010105,
          mature: false,
        },
        {
          name: "Body Jewelry",
          probability: 0,
          url: "https://www.bizrate.com/bodyjewelry/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Body Jewelry",
                probability: 0,
                id: 10720,
              },
            ],
          },
          id: 30010108,
          mature: false,
        },
        {
          name: "Diamonds",
          probability: 0,
          url: "https://www.bizrate.com/diamonds/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Diamonds",
                probability: 0,
                id: 10719,
              },
            ],
          },
          id: 30010107,
          mature: false,
        },
        {
          name: "Jewelry Boxes",
          probability: 0,
          url: "https://www.bizrate.com/jewelryboxes/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Jewelry Boxes",
                probability: 0,
                id: 10722,
              },
            ],
          },
          id: 30010110,
          mature: false,
        },
        {
          name: "Pins & Brooches",
          probability: 0,
          url: "https://www.bizrate.com/pinsbrooches/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Pins & Brooches",
                probability: 0,
                id: 10721,
              },
            ],
          },
          id: 30010109,
          mature: false,
        },
        {
          name: "Jewelry Sets",
          probability: 0,
          url: "https://www.bizrate.com/jewelrysets/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Jewelry Sets",
                probability: 0,
                id: 10723,
              },
            ],
          },
          id: 30010112,
          mature: false,
        },
        {
          name: "Watch Accessories",
          probability: 0,
          url: "https://www.bizrate.com/watch-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Watch Accessories",
                probability: 0,
                id: 100001541,
              },
            ],
          },
          id: 100001539,
          mature: false,
        },
        {
          name: "Earrings",
          probability: 0,
          url: "https://www.bizrate.com/earrings/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Earrings",
                probability: 0,
                id: 10714,
              },
            ],
          },
          id: 30010102,
          mature: false,
        },
        {
          name: "Bracelets",
          probability: 0,
          url: "https://www.bizrate.com/bracelets/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Bracelets",
                probability: 0,
                id: 10713,
              },
            ],
          },
          id: 30010101,
          mature: false,
        },
        {
          name: "Necklaces & Pendants",
          probability: 0,
          url: "https://www.bizrate.com/necklacespendants/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Necklaces & Pendants",
                probability: 0,
                id: 10716,
              },
            ],
          },
          id: 30010104,
          mature: false,
        },
        {
          name: "Cuff Links",
          probability: 0,
          url: "https://www.bizrate.com/cufflinks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Cuff Links",
                probability: 0,
                id: 10715,
              },
            ],
          },
          id: 30010103,
          mature: false,
        },
      ],
    },
    id: 30000000,
    mature: false,
  },
  {
    name: "Office Supplies",
    probability: 0,
    url: "https://www.bizrate.com/officesupplies/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
    children: {
      category: [
        {
          name: "Calculators",
          probability: 0,
          url: "https://www.bizrate.com/calculators1/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Calculators",
                probability: 0,
                id: 10008,
              },
            ],
          },
          id: 209,
          mature: false,
        },
        {
          name: "Office Furniture",
          probability: 0,
          url: "https://www.bizrate.com/officefurniture/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          children: {
            category: [
              {
                name: "Chair Mats",
                probability: 0,
                url: "https://www.bizrate.com/chair-mats/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Chair Mats",
                      probability: 0,
                      id: 100000066,
                    },
                  ],
                },
                id: 100000054,
                mature: false,
              },
              {
                name: "Desks",
                probability: 0,
                url: "https://www.bizrate.com/desks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Desks",
                      probability: 0,
                      id: 10709,
                    },
                  ],
                },
                id: 25010400,
                mature: false,
              },
              {
                name: "Office Partitions & Panels",
                probability: 0,
                url: "https://www.bizrate.com/office-partitions-panels/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Office Partitions & Panels",
                      probability: 0,
                      id: 100000083,
                    },
                  ],
                },
                id: 100000081,
                mature: false,
              },
              {
                name: "Chairs",
                probability: 0,
                url: "https://www.bizrate.com/officechairs/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Office Chairs",
                      probability: 0,
                      id: 10708,
                    },
                    {
                      name: "Office Footrests",
                      probability: 0,
                      id: 100000082,
                    },
                  ],
                },
                id: 25010300,
                mature: false,
              },
              {
                name: "Bookcases",
                probability: 0,
                url: "https://www.bizrate.com/bookcases/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Bookcases",
                      probability: 0,
                      id: 10707,
                    },
                  ],
                },
                id: 25010200,
                mature: false,
              },
              {
                name: "File Cabinets",
                probability: 0,
                url: "https://www.bizrate.com/filecabinets/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "File Cabinets",
                      probability: 0,
                      id: 10710,
                    },
                  ],
                },
                id: 25010500,
                mature: false,
              },
            ],
          },
          id: 25010000,
          mature: false,
        },
        {
          name: "Office Basics",
          probability: 0,
          url: "https://www.bizrate.com/officebasics/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          children: {
            category: [
              {
                name: "Paper",
                probability: 0,
                url: "https://www.bizrate.com/paper/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Paper",
                      probability: 0,
                      id: 10214,
                    },
                  ],
                },
                id: 21217,
                mature: false,
              },
              {
                name: "Office Stamps",
                probability: 0,
                url: "https://www.bizrate.com/office-stamps/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Office Stamps",
                      probability: 0,
                      id: 100000068,
                    },
                  ],
                },
                id: 100000056,
                mature: false,
              },
              {
                name: "Clipboards",
                probability: 0,
                url: "https://www.bizrate.com/clipboards/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Clipboards",
                      probability: 0,
                      id: 100000067,
                    },
                  ],
                },
                id: 100000055,
                mature: false,
              },
              {
                name: "Paper Shredder Waste Bags",
                probability: 0,
                url: "https://www.bizrate.com/paper-shredder-waste-bags/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Paper Shredder Waste Bags",
                      probability: 0,
                      id: 100000766,
                    },
                  ],
                },
                id: 100000756,
                mature: false,
              },
              {
                name: "Paper Fasteners",
                probability: 0,
                url: "https://www.bizrate.com/paper-fasteners/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Paper Fasteners",
                      probability: 0,
                      id: 100000070,
                    },
                  ],
                },
                id: 100000058,
                mature: false,
              },
              {
                name: "Stamp Pads",
                probability: 0,
                url: "https://www.bizrate.com/stamp-pads/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Stamp Pads",
                      probability: 0,
                      id: 100000069,
                    },
                  ],
                },
                id: 100000057,
                mature: false,
              },
              {
                name: "Letter Openers",
                probability: 0,
                url: "https://www.bizrate.com/letter-openers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Letter Openers",
                      probability: 0,
                      id: 100000075,
                    },
                  ],
                },
                id: 100000060,
                mature: false,
              },
              {
                name: "Notebooks & Pads",
                probability: 0,
                url: "https://www.bizrate.com/notebooks_pads/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Notebooks & Writing Pads",
                      probability: 0,
                      id: 100001605,
                    },
                  ],
                },
                id: 21200,
                mature: false,
              },
              {
                name: "File Folders",
                probability: 0,
                url: "https://www.bizrate.com/filefolders/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "File Folders",
                      probability: 0,
                      id: 100000484,
                    },
                  ],
                },
                id: 21201,
                mature: false,
              },
              {
                name: "Glue",
                probability: 0,
                url: "https://www.bizrate.com/glue/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Glue",
                      probability: 0,
                      id: 10206,
                    },
                  ],
                },
                id: 21202,
                mature: false,
              },
              {
                name: "Labels & Tags",
                probability: 0,
                url: "https://www.bizrate.com/labels_tags/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Labels & Tags",
                      probability: 0,
                      id: 10207,
                    },
                  ],
                },
                id: 21203,
                mature: false,
              },
              {
                name: "Scissors",
                probability: 0,
                url: "https://www.bizrate.com/scissors/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Scissors",
                      probability: 0,
                      id: 10208,
                    },
                  ],
                },
                id: 21207,
                mature: false,
              },
              {
                name: "Staples",
                probability: 0,
                url: "https://www.bizrate.com/staples/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Staples & Staple Removers",
                      probability: 0,
                      id: 10209,
                    },
                  ],
                },
                id: 21209,
                mature: false,
              },
              {
                name: "Staplers",
                probability: 0,
                url: "https://www.bizrate.com/staplers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Staplers",
                      probability: 0,
                      id: 10210,
                    },
                  ],
                },
                id: 21210,
                mature: false,
              },
              {
                name: "Business Forms & Record Keeping",
                probability: 0,
                url: "https://www.bizrate.com/business-forms-record-keeping/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Receipt Books",
                      probability: 0,
                      id: 100001621,
                    },
                    {
                      name: "Tax Forms",
                      probability: 0,
                      id: 100001624,
                    },
                    {
                      name: "Account & Record Books",
                      probability: 0,
                      id: 100001623,
                    },
                    {
                      name: "Guest Check Forms",
                      probability: 0,
                      id: 100001620,
                    },
                    {
                      name: "Purchase Order Forms",
                      probability: 0,
                      id: 100001619,
                    },
                  ],
                },
                id: 100001677,
                mature: false,
              },
              {
                name: "Tape",
                probability: 0,
                url: "https://www.bizrate.com/tape/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Tape",
                      probability: 0,
                      id: 10211,
                    },
                  ],
                },
                id: 21211,
                mature: false,
              },
              {
                name: "Pens",
                probability: 0,
                url: "https://www.bizrate.com/pens/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Pens",
                      probability: 0,
                      id: 10212,
                    },
                  ],
                },
                id: 21213,
                mature: false,
              },
              {
                name: "Pencils",
                probability: 0,
                url: "https://www.bizrate.com/pencils/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Pencils",
                      probability: 0,
                      id: 10213,
                    },
                  ],
                },
                id: 21214,
                mature: false,
              },
            ],
          },
          id: 212,
          mature: false,
        },
        {
          name: "Paper Shredders",
          probability: 0,
          url: "https://www.bizrate.com/paper-shredders/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Paper Shredders",
                probability: 0,
                id: 100000077,
              },
            ],
          },
          id: 100000062,
          mature: false,
        },
        {
          name: "Mailing & Shipping Supplies",
          probability: 0,
          url: "https://www.bizrate.com/mailing_shippingsupplies/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Mailing & Shipping Supplies",
                probability: 0,
                id: 10059,
              },
            ],
          },
          id: 10,
          mature: false,
        },
        {
          name: "Calendars, Organizers & Planners",
          probability: 0,
          url: "https://www.bizrate.com/calendarsorganizers_planners/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          children: {
            category: [
              {
                name: "Calendars",
                probability: 0,
                url: "https://www.bizrate.com/calendars/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Calendars",
                      probability: 0,
                      id: 10061,
                    },
                  ],
                },
                id: 1201,
                mature: false,
              },
              {
                name: "Organizers & Planners",
                probability: 0,
                url: "https://www.bizrate.com/organizers_planners/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Planners, Organizers & Address Books",
                      probability: 0,
                      id: 100001603,
                    },
                    {
                      name: "Office Boards",
                      probability: 0,
                      id: 100000106,
                    },
                  ],
                },
                id: 1202,
                mature: false,
              },
            ],
          },
          id: 12,
          mature: false,
        },
        {
          name: "Typewriters",
          probability: 0,
          url: "https://www.bizrate.com/typewriters/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Typewriters",
                probability: 0,
                id: 100000076,
              },
            ],
          },
          id: 100000063,
          mature: false,
        },
        {
          name: "Binders & Accessories",
          probability: 0,
          url: "https://www.bizrate.com/binders_accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          children: {
            category: [
              {
                name: "Binders",
                probability: 0,
                url: "https://www.bizrate.com/binders/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Binders",
                      probability: 0,
                      id: 10064,
                    },
                  ],
                },
                id: 1304,
                mature: false,
              },
              {
                name: "Binding Machines",
                probability: 0,
                url: "https://www.bizrate.com/bindingmachines/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Binding Machines",
                      probability: 0,
                      id: 10066,
                    },
                  ],
                },
                id: 1308,
                mature: false,
              },
              {
                name: "Laminating Machines",
                probability: 0,
                url: "https://www.bizrate.com/laminatingmachines/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Laminating Machines",
                      probability: 0,
                      id: 10067,
                    },
                  ],
                },
                id: 1309,
                mature: false,
              },
              {
                name: "Binder Accessories",
                probability: 0,
                url: "https://www.bizrate.com/binder-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Binder Pockets & Index Dividers",
                      probability: 0,
                      id: 100001586,
                    },
                    {
                      name: "Sheet Lifters",
                      probability: 0,
                      id: 100001585,
                    },
                    {
                      name: "Sheet Protectors",
                      probability: 0,
                      id: 100001587,
                    },
                    {
                      name: "Hole Reinforcements",
                      probability: 0,
                      id: 100001584,
                    },
                    {
                      name: "Binder Rings",
                      probability: 0,
                      id: 100001583,
                    },
                  ],
                },
                id: 100001593,
                mature: false,
              },
            ],
          },
          id: 13,
          mature: false,
        },
        {
          name: "Janitorial & Breakroom Supplies",
          probability: 0,
          url: "https://www.bizrate.com/janitorial_breakroomsupplies/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          children: {
            category: [
              {
                name: "Janitorial Supplies",
                probability: 0,
                url: "https://www.bizrate.com/cleaningsupplies/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Janitorial Supplies",
                      probability: 0,
                      id: 10068,
                    },
                  ],
                },
                id: 4600,
                mature: false,
              },
              {
                name: "Breakroom Supplies",
                probability: 0,
                url: "https://www.bizrate.com/breakroomsupplies/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Breakroom Supplies",
                      probability: 0,
                      id: 10069,
                    },
                  ],
                },
                id: 4601,
                mature: false,
              },
            ],
          },
          id: 46,
          mature: false,
        },
        {
          name: "Miscellaneous Office & School Supplies",
          probability: 0,
          url: "https://www.bizrate.com/miscellaneousoffice_schoolsupplies/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Miscellaneous Office & School Supplies",
                probability: 0,
                id: 10000,
              },
            ],
          },
          id: 47,
          mature: false,
        },
      ],
    },
    id: 24,
    mature: false,
  },
  {
    name: "Electronics",
    probability: 0,
    url: "https://www.bizrate.com/electronics/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
    children: {
      category: [
        {
          name: "DVD Players",
          probability: 0,
          url: "https://www.bizrate.com/dvdplayers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "DVD Players",
                probability: 0,
                id: 10395,
              },
            ],
          },
          id: 11530000,
          mature: false,
        },
        {
          name: "CD Duplicators",
          probability: 0,
          url: "https://www.bizrate.com/cdduplicators/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "CD Duplicators",
                probability: 0,
                id: 10364,
              },
            ],
          },
          id: 11000200,
          mature: false,
        },
        {
          name: "TV-Mounts",
          probability: 0,
          url: "https://www.bizrate.com/tvmounts/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "TV-Mounts",
                probability: 0,
                id: 10372,
              },
            ],
          },
          id: 11100809,
          mature: false,
        },
        {
          name: "GPS",
          probability: 0,
          url: "https://www.bizrate.com/gps1/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "GPS",
                probability: 0,
                id: 10376,
              },
            ],
          },
          id: 11210000,
          mature: false,
        },
        {
          name: "Digital Book Reader Accessories",
          probability: 0,
          url: "https://www.bizrate.com/digital-book-reader-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Digital Book Reader Accessories",
                probability: 0,
                id: 100002002,
              },
            ],
          },
          id: 100002003,
          mature: false,
        },
        {
          name: "Car Subwoofers",
          probability: 0,
          url: "https://www.bizrate.com/carsubwoofers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Car Subwoofers",
                probability: 0,
                id: 10430,
              },
            ],
          },
          id: 11940000,
          mature: false,
        },
        {
          name: "Car Crossovers",
          probability: 0,
          url: "https://www.bizrate.com/carcrossovers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Car Crossovers",
                probability: 0,
                id: 10423,
              },
            ],
          },
          id: 11860000,
          mature: false,
        },
        {
          name: "DVR",
          probability: 0,
          url: "https://www.bizrate.com/dvr1/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "DVR",
                probability: 0,
                id: 10396,
              },
            ],
          },
          id: 11540000,
          mature: false,
        },
        {
          name: "Fax Machines",
          probability: 0,
          url: "https://www.bizrate.com/faxmachines/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Fax Machines",
                probability: 0,
                id: 10377,
              },
            ],
          },
          id: 11380000,
          mature: false,
        },
        {
          name: "Camcorder Accessories",
          probability: 0,
          url: "https://www.bizrate.com/camcorderaccessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Camcorder Accessories",
                probability: 0,
                id: 10380,
              },
            ],
          },
          id: 11510300,
          mature: false,
        },
        {
          name: "Camcorders",
          probability: 0,
          url: "https://www.bizrate.com/camcorders/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Camcorders",
                probability: 0,
                id: 10375,
              },
            ],
          },
          id: 11140000,
          mature: false,
        },
        {
          name: "Car Speakers",
          probability: 0,
          url: "https://www.bizrate.com/carspeakers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Car Speakers",
                probability: 0,
                id: 10429,
              },
            ],
          },
          id: 11930000,
          mature: false,
        },
        {
          name: "Car CD/MP3 Players",
          probability: 0,
          url: "https://www.bizrate.com/carcdmp3players/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Car CD/MP3 Players",
                probability: 0,
                id: 10422,
              },
            ],
          },
          id: 11850000,
          mature: false,
        },
        {
          name: "Two-Way Radios",
          probability: 0,
          url: "https://www.bizrate.com/twowayradios/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Two-Way Radios",
                probability: 0,
                id: 10402,
              },
            ],
          },
          id: 11580300,
          mature: false,
        },
        {
          name: "Headphones",
          probability: 0,
          url: "https://www.bizrate.com/headphones/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Headphones",
                probability: 0,
                id: 9227,
              },
            ],
          },
          id: 9213,
          mature: false,
        },
        {
          name: "Mini-systems",
          probability: 0,
          url: "https://www.bizrate.com/minisystems/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Mini-systems",
                probability: 0,
                id: 10404,
              },
            ],
          },
          id: 11610000,
          mature: false,
        },
        {
          name: "Digital Picture Frames",
          probability: 0,
          url: "https://www.bizrate.com/digital-picture-frames/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Digital Picture Frames",
                probability: 0,
                id: 386,
              },
            ],
          },
          id: 385,
          mature: false,
        },
        {
          name: "Car Alarms",
          probability: 0,
          url: "https://www.bizrate.com/caralarms/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Car Alarms",
                probability: 0,
                id: 10203,
              },
            ],
          },
          id: 20100,
          mature: false,
        },
        {
          name: "XM & Sirius Satellite Radios",
          probability: 0,
          url: "https://www.bizrate.com/xm_siriussatelliteradios/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "XM & Sirius Satellite Radios",
                probability: 0,
                id: 10428,
              },
            ],
          },
          id: 11920000,
          mature: false,
        },
        {
          name: "Cash Registers & POS Equipment",
          probability: 0,
          url: "https://www.bizrate.com/cashregisters_posequipment/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Cash Registers & POS Equipment",
                probability: 0,
                id: 10373,
              },
            ],
          },
          id: 11100900,
          mature: false,
        },
        {
          name: "Car CD Changers",
          probability: 0,
          url: "https://www.bizrate.com/carcdchangers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Car CD Changers",
                probability: 0,
                id: 10421,
              },
            ],
          },
          id: 11840000,
          mature: false,
        },
        {
          name: "Electronics Cases & Bags",
          probability: 0,
          url: "https://www.bizrate.com/electronicscases_bags/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Electronics Cases & Bags",
                probability: 0,
                id: 10381,
              },
            ],
          },
          id: 11510500,
          mature: false,
        },
        {
          name: "TV",
          probability: 0,
          url: "https://www.bizrate.com/tv1/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "TV",
                probability: 0,
                id: 10394,
              },
            ],
          },
          id: 11520000,
          mature: false,
        },
        {
          name: "Digital Book Readers",
          probability: 0,
          url: "https://www.bizrate.com/digital-book-readers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Digital Book Readers",
                probability: 0,
                id: 100000809,
              },
            ],
          },
          id: 100000808,
          mature: false,
        },
        {
          name: "Home Theater",
          probability: 0,
          url: "https://www.bizrate.com/hometheatersystems/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Home Theater Systems",
                probability: 0,
                id: 10374,
              },
            ],
          },
          id: 11120000,
          mature: false,
        },
        {
          name: "Photography / Darkroom Equipment",
          probability: 0,
          url: "https://www.bizrate.com/photographydarkroomequipment/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Photography/Darkroom Equipment",
                probability: 0,
                id: 10371,
              },
            ],
          },
          id: 11100800,
          mature: false,
        },
        {
          name: "Audio & Video Accessories",
          probability: 0,
          url: "https://www.bizrate.com/audio-videoaccessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Audio & Video Accessories",
                probability: 0,
                id: 10389,
              },
            ],
          },
          id: 11510905,
          mature: false,
        },
        {
          name: "Home Security",
          probability: 0,
          url: "https://www.bizrate.com/homesecurity/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Home Security",
                probability: 0,
                id: 10388,
              },
            ],
          },
          id: 11510904,
          mature: false,
        },
        {
          name: "DVD/VCR Combos",
          probability: 0,
          url: "https://www.bizrate.com/dvdvcrcombos/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "DVD/VCR Combos",
                probability: 0,
                id: 10435,
              },
            ],
          },
          id: 11990000,
          mature: false,
        },
        {
          name: "Telephone Accessories",
          probability: 0,
          url: "https://www.bizrate.com/telephoneaccessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Telephone Accessories",
                probability: 0,
                id: 10387,
              },
            ],
          },
          id: 11510903,
          mature: false,
        },
        {
          name: "Radar Detectors & Jammers",
          probability: 0,
          url: "https://www.bizrate.com/radardetectors_jammers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Radar Detectors & Jammers",
                probability: 0,
                id: 10427,
              },
            ],
          },
          id: 11910000,
          mature: false,
        },
        {
          name: "Audio/Video Media",
          probability: 0,
          url: "https://www.bizrate.com/audiovideomedia/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Audio/Video Media",
                probability: 0,
                id: 10386,
              },
            ],
          },
          id: 11510902,
          mature: false,
        },
        {
          name: "Car Cassette Players",
          probability: 0,
          url: "https://www.bizrate.com/carcassetteplayers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Car Cassette Players",
                probability: 0,
                id: 10420,
              },
            ],
          },
          id: 11830000,
          mature: false,
        },
        {
          name: "Audio / Video Cables & Adapters",
          probability: 0,
          url: "https://www.bizrate.com/audiovideocables_adapters/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Audio/Video Cables & Adapters",
                probability: 0,
                id: 10385,
              },
            ],
          },
          id: 11510901,
          mature: false,
        },
        {
          name: "Phones",
          probability: 0,
          url: "https://www.bizrate.com/cordlessphones/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Phones",
                probability: 0,
                id: 10403,
              },
            ],
          },
          id: 11590000,
          mature: false,
        },
        {
          name: "Marine Electronics",
          probability: 0,
          url: "https://www.bizrate.com/marineelectronics/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Marine Electronics",
                probability: 0,
                id: 10365,
              },
            ],
          },
          id: 11000400,
          mature: false,
        },
        {
          name: "Cell Phone Accessories",
          probability: 0,
          url: "https://www.bizrate.com/cell-phone-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Cell Phone Accessories",
                probability: 0,
                id: 10382,
              },
            ],
          },
          id: 11510600,
          mature: false,
        },
        {
          name: "VCRs",
          probability: 0,
          url: "https://www.bizrate.com/vcrs/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "VCRs",
                probability: 0,
                id: 10434,
              },
            ],
          },
          id: 11980000,
          mature: false,
        },
        {
          name: "Car Amplifiers",
          probability: 0,
          url: "https://www.bizrate.com/caramplifiers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Car Amplifiers",
                probability: 0,
                id: 10419,
              },
            ],
          },
          id: 11820000,
          mature: false,
        },
        {
          name: "Satellite Receivers",
          probability: 0,
          url: "https://www.bizrate.com/satellitereceivers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Satellite Receivers",
                probability: 0,
                id: 10415,
              },
            ],
          },
          id: 11740000,
          mature: false,
        },
        {
          name: "Boomboxes",
          probability: 0,
          url: "https://www.bizrate.com/boomboxes/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Boomboxes",
                probability: 0,
                id: 10408,
              },
            ],
          },
          id: 11660000,
          mature: false,
        },
        {
          name: "Cell Phones",
          probability: 0,
          url: "https://www.bizrate.com/cellphones/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Cell Phones",
                probability: 0,
                id: 10400,
              },
            ],
          },
          id: 11580100,
          mature: false,
        },
        {
          name: "Integrated Amplifiers",
          probability: 0,
          url: "https://www.bizrate.com/integratedamplifiers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Integrated Amplifiers",
                probability: 0,
                id: 10433,
              },
            ],
          },
          id: 11970000,
          mature: false,
        },
        {
          name: "Car Monitors",
          probability: 0,
          url: "https://www.bizrate.com/carmonitors/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Car Monitors",
                probability: 0,
                id: 10426,
              },
            ],
          },
          id: 11890000,
          mature: false,
        },
        {
          name: "Copiers",
          probability: 0,
          url: "https://www.bizrate.com/copiers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Copiers",
                probability: 0,
                id: 10418,
              },
            ],
          },
          id: 11810000,
          mature: false,
        },
        {
          name: "Portable Tape & Digital Recorders",
          probability: 0,
          url: "https://www.bizrate.com/portabletape_digitalrecorders/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Portable Tape & Digital Recorders",
                probability: 0,
                id: 10414,
              },
            ],
          },
          id: 11730000,
          mature: false,
        },
        {
          name: "Portable Radios",
          probability: 0,
          url: "https://www.bizrate.com/portableradios/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Portable Radios",
                probability: 0,
                id: 10407,
              },
            ],
          },
          id: 11650000,
          mature: false,
        },
        {
          name: "Blu-Ray Disc Players",
          probability: 0,
          url: "https://www.bizrate.com/blue-ray-disc-players/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Blu-Ray Disc Players",
                probability: 0,
                id: 100001401,
              },
            ],
          },
          id: 100001400,
          mature: false,
        },
        {
          name: "Cameras",
          probability: 0,
          url: "https://www.bizrate.com/cameras/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Cameras",
                probability: 0,
                id: 10007,
              },
            ],
          },
          id: 197,
          mature: false,
        },
        {
          name: "Clock Radios",
          probability: 0,
          url: "https://www.bizrate.com/clockradios/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Clock Radios",
                probability: 0,
                id: 10383,
              },
            ],
          },
          id: 11510700,
          mature: false,
        },
        {
          name: "Barcode Scanners",
          probability: 0,
          url: "https://www.bizrate.com/barcodescanners/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Barcode Scanners",
                probability: 0,
                id: 10363,
              },
            ],
          },
          id: 11000100,
          mature: false,
        },
        {
          name: "Receivers",
          probability: 0,
          url: "https://www.bizrate.com/receivers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Receivers",
                probability: 0,
                id: 10366,
              },
            ],
          },
          id: 11070000,
          mature: false,
        },
        {
          name: "Projectors",
          probability: 0,
          url: "https://www.bizrate.com/projectors/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Projectors",
                probability: 0,
                id: 10037,
              },
            ],
          },
          id: 456,
          mature: false,
        },
        {
          name: "Turntables",
          probability: 0,
          url: "https://www.bizrate.com/turntables/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Turntables",
                probability: 0,
                id: 10432,
              },
            ],
          },
          id: 11960000,
          mature: false,
        },
        {
          name: "Car DVD Players",
          probability: 0,
          url: "https://www.bizrate.com/cardvdplayers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Car DVD Players",
                probability: 0,
                id: 10425,
              },
            ],
          },
          id: 11880000,
          mature: false,
        },
        {
          name: "Remote Controls",
          probability: 0,
          url: "https://www.bizrate.com/remotecontrol/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Remote Controls",
                probability: 0,
                id: 10417,
              },
            ],
          },
          id: 11800000,
          mature: false,
        },
        {
          name: "Cassette Decks",
          probability: 0,
          url: "https://www.bizrate.com/cassettedecks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Cassette Decks",
                probability: 0,
                id: 10413,
              },
            ],
          },
          id: 11720000,
          mature: false,
        },
        {
          name: "Stereo Speakers",
          probability: 0,
          url: "https://www.bizrate.com/stereospeakers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Stereo Speakers",
                probability: 0,
                id: 10398,
              },
            ],
          },
          id: 11560000,
          mature: false,
        },
        {
          name: "CD Players",
          probability: 0,
          url: "https://www.bizrate.com/cdplayers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "CD Players",
                probability: 0,
                id: 10367,
              },
            ],
          },
          id: 11080000,
          mature: false,
        },
        {
          name: "Rechargeable & Replacement Batteries",
          probability: 0,
          url: "https://www.bizrate.com/batteries1/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Rechargeable & Replacement Batteries",
                probability: 0,
                id: 10379,
              },
            ],
          },
          id: 11510200,
          mature: false,
        },
        {
          name: "Car Audio / Video Accessories",
          probability: 0,
          url: "https://www.bizrate.com/caraccessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Car Audio / Video Accessories",
                probability: 0,
                id: 10431,
              },
            ],
          },
          id: 11950000,
          mature: false,
        },
        {
          name: "Car Equalizers",
          probability: 0,
          url: "https://www.bizrate.com/carequalizers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Car Equalizers",
                probability: 0,
                id: 10424,
              },
            ],
          },
          id: 11870000,
          mature: false,
        },
        {
          name: "Portable Cassette Players",
          probability: 0,
          url: "https://www.bizrate.com/portablecassetteplayers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Portable Cassette Players",
                probability: 0,
                id: 10405,
              },
            ],
          },
          id: 11630000,
          mature: false,
        },
        {
          name: "GPS Accessories",
          probability: 0,
          url: "https://www.bizrate.com/gpsaccessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "GPS Accessories",
                probability: 0,
                id: 10369,
              },
            ],
          },
          id: 11100600,
          mature: false,
        },
      ],
    },
    id: 11000000,
    mature: false,
  },
  {
    name: "Home & Garden",
    probability: 0,
    url: "https://www.bizrate.com/home_garden/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
    children: {
      category: [
        {
          name: "Outdoor Cooking",
          probability: 0,
          url: "https://www.bizrate.com/outdoorcooking/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Outdoor Cooking",
                probability: 0,
                id: 10486,
              },
            ],
          },
          id: 13010600,
          mature: false,
        },
        {
          name: "Home Decor",
          probability: 0,
          url: "https://www.bizrate.com/homedecor1/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          children: {
            category: [
              {
                name: "Figurines",
                probability: 0,
                url: "https://www.bizrate.com/figurines/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Figurines",
                      probability: 0,
                      id: 10503,
                    },
                    {
                      name: "Sculptures",
                      probability: 0,
                      id: 408,
                    },
                  ],
                },
                id: 13020213,
                mature: false,
              },
              {
                name: "Holiday Ornaments & Decor",
                probability: 0,
                url: "https://www.bizrate.com/holidayornaments_decor/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                children: {
                  category: [
                    {
                      name: "Christmas Decor",
                      probability: 0,
                      url: "https://www.bizrate.com/christmas-decor/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Christmas Decor",
                            probability: 0,
                            id: 470,
                          },
                        ],
                      },
                      id: 469,
                      mature: false,
                    },
                    {
                      name: "Wreaths & Garlands",
                      probability: 0,
                      url: "https://www.bizrate.com/wreaths-garlands/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Wreaths & Garlands",
                            probability: 0,
                            id: 472,
                          },
                        ],
                      },
                      id: 471,
                      mature: false,
                    },
                    {
                      name: "Christmas Trees & Accessories",
                      probability: 0,
                      url: "https://www.bizrate.com/christmas-trees-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Christmas Trees & Accessories",
                            probability: 0,
                            id: 475,
                          },
                        ],
                      },
                      id: 473,
                      mature: false,
                    },
                    {
                      name: "Christmas & Holiday Ornaments",
                      probability: 0,
                      url: "https://www.bizrate.com/christmas-holiday-ornaments/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Christmas & Holiday Ornaments",
                            probability: 0,
                            id: 476,
                          },
                        ],
                      },
                      id: 474,
                      mature: false,
                    },
                    {
                      name: "Other Holiday Decor",
                      probability: 0,
                      url: "https://www.bizrate.com/other-holiday-decor/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Other Holiday Decor",
                            probability: 0,
                            id: 8204,
                          },
                        ],
                      },
                      id: 8203,
                      mature: false,
                    },
                    {
                      name: "Christmas Lights",
                      probability: 0,
                      url: "https://www.bizrate.com/christmas-lights/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Christmas Lights",
                            probability: 0,
                            id: 478,
                          },
                        ],
                      },
                      id: 477,
                      mature: false,
                    },
                    {
                      name: "Patriotic Decor",
                      probability: 0,
                      url: "https://www.bizrate.com/patriotic-decor/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Patriotic Decor",
                            probability: 0,
                            id: 481,
                          },
                        ],
                      },
                      id: 479,
                      mature: false,
                    },
                  ],
                },
                id: 13020211,
                mature: false,
              },
              {
                name: "Weather Instruments",
                probability: 0,
                url: "https://www.bizrate.com/weather-instruments/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Weather Instruments",
                      probability: 0,
                      id: 100000074,
                    },
                  ],
                },
                id: 100000065,
                mature: false,
              },
              {
                name: "Clocks",
                probability: 0,
                url: "https://www.bizrate.com/clocks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Clocks",
                      probability: 0,
                      id: 395,
                    },
                  ],
                },
                id: 13020209,
                mature: false,
              },
              {
                name: "Miscellaneous Home Decor",
                probability: 0,
                url: "https://www.bizrate.com/miscellaneoushomedecor/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Miscellaneous Home Decor",
                      probability: 0,
                      id: 10500,
                    },
                  ],
                },
                id: 13020210,
                mature: false,
              },
              {
                name: "Vases",
                probability: 0,
                url: "https://www.bizrate.com/vases/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Vases",
                      probability: 0,
                      id: 10498,
                    },
                  ],
                },
                id: 13020208,
                mature: false,
              },
              {
                name: "Candles & Accessories",
                probability: 0,
                url: "https://www.bizrate.com/candles_accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Candle & Oil Lamps",
                      probability: 0,
                      id: 8577,
                    },
                    {
                      name: "Candle Holders",
                      probability: 0,
                      id: 8578,
                    },
                    {
                      name: "Candle Accessories",
                      probability: 0,
                      id: 8579,
                    },
                    {
                      name: "Home Fragrance",
                      probability: 0,
                      id: 8580,
                    },
                    {
                      name: "Candles",
                      probability: 0,
                      id: 8576,
                    },
                  ],
                },
                id: 13020205,
                mature: false,
              },
              {
                name: "Picture Frames",
                probability: 0,
                url: "https://www.bizrate.com/picture-frames/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Photo Albums",
                      probability: 0,
                      id: 379,
                    },
                    {
                      name: "Picture Frames",
                      probability: 0,
                      id: 10494,
                    },
                  ],
                },
                id: 13020203,
                mature: false,
              },
              {
                name: "Mirrors",
                probability: 0,
                url: "https://www.bizrate.com/mirrors/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Vanity & Table Mirrors",
                      probability: 0,
                      id: 452,
                    },
                    {
                      name: "Wall & Floor Mirrors",
                      probability: 0,
                      id: 8202,
                    },
                    {
                      name: "Compact & Handheld Mirrors",
                      probability: 0,
                      id: 8206,
                    },
                  ],
                },
                id: 13020204,
                mature: false,
              },
              {
                name: "Wall Decor",
                probability: 0,
                url: "https://www.bizrate.com/artwork_posters/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                children: {
                  category: [
                    {
                      name: "Tin Signs",
                      probability: 0,
                      url: "https://www.bizrate.com/tin-signs/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Tin Signs",
                            probability: 0,
                            id: 450,
                          },
                        ],
                      },
                      id: 449,
                      mature: false,
                    },
                    {
                      name: "Stained Art Glass",
                      probability: 0,
                      url: "https://www.bizrate.com/stained-art-glass/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Stained Art Glass",
                            probability: 0,
                            id: 100001509,
                          },
                        ],
                      },
                      id: 100001510,
                      mature: false,
                    },
                    {
                      name: "Tapestries",
                      probability: 0,
                      url: "https://www.bizrate.com/tapestries/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Tapestries",
                            probability: 0,
                            id: 436,
                          },
                        ],
                      },
                      id: 434,
                      mature: false,
                    },
                    {
                      name: "Posters & Prints",
                      probability: 0,
                      url: "https://www.bizrate.com/posters-prints/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Posters & Prints",
                            probability: 0,
                            id: 413,
                          },
                        ],
                      },
                      id: 412,
                      mature: false,
                    },
                    {
                      name: "Plaques",
                      probability: 0,
                      url: "https://www.bizrate.com/plaques/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Plaques",
                            probability: 0,
                            id: 430,
                          },
                        ],
                      },
                      id: 429,
                      mature: false,
                    },
                    {
                      name: "Wall Sculptures & Masks",
                      probability: 0,
                      url: "https://www.bizrate.com/wall-sculptures-masks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Wall Sculptures & Masks",
                            probability: 0,
                            id: 446,
                          },
                        ],
                      },
                      id: 445,
                      mature: false,
                    },
                    {
                      name: "Decorative Wall Tiles",
                      probability: 0,
                      url: "https://www.bizrate.com/decorative-wall-tiles/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Decorative Wall Tiles",
                            probability: 0,
                            id: 9812,
                          },
                        ],
                      },
                      id: 9806,
                      mature: false,
                    },
                    {
                      name: "Decorative Wall Letters",
                      probability: 0,
                      url: "https://www.bizrate.com/decorative-wall-letters/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Decorative Wall Letters",
                            probability: 0,
                            id: 9813,
                          },
                        ],
                      },
                      id: 9807,
                      mature: false,
                    },
                  ],
                },
                id: 13020202,
                mature: false,
              },
            ],
          },
          id: 13020200,
          mature: false,
        },
        {
          name: "Home Organization",
          probability: 0,
          url: "https://www.bizrate.com/organization_storage/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Workbenches",
                probability: 0,
                id: 100001711,
              },
              {
                name: "Home Organization",
                probability: 0,
                id: 10704,
              },
            ],
          },
          id: 25000800,
          mature: false,
        },
        {
          name: "Water Garden & Irrigation Supplies",
          probability: 0,
          url: "https://www.bizrate.com/watergarden_irrigationsupplies/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Water Garden & Irrigation Supplies",
                probability: 0,
                id: 10492,
              },
            ],
          },
          id: 13011300,
          mature: false,
        },
        {
          name: "Kitchen Tools",
          probability: 0,
          url: "https://www.bizrate.com/kitchensupplies_utensils/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Kitchen Supplies & Utensils",
                probability: 0,
                id: 10479,
              },
              {
                name: "Food Wrap",
                probability: 0,
                id: 100001660,
              },
              {
                name: "Kitchen Aprons",
                probability: 0,
                id: 100001659,
              },
            ],
          },
          id: 13000100,
          mature: false,
        },
        {
          name: "Rugs",
          probability: 0,
          url: "https://www.bizrate.com/rugs/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Indoor & Outdoor Rugs",
                probability: 0,
                id: 10497,
              },
            ],
          },
          id: 13020207,
          mature: false,
        },
        {
          name: "Power Tools",
          probability: 0,
          url: "https://www.bizrate.com/powertools/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Power Tools",
                probability: 0,
                id: 8722,
              },
            ],
          },
          id: 25000600,
          mature: false,
        },
        {
          name: "Arts & Crafts Supplies",
          probability: 0,
          url: "https://www.bizrate.com/arts_craftssupplies/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          children: {
            category: [
              {
                name: "Craft Supplies",
                probability: 0,
                url: "https://www.bizrate.com/craftsupplies/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Rulers",
                      probability: 0,
                      id: 100000072,
                    },
                    {
                      name: "Paper Trimmers & Blades",
                      probability: 0,
                      id: 100000071,
                    },
                    {
                      name: "Craft Supplies",
                      probability: 0,
                      id: 10587,
                    },
                  ],
                },
                id: 13600200,
                mature: false,
              },
              {
                name: "Art Supplies",
                probability: 0,
                url: "https://www.bizrate.com/artsupplies/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Art Supplies",
                      probability: 0,
                      id: 10586,
                    },
                  ],
                },
                id: 13600100,
                mature: false,
              },
            ],
          },
          id: 13600000,
          mature: false,
        },
        {
          name: "Landscape Supplies",
          probability: 0,
          url: "https://www.bizrate.com/landscapesupplies/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Landscape Supplies",
                probability: 0,
                id: 100001948,
              },
            ],
          },
          id: 13011000,
          mature: false,
        },
        {
          name: "Bedding",
          probability: 0,
          url: "https://www.bizrate.com/bedding/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          children: {
            category: [
              {
                name: "Duvet & Comforter Covers",
                probability: 0,
                url: "https://www.bizrate.com/duvet-comforter-covers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Duvet & Comforter Covers",
                      probability: 0,
                      id: 390,
                    },
                  ],
                },
                id: 389,
                mature: false,
              },
              {
                name: "Bedskirts",
                probability: 0,
                url: "https://www.bizrate.com/bedskirts/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Bedskirts",
                      probability: 0,
                      id: 454,
                    },
                  ],
                },
                id: 453,
                mature: false,
              },
              {
                name: "Featherbeds & Covers",
                probability: 0,
                url: "https://www.bizrate.com/featherbeds-covers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Featherbeds & Covers",
                      probability: 0,
                      id: 457,
                    },
                  ],
                },
                id: 455,
                mature: false,
              },
              {
                name: "Mattress Pads",
                probability: 0,
                url: "https://www.bizrate.com/mattress-pads/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Mattress Pads",
                      probability: 0,
                      id: 426,
                    },
                  ],
                },
                id: 425,
                mature: false,
              },
              {
                name: "Blankets & Throws",
                probability: 0,
                url: "https://www.bizrate.com/blankets/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Blankets & Throws",
                      probability: 0,
                      id: 365,
                    },
                  ],
                },
                id: 364,
                mature: false,
              },
              {
                name: "Sheets",
                probability: 0,
                url: "https://www.bizrate.com/sheets/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Sheets",
                      probability: 0,
                      id: 398,
                    },
                  ],
                },
                id: 397,
                mature: false,
              },
              {
                name: "Pillows",
                probability: 0,
                url: "https://www.bizrate.com/pillows/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Pillows",
                      probability: 0,
                      id: 432,
                    },
                  ],
                },
                id: 431,
                mature: false,
              },
              {
                name: "Bedding Sets",
                probability: 0,
                url: "https://www.bizrate.com/bedding-sets/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Bedding Sets",
                      probability: 0,
                      id: 371,
                    },
                  ],
                },
                id: 370,
                mature: false,
              },
              {
                name: "Comforters",
                probability: 0,
                url: "https://www.bizrate.com/comforters/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Comforters",
                      probability: 0,
                      id: 373,
                    },
                  ],
                },
                id: 372,
                mature: false,
              },
              {
                name: "Bedspreads",
                probability: 0,
                url: "https://www.bizrate.com/bedspreads/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Bedspreads",
                      probability: 0,
                      id: 375,
                    },
                  ],
                },
                id: 374,
                mature: false,
              },
              {
                name: "Decorative Pillows",
                probability: 0,
                url: "https://www.bizrate.com/decorative-pillows/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Decorative Pillows",
                      probability: 0,
                      id: 441,
                    },
                  ],
                },
                id: 440,
                mature: false,
              },
              {
                name: "Pillowcases & Pillow Shams",
                probability: 0,
                url: "https://www.bizrate.com/pillowcases-pillow-shams/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Pillowcases & Pillow Shams",
                      probability: 0,
                      id: 411,
                    },
                  ],
                },
                id: 409,
                mature: false,
              },
              {
                name: "Quilts",
                probability: 0,
                url: "https://www.bizrate.com/quilts/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Quilts",
                      probability: 0,
                      id: 382,
                    },
                  ],
                },
                id: 381,
                mature: false,
              },
            ],
          },
          id: 13030200,
          mature: false,
        },
        {
          name: "Safety & Security",
          probability: 0,
          url: "https://www.bizrate.com/safety_security/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Safety & Security",
                probability: 0,
                id: 10700,
              },
            ],
          },
          id: 25000400,
          mature: false,
        },
        {
          name: "Cleaning Supplies",
          probability: 0,
          url: "https://www.bizrate.com/smallstorage_cleaningsupplies/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Cleaning Supplies",
                probability: 0,
                id: 10560,
              },
            ],
          },
          id: 13090100,
          mature: false,
        },
        {
          name: "Outdoor Decor",
          probability: 0,
          url: "https://www.bizrate.com/outdoordecor/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Outdoor Decor",
                probability: 0,
                id: 8358,
              },
            ],
          },
          id: 13010100,
          mature: false,
        },
        {
          name: "Fireplace & Accessories",
          probability: 0,
          url: "https://www.bizrate.com/fireplace-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          children: {
            category: [
              {
                name: "Fireplaces & Mantels",
                probability: 0,
                url: "https://www.bizrate.com/fireplaces-mantels/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Fireplaces & Mantels",
                      probability: 0,
                      id: 421,
                    },
                  ],
                },
                id: 418,
                mature: false,
              },
              {
                name: "Fireplace Parts & Accessories",
                probability: 0,
                url: "https://www.bizrate.com/fireplace-parts-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Fireplace Parts & Accessories",
                      probability: 0,
                      id: 443,
                    },
                  ],
                },
                id: 442,
                mature: false,
              },
              {
                name: "Home Heating Stoves",
                probability: 0,
                url: "https://www.bizrate.com/home-heating-stoves/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Home Heating Stoves",
                      probability: 0,
                      id: 428,
                    },
                  ],
                },
                id: 427,
                mature: false,
              },
            ],
          },
          id: 13020212,
          mature: false,
        },
        {
          name: "Pest Control",
          probability: 0,
          url: "https://www.bizrate.com/pestcontrol/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Pest Control",
                probability: 0,
                id: 10491,
              },
            ],
          },
          id: 13011200,
          mature: false,
        },
        {
          name: "Bath Towels",
          probability: 0,
          url: "https://www.bizrate.com/bathlinens/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Bath Towels",
                probability: 0,
                id: 10509,
              },
            ],
          },
          id: 13030400,
          mature: false,
        },
        {
          name: "Paint & Wall Coverings",
          probability: 0,
          url: "https://www.bizrate.com/paint_wallcoveringsupplies/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Paint & Wall Covering Supplies",
                probability: 0,
                id: 10698,
              },
            ],
          },
          id: 25000200,
          mature: false,
        },
        {
          name: "Furniture",
          probability: 0,
          url: "https://www.bizrate.com/furniture/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          children: {
            category: [
              {
                name: "Living Room Furniture",
                probability: 0,
                url: "https://www.bizrate.com/living-room-furniture/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Coffee & Cocktail Tables",
                      probability: 0,
                      id: 8257,
                    },
                    {
                      name: "Arm Chairs & Recliners",
                      probability: 0,
                      id: 8259,
                    },
                    {
                      name: "Massage Chairs",
                      probability: 0,
                      id: 8261,
                    },
                    {
                      name: "Rocking Chairs & Gliders",
                      probability: 0,
                      id: 8263,
                    },
                    {
                      name: "Media Storage",
                      probability: 0,
                      id: 8265,
                    },
                    {
                      name: "Ottomans & Benches",
                      probability: 0,
                      id: 8267,
                    },
                    {
                      name: "TV & Entertainment Stands",
                      probability: 0,
                      id: 8269,
                    },
                    {
                      name: "Room Dividers & Privacy Screens",
                      probability: 0,
                      id: 8271,
                    },
                    {
                      name: "Bean Bags",
                      probability: 0,
                      id: 8273,
                    },
                    {
                      name: "Side & End Tables",
                      probability: 0,
                      id: 8312,
                    },
                    {
                      name: "Console Tables",
                      probability: 0,
                      id: 8314,
                    },
                    {
                      name: "Sofas",
                      probability: 0,
                      id: 8251,
                    },
                    {
                      name: "Love Seats",
                      probability: 0,
                      id: 8252,
                    },
                    {
                      name: "Sofa Beds & Sleepers",
                      probability: 0,
                      id: 8253,
                    },
                    {
                      name: "Futons & Futon Frames",
                      probability: 0,
                      id: 8254,
                    },
                    {
                      name: "Sectionals",
                      probability: 0,
                      id: 8255,
                    },
                    {
                      name: "Slipcovers",
                      probability: 0,
                      id: 8256,
                    },
                  ],
                },
                id: 13170101,
                mature: false,
              },
              {
                name: "Dining Room Furniture",
                probability: 0,
                url: "https://www.bizrate.com/diningroomfurniture/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Dining Room Sets",
                      probability: 0,
                      id: 100001285,
                    },
                    {
                      name: "Dining Room Chairs",
                      probability: 0,
                      id: 100001284,
                    },
                    {
                      name: "Dining Room Tables",
                      probability: 0,
                      id: 8313,
                    },
                    {
                      name: "Pub Furniture",
                      probability: 0,
                      id: 8315,
                    },
                    {
                      name: "Chair Cushions",
                      probability: 0,
                      id: 100001340,
                    },
                    {
                      name: "Cabinets & Buffets",
                      probability: 0,
                      id: 100001291,
                    },
                  ],
                },
                id: 13170102,
                mature: false,
              },
              {
                name: "Kitchen Furniture",
                probability: 0,
                url: "https://www.bizrate.com/kitchenfurniture1/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Kitchenettes",
                      probability: 0,
                      id: 100001878,
                    },
                    {
                      name: "Kitchen Pantries & Cabinets",
                      probability: 0,
                      id: 100001877,
                    },
                    {
                      name: "Countertops",
                      probability: 0,
                      id: 100001879,
                    },
                    {
                      name: "Home Bars",
                      probability: 0,
                      id: 100001876,
                    },
                    {
                      name: "Kitchen Carts & Islands",
                      probability: 0,
                      id: 100001437,
                    },
                    {
                      name: "Wine Racks",
                      probability: 0,
                      id: 100001885,
                    },
                    {
                      name: "Kitchen Racks & Stands",
                      probability: 0,
                      id: 100001433,
                    },
                    {
                      name: "Prep Tables",
                      probability: 0,
                      id: 100001419,
                    },
                  ],
                },
                id: 13170103,
                mature: false,
              },
              {
                name: "Bedroom Furniture",
                probability: 0,
                url: "https://www.bizrate.com/bedroomfurniture/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                children: {
                  category: [
                    {
                      name: "Dressers & Nightstands",
                      probability: 0,
                      url: "https://www.bizrate.com/dressers-nightstands/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Dressers & Nightstands",
                            probability: 0,
                            id: 8339,
                          },
                        ],
                      },
                      id: 8338,
                      mature: false,
                    },
                    {
                      name: "Beds",
                      probability: 0,
                      url: "https://www.bizrate.com/beds/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Beds & Headboards",
                            probability: 0,
                            id: 8341,
                          },
                          {
                            name: "Bunk Beds",
                            probability: 0,
                            id: 100001092,
                          },
                        ],
                      },
                      id: 8340,
                      mature: false,
                    },
                    {
                      name: "Bedroom Sets",
                      probability: 0,
                      url: "https://www.bizrate.com/bedroom-sets/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Bedroom Sets",
                            probability: 0,
                            id: 8343,
                          },
                        ],
                      },
                      id: 8342,
                      mature: false,
                    },
                    {
                      name: "Bedroom Vanities",
                      probability: 0,
                      url: "https://www.bizrate.com/vanities/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Bedroom Vanities & Stools",
                            probability: 0,
                            id: 8345,
                          },
                        ],
                      },
                      id: 8344,
                      mature: false,
                    },
                    {
                      name: "Mattresses & Boxsprings",
                      probability: 0,
                      url: "https://www.bizrate.com/mattresses-boxsprings/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Mattresses & Boxsprings",
                            probability: 0,
                            id: 8347,
                          },
                        ],
                      },
                      id: 8346,
                      mature: false,
                    },
                    {
                      name: "Armoires & Wardrobes",
                      probability: 0,
                      url: "https://www.bizrate.com/armoires-wardrobes/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Armoires & Wardrobes",
                            probability: 0,
                            id: 8349,
                          },
                        ],
                      },
                      id: 8348,
                      mature: false,
                    },
                  ],
                },
                id: 13170104,
                mature: false,
              },
            ],
          },
          id: 13170100,
          mature: false,
        },
        {
          name: "Seeds & Bulbs",
          probability: 0,
          url: "https://www.bizrate.com/seeds_bulbs/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Seeds & Bulbs",
                probability: 0,
                id: 10483,
              },
            ],
          },
          id: 13010300,
          mature: false,
        },
        {
          name: "Flooring Supplies",
          probability: 0,
          url: "https://www.bizrate.com/flooringsupplies/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Flooring Supplies",
                probability: 0,
                id: 10705,
              },
            ],
          },
          id: 25000900,
          mature: false,
        },
        {
          name: "Desk Accessories",
          probability: 0,
          url: "https://www.bizrate.com/deskaccessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          children: {
            category: [
              {
                name: "Paperweights",
                probability: 0,
                url: "https://www.bizrate.com/paperweights/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Paperweights",
                      probability: 0,
                      id: 9815,
                    },
                  ],
                },
                id: 9809,
                mature: false,
              },
              {
                name: "Organizers, Etc.",
                probability: 0,
                url: "https://www.bizrate.com/organizersetc/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Organizers, Etc.",
                      probability: 0,
                      id: 10558,
                    },
                  ],
                },
                id: 13080202,
                mature: false,
              },
              {
                name: "Desk Sets",
                probability: 0,
                url: "https://www.bizrate.com/desksets/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Desk Sets",
                      probability: 0,
                      id: 10557,
                    },
                  ],
                },
                id: 13080201,
                mature: false,
              },
            ],
          },
          id: 13080200,
          mature: false,
        },
        {
          name: "Cutlery",
          probability: 0,
          url: "https://www.bizrate.com/cutlery/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Butcher Blocks",
                probability: 0,
                id: 100001432,
              },
              {
                name: "Cutlery",
                probability: 0,
                id: 10480,
              },
            ],
          },
          id: 13000200,
          mature: false,
        },
        {
          name: "Bath Accessories",
          probability: 0,
          url: "https://www.bizrate.com/bathaccessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Shower Mats",
                probability: 0,
                id: 100001303,
              },
              {
                name: "Bathroom Accessories & Sets",
                probability: 0,
                id: 100001310,
              },
              {
                name: "Towel Stands & Warmers",
                probability: 0,
                id: 100001309,
              },
              {
                name: "Bathroom Hardware",
                probability: 0,
                id: 100001311,
              },
              {
                name: "Bath Rugs",
                probability: 0,
                id: 100001306,
              },
              {
                name: "Shower Curtain Hooks & Rods",
                probability: 0,
                id: 100001305,
              },
              {
                name: "Bathroom Furniture",
                probability: 0,
                id: 100001308,
              },
              {
                name: "Shower Curtains",
                probability: 0,
                id: 100001307,
              },
            ],
          },
          id: 13030600,
          mature: false,
        },
        {
          name: "Tableware",
          probability: 0,
          url: "https://www.bizrate.com/tableware/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          children: {
            category: [
              {
                name: "Bar Shakers & Tools",
                probability: 0,
                url: "https://www.bizrate.com/barshakers_tools/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Shakers & Bar Tools",
                      probability: 0,
                      id: 268,
                    },
                  ],
                },
                id: 13040303,
                mature: false,
              },
              {
                name: "Flatware",
                probability: 0,
                url: "https://www.bizrate.com/flatware/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Flatware",
                      probability: 0,
                      id: 10513,
                    },
                    {
                      name: "Serving Flatware",
                      probability: 0,
                      id: 349,
                    },
                  ],
                },
                id: 13040205,
                mature: false,
              },
              {
                name: "Dinnerware & Serveware",
                probability: 0,
                url: "https://www.bizrate.com/dinnerware_serveware/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                children: {
                  category: [
                    {
                      name: "Chafers",
                      probability: 0,
                      url: "https://www.bizrate.com/chafers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Chafers",
                            probability: 0,
                            id: 292,
                          },
                        ],
                      },
                      id: 290,
                      mature: false,
                    },
                    {
                      name: "Gravy Boats & Tureens",
                      probability: 0,
                      url: "https://www.bizrate.com/gravy-boats-tureens/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Gravy Boats & Tureens",
                            probability: 0,
                            id: 323,
                          },
                        ],
                      },
                      id: 322,
                      mature: false,
                    },
                    {
                      name: "Trivets & Coasters",
                      probability: 0,
                      url: "https://www.bizrate.com/trivets-coasters/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Trivets & Coasters",
                            probability: 0,
                            id: 355,
                          },
                        ],
                      },
                      id: 354,
                      mature: false,
                    },
                    {
                      name: "Coffee Pots",
                      probability: 0,
                      url: "https://www.bizrate.com/coffee-pots/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Coffee Pots",
                            probability: 0,
                            id: 325,
                          },
                        ],
                      },
                      id: 324,
                      mature: false,
                    },
                    {
                      name: "Plates",
                      probability: 0,
                      url: "https://www.bizrate.com/plates/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Plates",
                            probability: 0,
                            id: 357,
                          },
                        ],
                      },
                      id: 356,
                      mature: false,
                    },
                    {
                      name: "Creamers & Sugar Bowls",
                      probability: 0,
                      url: "https://www.bizrate.com/creamers-sugar-bowls/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Creamers & Sugar Bowls",
                            probability: 0,
                            id: 296,
                          },
                        ],
                      },
                      id: 295,
                      mature: false,
                    },
                    {
                      name: "Platters",
                      probability: 0,
                      url: "https://www.bizrate.com/platters/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Platters",
                            probability: 0,
                            id: 331,
                          },
                        ],
                      },
                      id: 330,
                      mature: false,
                    },
                    {
                      name: "Bowls",
                      probability: 0,
                      url: "https://www.bizrate.com/bowls/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Bowls",
                            probability: 0,
                            id: 300,
                          },
                        ],
                      },
                      id: 299,
                      mature: false,
                    },
                    {
                      name: "Teapots & Tea Sets",
                      probability: 0,
                      url: "https://www.bizrate.com/teapots-tea-sets/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Teapots & Tea Sets",
                            probability: 0,
                            id: 333,
                          },
                        ],
                      },
                      id: 332,
                      mature: false,
                    },
                    {
                      name: "Stands & Serving Trays",
                      probability: 0,
                      url: "https://www.bizrate.com/stands-serving-trays/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Stands & Serving Trays",
                            probability: 0,
                            id: 346,
                          },
                        ],
                      },
                      id: 345,
                      mature: false,
                    },
                    {
                      name: "Dinnerware Sets",
                      probability: 0,
                      url: "https://www.bizrate.com/dinnerware-sets/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Dinnerware Sets",
                            probability: 0,
                            id: 316,
                          },
                        ],
                      },
                      id: 315,
                      mature: false,
                    },
                    {
                      name: "Butter Dishes",
                      probability: 0,
                      url: "https://www.bizrate.com/butter-dishes/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Butter Dishes",
                            probability: 0,
                            id: 348,
                          },
                        ],
                      },
                      id: 347,
                      mature: false,
                    },
                    {
                      name: "Decanters & Pitchers",
                      probability: 0,
                      url: "https://www.bizrate.com/decanters-pitchers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Decanters & Pitchers",
                            probability: 0,
                            id: 318,
                          },
                        ],
                      },
                      id: 317,
                      mature: false,
                    },
                    {
                      name: "Beverage Service",
                      probability: 0,
                      url: "https://www.bizrate.com/beverage-service/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Beverage Service",
                            probability: 0,
                            id: 289,
                          },
                        ],
                      },
                      id: 288,
                      mature: false,
                    },
                  ],
                },
                id: 13040203,
                mature: false,
              },
              {
                name: "Drinkware",
                probability: 0,
                url: "https://www.bizrate.com/drinkware/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                children: {
                  category: [
                    {
                      name: "Water & Juice Glasses",
                      probability: 0,
                      url: "https://www.bizrate.com/water-juice-glasses/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Water & Juice Glasses",
                            probability: 0,
                            id: 310,
                          },
                        ],
                      },
                      id: 306,
                      mature: false,
                    },
                    {
                      name: "Bar & Cocktail Glasses",
                      probability: 0,
                      url: "https://www.bizrate.com/bar-cocktail-glasses/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Bar & Cocktail Glasses",
                            probability: 0,
                            id: 294,
                          },
                        ],
                      },
                      id: 293,
                      mature: false,
                    },
                    {
                      name: "Cups & Saucers",
                      probability: 0,
                      url: "https://www.bizrate.com/cups-saucers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Cups & Saucers",
                            probability: 0,
                            id: 327,
                          },
                        ],
                      },
                      id: 326,
                      mature: false,
                    },
                    {
                      name: "Mugs",
                      probability: 0,
                      url: "https://www.bizrate.com/mugs/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Mugs",
                            probability: 0,
                            id: 328,
                          },
                        ],
                      },
                      id: 329,
                      mature: false,
                    },
                    {
                      name: "Wine Glasses & Champagne Flutes",
                      probability: 0,
                      url: "https://www.bizrate.com/wine-glasses-champagne-flutes/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                      atoms: {
                        atom: [
                          {
                            name: "Wine Glasses & Champagne Flutes",
                            probability: 0,
                            id: 314,
                          },
                        ],
                      },
                      id: 313,
                      mature: false,
                    },
                  ],
                },
                id: 13040204,
                mature: false,
              },
            ],
          },
          id: 13040200,
          mature: false,
        },
        {
          name: "Outdoor Furniture",
          probability: 0,
          url: "https://www.bizrate.com/outdoorfurniture/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          children: {
            category: [
              {
                name: "Outdoor Cushions",
                probability: 0,
                url: "https://www.bizrate.com/outdoor-cushions/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Outdoor Cushions",
                      probability: 0,
                      id: 100001552,
                    },
                  ],
                },
                id: 100001544,
                mature: false,
              },
              {
                name: "Picnic Tables",
                probability: 0,
                url: "https://www.bizrate.com/picnic-tables/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Picnic Tables",
                      probability: 0,
                      id: 100001558,
                    },
                  ],
                },
                id: 100001550,
                mature: false,
              },
              {
                name: "Patio Furniture",
                probability: 0,
                url: "https://www.bizrate.com/patio-furniture/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Patio Furniture",
                      probability: 0,
                      id: 100001557,
                    },
                  ],
                },
                id: 100001549,
                mature: false,
              },
              {
                name: "Outdoor Furniture Covers",
                probability: 0,
                url: "https://www.bizrate.com/outdoor-furniture-covers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Outdoor Furniture Covers",
                      probability: 0,
                      id: 100001564,
                    },
                  ],
                },
                id: 100001565,
                mature: false,
              },
              {
                name: "Outdoor Canopies",
                probability: 0,
                url: "https://www.bizrate.com/outdoor-canopies/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Outdoor Canopies",
                      probability: 0,
                      id: 100001554,
                    },
                  ],
                },
                id: 100001546,
                mature: false,
              },
              {
                name: "Patio Umbrellas",
                probability: 0,
                url: "https://www.bizrate.com/patio-umbrellas/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Patio Umbrellas & Stands",
                      probability: 0,
                      id: 100001553,
                    },
                  ],
                },
                id: 100001545,
                mature: false,
              },
              {
                name: "Hammocks & Stands",
                probability: 0,
                url: "https://www.bizrate.com/hammocks-stands/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Hammocks & Stands",
                      probability: 0,
                      id: 100001556,
                    },
                  ],
                },
                id: 100001548,
                mature: false,
              },
              {
                name: "Outdoor Benches",
                probability: 0,
                url: "https://www.bizrate.com/outdoor-benches/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Outdoor Benches",
                      probability: 0,
                      id: 100001555,
                    },
                  ],
                },
                id: 100001547,
                mature: false,
              },
            ],
          },
          id: 13010500,
          mature: false,
        },
        {
          name: "Electrical Supplies",
          probability: 0,
          url: "https://www.bizrate.com/electricalsupplies/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Electrical Supplies",
                probability: 0,
                id: 10703,
              },
            ],
          },
          id: 25000700,
          mature: false,
        },
        {
          name: "Miscellaneous Home & Garden",
          probability: 0,
          url: "https://www.bizrate.com/miscellaneoushome_garden/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Miscellaneous Home & Garden",
                probability: 0,
                id: 10580,
              },
            ],
          },
          id: 13130000,
          mature: false,
        },
        {
          name: "Table Linens",
          probability: 0,
          url: "https://www.bizrate.com/tablelinens/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Table Linens",
                probability: 0,
                id: 10517,
              },
            ],
          },
          id: 13040400,
          mature: false,
        },
        {
          name: "Fertilizers",
          probability: 0,
          url: "https://www.bizrate.com/fertilizers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Fertilizers",
                probability: 0,
                id: 10487,
              },
            ],
          },
          id: 13010700,
          mature: false,
        },
        {
          name: "Window Treatments",
          probability: 0,
          url: "https://www.bizrate.com/windowtreatments/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          children: {
            category: [
              {
                name: "Rods & Accessories",
                probability: 0,
                url: "https://www.bizrate.com/curtain-rods-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Curtain Rods & Accessories",
                      probability: 0,
                      id: 359,
                    },
                  ],
                },
                id: 358,
                mature: false,
              },
              {
                name: "Valances",
                probability: 0,
                url: "https://www.bizrate.com/window-valances/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Window Valances",
                      probability: 0,
                      id: 344,
                    },
                  ],
                },
                id: 343,
                mature: false,
              },
              {
                name: "Curtains & Drapes",
                probability: 0,
                url: "https://www.bizrate.com/curtains_drapes/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Curtains & Drapes",
                      probability: 0,
                      id: 10504,
                    },
                  ],
                },
                id: 13020301,
                mature: false,
              },
              {
                name: "Shades",
                probability: 0,
                url: "https://www.bizrate.com/window-shades/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Window Shades",
                      probability: 0,
                      id: 335,
                    },
                  ],
                },
                id: 334,
                mature: false,
              },
              {
                name: "Blinds",
                probability: 0,
                url: "https://www.bizrate.com/window-blinds/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Window Blinds",
                      probability: 0,
                      id: 352,
                    },
                  ],
                },
                id: 351,
                mature: false,
              },
              {
                name: "Shutters",
                probability: 0,
                url: "https://www.bizrate.com/window-shutters/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Window Shutters",
                      probability: 0,
                      id: 321,
                    },
                  ],
                },
                id: 319,
                mature: false,
              },
            ],
          },
          id: 13020300,
          mature: false,
        },
        {
          name: "Hand Tools",
          probability: 0,
          url: "https://www.bizrate.com/handtools/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Hand Tools",
                probability: 0,
                id: 10701,
              },
            ],
          },
          id: 25000500,
          mature: false,
        },
        {
          name: "Cooking & Baking",
          probability: 0,
          url: "https://www.bizrate.com/cooking_baking/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Bakeware",
                probability: 0,
                id: 100001658,
              },
              {
                name: "Cookware",
                probability: 0,
                id: 100001657,
              },
            ],
          },
          id: 13050200,
          mature: false,
        },
        {
          name: "Garden Tools",
          probability: 0,
          url: "https://www.bizrate.com/gardentools/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Manual Garden Tools",
                probability: 0,
                id: 10482,
              },
            ],
          },
          id: 13010200,
          mature: false,
        },
        {
          name: "Garden Organization",
          probability: 0,
          url: "https://www.bizrate.com/gardenorganization_storage/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Garden Organization & Storage",
                probability: 0,
                id: 10489,
              },
              {
                name: "Sheds & Outdoor Storage Containers",
                probability: 0,
                id: 100001950,
              },
            ],
          },
          id: 13010900,
          mature: false,
        },
        {
          name: "Home Hardware",
          probability: 0,
          url: "https://www.bizrate.com/homehardware/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Home Hardware",
                probability: 0,
                id: 10699,
              },
            ],
          },
          id: 25000300,
          mature: false,
        },
        {
          name: "Lawn Mowers & Tractors",
          probability: 0,
          url: "https://www.bizrate.com/lawn-mowers-tractors/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Lawn Tractor Attachments",
                probability: 0,
                id: 100001810,
              },
              {
                name: "Lawn Mowers & Tractors",
                probability: 0,
                id: 100001774,
              },
            ],
          },
          id: 100001773,
          mature: false,
        },
        {
          name: "Building Supplies",
          probability: 0,
          url: "https://www.bizrate.com/buildingsupplies/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Building Supplies",
                probability: 0,
                id: 10706,
              },
            ],
          },
          id: 25001000,
          mature: false,
        },
        {
          name: "Lighting",
          probability: 0,
          url: "https://www.bizrate.com/homelighting/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          children: {
            category: [
              {
                name: "Home Lighting Accessories",
                probability: 0,
                url: "https://www.bizrate.com/home-lighting-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Home Lighting Accessories",
                      probability: 0,
                      id: 8455,
                    },
                  ],
                },
                id: 8449,
                mature: false,
              },
              {
                name: "String Lights",
                probability: 0,
                url: "https://www.bizrate.com/string-lights/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "String Lights",
                      probability: 0,
                      id: 8460,
                    },
                  ],
                },
                id: 8459,
                mature: false,
              },
              {
                name: "Landscape Lighting & Accessories",
                probability: 0,
                url: "https://www.bizrate.com/landscape-lighting-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Landscape Lighting & Accessories",
                      probability: 0,
                      id: 8462,
                    },
                  ],
                },
                id: 8461,
                mature: false,
              },
              {
                name: "Outdoor Wall & Ceiling Lighting",
                probability: 0,
                url: "https://www.bizrate.com/outdoo-wall-ceiling-lighting/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Outdoor Wall & Ceiling Lighting",
                      probability: 0,
                      id: 8464,
                    },
                  ],
                },
                id: 8463,
                mature: false,
              },
              {
                name: "Night Lights",
                probability: 0,
                url: "https://www.bizrate.com/night-lights/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Night Lights",
                      probability: 0,
                      id: 9425,
                    },
                  ],
                },
                id: 9424,
                mature: false,
              },
              {
                name: "Security Lighting",
                probability: 0,
                url: "https://www.bizrate.com/security-lighting/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Security Lighting",
                      probability: 0,
                      id: 8466,
                    },
                  ],
                },
                id: 8465,
                mature: false,
              },
              {
                name: "Chandeliers",
                probability: 0,
                url: "https://www.bizrate.com/chandeliers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Chandeliers",
                      probability: 0,
                      id: 8276,
                    },
                  ],
                },
                id: 8275,
                mature: false,
              },
              {
                name: "Wall Mounts & Picture Lighting",
                probability: 0,
                url: "https://www.bizrate.com/wall-mounts-picture-lighting/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Wall Mounts & Picture Lighting",
                      probability: 0,
                      id: 8508,
                    },
                  ],
                },
                id: 8501,
                mature: false,
              },
              {
                name: "Bathroom & Vanity Lighting",
                probability: 0,
                url: "https://www.bizrate.com/bathroom-vanity-lighting/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Bathroom & Vanity Lighting",
                      probability: 0,
                      id: 8509,
                    },
                  ],
                },
                id: 8502,
                mature: false,
              },
              {
                name: "Floor Lamps",
                probability: 0,
                url: "https://www.bizrate.com/floor-lamps/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Floor Lamps",
                      probability: 0,
                      id: 8510,
                    },
                  ],
                },
                id: 8504,
                mature: false,
              },
              {
                name: "Table Lamps",
                probability: 0,
                url: "https://www.bizrate.com/table-lamps/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Table Lamps",
                      probability: 0,
                      id: 8512,
                    },
                  ],
                },
                id: 8505,
                mature: false,
              },
              {
                name: "Desk Lamps",
                probability: 0,
                url: "https://www.bizrate.com/desk-lamps/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Desk Lamps",
                      probability: 0,
                      id: 8511,
                    },
                  ],
                },
                id: 8506,
                mature: false,
              },
              {
                name: "Lamp Shades & Bases",
                probability: 0,
                url: "https://www.bizrate.com/lamp-shades-bases/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Lamp Shades & Bases",
                      probability: 0,
                      id: 8513,
                    },
                  ],
                },
                id: 8507,
                mature: false,
              },
              {
                name: "Ceiling Fans",
                probability: 0,
                url: "https://www.bizrate.com/ceiling-fans/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Ceiling Fans",
                      probability: 0,
                      id: 8450,
                    },
                  ],
                },
                id: 8444,
                mature: false,
              },
              {
                name: "Pendant Lighting",
                probability: 0,
                url: "https://www.bizrate.com/pendant-lighting/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Pendant Lighting",
                      probability: 0,
                      id: 8451,
                    },
                  ],
                },
                id: 8445,
                mature: false,
              },
              {
                name: "Flush Mounts",
                probability: 0,
                url: "https://www.bizrate.com/flush-mounts/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Flush Mounts",
                      probability: 0,
                      id: 8452,
                    },
                  ],
                },
                id: 8446,
                mature: false,
              },
              {
                name: "Track Lights",
                probability: 0,
                url: "https://www.bizrate.com/track-lights/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Track Lights",
                      probability: 0,
                      id: 8453,
                    },
                  ],
                },
                id: 8447,
                mature: false,
              },
              {
                name: "Neon Signs",
                probability: 0,
                url: "https://www.bizrate.com/neon-signs/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Neon Signs",
                      probability: 0,
                      id: 9798,
                    },
                  ],
                },
                id: 9791,
                mature: false,
              },
              {
                name: "Recessed Lighting",
                probability: 0,
                url: "https://www.bizrate.com/recessed-lighting/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Recessed Lighting",
                      probability: 0,
                      id: 8454,
                    },
                  ],
                },
                id: 8448,
                mature: false,
              },
              {
                name: "Ceiling Medallions",
                probability: 0,
                url: "https://www.bizrate.com/ceiling-medallions/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Ceiling Medallions",
                      probability: 0,
                      id: 9797,
                    },
                  ],
                },
                id: 9792,
                mature: false,
              },
            ],
          },
          id: 13070300,
          mature: false,
        },
        {
          name: "Plumbing Supplies",
          probability: 0,
          url: "https://www.bizrate.com/plumbingsupplies/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Other Plumbing Supplies",
                probability: 0,
                id: 8674,
              },
              {
                name: "Shower Heads",
                probability: 0,
                id: 8676,
              },
              {
                name: "Toilets",
                probability: 0,
                id: 8677,
              },
              {
                name: "Toilet Seats",
                probability: 0,
                id: 8680,
              },
              {
                name: "Garbage Disposals",
                probability: 0,
                id: 8682,
              },
              {
                name: "Bathtubs",
                probability: 0,
                id: 8661,
              },
              {
                name: "Shower Enclosures",
                probability: 0,
                id: 8662,
              },
              {
                name: "Faucets & Sprayers",
                probability: 0,
                id: 8663,
              },
              {
                name: "Plumbing Pipes & Fittings",
                probability: 0,
                id: 8664,
              },
              {
                name: "Sinks",
                probability: 0,
                id: 8665,
              },
              {
                name: "Drain Hardware",
                probability: 0,
                id: 8666,
              },
              {
                name: "Toilet Parts",
                probability: 0,
                id: 8732,
              },
              {
                name: "Commercial Sinks",
                probability: 0,
                id: 100001420,
              },
            ],
          },
          id: 25000100,
          mature: false,
        },
        {
          name: "Outdoor Power Equipment",
          probability: 0,
          url: "https://www.bizrate.com/outdoor-power-equipment/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Outdoor Power Equipment",
                probability: 0,
                id: 100001776,
              },
            ],
          },
          id: 100001772,
          mature: false,
        },
      ],
    },
    id: 13000000,
    mature: false,
  },
  {
    name: "Video Games",
    probability: 0,
    url: "https://www.bizrate.com/videogames1/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
    children: {
      category: [
        {
          name: "Video Game Accessories",
          probability: 0,
          url: "https://www.bizrate.com/videogameaccessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          children: {
            category: [
              {
                name: "Nintendo DS Accessories",
                probability: 0,
                url: "https://www.bizrate.com/nintendodsaccessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Nintendo DS Accessories",
                      probability: 0,
                      id: 10623,
                    },
                  ],
                },
                id: 15010209,
                mature: false,
              },
              {
                name: "Video Game Chairs",
                probability: 0,
                url: "https://www.bizrate.com/video-game-chairs/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Video Game Chairs",
                      probability: 0,
                      id: 9802,
                    },
                  ],
                },
                id: 9800,
                mature: false,
              },
              {
                name: "PlayStation 3 Accessories",
                probability: 0,
                url: "https://www.bizrate.com/playstation-3-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "PlayStation 3 Accessories",
                      probability: 0,
                      id: 9619,
                    },
                  ],
                },
                id: 9610,
                mature: false,
              },
              {
                name: "PSP Accessories",
                probability: 0,
                url: "https://www.bizrate.com/psp-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "PSP Accessories",
                      probability: 0,
                      id: 9620,
                    },
                  ],
                },
                id: 9611,
                mature: false,
              },
              {
                name: "GameCube Accessories",
                probability: 0,
                url: "https://www.bizrate.com/gamecube-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "GameCube Accessories",
                      probability: 0,
                      id: 9621,
                    },
                  ],
                },
                id: 9612,
                mature: false,
              },
              {
                name: "Wii Accessories",
                probability: 0,
                url: "https://www.bizrate.com/wii-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Wii Accessories",
                      probability: 0,
                      id: 9614,
                    },
                  ],
                },
                id: 9613,
                mature: false,
              },
              {
                name: "Game Boy Micro Accessories",
                probability: 0,
                url: "https://www.bizrate.com/gameboymicroaccessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Game Boy Micro Accessories",
                      probability: 0,
                      id: 10620,
                    },
                  ],
                },
                id: 15010206,
                mature: false,
              },
              {
                name: "Nintendo Accessories",
                probability: 0,
                url: "https://www.bizrate.com/gamecubeaccessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Nintendo Accessories",
                      probability: 0,
                      id: 10619,
                    },
                  ],
                },
                id: 15010205,
                mature: false,
              },
              {
                name: "Game Boy Advance Accessories",
                probability: 0,
                url: "https://www.bizrate.com/gameboyadvanceaccessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Game Boy Advance Accessories",
                      probability: 0,
                      id: 10621,
                    },
                  ],
                },
                id: 15010207,
                mature: false,
              },
              {
                name: "PlayStation Accessories",
                probability: 0,
                url: "https://www.bizrate.com/playstationaccessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "PlayStation Accessories",
                      probability: 0,
                      id: 10616,
                    },
                  ],
                },
                id: 15010202,
                mature: false,
              },
              {
                name: "PlayStation 4 Accessories",
                probability: 0,
                url: "https://www.bizrate.com/playstation-4-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "PlayStation 4 Accessories",
                      probability: 0,
                      id: 100003469,
                    },
                  ],
                },
                id: 100003434,
                mature: false,
              },
              {
                name: "Dreamcast Accessories",
                probability: 0,
                url: "https://www.bizrate.com/dreamcastaccessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Dreamcast Accessories",
                      probability: 0,
                      id: 10615,
                    },
                  ],
                },
                id: 15010201,
                mature: false,
              },
              {
                name: "Xbox Accessories",
                probability: 0,
                url: "https://www.bizrate.com/xboxaccessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Xbox Accessories",
                      probability: 0,
                      id: 10618,
                    },
                  ],
                },
                id: 15010204,
                mature: false,
              },
              {
                name: "PlayStation 2 Accessories",
                probability: 0,
                url: "https://www.bizrate.com/playstation2accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "PlayStation 2 Accessories",
                      probability: 0,
                      id: 10617,
                    },
                  ],
                },
                id: 15010203,
                mature: false,
              },
            ],
          },
          id: 15010200,
          mature: false,
        },
        {
          name: "Video Games",
          probability: 0,
          url: "https://www.bizrate.com/videogames/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Xbox One Games",
                probability: 0,
                id: 100003448,
              },
              {
                name: "Gamecube Games",
                probability: 0,
                id: 100003458,
              },
              {
                name: "Video Games",
                probability: 0,
                id: 10614,
              },
              {
                name: "3DS Games",
                probability: 0,
                id: 100003457,
              },
              {
                name: "PS3 Games",
                probability: 0,
                id: 100003454,
              },
              {
                name: "PS4 Games",
                probability: 0,
                id: 100003453,
              },
              {
                name: "PS1 Games",
                probability: 0,
                id: 100003456,
              },
              {
                name: "PS2 Games",
                probability: 0,
                id: 100003455,
              },
              {
                name: "Wii U Games",
                probability: 0,
                id: 100003450,
              },
              {
                name: "Xbox 360 Games",
                probability: 0,
                id: 100003449,
              },
              {
                name: "Sony PSP Games",
                probability: 0,
                id: 100003452,
              },
              {
                name: "Wii Games",
                probability: 0,
                id: 100003451,
              },
            ],
          },
          id: 15010100,
          mature: false,
        },
        {
          name: "Miscellaneous Video Games",
          probability: 0,
          url: "https://www.bizrate.com/miscellaneousvideogames/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Miscellaneous Video Games",
                probability: 0,
                id: 10633,
              },
            ],
          },
          id: 15010400,
          mature: false,
        },
        {
          name: "Video Game Consoles",
          probability: 0,
          url: "https://www.bizrate.com/video-game-consoles/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          children: {
            category: [
              {
                name: "Nintendo DS",
                probability: 0,
                url: "https://www.bizrate.com/nintendods/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Nintendo DS",
                      probability: 0,
                      id: 10632,
                    },
                  ],
                },
                id: 15010309,
                mature: false,
              },
              {
                name: "Game Boy Micro Consoles",
                probability: 0,
                url: "https://www.bizrate.com/gameboymicroconsoles/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Game Boy Micro Consoles",
                      probability: 0,
                      id: 10629,
                    },
                  ],
                },
                id: 15010306,
                mature: false,
              },
              {
                name: "Nintendo Consoles",
                probability: 0,
                url: "https://www.bizrate.com/nintendoconsoles/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Nintendo Consoles",
                      probability: 0,
                      id: 10628,
                    },
                  ],
                },
                id: 15010305,
                mature: false,
              },
              {
                name: "Wii Consoles",
                probability: 0,
                url: "https://www.bizrate.com/wii-consoles/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Wii Consoles",
                      probability: 0,
                      id: 9615,
                    },
                  ],
                },
                id: 9606,
                mature: false,
              },
              {
                name: "GameCube Consoles",
                probability: 0,
                url: "https://www.bizrate.com/gamecube-consoles/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "GameCube Consoles",
                      probability: 0,
                      id: 9618,
                    },
                  ],
                },
                id: 9607,
                mature: false,
              },
              {
                name: "Game Boy Advance Consoles",
                probability: 0,
                url: "https://www.bizrate.com/gameboyadvanceconsoles/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Game Boy Advance Consoles",
                      probability: 0,
                      id: 10630,
                    },
                  ],
                },
                id: 15010307,
                mature: false,
              },
              {
                name: "PSP Consoles",
                probability: 0,
                url: "https://www.bizrate.com/psp-consoles/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "PSP Consoles",
                      probability: 0,
                      id: 9617,
                    },
                  ],
                },
                id: 9608,
                mature: false,
              },
              {
                name: "PlayStation 3 Consoles",
                probability: 0,
                url: "https://www.bizrate.com/playstation-3-consoles/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "PlayStation 3 Consoles",
                      probability: 0,
                      id: 9616,
                    },
                  ],
                },
                id: 9609,
                mature: false,
              },
              {
                name: "Xbox One Consoles",
                probability: 0,
                url: "https://www.bizrate.com/xbox-one-consoles/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Xbox One Consoles",
                      probability: 0,
                      id: 100003461,
                    },
                  ],
                },
                id: 100003430,
                mature: false,
              },
              {
                name: "Nintendo 3DS",
                probability: 0,
                url: "https://www.bizrate.com/nintendo-3ds/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "3DS Consoles",
                      probability: 0,
                      id: 100003468,
                    },
                  ],
                },
                id: 100003432,
                mature: false,
              },
              {
                name: "Xbox 360 Consoles",
                probability: 0,
                url: "https://www.bizrate.com/xbox-360-consoles/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Xbox 360 Consoles",
                      probability: 0,
                      id: 100003462,
                    },
                  ],
                },
                id: 100003431,
                mature: false,
              },
              {
                name: "PlayStation Consoles",
                probability: 0,
                url: "https://www.bizrate.com/playstationconsoles/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "PlayStation Consoles",
                      probability: 0,
                      id: 10625,
                    },
                  ],
                },
                id: 15010302,
                mature: false,
              },
              {
                name: "Dreamcast Consoles",
                probability: 0,
                url: "https://www.bizrate.com/dreamcastconsoles/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Dreamcast Consoles",
                      probability: 0,
                      id: 10624,
                    },
                  ],
                },
                id: 15010301,
                mature: false,
              },
              {
                name: "Xbox Consoles",
                probability: 0,
                url: "https://www.bizrate.com/xboxconsoles/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Xbox Consoles",
                      probability: 0,
                      id: 10627,
                    },
                  ],
                },
                id: 15010304,
                mature: false,
              },
              {
                name: "PlayStation 2 Consoles",
                probability: 0,
                url: "https://www.bizrate.com/playstation2consoles/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "PlayStation 2 Consoles",
                      probability: 0,
                      id: 10626,
                    },
                  ],
                },
                id: 15010303,
                mature: false,
              },
              {
                name: "PlayStation 4 Consoles",
                probability: 0,
                url: "https://www.bizrate.com/playstation-4-consoles/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "PS4 Consoles",
                      probability: 0,
                      id: 100003464,
                    },
                  ],
                },
                id: 100003433,
                mature: false,
              },
              {
                name: "Wii U Consoles",
                probability: 0,
                url: "https://www.bizrate.com/wii-u-consoles/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Wii U Consoles",
                      probability: 0,
                      id: 100003460,
                    },
                  ],
                },
                id: 100003436,
                mature: false,
              },
              {
                name: "Playstation Vita Consoles",
                probability: 0,
                url: "https://www.bizrate.com/playstation-vita-consoles/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Playstation Vita Consoles",
                      probability: 0,
                      id: 100003463,
                    },
                  ],
                },
                id: 100003435,
                mature: false,
              },
            ],
          },
          id: 15010300,
          mature: false,
        },
      ],
    },
    id: 15000000,
    mature: false,
  },
  {
    name: "Babies & Kids",
    probability: 0,
    url: "https://www.bizrate.com/babies_kids/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
    children: {
      category: [
        {
          name: "Baby Gear",
          probability: 0,
          url: "https://www.bizrate.com/babygear/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          children: {
            category: [
              {
                name: "Baby Carriers",
                probability: 0,
                url: "https://www.bizrate.com/baby-carriers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Baby Carriers",
                      probability: 0,
                      id: 100001338,
                    },
                  ],
                },
                id: 100001336,
                mature: false,
              },
              {
                name: "Stroller Accessories",
                probability: 0,
                url: "https://www.bizrate.com/stroller-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Stroller Accessories",
                      probability: 0,
                      id: 100001337,
                    },
                  ],
                },
                id: 100001335,
                mature: false,
              },
              {
                name: "Baby & Toddler Safety",
                probability: 0,
                url: "https://www.bizrate.com/babymonitorssafety/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Baby & Toddler Safety",
                      probability: 0,
                      id: 10570,
                    },
                  ],
                },
                id: 13090210,
                mature: false,
              },
              {
                name: "Car Seat Accessories",
                probability: 0,
                url: "https://www.bizrate.com/car-seat-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Car Seat Accessories",
                      probability: 0,
                      id: 100001397,
                    },
                  ],
                },
                id: 100001396,
                mature: false,
              },
              {
                name: "Baby Feeding",
                probability: 0,
                url: "https://www.bizrate.com/babyfeeding/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Nursing & Feeding",
                      probability: 0,
                      id: 100001706,
                    },
                  ],
                },
                id: 13090209,
                mature: false,
              },
              {
                name: "Car Seats",
                probability: 0,
                url: "https://www.bizrate.com/carseats/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Car Seats",
                      probability: 0,
                      id: 10568,
                    },
                  ],
                },
                id: 13090208,
                mature: false,
              },
              {
                name: "Bath & Potty",
                probability: 0,
                url: "https://www.bizrate.com/babybathtubs_potties/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Baby Bath",
                      probability: 0,
                      id: 10567,
                    },
                    {
                      name: "Potty Training",
                      probability: 0,
                      id: 100001485,
                    },
                  ],
                },
                id: 13090207,
                mature: false,
              },
              {
                name: "Baby Play Yards",
                probability: 0,
                url: "https://www.bizrate.com/babyplayyards/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Baby Play Yards",
                      probability: 0,
                      id: 10566,
                    },
                  ],
                },
                id: 13090206,
                mature: false,
              },
              {
                name: "Baby Walkers & Entertainers",
                probability: 0,
                url: "https://www.bizrate.com/babywalkersexersaucers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Baby Walkers & Entertainers",
                      probability: 0,
                      id: 10565,
                    },
                  ],
                },
                id: 13090205,
                mature: false,
              },
              {
                name: "Baby Jumpers & Swings",
                probability: 0,
                url: "https://www.bizrate.com/babyjumpers_swings/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Baby Jumpers & Swings",
                      probability: 0,
                      id: 10564,
                    },
                  ],
                },
                id: 13090204,
                mature: false,
              },
              {
                name: "Diapering",
                probability: 0,
                url: "https://www.bizrate.com/diapering/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Diapering",
                      probability: 0,
                      id: 100001315,
                    },
                  ],
                },
                id: 100001482,
                mature: false,
              },
              {
                name: "Baby Bouncers & Rockers",
                probability: 0,
                url: "https://www.bizrate.com/babybouncers_rockers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Baby Bouncers & Rockers",
                      probability: 0,
                      id: 10563,
                    },
                  ],
                },
                id: 13090203,
                mature: false,
              },
              {
                name: "Baby Monitors",
                probability: 0,
                url: "https://www.bizrate.com/baby-monitors/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Baby Monitors",
                      probability: 0,
                      id: 100001483,
                    },
                  ],
                },
                id: 100001481,
                mature: false,
              },
              {
                name: "High Chairs / Boosters",
                probability: 0,
                url: "https://www.bizrate.com/highchairsboosters/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "High Chairs/Boosters",
                      probability: 0,
                      id: 10562,
                    },
                  ],
                },
                id: 13090202,
                mature: false,
              },
              {
                name: "Strollers / Joggers",
                probability: 0,
                url: "https://www.bizrate.com/strollersjoggers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Strollers/Joggers",
                      probability: 0,
                      id: 10561,
                    },
                  ],
                },
                id: 13090201,
                mature: false,
              },
            ],
          },
          id: 13090200,
          mature: false,
        },
        {
          name: "Bedding",
          probability: 0,
          url: "https://www.bizrate.com/baby_kidsbedding/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          children: {
            category: [
              {
                name: "Baby Bedding",
                probability: 0,
                url: "https://www.bizrate.com/baby-bedding/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Baby Bedding",
                      probability: 0,
                      id: 10555,
                    },
                  ],
                },
                id: 13060203,
                mature: false,
              },
              {
                name: "Kids' Bedding",
                probability: 0,
                url: "https://www.bizrate.com/kidsbedding/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Kids' Bedding",
                      probability: 0,
                      id: 10554,
                    },
                  ],
                },
                id: 13060202,
                mature: false,
              },
            ],
          },
          id: 13060200,
          mature: false,
        },
        {
          name: "Children's Healthcare",
          probability: 0,
          url: "https://www.bizrate.com/childrenshealthcare/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Children's Healthcare",
                probability: 0,
                id: 100001673,
              },
            ],
          },
          id: 22003,
          mature: false,
        },
        {
          name: "Accessories",
          probability: 0,
          url: "https://www.bizrate.com/baby-kids-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          children: {
            category: [
              {
                name: "Baby Bibs & Burp Cloths",
                probability: 0,
                url: "https://www.bizrate.com/baby-bibs-burp-cloths/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Baby Bibs & Burp Cloths",
                      probability: 0,
                      id: 9795,
                    },
                  ],
                },
                id: 9794,
                mature: false,
              },
              {
                name: "Diaper Bags",
                probability: 0,
                url: "https://www.bizrate.com/diaperbags/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Diaper Bags",
                      probability: 0,
                      id: 10356,
                    },
                  ],
                },
                id: 10250400,
                mature: false,
              },
              {
                name: "Kids' Ties",
                probability: 0,
                url: "https://www.bizrate.com/kids-ties/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Kids' Ties",
                      probability: 0,
                      id: 100000052,
                    },
                  ],
                },
                id: 100000050,
                mature: false,
              },
              {
                name: "Kids' Belts",
                probability: 0,
                url: "https://www.bizrate.com/kids-belts/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Kids' Belts",
                      probability: 0,
                      id: 100000053,
                    },
                  ],
                },
                id: 100000051,
                mature: false,
              },
              {
                name: "Miscellaneous Baby & Kids'  Accessories",
                probability: 0,
                url: "https://www.bizrate.com/miscellaneous-baby-kids-accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Miscellaneous Baby & Kids' Accessories",
                      probability: 0,
                      id: 100001809,
                    },
                  ],
                },
                id: 100001808,
                mature: false,
              },
              {
                name: "Kids' Gloves & Mittens",
                probability: 0,
                url: "https://www.bizrate.com/kids-gloves-mittens/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Kids' Gloves & Mittens",
                      probability: 0,
                      id: 100000686,
                    },
                  ],
                },
                id: 100000682,
                mature: false,
              },
              {
                name: "Kids' Underwear",
                probability: 0,
                url: "https://www.bizrate.com/kids-underwear/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Kids' Underwear",
                      probability: 0,
                      id: 100001768,
                    },
                  ],
                },
                id: 100001754,
                mature: false,
              },
              {
                name: "Socks",
                probability: 0,
                url: "https://www.bizrate.com/baby-kids-socks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Baby & Kids' Socks",
                      probability: 0,
                      id: 100001767,
                    },
                  ],
                },
                id: 100001753,
                mature: false,
              },
              {
                name: "Kids' Scarves",
                probability: 0,
                url: "https://www.bizrate.com/kids-scarves/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Kids' Scarves",
                      probability: 0,
                      id: 100000688,
                    },
                  ],
                },
                id: 100000684,
                mature: false,
              },
              {
                name: "Hats",
                probability: 0,
                url: "https://www.bizrate.com/baby-kids-hats/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Baby & Kids' Hats",
                      probability: 0,
                      id: 100000687,
                    },
                  ],
                },
                id: 100000683,
                mature: false,
              },
            ],
          },
          id: 10260000,
          mature: false,
        },
        {
          name: "Furniture",
          probability: 0,
          url: "https://www.bizrate.com/baby_kidsfurniture/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Baby & Kids' Furniture",
                probability: 0,
                id: 10552,
              },
            ],
          },
          id: 13060100,
          mature: false,
        },
        {
          name: "Maternity Clothes",
          probability: 0,
          url: "https://www.bizrate.com/maternityclothes/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          children: {
            category: [
              {
                name: "Maternity Intimate Apparel",
                probability: 0,
                url: "https://www.bizrate.com/maternityintimateapparel/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Maternity Intimate Apparel",
                      probability: 0,
                      id: 10344,
                    },
                  ],
                },
                id: 10140300,
                mature: false,
              },
              {
                name: "Maternity Tops",
                probability: 0,
                url: "https://www.bizrate.com/maternitytops/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Maternity Tops",
                      probability: 0,
                      id: 10346,
                    },
                  ],
                },
                id: 10140800,
                mature: false,
              },
              {
                name: "Maternity Swimwear",
                probability: 0,
                url: "https://www.bizrate.com/maternity-swimwear/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Maternity Swimwear",
                      probability: 0,
                      id: 100001476,
                    },
                  ],
                },
                id: 100001475,
                mature: false,
              },
              {
                name: "Maternity Dresses / Skirts",
                probability: 0,
                url: "https://www.bizrate.com/maternitydressesskirts/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Maternity Dresses/Skirts",
                      probability: 0,
                      id: 10343,
                    },
                  ],
                },
                id: 10140100,
                mature: false,
              },
              {
                name: "Maternity Bottoms",
                probability: 0,
                url: "https://www.bizrate.com/maternitybottoms/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Maternity Bottoms",
                      probability: 0,
                      id: 10345,
                    },
                  ],
                },
                id: 10140600,
                mature: false,
              },
            ],
          },
          id: 10140000,
          mature: false,
        },
        {
          name: "Clothes",
          probability: 0,
          url: "https://www.bizrate.com/baby-kids-clothes/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          children: {
            category: [
              {
                name: "Sets",
                probability: 0,
                url: "https://www.bizrate.com/baby-kids-sets/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Baby & Kids' Sets",
                      probability: 0,
                      id: 9796,
                    },
                  ],
                },
                id: 9793,
                mature: false,
              },
              {
                name: "Swimwear",
                probability: 0,
                url: "https://www.bizrate.com/baby-kids-swimwear/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Baby & Kids' Swimwear",
                      probability: 0,
                      id: 100001764,
                    },
                  ],
                },
                id: 100001750,
                mature: false,
              },
              {
                name: "Sweaters",
                probability: 0,
                url: "https://www.bizrate.com/baby-kids-sweaters/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Baby & Kids' Sweaters",
                      probability: 0,
                      id: 100001763,
                    },
                  ],
                },
                id: 100001749,
                mature: false,
              },
              {
                name: "Sleepwear",
                probability: 0,
                url: "https://www.bizrate.com/baby-kids-sleepwear/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Baby & Kids' Sleepwear",
                      probability: 0,
                      id: 100001766,
                    },
                  ],
                },
                id: 100001752,
                mature: false,
              },
              {
                name: "Dresses / Skirts",
                probability: 0,
                url: "https://www.bizrate.com/baby-kids-dresses-skirts/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Baby & Kids' Dresses /Skirts",
                      probability: 0,
                      id: 100001760,
                    },
                  ],
                },
                id: 100001746,
                mature: false,
              },
              {
                name: "Jeans",
                probability: 0,
                url: "https://www.bizrate.com/baby-kids-jeans/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Baby & Kids' Jeans",
                      probability: 0,
                      id: 100001759,
                    },
                  ],
                },
                id: 100001745,
                mature: false,
              },
              {
                name: "Tops",
                probability: 0,
                url: "https://www.bizrate.com/baby-kids-tops/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Baby & Kids' Tops",
                      probability: 0,
                      id: 100001762,
                    },
                  ],
                },
                id: 100001748,
                mature: false,
              },
              {
                name: "Infant Bodysuits",
                probability: 0,
                url: "https://www.bizrate.com/infant-bodysuits/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Baby & Toddler Layette / Onesie",
                      probability: 0,
                      id: 10287,
                    },
                  ],
                },
                id: 10030400,
                mature: false,
              },
              {
                name: "Outerwear",
                probability: 0,
                url: "https://www.bizrate.com/baby-kids-outerwear/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Baby & Kids' Outerwear",
                      probability: 0,
                      id: 100001761,
                    },
                  ],
                },
                id: 100001747,
                mature: false,
              },
              {
                name: "Shorts",
                probability: 0,
                url: "https://www.bizrate.com/baby-kids-shorts/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Baby & Kids' Shorts",
                      probability: 0,
                      id: 100001758,
                    },
                  ],
                },
                id: 100001744,
                mature: false,
              },
              {
                name: "Pants",
                probability: 0,
                url: "https://www.bizrate.com/baby-kids-pants/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Baby & Kids' Pants",
                      probability: 0,
                      id: 100001757,
                    },
                  ],
                },
                id: 100001743,
                mature: false,
              },
              {
                name: "Suits",
                probability: 0,
                url: "https://www.bizrate.com/baby-kids-suits/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
                atoms: {
                  atom: [
                    {
                      name: "Baby & Kids' Suits",
                      probability: 0,
                      id: 100001770,
                    },
                  ],
                },
                id: 100001756,
                mature: false,
              },
            ],
          },
          id: 10020000,
          mature: false,
        },
        {
          name: "Shoes",
          probability: 0,
          url: "https://www.bizrate.com/baby-kids-shoes/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Baby & Kids' Shoes",
                probability: 0,
                id: 100001769,
              },
            ],
          },
          id: 100001755,
          mature: false,
        },
      ],
    },
    id: 17000000,
    mature: false,
  },
  {
    name: "Musical Instruments & Accessories",
    probability: 0,
    url: "https://www.bizrate.com/musicalinstruments_accessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
    children: {
      category: [
        {
          name: "Karaoke Media",
          probability: 0,
          url: "https://www.bizrate.com/karaokemedia/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Karaoke Media",
                probability: 0,
                id: 10679,
              },
            ],
          },
          id: 21000100,
          mature: false,
        },
        {
          name: "DJ Equipment",
          probability: 0,
          url: "https://www.bizrate.com/djequipment/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "DJ Equipment",
                probability: 0,
                id: 10254,
              },
            ],
          },
          id: 521904,
          mature: false,
        },
        {
          name: "Keyboards/Midi",
          probability: 0,
          url: "https://www.bizrate.com/keyboardsmidi/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Keyboards/Midi",
                probability: 0,
                id: 10253,
              },
            ],
          },
          id: 521903,
          mature: false,
        },
        {
          name: "Drums / Percussion",
          probability: 0,
          url: "https://www.bizrate.com/drumspercussion/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Drums/Percussion",
                probability: 0,
                id: 10252,
              },
            ],
          },
          id: 521902,
          mature: false,
        },
        {
          name: "Orchestral Instruments",
          probability: 0,
          url: "https://www.bizrate.com/orchestralinstruments/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Brass Instruments",
                probability: 0,
                id: 100001582,
              },
              {
                name: "String Instruments",
                probability: 0,
                id: 100001581,
              },
              {
                name: "Wind Instruments",
                probability: 0,
                id: 100001580,
              },
            ],
          },
          id: 521901,
          mature: false,
        },
        {
          name: "Guitar / Bass",
          probability: 0,
          url: "https://www.bizrate.com/guitarbass/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Guitar/Bass",
                probability: 0,
                id: 10250,
              },
            ],
          },
          id: 521900,
          mature: false,
        },
        {
          name: "Instrument Strings",
          probability: 0,
          url: "https://www.bizrate.com/instrumentstrings/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Instrument Strings",
                probability: 0,
                id: 10262,
              },
            ],
          },
          id: 521912,
          mature: false,
        },
        {
          name: "Musical Instrument Cables",
          probability: 0,
          url: "https://www.bizrate.com/musicalinstrumentcables/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Musical Instrument Cables",
                probability: 0,
                id: 10261,
              },
            ],
          },
          id: 521911,
          mature: false,
        },
        {
          name: "Musical Instrument Effects / Processors",
          probability: 0,
          url: "https://www.bizrate.com/musicalinstrumenteffectsprocessors/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Musical Instrument Effects/Processors",
                probability: 0,
                id: 10260,
              },
            ],
          },
          id: 521910,
          mature: false,
        },
        {
          name: "Karaoke Equipment",
          probability: 0,
          url: "https://www.bizrate.com/karaokeequipment/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Karaoke Equipment",
                probability: 0,
                id: 10370,
              },
            ],
          },
          id: 11100700,
          mature: false,
        },
        {
          name: "Musical Instrument Microphones",
          probability: 0,
          url: "https://www.bizrate.com/musicalinstrumentmicrophones/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Musical Instrument Microphones",
                probability: 0,
                id: 10259,
              },
            ],
          },
          id: 521909,
          mature: false,
        },
        {
          name: "Mixers/Multi Track Recorders",
          probability: 0,
          url: "https://www.bizrate.com/mixersmultitrackrecorders/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Mixers/Multi Track Recorders",
                probability: 0,
                id: 10258,
              },
            ],
          },
          id: 521908,
          mature: false,
        },
        {
          name: "Musical Instrument Cases / Racks",
          probability: 0,
          url: "https://www.bizrate.com/musicalinstrumentcasesracks/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Musical Instrument Cases/Racks",
                probability: 0,
                id: 10257,
              },
            ],
          },
          id: 521907,
          mature: false,
        },
        {
          name: "Musical Instrument Lighting Equipment",
          probability: 0,
          url: "https://www.bizrate.com/musicalinstrumentlightingequipment/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Musical Instrument Lighting Equipment",
                probability: 0,
                id: 10256,
              },
            ],
          },
          id: 521906,
          mature: false,
        },
        {
          name: "Musical Instrument Amplifiers",
          probability: 0,
          url: "https://www.bizrate.com/musicalinstrumentamplifiers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Musical Instrument Amplifiers",
                probability: 0,
                id: 10255,
              },
            ],
          },
          id: 521905,
          mature: false,
        },
        {
          name: "Musical Instrument Software / Sample CDs",
          probability: 0,
          url: "https://www.bizrate.com/musicalinstrumentsoftwaresamplecds/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Musical Instrument Software/Sample CDs",
                probability: 0,
                id: 10266,
              },
            ],
          },
          id: 521916,
          mature: false,
        },
        {
          name: "Musical Instrument Monitors / Speakers",
          probability: 0,
          url: "https://www.bizrate.com/musicalinstrumentmonitorsspeakers/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Musical Instrument Monitors/Speakers",
                probability: 0,
                id: 10265,
              },
            ],
          },
          id: 521915,
          mature: false,
        },
        {
          name: "Musical Instrument Accessories",
          probability: 0,
          url: "https://www.bizrate.com/musicalinstrumentaccessories/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Sheet Music",
                probability: 0,
                id: 100002034,
              },
              {
                name: "Musical Instrument Accessories",
                probability: 0,
                id: 10264,
              },
            ],
          },
          id: 521914,
          mature: false,
        },
        {
          name: "Miscellaneous Musical Instruments",
          probability: 0,
          url: "https://www.bizrate.com/miscellaneousmusicalinstruments/?rf=af1&af_assettype_id=10&af_creative_id=2975&af_id=[PUBLISHER_ID]&af_placement_id=1",
          atoms: {
            atom: [
              {
                name: "Miscellaneous Musical Instruments",
                probability: 0,
                id: 10263,
              },
            ],
          },
          id: 521913,
          mature: false,
        },
      ],
    },
    id: 21000000,
    mature: false,
  },
];
