import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  generateDeepLinkAPI,
  getAllCategoriesAPI,
  getAllMerchantsAPI,
  getAllProductsAPI,
} from "./appService";

export interface AppState {
  loading: boolean;
  showBackButton: boolean;
  allMerchantsList: any[];
  error: any;
  productList: any[];
  categoriesList?: any[]; 
}

const initialState: AppState = {
  loading: false,
  showBackButton: false,
  allMerchantsList: [],
  error: {},
  productList: [],
  categoriesList: [],
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading: (state: AppState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setShowBackButton: (state: AppState, action: PayloadAction<boolean>) => {
      state.showBackButton = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    // All brands
    builder.addCase(getAllMerchantsAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAllMerchantsAPI.fulfilled,
      (state: AppState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.allMerchantsList = action.payload?.data;
      }
    );
    builder.addCase(
      getAllMerchantsAPI.rejected,
      (state: AppState, action: any) => {
        state.loading = false;
      }
    );

    // Deep Links
    builder.addCase(generateDeepLinkAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      generateDeepLinkAPI.fulfilled,
      (state: AppState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
      }
    );
    builder.addCase(
      generateDeepLinkAPI.rejected,
      (state: AppState, action: any) => {
        state.loading = false;
      }
    );

    // All Products
    builder.addCase(getAllProductsAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAllProductsAPI.fulfilled,
      (state: AppState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.productList = action.payload?.products;
      }
    );
    builder.addCase(
      getAllProductsAPI.rejected,
      (state: AppState, action: any) => {
        state.loading = false;
      }
    );

    // All Categories
    builder.addCase(getAllCategoriesAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAllCategoriesAPI.fulfilled,
      (state: AppState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.categoriesList = action?.payload?.data[0]?.category[0]?.children?.category;
      }
    );
    builder.addCase(
      getAllCategoriesAPI.rejected,
      (state: AppState, action: any) => {
        state.loading = false;
      }
    );
  },
});

export const { setLoading, setShowBackButton } = appSlice.actions;

export default appSlice.reducer;
