import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP, SERVER_IP_URL_BACKEND } from "../../../config";
import { post, get, put } from "../../../library/Requests/helpers";
import { siteName } from "../../../Utills/Utill";

export const signedImageUploadAPi = createAsyncThunk(
  "SIGNED_IMAGE_UPLOAD_API_CALL",
  async (body: any) =>
    await post(`${SERVER_IP_URL_BACKEND}/img/signedurl`, body)
);

export const ImageUploadAPi = createAsyncThunk(
  "IMAGE_UPLOAD_API_CALL",
  async (body: any) => await post(`${SERVER_IP_URL_BACKEND}/img/upload`, body)
);

export const allImageUploadAPi = createAsyncThunk(
  "ALL_IMAGE_UPLOAD_API_CALL",
  async (body: any) => await post(`${SERVER_IP_URL_BACKEND}/img/create`, body)
);

export const getAllImageApi = createAsyncThunk(
  "GET_ALL_IMAGE_API",
  async (body: any) =>
    await get(
      `${SERVER_IP_URL_BACKEND}/img/all?offerSection=${body?.type}&websiteName=${siteName}`
    )
);

export const uploadThemeApi = createAsyncThunk(
  "UPLOAD_THEME_DETAILS",
  async (body: any) => await post(`${SERVER_IP_URL_BACKEND}/theme/create`, body)
);

export const getThemeDetailsApi = createAsyncThunk(
  "GET_THEME_DETAILS_API",
  async (body: any) => await post(`${SERVER_IP_URL_BACKEND}/theme/all`, body)
);

export const updateSelectedBrandsApi = createAsyncThunk(
  "UPDATE_SELECTED_BRAND_API",
  async (body: any) =>
    await post(`${SERVER_IP_URL_BACKEND}/merchant/update`, body)
);

export const getSelectedBrandsApi = createAsyncThunk(
  "getSelectedBrandsApi",
  async (body: any) =>
    await get(
      `${SERVER_IP_URL_BACKEND}/home/getMerchants?isSelected=true&websiteName=${body?.siteName}`
    )
);
