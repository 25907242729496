import { Carousel, Skeleton, Space } from "antd";
import { TopBrandStyled } from "./TopBrand.styled";
import topoIcon from "../../images/Topographic5.svg";
import brandLOgo from "../../images/Brooks_Brothers_logo.svg";
import arrow from "../../images/arrowRight.svg";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getAllMerchantsAPI } from "../../redux/slices/app/appService";
import { checkIsMobile, v_id } from "../../Utills/Utill";
import { getSelectedBrandsApi } from "../../redux/slices/Admin/AdminService";

const TopBrandDeals = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { themeDetails, selectedBrands } = useSelector(
    (state: any) => state?.admin
  );

  useEffect(() => {
    getBrands();
  }, []);

  const getBrands = async () => {
    setLoading(true);
    await dispatch(getAllMerchantsAPI());
    setLoading(false);
  };

  const grabDeals = async (item: any) => {
    // const resp: any = await dispatch(generateDeepLinkAPI({
    //   campaign_id: v_id,
    //   merchantUrl: item?.merchantInfo?.merchantUrl,
    //   // placement_id: "",
    // }));
    // window.open(resp?.payload?.deepLinkInfo?.link, "_blank");

    history.push(`/products?byBrand=${item?.mid}`);
  };

  console.log("selectedBrands :I:>>", selectedBrands);

  return (
    <TopBrandStyled className="top_brands_styled">
      <div className="topo">
        <img
          src={topoIcon}
          alt="topo"
          style={{
            background: themeDetails?.topBrandDealBG
              ? themeDetails?.topBrandDealBG
              : "none",
          }}
        />
      </div>
      <div className="header_wrp">
        <h1 className="header">Top Brand’s Deals</h1>
        <p className="view_all" onClick={() => history?.push("/brands")}>
          View All
        </p>
      </div>

      <div className="w-100 carousel_wrap">
        {loading && (
          <>
            <Space className="loader_wrapper">
              <Skeleton.Input active={true} size={"large"} />
              <Skeleton.Input active={true} size={"large"} />
              {!checkIsMobile() && (
                <Skeleton.Input active={true} size={"large"} />
              )}
              {!checkIsMobile() && (
                <Skeleton.Input active={true} size={"large"} />
              )}
            </Space>
            <Space className="loader_wrapper">
              <Skeleton.Input active={true} size={"large"} />
              <Skeleton.Input active={true} size={"large"} />
              {!checkIsMobile() && (
                <Skeleton.Input active={true} size={"large"} />
              )}
              {!checkIsMobile() && (
                <Skeleton.Input active={true} size={"large"} />
              )}
            </Space>
          </>
        )}
        {!loading && !checkIsMobile() && (
          <Carousel
            arrows={checkIsMobile() ? false : true}
            infinite={true}
            slidesPerRow={checkIsMobile() ? 1 : 5}
            // @ts-ignore
            arrowOffset={10}
            arrowSize={30}
            dotWidth={20}
          >
            {selectedBrands?.slice(0, 5)?.map(
              (item: any, idx: number) =>
                (selectedBrands[idx * 2 + 1]?.merchantInfo?.name ||
                  selectedBrands[idx * 2]?.merchantInfo?.name) && (
                  <div
                    className={`main_slide ${checkIsMobile() ? `ml-2` : ``}`}
                    key={`ms_${idx}`}
                  >
                    {selectedBrands[idx * 2]?.merchantInfo?.name && (
                      <div className={`inner_slide`}>
                        <div className="img_wrapper">
                          <img
                            src={selectedBrands[idx * 2]?.merchantInfo?.logoUrl}
                            alt="new"
                            className="brand_logo"
                          />
                        </div>
                        <div
                          className="grab_deal_wrp"
                          onClick={() => grabDeals(selectedBrands[idx * 2])}
                        >
                          <div
                            className="grab_deal"
                            style={{
                              background: themeDetails?.grabDealButtonBG
                                ? themeDetails?.grabDealButtonBG
                                : `#000`,
                              color: themeDetails?.grabDealButtonTextColor
                                ? themeDetails?.grabDealButtonTextColor
                                : `#fff`,
                            }}
                          >
                            Grab Deals
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_3668_15440)">
                                <path
                                  d="M7.68437 7.00042L4.79688 4.11292L5.62171 3.28809L9.33404 7.00042L5.62171 10.7128L4.79688 9.88792L7.68437 7.00042Z"
                                  fill={
                                    themeDetails?.grabDealButtonTextColor
                                      ? themeDetails?.grabDealButtonTextColor
                                      : `#fff`
                                  }
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_3668_15440">
                                  <rect width="14" height="14" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                        </div>
                        <div className="time_wrp">
                          <div
                            className="timer_sec"
                            style={{
                              background: themeDetails?.brandTagBG
                                ? themeDetails?.brandTagBG
                                : `#000`,
                              color: themeDetails?.brandTagTextColor
                                ? themeDetails?.brandTagTextColor
                                : `#fff`,
                            }}
                          >
                            {selectedBrands[idx * 2]?.merchantInfo?.name}
                          </div>
                        </div>
                      </div>
                    )}
                    {selectedBrands[idx * 2 + 1]?.merchantInfo?.name && (
                      <div className="inner_slide">
                        <div className="img_wrapper">
                          <img
                            src={
                              selectedBrands[idx * 2 + 1]?.merchantInfo?.logoUrl
                            }
                            alt="new"
                            className="brand_logo"
                          />
                        </div>
                        <div
                          className="grab_deal_wrp"
                          onClick={() => grabDeals(selectedBrands[idx * 2 + 1])}
                        >
                          <div
                            className="grab_deal"
                            style={{
                              background: themeDetails?.grabDealButtonBG
                                ? themeDetails?.grabDealButtonBG
                                : `#000`,
                              color: themeDetails?.grabDealButtonTextColor
                                ? themeDetails?.grabDealButtonTextColor
                                : `#fff`,
                            }}
                          >
                            Grab Deals
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_3668_15440)">
                                <path
                                  d="M7.68437 7.00042L4.79688 4.11292L5.62171 3.28809L9.33404 7.00042L5.62171 10.7128L4.79688 9.88792L7.68437 7.00042Z"
                                  fill={
                                    themeDetails?.grabDealButtonTextColor
                                      ? themeDetails?.grabDealButtonTextColor
                                      : `#fff`
                                  }
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_3668_15440">
                                  <rect width="14" height="14" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                        </div>
                        <div className="time_wrp">
                          <div
                            className="timer_sec"
                            style={{
                              background: themeDetails?.brandTagBG
                                ? themeDetails?.brandTagBG
                                : `#000`,
                              color: themeDetails?.brandTagTextColor
                                ? themeDetails?.brandTagTextColor
                                : `#fff`,
                            }}
                          >
                            {selectedBrands[idx * 2 + 1]?.merchantInfo?.name}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )
            )}
          </Carousel>
        )}
        {checkIsMobile() && !loading && (
          <div
            className={`mobl_brand_wrapper ${
              selectedBrands?.length <= 2 ? `less_than_2_item` : ``
            }`}
          >
            {selectedBrands?.slice(0, 5)?.map(
              (item: any, idx: number) =>
                (selectedBrands[idx * 2 + 1]?.merchantInfo?.name ||
                  selectedBrands[idx * 2]?.merchantInfo?.name) && (
                  <div
                    className={`main_slide ${checkIsMobile() ? `ml-2` : ``}`}
                    key={`ms_${idx}`}
                  >
                    {selectedBrands[idx * 2]?.merchantInfo?.name && (
                      <div className={`inner_slide`}>
                        <div className="img_wrapper">
                          <img
                            src={selectedBrands[idx * 2]?.merchantInfo?.logoUrl}
                            alt="new"
                            className="brand_logo"
                          />
                        </div>
                        <div
                          className="grab_deal_wrp"
                          onClick={() => grabDeals(selectedBrands[idx * 2])}
                        >
                          <div
                            className="grab_deal"
                            style={{
                              background: themeDetails?.grabDealButtonBG
                                ? themeDetails?.grabDealButtonBG
                                : `#000`,
                              color: themeDetails?.grabDealButtonTextColor
                                ? themeDetails?.grabDealButtonTextColor
                                : `#fff`,
                            }}
                          >
                            Grab Deals
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_3668_15440)">
                                <path
                                  d="M7.68437 7.00042L4.79688 4.11292L5.62171 3.28809L9.33404 7.00042L5.62171 10.7128L4.79688 9.88792L7.68437 7.00042Z"
                                  fill={
                                    themeDetails?.grabDealButtonTextColor
                                      ? themeDetails?.grabDealButtonTextColor
                                      : `#fff`
                                  }
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_3668_15440">
                                  <rect width="14" height="14" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                        </div>
                        <div className="time_wrp">
                          <div
                            className="timer_sec"
                            style={{
                              background: themeDetails?.brandTagBG
                                ? themeDetails?.brandTagBG
                                : `#000`,
                              color: themeDetails?.brandTagTextColor
                                ? themeDetails?.brandTagTextColor
                                : `#fff`,
                            }}
                          >
                            {selectedBrands[idx * 2]?.merchantInfo?.name}
                          </div>
                        </div>
                      </div>
                    )}
                    {selectedBrands[idx * 2 + 1]?.merchantInfo?.name && (
                      <div className="inner_slide">
                        <div className="img_wrapper">
                          <img
                            src={
                              selectedBrands[idx * 2 + 1]?.merchantInfo?.logoUrl
                            }
                            alt="new"
                            className="brand_logo"
                          />
                        </div>
                        <div
                          className="grab_deal_wrp"
                          onClick={() => grabDeals(selectedBrands[idx * 2 + 1])}
                        >
                          <div
                            className="grab_deal"
                            style={{
                              background: themeDetails?.grabDealButtonBG
                                ? themeDetails?.grabDealButtonBG
                                : `#000`,
                              color: themeDetails?.grabDealButtonTextColor
                                ? themeDetails?.grabDealButtonTextColor
                                : `#fff`,
                            }}
                          >
                            Grab Deals
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_3668_15440)">
                                <path
                                  d="M7.68437 7.00042L4.79688 4.11292L5.62171 3.28809L9.33404 7.00042L5.62171 10.7128L4.79688 9.88792L7.68437 7.00042Z"
                                  fill={
                                    themeDetails?.grabDealButtonTextColor
                                      ? themeDetails?.grabDealButtonTextColor
                                      : `#fff`
                                  }
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_3668_15440">
                                  <rect width="14" height="14" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                        </div>
                        <div className="time_wrp">
                          <div
                            className="timer_sec"
                            style={{
                              background: themeDetails?.brandTagBG
                                ? themeDetails?.brandTagBG
                                : `#000`,
                              color: themeDetails?.brandTagTextColor
                                ? themeDetails?.brandTagTextColor
                                : `#fff`,
                            }}
                          >
                            {selectedBrands[idx * 2 + 1]?.merchantInfo?.name}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )
            )}
          </div>
        )}
      </div>

      <br />
    </TopBrandStyled>
  );
};

export default TopBrandDeals;
