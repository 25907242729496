
import arrow from "../../../../../images/arrowRight.svg";
import brandLogo from "../../../../../images/Brooks_Brothers_logo.svg";
import { useHistory } from "react-router-dom";

import { useSelector } from "react-redux";
import { ProductCardStyled } from "./ProductCard.styled";

const ProductCard = ({ product }: any) => {
  const history = useHistory();
  const { allMerchantsList }: any = useSelector(
    // @ts-ignore
    (reduxState): any => reduxState.app
  );


  return (
    <ProductCardStyled className="pro_card_styled">
      <div className="main_sec">
        {/* {getBadge()} */}
        <div className="img_wrp">
          <img src={product?.images?.image[0]?.value} alt="img" />
        </div>
        <div className="brand_logo">
          <img
            src={brandLogo}
            alt="new"
          />
        </div>
        <div className="price_sec">
          <span className="main_price">{product?.price?.value}</span>
          <span className="sale_txt">Sale</span>
        </div>
        {product?.originalPrice?.value && (
          <div className="acc_price_sec">
            <span className="text">Reg. Price</span>
            {<span className="price">{product?.originalPrice?.value}</span>}
          </div>
        )}
        {/* {product?.manufacturer && (
          <div className="tag_sec">
            <div>{product?.manufacturer}</div>
          </div>
        )} */}
        <div className="title_sec">
          <div>{product?.title}</div>
        </div>
        <div className="grab_deal_wrp">
          <div className="grab_deal">
            Grab Deals
            <img src={arrow} alt="arraow" />
          </div>
        </div>
      </div>
    </ProductCardStyled>
  );
};

export default ProductCard;
