import { OfferPageStyled } from "./OfferPage.styled";
import offer1 from "../../images/offer_banner.svg";
import offer2 from "../../images/courselImages/FashionBanner.png";
import offer3 from "../../images/courselImages/FeaturedProduct.jpg";
import { useEffect, useState } from "react";
import { getAllImageApi } from "../../redux/slices/Admin/AdminService";
import { useDispatch } from "react-redux";

const OfferPage = () => {
  const dispatch = useDispatch();
  const [offerList, setOfferList]: any = useState([]);

  useEffect(() => {
    getOfferList();
  }, []);

  const getOfferList = async () => {
    const res: any = await dispatch(getAllImageApi({ type: "Offer" }));
    if (res?.payload?.data?.length > 0) {
      setOfferList(res?.payload?.data);
    }
  };
  return (
    <OfferPageStyled>
      <div className="main_wrapper">
        {offerList?.map((item: any, idx: number) => (
          <img src={item?.url} alt="offer1" key={`img_${idx}`} />
        ))}
      </div>
    </OfferPageStyled>
  );
};

export default OfferPage;
