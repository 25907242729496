import React, { useEffect, useState } from "react";
import { Button, Drawer, Space, Checkbox, CheckboxProps } from "antd";
import { CanvasFilterStyled } from "./CanvasFilter.styled";
import { useSelector } from "react-redux";
import { CloseOutlined, PlusOutlined, MinusOutlined } from "@ant-design/icons";
// @ts-ignore
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import { checkIsMobile } from "../../Utills/Utill";
import "./canvas.css";

const CanvasFilter: React.FC = ({
  open,
  setOpen,
  setPriceVal,
  priceVal,
  applyFilterCall,
  clearFilter,
  checkedList,
  setCheckedList,
  checkedCategories,
  setCheckedCategories,
}: any) => {
  const { allMerchantsList, categoriesList }: any = useSelector(
    // @ts-ignore
    (reduxState): any => reduxState.app
  );

  const [showBrands, setShowBrands] = useState(true);
  const [showCategories, setShowCategories] = useState(true);

  const onClose = () => {
    setOpen(false);
  };

  const onChange = (item: string) => {
    const checked = isChecked(item);
    if (checked) {
      const filterList = checkedList?.filter((i: any) => i !== item);
      setCheckedList(filterList);
    } else {
      setCheckedList([...checkedList, item]);
    }
  };

  const cateGoriesChange = (item: any) => {
    const checked: any = isCateChaked(item?.id);

    if (checked) {
      const filterList = checkedCategories?.filter((i: any) => i !== item?.id);
      setCheckedCategories(filterList);
    } else {
      setCheckedCategories([...checkedCategories, item?.id]);
    }
  };

  const isChecked = (val: any) => {
    return checkedList.includes(val);
  };

  const isCateChaked = (val: string) => {
    return checkedCategories.includes(val);
  };
  const applyFilter = () => {
    applyFilterCall(
      checkedList[0]?.mid ? checkedList[0]?.mid : 0,
      checkedCategories?.length > 0 ? checkedCategories[0] : 0
    );

    onClose();
  };

  return (
    <>
      <Drawer
        title="Filter"
        placement={checkIsMobile() ? "bottom" : "left"}
        width={400}
        onClose={onClose}
        open={open}
        className="pro_filter_canvas"
        extra={
          <Space>
            <CloseOutlined onClick={onClose} />
          </Space>
        }
      >
        <CanvasFilterStyled>
          <div>
            <h4 className="Main_Filter_wrapper">
              <span>Categories</span>
              <span>
                {showCategories ? (
                  <MinusOutlined
                    onClick={() => setShowCategories(!showCategories)}
                  />
                ) : (
                  <PlusOutlined
                    onClick={() => setShowCategories(!showCategories)}
                  />
                )}
              </span>
            </h4>
            {showCategories && (
              <div className="all_brands">
                {categoriesList?.map((item: any, idex: number) => (
                  <div className="check_wrapper mt-2">
                    <Checkbox
                      onChange={() => cateGoriesChange(item)}
                      // @ts-ignore
                      checked={isCateChaked(item?.id)}
                    >
                      {item?.name}
                    </Checkbox>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="mt-3">
            <h4 className="Main_Filter_wrapper">
              <span>Brands</span>
              <span>
                {showBrands ? (
                  <MinusOutlined onClick={() => setShowBrands(!showBrands)} />
                ) : (
                  <PlusOutlined onClick={() => setShowBrands(!showBrands)} />
                )}
              </span>
            </h4>
            {showBrands && (
              <div className="all_brands">
                {allMerchantsList?.map((item: any, idex: number) => (
                  <div className="check_wrapper mt-2">
                    <Checkbox
                      onChange={() => onChange(item)}
                      checked={isChecked(item)}
                    >
                      {item?.merchantInfo?.name}
                    </Checkbox>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="price_section">
            <h4 className="Main_Filter_wrapper">Price</h4>
            <div className="input_wrap">
              <div className="first_input">
                <label>Min Price</label>
                <input type="text" value={priceVal[0]} disabled />
              </div>
              <div className="second_input">
                <label>Max Price</label>
                <input type="text" value={priceVal[1]} disabled />
              </div>
            </div>
            <div className="sloder_wrapper">
              <RangeSlider
                value={priceVal}
                defaultValue={[0, 10000]}
                onInput={setPriceVal}
                max={10000}
                min={0}
                step={1}
                id={`price_range`}
              />
            </div>
          </div>
        </CanvasFilterStyled>
        <div className="footer_sec">
          <Button
            className="cancel_btn"
            onClick={() => {
              setCheckedList([]);
              setCheckedCategories([]);
              setOpen(false);
              clearFilter();
              // history.push("/products");
            }}
            // disabled={checkedList?.length === 0}
          >
            Clear All
          </Button>
          <Button
            className="apply_btn"
            onClick={applyFilter}
            // disabled={checkedList?.length === 0}
          >
            Apply
          </Button>
        </div>
      </Drawer>
    </>
  );
};

export default CanvasFilter;
