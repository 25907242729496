import styled from "styled-components";

export const SignupStyled = styled.div`
  .SignupContainer {
    margin-top: 30px;
  }
  .password_field {
    position: relative;
    .eyeIcon {
      position: absolute;
      right: 10px;
      top: 44px;
      cursor: pointer;
    }
  }
  .sign_con_pass_error {
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #dc3545;
  }
  .sign_up_button {
    background: var(--FARFETCH-COLORS-Mine-Shaft, #222);
  }
  .sign_up_button:disabled {
    opacity: 0.8;
  }
`;
