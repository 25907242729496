import styled from "styled-components";

export const ProductCardStyled = styled.div`
  .main_sec {
    width: 307px;
    border-radius: 8px;
    padding: 10px;
    position: relative;
    box-shadow: 1px 3px 16px 0px rgba(32, 32, 32, 0.12);
    .img_wrp {
      width: 100%;
      height: 180px;
      border-radius: 8px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 8px;
      }
    }

    .tag_sec {
      margin-top: 15px;
      display: flex;
      padding: 5px 12px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 24px;
      border: 1px solid var(--www-farfetch-com-blue, #6729ff);
      width: fit-content;
      margin-bottom: 15px;
      color: var(--www-farfetch-com-blue, #6729ff);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .brand_logo {
      width: auto;
      max-width: 100%;
      height: 22px;
      display: flex;
      justify-content: start;
      margin-top: 10px;
      margin-bottom: 10px;
      img {
        width: auto;
        height: 100%;
        max-width: 100%;
      }
    }

    .price_sec {
      margin-top: 10px;
      margin-bottom: 8px;
      .main_price {
        color: var(--Error-Colour-400, #cc1433);
        font-family: "Helvetica Neue";
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px; /* 100% */
      }
      .sale_txt {
        color: var(--Error-Colour-400, #cc1433);
        font-family: "Helvetica Neue";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-left: 10px;
      }
    }

    .acc_price_sec {
      margin-bottom: 14px;
      .text {
        align-self: stretch;
      }
      .price {
        color: var(--Neutral-800, #606e79);

        /* www.farfetch.com/Helvetica Neue/Regular 14 */
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        margin-left: 3px;
      }
    }

    .title_sec {
      overflow: hidden;
      color: #181818;
      text-overflow: ellipsis;
      // font-family: "Helvetica Neue";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 137.5% */
      margin-bottom: 50px;
      div {
        display: -webkit-box;
        -webkit-line-clamp: 2; /* Limit to 2 lines */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .grab_deal_wrp {
    display: flex;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    bottom: 10px;
    width: 93%;
    .grab_deal {
      display: flex;
      width: 100%;
      padding: 8px 24px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 90px;
      background: var(--www-farfetch-com-mine-shaft, #222);

      color: var(--Shade-0, #fff);
      font-family: "Helvetica Neue";
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.024px;
      text-transform: uppercase;
    }
  }
`;
