import styled from "styled-components";

export const ThemeStyled = styled.div`
  .main_thm_wrapper {
    .required {
      color: red;
    }
    label {
      color: var(--Neutral-700, #7f8b93);
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 125% */
    }
    .heading {
      color: var(--Shade-100, #000);
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 27px; /* 135% */
      margin-bottom: 24px;
    }
    .theme_card {
      border-radius: 16px;
      border: 1px solid #cfd3d7;
      background: #fff;
      padding: 24px;
      margin-bottom: 25px;
      .card_head {
        color: var(--Shade-100, #000);
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px; /* 177.778% */
        margin-bottom: 16px;
      }

      .ant-input {
        border-radius: 18px;
        border: 1px solid #cfd3d7;
        background: #fff;
        color: var(--Shade-100, #000);
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        height: 50px;
      }
    }

    .logo_card {
      .main_img_wrp {
        width: 546px;
        height: 76px;
        border-radius: 5px;
        border: 1px solid var(--Neutral-100, #eceeef);
        background: var(--Neutral-50, #f8f7fa);
        margin-bottom: 16px;
        position: relative;

        .pre_img_sec {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          p {
            margin-bottom: 0px;
            color: var(--Shade-100, #000);
            text-align: center;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 166.667% */
          }
        }
        .uploaded_logo {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    .small_logo_card {
      .main_img_wrp {
        width: 200px;
        height: 200px;
        border-radius: 5px;
        border: 1px solid var(--Neutral-100, #eceeef);
        background: var(--Neutral-50, #f8f7fa);
        margin-bottom: 16px;
        position: relative;

        .pre_img_sec {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          p {
            margin-bottom: 0px;
            color: var(--Shade-100, #000);
            text-align: center;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 166.667% */
          }
        }
      }
    }
    .card_body {
      display: flex;
      gap: 20px;
      align-items: center;
      margin-bottom: 20px;
      .row {
        display: flex;
        width: 100%;
        .right_col {
          display: flex;
          gap: 15px;
          .ant-input {
            max-width: 80%;
            height: 40px;
          }
        }
      }
      .body_title {
        color: #000;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 187.5% */
      }
      .color_display {
        width: 40px;
        height: 40px;
        border-radius: 8px;
        cursor: pointer;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.4);
      }

      .edit_wrap {
        span {
          cursor: pointer;
        }
      }
    }
    .btn_sec {
      display: flex;
      justify-content: end;
      align-item: center;

      .add_btn {
        width: 277px;
        height: 56px;
        border-radius: 18px;
        background: var(--FARFETCH-COLORS-Mine-Shaft, #222);
        color: var(--Shade-0, #fff);
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
        letter-spacing: 0.032px;
        text-transform: uppercase;
      }
    }
    .uploaded_icon {
      width: 100%;
      height: 100%;
    }
    .delete_btn_wrap {
      position: absolute;
      width: 32px;
      height: 32px;
      right: 10px;
      top: 10px;
      border-radius: 4px;
      border: 1px solid var(--Neutral-100, #eceeef);
      background: #fff;
      box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.08);
      backdrop-filter: blur(4.5px);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
`;
