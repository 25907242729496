import MatureImg from "../images/categories/leaf.png";
import ComputersSoftwareImg from "../images/categories/Computer.svg";
import PetSupImg from "../images/categories/Petsupplies.svg";
import clothImg from "../images/categories/ClothingAccessories.svg";
import sportSImg from "../images/categories/SportsEquipment.svg";
import toyImg from "../images/categories/Toys.svg";
import giftImg from "../images/categories/FlowesFoodsGift.svg";
import applianceImg from "../images/categories/Applinces.svg";
import otherImg from "../images/categories/Other.svg";
import booksImg from "../images/categories/Books.svg";
import autoMotiveImg from "../images/categories/autoMotive.svg";
import healthIcon from "../images/categories/health.svg";
import dvdIcon from "../images/categories/dvd.svg";
import musicIcon from "../images/categories/Music.svg";
import jewelryIcon from "../images/categories/jewelry.svg";
import officeSp from "../images/categories/officeSp.svg";
import electronicIcon from "../images/categories/Electronics.svg";
import homeGarden from "../images/categories/HomeGarden.svg";
import videoGameIcon from "../images/categories/VideoGames.svg";
import babiesKidIcon from "../images/categories/babiesKids.svg";
import musicInsIcon from "../images/categories/musicalIns.svg";

export const v_id = "5035";

export const getBrandLogo = (brands: any[], brandId: string) => {
  const item: any = brands?.filter((i: any) => i?.mid === brandId);
  return item[0]?.merchantInfo?.logoUrl;
};

export const Categoriesimages = {
  23000000: MatureImg,
  4: ComputersSoftwareImg,
  31000000: PetSupImg,
  10000000: clothImg,
  12000000: sportSImg,
  14000000: toyImg,
  16000000: giftImg,
  18000000: applianceImg,
  20000000: otherImg,
  80: booksImg,
  22000000: autoMotiveImg,
  50: healthIcon,
  51: dvdIcon,
  52: musicIcon,
  30000000: jewelryIcon,
  24: officeSp,
  11000000: electronicIcon,
  13000000: homeGarden,
  15000000: videoGameIcon,
  17000000: babiesKidIcon,
  21000000: musicInsIcon,
};

export const topBrandsList = [
  {
    _id: "66e52f1a5b9533700e00e0c7",
    mid: "316730",
    merchantInfo: {
      name: "Ali Express",
      url: "https://link.sylikes.com/?publisherId=728088&url=https%3A%2F%2Fwww.aliexpress.com%2F&afCreativeId=3113",
      merchantUrl: "https://www.aliexpress.com/",
      logoUrl: "https://s6.cnnx.io/merchant/316730.gif",
      logoUrlSmall: "https://s6.cnnx.io/merchant/little/316730.gif",
    },
    merchantRating: {
      rating: {
        dimensionalAverages: {
          average: [
            {
              value: "0.0",
              dimension: "ffOverallRating",
            },
          ],
        },
      },
    },
    created_time: "2024-09-14T06:37:14.433Z",
    __v: 0,
  },
  {
    _id: "66e52f1a5b9533700e00e0d9",
    mid: "332368",
    merchantInfo: {
      name: "Temu",
      url: "https://link.sylikes.com/?publisherId=728088&url=https%3A%2F%2Fwww.temu.com&afCreativeId=3113",
      merchantUrl: "https://www.temu.com",
      logoUrl: "https://s9.cnnx.io/merchant/332368.gif",
      logoUrlSmall: "https://s9.cnnx.io/merchant/little/332368.gif",
    },
    merchantRating: {
      rating: {
        dimensionalAverages: {
          average: [
            {
              value: "0.0",
              dimension: "ffOverallRating",
            },
          ],
        },
      },
    },
    created_time: "2024-09-14T06:37:14.439Z",
    __v: 0,
  },

  {
    _id: "66e52f1a5b9533700e00e0bb",
    mid: "302351",
    merchantInfo: {
      name: "Poshmark",
      url: "https://link.sylikes.com/?publisherId=728088&url=https%3A%2F%2Fwww.poshmark.com&afCreativeId=3113",
      merchantUrl: "http://poshmark.com",
      logoUrl: "https://s7.cnnx.io/merchant/302351.gif",
      logoUrlSmall: "https://s7.cnnx.io/merchant/little/302351.gif",
    },
    merchantRating: {
      rating: {
        dimensionalAverages: {
          average: [
            {
              value: "0.0",
              dimension: "ffOverallRating",
            },
          ],
        },
      },
    },
    created_time: "2024-09-14T06:37:14.429Z",
    __v: 0,
  },

  {
    _id: "66e52f1a5b9533700e00e081",
    mid: "25533",
    merchantInfo: {
      name: "Lucky Brand",
      url: "https://link.sylikes.com/?publisherId=728088&url=https%3A%2F%2Fwww.luckybrand.com&afCreativeId=3113",
      merchantUrl: "http://www.luckybrand.com",
      logoUrl: "https://s9.cnnx.io/merchant/25533.gif",
      logoUrlSmall: "https://s9.cnnx.io/merchant/little/25533.gif",
    },
    merchantRating: {
      rating: {
        dimensionalAverages: {
          average: [
            {
              value: "0.0",
              dimension: "ffOverallRating",
            },
          ],
        },
      },
    },
    created_time: "2024-09-14T06:37:14.401Z",
    __v: 0,
  },
  {
    _id: "66e52f1a5b9533700e00e083",
    mid: "27754",
    merchantInfo: {
      name: "Skechers",
      url: "https://link.sylikes.com/?publisherId=728088&url=https%3A%2F%2Fwww.skechers.com%2F&afCreativeId=3113",
      merchantUrl: "https://www.skechers.com/",
      logoUrl: "https://s10.cnnx.io/merchant/27754.gif",
      logoUrlSmall: "https://s10.cnnx.io/merchant/little/27754.gif",
    },
    merchantRating: {
      rating: {
        dimensionalAverages: {
          average: [
            {
              value: "0.0",
              dimension: "ffOverallRating",
            },
          ],
        },
      },
    },
    created_time: "2024-09-14T06:37:14.404Z",
    __v: 0,
  },
  {
    _id: "66e52f1a5b9533700e00e0ca",
    mid: "317246",
    merchantInfo: {
      name: "Liquid I.V.",
      url: "https://link.sylikes.com/?publisherId=728088&url=https%3A%2F%2Fwww.liquid-iv.com%2F&afCreativeId=3113",
      merchantUrl: "https://liquid-iv.com/",
      logoUrl: "https://s7.cnnx.io/merchant/317246.gif",
      logoUrlSmall: "https://s7.cnnx.io/merchant/little/317246.gif",
    },
    merchantRating: {
      rating: {
        dimensionalAverages: {
          average: [
            {
              value: "0.0",
              dimension: "ffOverallRating",
            },
          ],
        },
      },
    },
    created_time: "2024-09-14T06:37:14.434Z",
    __v: 0,
  },
  {
    _id: "66e52f1a5b9533700e00e09e",
    mid: "164388",
    merchantInfo: {
      name: "Michael Kors",
      url: "https://link.sylikes.com/?publisherId=728088&url=https%3A%2F%2Fwww.michaelkors.com%2F&afCreativeId=3113",
      merchantUrl: "http://www.michaelkors.com/",
      logoUrl: "https://s9.cnnx.io/merchant/164388.gif",
      logoUrlSmall: "https://s9.cnnx.io/merchant/little/164388.gif",
    },
    merchantRating: {
      rating: {
        dimensionalAverages: {
          average: [
            {
              value: "0.0",
              dimension: "ffOverallRating",
            },
          ],
        },
      },
    },
    created_time: "2024-09-14T06:37:14.420Z",
    __v: 0,
  },
  {
    _id: "66e52f1a5b9533700e00e0c6",
    mid: "316282",
    merchantInfo: {
      name: "e.l.f. Cosmetics",
      url: "https://link.sylikes.com/?publisherId=728088&url=https%3A%2F%2Fwww.elfcosmetics.com%2F&afCreativeId=3113",
      merchantUrl: "https://www.elfcosmetics.com/",
      logoUrl: "https://s8.cnnx.io/merchant/316282.gif",
      logoUrlSmall: "https://s8.cnnx.io/merchant/little/316282.gif",
    },
    merchantRating: {
      rating: {
        dimensionalAverages: {
          average: [
            {
              value: "0.0",
              dimension: "ffOverallRating",
            },
          ],
        },
      },
    },
    created_time: "2024-09-14T06:37:14.433Z",
    __v: 0,
  },
  {
    _id: "66e52f1a5b9533700e00e0a3",
    mid: "199042",
    merchantInfo: {
      name: "Crocs - Official Site",
      url: "https://link.sylikes.com/?publisherId=728088&url=https%3A%2F%2Fwww.crocs.com&afCreativeId=3113",
      merchantUrl: "http://www.crocs.com",
      logoUrl: "https://s8.cnnx.io/merchant/199042.gif",
      logoUrlSmall: "https://s8.cnnx.io/merchant/little/199042.gif",
    },
    merchantRating: {
      rating: {
        dimensionalAverages: {
          average: [
            {
              value: "0.0",
              dimension: "ffOverallRating",
            },
          ],
        },
      },
    },
    created_time: "2024-09-14T06:37:14.422Z",
    __v: 0,
  },
  {
    _id: "66e52f1a5b9533700e00e0c4",
    mid: "315846",
    merchantInfo: {
      name: "Nautica",
      url: "https://link.sylikes.com/?publisherId=728088&url=https%3A%2F%2Fwww.nautica.com&afCreativeId=3113",
      merchantUrl: "https://www.nautica.com",
      logoUrl: "https://s7.cnnx.io/merchant/315846.gif",
      logoUrlSmall: "https://s7.cnnx.io/merchant/little/315846.gif",
    },
    merchantRating: {
      rating: {
        dimensionalAverages: {
          average: [
            {
              value: "0.0",
              dimension: "ffOverallRating",
            },
          ],
        },
      },
    },
    created_time: "2024-09-14T06:37:14.432Z",
    __v: 0,
  },
];

export const checkIsMobile = () => {
  return window.innerWidth <= 767;
};

export const siteName = window.location.hostname
  .replace("www.", "")
  ?.split(".")[0]
  ? window.location.hostname.replace("www.", "")?.split(".")[0]
  : "sunsetdeals";
