export const getTotalAmount = (cartItems) => {
  var totalAmount = 0;
  cartItems.map((itemCard, indexItem) => {
    var itemPrice = itemCard?.quantity * itemCard?.priceList[0]?.price;
    totalAmount = totalAmount + itemPrice;
  });
  return totalAmount;
};

export const getProductPrice = (product) => {
  console.log("product2", product);
  var totalAmount = "---";
  const userData = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
  if (userData) {
    console.log("userData", userData);
    product?.priceList.map((itemp) => {
      if (
        itemp?.city == userData?.data?.city &&
        itemp?.client == userData?.data?.companyName
      ) {
        totalAmount =
          itemp?.price + "(" + itemp?.discount + "%) / " + product?.priceType;
      }
    });
  }

  return totalAmount;
};

export const getAuthToken = () => {
  const dNAme = "user";
  //user
  const accessToken =
    localStorage.getItem(dNAme) &&
    JSON.parse(localStorage.getItem(dNAme)) &&
    JSON.parse(localStorage.getItem(dNAme))?.token
      ? JSON.parse(localStorage.getItem(dNAme)).token
      : undefined;
  return accessToken;
};
export const handle401Error = () => {
  localStorage.clear();
  // localStorage.setItem("redirectTo", rUrl);
  window.location.reload();
  // window.location.href = "/generic-user?authenticationFailed=1" + previewParam + templateIdParam;
};
export const getExtansionTokenPost = () => {
  const dNAme = "extensionUser";
  //user
  const accessToken =
    localStorage.getItem(dNAme) &&
    JSON.parse(localStorage.getItem(dNAme)) &&
    JSON.parse(localStorage.getItem(dNAme))?.token
      ? JSON.parse(localStorage.getItem(dNAme)).token
      : undefined;
  return accessToken;
  // return "12855|CpCE51PAwEAdzyufuTonvlSmlGYwz07CW4OA12bN";
};
