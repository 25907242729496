import { useEffect, useState } from "react";
import { OfferPageStyled } from "./OfferPage.styled";
import { useDispatch, useSelector } from "react-redux";
import { getAllImageApi } from "../../../../redux/slices/Admin/AdminService";

const OfferPagePreview = () => {
  const [offersList, setOffersList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    getOfferImageData();
  }, [])

  const getOfferImageData = async () => {
    // const res: any = await dispatch(getAllImageApi({ type: "Offer" }));
    // @ts-ignore
    const res: any = JSON.parse(localStorage?.getItem("localOfferList"));
    setOffersList(res);
  };
  console.log("Offer List", offersList);
  return (
    <OfferPageStyled>
      <div className="main_wrapper">
        {offersList
          ?.filter((i: any) => i?.imageUrl)
          ?.map((item: any, idx: number) => (
            <img key={`img_${idx}`} src={item?.imageUrl} alt="offer1" />
          ))}
        {/* <img src={offer1} alt="offer1" />
        <img src={offer2} alt="offer1" />
        <img src={offer3} alt="offer1" /> */}
      </div>
    </OfferPageStyled>
  );
};

export default OfferPagePreview;
