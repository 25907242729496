import { CookiesStyled } from "./Coolies.styled";
import arrow from "../../images/arrow-right-black.svg";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const Cookies = () => {
  const history = useHistory();
  const { themeDetails } = useSelector((state: any) => state?.admin);
  return (
    <CookiesStyled>
      <div
        id="main-content"
        className="mh-content"
        role="main"
        // itemprop="mainContentOfPage"
      >
        <div className="entry-content clearfix">
          <article
            id="page-738"
            className="post-738 page type-page status-publish"
          >
            <header className="page-header" onClick={() => history?.goBack()}>
              <img src={arrow} alt="arrow" />
              <h1 className="entry-title page-title">Cookie Policy</h1>{" "}
            </header>
            <div className="entry-content clearfix">
              <p>
                <em>Last updated: 12/08/2020</em>
              </p>
              <p>
                This Cookie Policy explains how we use cookies and similar
                technologies to recognize you when you visit our website. It
                explains what these technologies are and why we use them, as
                well as your rights to control our use of them.
              </p>
              <h3>
                <strong>
                  What are cookies and how are they used on our website?
                </strong>
              </h3>
              <p>
                Our website uses cookies, which are small text files (often
                including a unique identifier), that are sent to your browser
                from a website’s computers and stored on your computer’s hard
                drive or on your tablet or mobile device. We may place and
                access cookies and similar technologies on your computer, tablet
                or mobile device, which are known as first party cookies.
                Cookies may also be placed and accessed by some of our third
                party partners, which are known as third party cookies and are
                described below.
              </p>
              <p>
                Cookies that we use may help you to navigate the website, may be
                used to deliver content specific to your interests and to
                prevent you from having to re-enter your username and password
                each time you access the website. We may also use cookies to
                control the display of ads, to track usage patterns on the
                sites, to deliver editorial content, to record requests for
                subscriptions, to personalize information and to collect
                aggregate website usage information.
              </p>
              <h3>
                <strong>Types of cookies used on our website?</strong>
              </h3>
              <p>We use the following types of cookies:</p>
              <ul>
                <li>
                  <strong>Strictly necessary cookies:</strong>&nbsp;These are
                  cookies that are essential to enable you to move around the
                  website and to use its features. For example, cookies that
                  enable you to log in to our website.
                </li>
                <li>
                  <strong>Performance cookies:</strong>&nbsp;These cookies
                  collect information about how you and other visitors use our
                  website, for instance which pages are viewed most often, and
                  if you or other visitors receive error messages from certain
                  pages. These cookies don’t collect information that identifies
                  you. All information that these cookies collect is anonymous
                  and is used to improve how our website works.
                </li>
                <li>
                  <strong>Functionality Cookies:</strong>&nbsp;These are cookies
                  that allow a website to remember choices you make (such as
                  your username) and to provide content and features tailored to
                  your interests.
                </li>
                <li>
                  <strong>Advertising Cookies:</strong>&nbsp;These cookies are
                  used to deliver adverts to you which are more relevant to you
                  and your interests. They are also used to limit the number of
                  times you see an advertisement as well as measuring the
                  effectiveness of the advertising.
                </li>
                <li>
                  <strong>Analytics and customization Cookies:</strong>
                  &nbsp;These cookies collect information that is used either in
                  aggregate form to help us understand how our website is being
                  used, how effective our marketing campaigns are, or to help us
                  customize our website for you.
                </li>
                <li>
                  <strong>Social networking Cookies:</strong>&nbsp;These cookies
                  are used to enable you to share pages and content you find
                  interesting on our website through third party social
                  networking and other websites. These cookies may also be used
                  for advertising purposes too.
                </li>
              </ul>
              <p>
                The types of cookies described above may be “session cookies”
                which are temporary cookies that are only stored on your device
                while you are visiting our website, or “persistent cookies”
                which are cookies that are stored on your device for a period of
                time after you leave our website.
              </p>
              <p>
                <strong>Third Party Cookies:</strong>&nbsp;Some of our third
                party partners (including advertisers and marketing services
                companies) may set and access cookies and similar technologies
                on your computer as well, or we may do so on their behalf. These
                cookies may provide information to those third parties about
                your browsing habits and may help them to identify if certain
                services they provide are being used and to serve advertisements
                that are more relevant to you. We do not have control over how
                these third parties use such cookies and similar technologies or
                the information derived therefrom.
              </p>
              <p>
                <strong>Web Beacons:</strong>&nbsp;We may also use “web beacons”
                (also known as “clear GIFs” or “pixel tags”) or similar
                technologies, on our website and in our communications with you
                to enable us to know whether you have visited a web page or
                received a message. A web beacon is typically a one-pixel,
                transparent image (although it can be a visible image as well),
                located on a web page or in an e-mail or other type of message,
                which is retrieved from a remote site on the Internet enabling
                the verification of an individual’s viewing or receipt of a web
                page or message.
              </p>
              <p>
                A web beacon helps us to understand whether you came to our
                website from an online advert displayed on a third party
                website, measure successes of email marketing campaigns and
                improve our website performance. They may also enable us to
                relate your viewing or receipt of a web page or message to other
                information about you, including your personally identifiable
                information.
              </p>
              <p>
                In many instances, web beacons are reliant on cookies to
                function properly, and so declining cookies will impair their
                functioning.
              </p>
              <h3>
                <strong>How can I control cookies?</strong>
              </h3>
              <p>
                You can delete cookies by changing your privacy preferences in
                your Internet browser or by using the menu made available on the
                cookie banner. You also have the ability to accept or decline
                cookies. Most web browsers automatically accept cookies, but you
                can usually modify your browser setting to decline cookies if
                you prefer. If you choose to decline cookies, you may not be
                able to sign in or use other interactive features of our website
                that depend on cookies.
              </p>
              <p>
                Some of the network advertisers, ad agencies and other vendors
                we work with to serve ads on third party websites and across the
                internet, or analytics providers which provide us with
                information regarding use of the website and effectiveness of
                our ads may be members of the Network Advertising Initiatives,
                the Digital Advertising Alliance Self-Regulatory Program for
                Online Behavioral Advertising and/or the European Interactive
                Digital Advertising Alliance. To opt-out from receiving
                interest-based advertising from some or all of the participating
                companies, please visit&nbsp;
                <a href="http://www.aboutads.info/choices/" style={{color: themeDetails?.linkTextColor}}>
                  http://www.aboutads.info/choices/
                </a>{" "}
                and&nbsp;
                <a href="http://www.youronlinechoices.eu/" style={{color: themeDetails?.linkTextColor}}>
                  http://www.youronlinechoices.eu/
                </a>
                . To opt-out of participating in Google Analytics data follow
                the instructions:&nbsp;
                <a href="https://tools.google.com/dlpage/gaoptout" style={{color: themeDetails?.linkTextColor}}>
                  https://tools.google.com/dlpage/gaoptout
                </a>
                . Please note opting out through these mechanisms does not opt
                you out of being served advertising. You will still receive
                other types of online advertising from participating companies
                and any type of advertising from non-participating companies,
                and the web sites you visit may still collect information for
                other purposes.
              </p>
              <p>
                To find out more information about cookies, please visit
                <a href="http://www.allaboutcookies.org/" style={{color: themeDetails?.linkTextColor}}>
                  &nbsp;www.allaboutcookies.org
                </a>
                .
              </p>
              <h3>
                <strong>How often will we update this Cookie Policy?</strong>
              </h3>
              <p>
                We may update this Cookie Policy from time to time in order to
                reflect, for example, changes to the cookies we use or for other
                operational, legal or regulatory reasons. Please therefore
                re-visit this Cookie Policy regularly to stay informed about our
                use of cookies and related technologies.
              </p>
              <p>
                The date at the top of this Cookie Policy indicates when it was
                last updated.
              </p>
              <h3>
                <strong>Contact Us</strong>
              </h3>
              <p>
                If you have questions about this policy, or about the Website,
                please contact us at{" "}
                <a href="mailto:admin@sunsetdeals.com" style={{color: themeDetails?.linkTextColor}}>admin@sunsetdeals.com</a>
                .&nbsp;For more information about{" "}
                <a href="https://sunsetdeals.com/terms-of-use" style={{color: themeDetails?.linkTextColor}}>
                  Terms of Use
                </a>{" "}
                or{" "}
                <a href="https://sunsetdeals.com/your-privacy-rights/" style={{color: themeDetails?.linkTextColor}}>
                  Your Privacy Rights
                </a>
                , please click their respective links.
              </p>
            </div>
          </article>
          <p>
            <em>Last updated: 12/08/2020</em>
          </p>
          <p>
            This Cookie Policy explains how we use cookies and similar
            technologies to recognize you when you visit our website. It
            explains what these technologies are and why we use them, as well as
            your rights to control our use of them.
          </p>
          <h3>
            <strong>
              What are cookies and how are they used on our website?
            </strong>
          </h3>
          <p>
            Our website uses cookies, which are small text files (often
            including a unique identifier), that are sent to your browser from a
            website’s computers and stored on your computer’s hard drive or on
            your tablet or mobile device. We may place and access cookies and
            similar technologies on your computer, tablet or mobile device,
            which are known as first party cookies. Cookies may also be placed
            and accessed by some of our third party partners, which are known as
            third party cookies and are described below.
          </p>
          <p>
            Cookies that we use may help you to navigate the website, may be
            used to deliver content specific to your interests and to prevent
            you from having to re-enter your username and password each time you
            access the website. We may also use cookies to control the display
            of ads, to track usage patterns on the sites, to deliver editorial
            content, to record requests for subscriptions, to personalize
            information and to collect aggregate website usage information.
          </p>
          <h3>
            <strong>Types of cookies used on our website?</strong>
          </h3>
          <p>We use the following types of cookies:</p>
          <ul>
            <li>
              <strong>Strictly necessary cookies:</strong>&nbsp;These are
              cookies that are essential to enable you to move around the
              website and to use its features. For example, cookies that enable
              you to log in to our website.
            </li>
            <li>
              <strong>Performance cookies:</strong>&nbsp;These cookies collect
              information about how you and other visitors use our website, for
              instance which pages are viewed most often, and if you or other
              visitors receive error messages from certain pages. These cookies
              don’t collect information that identifies you. All information
              that these cookies collect is anonymous and is used to improve how
              our website works.
            </li>
            <li>
              <strong>Functionality Cookies:</strong>&nbsp;These are cookies
              that allow a website to remember choices you make (such as your
              username) and to provide content and features tailored to your
              interests.
            </li>
            <li>
              <strong>Advertising Cookies:</strong>&nbsp;These cookies are used
              to deliver adverts to you which are more relevant to you and your
              interests. They are also used to limit the number of times you see
              an advertisement as well as measuring the effectiveness of the
              advertising.
            </li>
            <li>
              <strong>Analytics and customization Cookies:</strong>&nbsp;These
              cookies collect information that is used either in aggregate form
              to help us understand how our website is being used, how effective
              our marketing campaigns are, or to help us customize our website
              for you.
            </li>
            <li>
              <strong>Social networking Cookies:</strong>&nbsp;These cookies are
              used to enable you to share pages and content you find interesting
              on our website through third party social networking and other
              websites. These cookies may also be used for advertising purposes
              too.
            </li>
          </ul>
          <p>
            The types of cookies described above may be “session cookies” which
            are temporary cookies that are only stored on your device while you
            are visiting our website, or “persistent cookies” which are cookies
            that are stored on your device for a period of time after you leave
            our website.
          </p>
          <p>
            <strong>Third Party Cookies:</strong>&nbsp;Some of our third party
            partners (including advertisers and marketing services companies)
            may set and access cookies and similar technologies on your computer
            as well, or we may do so on their behalf. These cookies may provide
            information to those third parties about your browsing habits and
            may help them to identify if certain services they provide are being
            used and to serve advertisements that are more relevant to you. We
            do not have control over how these third parties use such cookies
            and similar technologies or the information derived therefrom.
          </p>
          <p>
            <strong>Web Beacons:</strong>&nbsp;We may also use “web beacons”
            (also known as “clear GIFs” or “pixel tags”) or similar
            technologies, on our website and in our communications with you to
            enable us to know whether you have visited a web page or received a
            message. A web beacon is typically a one-pixel, transparent image
            (although it can be a visible image as well), located on a web page
            or in an e-mail or other type of message, which is retrieved from a
            remote site on the Internet enabling the verification of an
            individual’s viewing or receipt of a web page or message.
          </p>
          <p>
            A web beacon helps us to understand whether you came to our website
            from an online advert displayed on a third party website, measure
            successes of email marketing campaigns and improve our website
            performance. They may also enable us to relate your viewing or
            receipt of a web page or message to other information about you,
            including your personally identifiable information.
          </p>
          <p>
            In many instances, web beacons are reliant on cookies to function
            properly, and so declining cookies will impair their functioning.
          </p>
          <h3>
            <strong>How can I control cookies?</strong>
          </h3>
          <p>
            You can delete cookies by changing your privacy preferences in your
            Internet browser or by using the menu made available on the cookie
            banner. You also have the ability to accept or decline cookies. Most
            web browsers automatically accept cookies, but you can usually
            modify your browser setting to decline cookies if you prefer. If you
            choose to decline cookies, you may not be able to sign in or use
            other interactive features of our website that depend on cookies.
          </p>
          <p>
            Some of the network advertisers, ad agencies and other vendors we
            work with to serve ads on third party websites and across the
            internet, or analytics providers which provide us with information
            regarding use of the website and effectiveness of our ads may be
            members of the Network Advertising Initiatives, the Digital
            Advertising Alliance Self-Regulatory Program for Online Behavioral
            Advertising and/or the European Interactive Digital Advertising
            Alliance. To opt-out from receiving interest-based advertising from
            some or all of the participating companies, please visit&nbsp;
            <a href="http://www.aboutads.info/choices/" style={{color: themeDetails?.linkTextColor}}>
              http://www.aboutads.info/choices/
            </a>{" "}
            and&nbsp;
            <a href="http://www.youronlinechoices.eu/" style={{color: themeDetails?.linkTextColor}}>
              http://www.youronlinechoices.eu/
            </a>
            . To opt-out of participating in Google Analytics data follow the
            instructions:&nbsp;
            <a href="https://tools.google.com/dlpage/gaoptout" style={{color: themeDetails?.linkTextColor}}>
              https://tools.google.com/dlpage/gaoptout
            </a>
            . Please note opting out through these mechanisms does not opt you
            out of being served advertising. You will still receive other types
            of online advertising from participating companies and any type of
            advertising from non-participating companies, and the web sites you
            visit may still collect information for other purposes.
          </p>
          <p>
            To find out more information about cookies, please visit
            <a href="http://www.allaboutcookies.org/" style={{color: themeDetails?.linkTextColor}}>
              &nbsp;www.allaboutcookies.org
            </a>
            .
          </p>
          <h3>
            <strong>How often will we update this Cookie Policy?</strong>
          </h3>
          <p>
            We may update this Cookie Policy from time to time in order to
            reflect, for example, changes to the cookies we use or for other
            operational, legal or regulatory reasons. Please therefore re-visit
            this Cookie Policy regularly to stay informed about our use of
            cookies and related technologies.
          </p>
          <p>
            The date at the top of this Cookie Policy indicates when it was last
            updated.
          </p>
          <h3>
            <strong>Contact Us</strong>
          </h3>
          <p>
            If you have questions about this policy, or about the Website,
            please contact us at{" "}
            <a href="mailto:admin@sunsetdeals.com" style={{color: themeDetails?.linkTextColor}}>admin@sunsetdeals.com</a>
            .&nbsp;For more information about{" "}
            <a href="https://sunsetdeals.com/terms-of-use" style={{color: themeDetails?.linkTextColor}}>
              Terms of Use
            </a> or{" "}
            <a href="https://sunsetdeals.com/your-privacy-rights/" style={{color: themeDetails?.linkTextColor}}>
              Your Privacy Rights
            </a>
            , please click their respective links.
          </p>
        </div>{" "}
      </div>
    </CookiesStyled>
  );
};

export default Cookies;
